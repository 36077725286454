<template>
  <div class="sub-page">
    <div class="sub-page-left">
      <div class="sub-page-item">
        <div class="title">
          <div class="title-top"></div>
          <div class="title-bottom">
            <div class="title-text">
              <span class="span1" @click="openOldSee">需关注长者</span>
              <span class="span">09月20日 共0位</span>
            </div>
            <div class="hide"></div>
            <div class="hide hide1"></div>
            <div class="hide hide2"></div>
            <div class="hide hide3"></div>
          </div>
        </div>

        <div class="button">
          <el-button size="small" @click="openOldSee">查看更多</el-button>
        </div>

        <div class="scroll">
          <div class="scroll-box">
            <div class="scroll-list" v-for="(item, index) in 20" :key="index">
              <!-- <div class="scroll-list-hide"></div> -->
              <div class="scroll-cell">
                <div class="scroll-cell-text">
                  <span>某某小区进行了65位老人的推荐。</span>
                </div>
                <div class="scroll-cell-text date">
                  <span>时间：2022-06-08</span>
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>

      <div class="sub-page-item">
        <div class="title">
          <div class="title-top"></div>
          <div class="title-bottom">
            <div class="title-text">
              <span>需关注长者比例</span>
              <span class="span">09月20日-09月26日</span>
            </div>
            <div class="hide"></div>
            <div class="hide hide1"></div>
            <div class="hide hide2"></div>
            <div class="hide hide3"></div>
          </div>
        </div>
        <div class="chart" id="chart"></div>
      </div>

      <div class="sub-page-item sub-page-item1">
        <div class="title">
          <div class="title-top"></div>
          <div class="title-bottom">
            <div class="title-text" @click="openOldInfo">
              <span class="span1">长者基本信息</span>
            </div>
            <div class="hide"></div>
            <div class="hide hide1"></div>
            <div class="hide hide2"></div>
            <div class="hide hide3"></div>
          </div>
        </div>
        <div class="charts-box">
          <div class="chart-box" @click="openOldInfo">
            <span class="text">80</span>
            <span class="text">守护老年人数量</span>
          </div>
          <div class="chart-box1" id="chart1">
          </div>
        </div>

      </div>
    </div>

    <div class="sub-page-center">
      <div class="sub-page-item sub-page-item4">
        <div class="title">
          <div class="title-top"></div>
          <div class="title-bottom">
            <div class="title-text">
              <span>实时监测</span>
              <span class="span"></span>
            </div>
            <div class="hide"></div>
            <div class="hide hide1"></div>
            <div class="hide hide2"></div>
            <div class="hide hide3"></div>
          </div>
        </div>

        <div class="sub-page-box">
          <div class="sub-page-column">
            <div class="sub-page-column-item">
              <div class="sub-page-column-item1 sub-page-column-item2" id="chart3"></div>
            </div>
            <div class="sub-page-column-item">
              <div class="sub-page-column-item1 sub-page-column-item3" id="chart4"></div>
            </div>
            <div class="sub-page-column-item">
              <div class="sub-page-column-item1 sub-page-column-item2" id="chart5"></div>
            </div>
            <div class="sub-page-column-item">
              <div class="sub-page-column-item1 sub-page-column-item3" id="chart6"></div>
            </div>
          </div>
          <div class="sub-page-info">
            <el-carousel :interval="2000">
              <el-carousel-item v-for="item in 4" :key="item">
                <span>李明明</span>
                <span>男</span>
                <span>50岁</span>
                <span>3号楼301室-02床</span>
              </el-carousel-item>
            </el-carousel>

          </div>
        </div>
        <!-- <div class="sub-page-image"></div> -->
        <div class="sub-page-image-position">
          <img class="sub-page-image" src="../../../assets/img/zhdp/background2.png" alt="">
        </div>

      </div>

      <div class="sub-page-item sub-page-item5">
        <div class="flex-box">
          <div class="title">
            <div class="title-top"></div>
            <div class="title-bottom">
              <div class="title-text">
                <span>睡眠质量排行榜</span>
              </div>
              <div class="hide"></div>
              <div class="hide hide1"></div>
              <div class="hide hide2"></div>
              <div class="hide hide3"></div>
            </div>
          </div>
          <div class="title">
            <div class="title-top"></div>
            <div class="title-bottom">
              <div class="title-text">
                <span>跌倒风险排行榜</span>
              </div>
              <div class="hide"></div>
              <div class="hide hide1"></div>
              <div class="hide hide2"></div>
              <div class="hide hide3"></div>
            </div>
          </div>
          <div class="title">
            <div class="title-top"></div>
            <div class="title-bottom">
              <div class="title-text">
                <span>呼吸异常指数排行榜</span>
              </div>
              <div class="hide"></div>
              <div class="hide hide1"></div>
              <div class="hide hide2"></div>
              <div class="hide hide3"></div>
            </div>
          </div>
        </div>

        <div class="sub-page-map">
          <div class="sub-page-item-box">
            <div class="sub-page-item-head">
              <span>排名</span>
              <span>床位</span>
              <span>得分</span>
            </div>
            <div class="sub-page-item-scroll">
              <div class="sub-page-item-scroll-item" v-for="(item, index) in 20" :key="index">
                <span>1</span>
                <span>301-01</span>
                <span>80</span>
              </div>
            </div>
          </div>
          <div class="sub-page-item-box">
            <div class="sub-page-item-head">
              <span>排名</span>
              <span>床位</span>
              <span>得分</span>
            </div>
            <div class="sub-page-item-scroll">
              <div class="sub-page-item-scroll-item" v-for="(item, index) in 20" :key="index">
                <span>1</span>
                <span>301-01</span>
                <span>80</span>
              </div>
            </div>
          </div>
          <div class="sub-page-item-box">
            <div class="sub-page-item-head">
              <span>排名</span>
              <span>床位</span>
              <span>得分</span>
            </div>
            <div class="sub-page-item-scroll">
              <div class="sub-page-item-scroll-item" v-for="(item, index) in 20" :key="index">
                <span>1</span>
                <span>301-01</span>
                <span>80</span>
              </div>
            </div>
          </div>
        </div>
      </div>

    </div>

    <div class="sub-page-right">
      <div class="sub-page-item sub-page-item2">
        <div class="title">
          <div class="title-top"></div>
          <div class="title-bottom">
            <div class="title-text">
              <span>异常报警</span>
            </div>
            <div class="hide"></div>
            <div class="hide hide1"></div>
            <div class="hide hide2"></div>
            <div class="hide hide3"></div>
          </div>
        </div>
        <div class="echarts1" id="chart2">
          <div class="warn">
            <img src="../../../assets/img/zhdp/warn1.png" alt="">
          </div>
        </div>
      </div>
      <div class="sub-page-item sub-page-item2">
        <div class="title">
          <div class="title-top"></div>
          <div class="title-bottom">
            <div class="title-text">
              <span>设备运行状态</span>
            </div>
            <div class="hide"></div>
            <div class="hide hide1"></div>
            <div class="hide hide2"></div>
            <div class="hide hide3"></div>
          </div>
        </div>
        <div class="echarts1 echarts-box">
          <div class="echarts-box-item" @click="openEquipmentOperationStatus('报警')">
            <img src="../../../assets/img/zhdp/icon1.png" alt="" class="image">
            <span>0天</span>
            <span>已守护</span>
          </div>
          <div class="echarts-box-item" @click="openEquipmentOperationStatus('睡眠')">
            <img src="../../../assets/img/zhdp/icon2.png" alt="" class="image">
            <span>0天</span>
            <span>已守护</span>
          </div>
          <div class="echarts-box-item" @click="openEquipmentOperationStatus('跌倒')">
            <img src="../../../assets/img/zhdp/icon3.png" alt="" class="image">
            <span>0天</span>
            <span>已守护</span>
          </div>
        </div>
      </div>
      <div class="sub-page-item sub-page-item3">
        <div class="title">
          <div class="title-top"></div>
          <div class="title-bottom">
            <div class="title-text">
              <span>及时发现</span>
            </div>
            <div class="hide"></div>
            <div class="hide hide1"></div>
            <div class="hide hide2"></div>
            <div class="hide hide3"></div>
          </div>
        </div>
        <div class="labels">
          <div class="labels-item" @click="openFind('离床')">
            <img src="../../../assets/img/zhdp/labels1.png" alt="" class="image">
            <div class="info">
              <span>次</span>
              <span>离床</span>
            </div>
          </div>

          <div class="labels-item" @click="openFind('跌倒异常')">
            <img src="../../../assets/img/zhdp/labels2.png" alt="" class="image">
            <div class="info">
              <span>次</span>
              <span>跌倒异常</span>
            </div>
          </div>

          <div class="labels-item" @click="openFind('呼吸异常')">
            <img src="../../../assets/img/zhdp/labels3.png" alt="" class="image">
            <div class="info">
              <span>次</span>
              <span>呼吸异常</span>
            </div>
          </div>

          <div class="labels-item" @click="openFind('心动异常')">
            <img src="../../../assets/img/zhdp/labels4.png" alt="" class="image">
            <div class="info">
              <span>次</span>
              <span>心动异常</span>
            </div>
          </div>

          <div class="labels-item" @click="openFind('卫生间停留异常')">
            <img src="../../../assets/img/zhdp/labels5.png" alt="" class="image">
            <div class="info">
              <span>次</span>
              <span>卫生间停留异常</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 需关注长者 -->
    <OldSee v-if="showOldSee" @close="closeOldSee"></OldSee>
    <!-- 长者基础信息 守护老年人数量 -->
    <OldInfo v-if="showOldInfo" :titleName="titleName" @close="closeOldInfo"></OldInfo>
    <!-- 异常警报 -->
    <AbnormalAlarm v-if="showAbnormalAlarm" :errType="errType" @close="closeAbnormalAlarm"></AbnormalAlarm>
    <!-- 设备运行状态 -->
    <EquipmentOperationStatus v-if="showEquipmentOperationStatus" :statusName="statusName" @close="closeEquipmentOperationStatus"></EquipmentOperationStatus>
    <!-- 及时发现 -->
    <Find v-if="showFind" :findName="findName" @close="closeFind"></Find>
  </div>
</template>

<script>
import OldSee from './oldSee.vue'
import OldInfo from './oldInfo.vue'
import AbnormalAlarm from './abnormalAlarm.vue'
import EquipmentOperationStatus from './equipmentOperationStatus.vue'
import Find from './find.vue'
export default {
  components: {
    OldSee,
    OldInfo,
    AbnormalAlarm,
    EquipmentOperationStatus,
    Find
  },
  data () {
    return {
      myChart: null,
      myChart1: null,
      myChart2: null,
      myChart3: null,

      myChart4: null,
      myChart5: null,
      myChart6: null,
      myChart7: null,
      data: [
        { value: 300, name: '高危' },
        { value: 300, name: '低危' },
        { value: 300, name: '中危' }
      ],
      datas: ['60岁以下', '60-69岁', '70-79岁', '80-89岁', '90-99岁', '100岁及以上'],
      datas2: [120, 200, 150, 80, 70, 150, 80, 70],
      color: ['#FA6400', '#44D7B6', '#0091FF'],
      startValue: 0,
      endValue: 3,
      setInterval: null,
      setInterva1: null,
      setInterval2: null,
      startValue3: 0,
      endValue3: 2,
      setInterval3: null,
      startValue4: 0,
      endValue4: 3,
      setInterval4: null,
      startValue5: 0,
      endValue5: 2,
      setInterval5: null,
      startValue6: 0,
      endValue6: 3,
      setInterval6: null,
      showOldSee: false,
      showOldInfo: false,
      titleName: '',
      showAbnormalAlarm: false,
      errType: '',
      showEquipmentOperationStatus: false,
      statusName: '',
      showFind: false,
      findName: ''
    }
  },
  mounted () {
    this.$nextTick(() => {
      this.canvas()
      this.changeInfo1()
      this.canvas1()
      this.changeInfo2()
      this.canvas2()
      this.canvas3()
      this.canvas4()
      this.canvas5()
      this.canvas6()
    })
    window.addEventListener('resize', this.getresize)
  },
  beforeDestroy () {
    // 页面销毁时销毁监听事件
    window.removeEventListener('resize', this.getresize)
    clearInterval(this.setInterva)
    clearInterval(this.setInterva1)
    clearInterval(this.setInterva2)
    clearInterval(this.setInterva3)
    clearInterval(this.setInterva4)
    clearInterval(this.setInterva5)
    clearInterval(this.setInterva6)
  },
  methods: {
    getresize () {
      setTimeout(() => {
        this.myChart.resize()
        this.myChart1.resize()
        this.myChart2.resize()
        this.myChart3.resize()
        this.myChart4.resize()
        this.myChart5.resize()
        this.myChart6.resize()
      }, 500)
    },
    canvas () {
      var chartDom = document.getElementById('chart')
      this.myChart = this.$echarts.init(chartDom)
      const option = {
        color: ['#80FFA5', '#00DDFF', '#37A2FF', '#FF0087', '#FFBF00'],
        legend: {
          show: false
        },
        grid: {
          top: '10%',
          left: '3%',
          right: '10%',
          bottom: '1%',
          containLabel: true
        },
        label: {
          show: true
        },
        xAxis: {
          type: 'category',
          boundaryGap: false,
          data: ['60岁以下', '60-69岁', '70-79岁', '80-89岁', '90-99岁', '100岁及以上', '60岁以下', '60-69岁', '70-79岁', '80-89岁'],
          axisLabel: {
            show: true,
            color: '#fff',
            fontSize: 10,
            interval: 0
          },
          axisLine: {
            show: true,
            lineStyle: {
              color: '#fff'
            }
          },
          axisTick: {
            // y轴刻度线
            show: false
          }
        },
        yAxis: {
          type: 'value',
          axisLine: {
            show: true,
            lineStyle: {
              color: '#ffff'
            }
          },
          axisLabel: {
            show: true,
            color: '#fff',
            fontSize: 11
          },
          splitLine: {
            show: false,
            lineStyle: {
              color: '#fff'
            }
          }
        },
        series: [
          {
            type: 'line',

            itemStyle: {
              color: '#fff', // 折线点的颜色
              borderColor: 'rgba(0,0,0,0)', // 拐点边框颜色
              borderWidth: 2 // 拐点边框大小
            },
            label: {
              show: true,
              position: 'top',
              color: 'rgba(255, 255, 255, 1)'
            },
            areaStyle: {
              opacity: 0.8,
              color: new this.$echarts.graphic.LinearGradient(0, 0, 0, 1, [
                {
                  offset: 0,
                  color: 'rgba(229,135,58,0.4314)'
                },
                {
                  offset: 1,
                  color: 'rgba(1,17,59,0)'
                }
              ])
            },
            data: ['100', '150', '50', '200', '250', '100', '100', '150', '50', '200'],
            animation: true
          }
        ],
        dataZoom: [
          // 滑动条
          {
            show: false, // 是否显示滑动条，不影响使用
            startValue: this.startValue, // 从头开始。
            endValue: this.endValue // 一次性展示6个。
          }
        ]
      }

      option && this.myChart.setOption(option)
      this.update()
    },
    update () {
      this.setInterval = setInterval(() => {
        this.startValue++
        this.endValue++
        if (this.endValue > 10) {
          this.startValue = 0
          this.endValue = 3
        }
        this.myChart.setOption({
          dataZoom: [
            // 滑动条
            {
              show: false, // 是否显示滑动条，不影响使用
              startValue: this.startValue, // 从头开始。
              endValue: this.endValue // 一次性展示6个。

            }
          ]
        })
      }, 2000)
    },
    noUpdate () {
      clearInterval(this.setInterval)
    },
    changeInfo1 () {
      const scroll1 = document.querySelector('#chart1')
      scroll1.addEventListener('mouseenter', () => this.noUpdate1())
      scroll1.addEventListener('mouseleave', () => this.update1())
    },
    canvas1 () {
      var chartDom = document.getElementById('chart1')
      this.myChart1 = this.$echarts.init(chartDom)
      this.myChart1.on('click', res => {
        this.titleName = res.name
        this.showOldInfo = true
      })
      const option = {
        grid: {
          containLabel: true,
          top: '2%', // 一下数值可为百分比也可为具体像素值
          right: '14%',
          bottom: '0%',
          left: '8%'
        },
        label: {
          show: true,
          color: '#fff',
          fontSize: 12,
          position: 'right',
          height: 100,
          offset: [0, 0]
        },
        xAxis: [
          // x轴数据设置
          {
            type: 'value',
            splitLine: {
              show: false
            },
            axisLine: {
              lineStyle: {
                color: '#021B63'
              }
            }
          }
        ],
        yAxis: [
          {
            type: 'category',
            offset: 0,
            axisLine: {
              show: false,
              // 这是x轴文字颜色
              lineStyle: {
                color: '#fff'
              }
            },
            data: this.datas,
            axisTick: {
              show: false
            }
          }
        ],
        series: [
          {
            data: this.datas2,
            type: 'bar',
            barWidth: 12,
            label: {
              show: true
            },
            itemStyle: {
              color: params => {
                const colors = [
                  ['#FB7371', '#FF997C'],
                  ['#8D3510', '#FFA783'],
                  ['#651180', '#D099FF'],
                  ['#4B8D10', '#83FFC0'],
                  ['#8D104B', '#FF83D8'],
                  ['#7F610F', '#FFE899'],
                  ['#108D89', '#83FFE9'],
                  ['#108D89', '#83FFE9']
                ]
                const colorItem = colors[params.dataIndex]
                return new this.$echarts.graphic.LinearGradient(1, 0, 0, 0, [{
                  offset: 0,
                  color: colorItem[1]
                }, {
                  offset: 1,
                  color: colorItem[0]
                }])
              },
              borderRadius: [10, 10, 10, 10]
            },
            showBackground: false,
            backgroundStyle: {
              color: 'rgba(180, 180, 180, 0.2)'
            }
          }
        ]
      }

      option && this.myChart1.setOption(option)
      this.update1()
    },
    update1 () {
      this.setInterval1 = setInterval(() => {
        this.datas.unshift(this.datas.pop())
        this.datas2.unshift(this.datas2.pop())
        this.myChart1.setOption({
          yAxis: {
            data: this.datas
          },
          series: {
            data: this.datas2
          }
        })
      }, 2000)
    },
    noUpdate1 () {
      clearInterval(this.setInterval1)
    },
    changeInfo2 () {
      const scroll1 = document.querySelector('#chart2')
      scroll1.addEventListener('mouseenter', () => this.noUpdate2())
      scroll1.addEventListener('mouseleave', () => this.update2())
    },
    canvas2 () {
      var chartDom = document.getElementById('chart2')
      this.myChart2 = this.$echarts.init(chartDom)
      this.myChart2.on('click', res => {
        console.log(res)
        this.errType = res.name
        this.showAbnormalAlarm = true
      })
      const option = {
        color: this.color,
        title: {
          text: '总人数\n23459',
          left: 'center',
          top: 'center',
          textStyle: {
            fontSize: 12,
            color: '#FFFFFF'
          }
        },
        tooltip: {
          trigger: 'item'
        },
        legend: {
          show: false,
          y: 'bottom',
          x: 'left',
          textStyle: {
            fontSize: 12,
            color: '#FFFFFF'
          }
        },
        series: [
          {
            name: '高血压',
            type: 'pie',
            radius: ['30%', '40%'],
            data: this.data,
            label: {
              formatter: '{b}{c}人',
              fontSize: 13,
              color: '#FFFFFF'
            }
          }
        ]
      }

      option && this.myChart2.setOption(option)
      this.update2()
    },
    update2 () {
      this.setInterval2 = setInterval(() => {
        // const obj = JSON.parse(JSON.stringify(this.data[0]))
        // if (this.data.length > 4) {
        //   this.data.shift()
        // }
        // this.data.push(obj)

        this.data.unshift(this.data.pop())
        this.color.unshift(this.color.pop())
        this.myChart2.setOption({
          color: this.color,
          series: {
            data: this.data
          }
        })
      }, 2000)
    },
    noUpdate2 () {
      clearInterval(this.setInterval2)
    },
    canvas3 () {
      var chartDom = document.getElementById('chart3')
      this.myChart3 = this.$echarts.init(chartDom)
      const option = {
        color: ['#80FFA5', '#00DDFF', '#37A2FF', '#FF0087', '#FFBF00'],
        legend: {
          show: false
        },
        title: {
          text: '睡眠',
          left: 'center',
          textStyle: {
            color: '#fff',
            fontSize: 12
          }
        },
        grid: {
          top: '20%',
          left: '3%',
          right: '10%',
          bottom: '1%',
          containLabel: true
        },
        label: {
          show: true
        },
        xAxis: {
          type: 'category',
          boundaryGap: false,
          data: ['少于6小时', '6-7小时', '7-8小时', '多于8小时', '少于6小时', '6-7小时', '7-8小时', '多于8小时', '少于6小时', '6-7小时', '7-8小时'],
          axisLabel: {
            show: true,
            color: '#fff',
            fontSize: 10,
            interval: 0
          },
          axisLine: {
            show: true,
            lineStyle: {
              color: '#fff'
            }
          },
          axisTick: {
            // y轴刻度线
            show: false
          }
        },
        yAxis: {
          type: 'value',
          axisLine: {
            show: true,
            lineStyle: {
              color: '#ffff'
            }
          },
          axisLabel: {
            show: true,
            color: '#fff',
            fontSize: 10
          },
          splitLine: {
            show: false,
            lineStyle: {
              color: '#fff'
            }
          }
        },
        series: [
          {
            type: 'line',

            itemStyle: {
              fontSize: 10,
              color: '#fff', // 折线点的颜色
              borderColor: 'rgba(0,0,0,0)', // 拐点边框颜色
              borderWidth: 2 // 拐点边框大小
            },
            label: {
              show: true,
              position: 'top',
              color: 'rgba(255, 255, 255, 1)'
            },
            areaStyle: {
              opacity: 0.8,
              color: new this.$echarts.graphic.LinearGradient(0, 0, 0, 1, [
                {
                  offset: 0,
                  color: 'rgba(229,135,58,0.4314)'
                },
                {
                  offset: 1,
                  color: 'rgba(1,17,59,0)'
                }
              ])
            },
            data: [220, 302, 181, 234, 220, 302, 181, 234, 220, 302, 181]
          }
        ],
        dataZoom: [
          // 滑动条
          {
            show: false, // 是否显示滑动条，不影响使用
            startValue: this.startValue3, // 从头开始。
            endValue: this.endValue3 // 一次性展示6个。
          }
        ]
      }

      option && this.myChart3.setOption(option)
      this.update3()
    },
    update3 () {
      this.setInterval3 = setInterval(() => {
        this.startValue3++
        this.endValue3++
        if (this.endValue3 > 10) {
          this.startValue3 = 0
          this.endValue3 = 2
        }
        this.myChart3.setOption({
          dataZoom: [
            // 滑动条
            {
              show: false, // 是否显示滑动条，不影响使用
              startValue: this.startValue3, // 从头开始。
              endValue: this.endValue3 // 一次性展示6个。
            }
          ]
        })
      }, 2000)
    },
    canvas4 () {
      var chartDom = document.getElementById('chart4')
      this.myChart4 = this.$echarts.init(chartDom)
      const option = {
        title: {
          text: '跌倒',
          left: 'center',
          textStyle: {
            color: '#fff',
            fontSize: 12
          }
        },
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'shadow'
          }
        },
        legend: {
          show: false,
          orient: 'horizontal',
          // x: '80%',
          y: 'top',
          x: 'right',
          textStyle: {
            fontSize: 12,
            color: '#FFFFFF'
          },
          padding: [25, 34, 0, 0]
        },
        grid: {
          show: false,
          top: '30%', // 一下数值可为百分比也可为具体像素值
          right: '4%',
          bottom: '20%',
          left: '13%'
        },
        xAxis: {
          type: 'category',
          data: ['12:00', '14:00', '16:00', '18:00', '10:00', '8:00', '12:00', '14:00', '16:00', '18:00', '10:00', '8:00', '12:00', '14:00', '16:00', '18:00'],
          axisLabel: {
            show: true,
            color: '#fff',
            fontSize: 10,
            interval: 0
          },
          axisLine: {
            show: true,
            lineStyle: {
              color: ['#fff']
            },
            textStyle: {
              color: '#04B4D0',
              fontSize: 10
            }
          },
          axisTick: {
            // y轴刻度线
            show: false
          }
        },
        yAxis: {
          type: 'value',
          axisLine: {
            show: true,
            lineStyle: {
              color: '#447DC3'
            }
          },
          axisLabel: {
            show: true,
            color: '#fff',
            fontSize: 14
          },
          splitLine: {
            show: false,
            lineStyle: {
              color: '#447DC3'
            }
          }
        },
        series: [
          {
            name: '跌倒',
            data: [120, 200, 150, 80, 70, 120, 200, 150, 80, 70, 120, 200, 150, 80],
            type: 'bar',
            barWidth: 14,
            itemStyle: { // 左面
              color: params => {
                const colors = [
                  ['#FB7371', '#FF997C'],
                  ['#8D3510', '#FFA783'],
                  ['#651180', '#D099FF'],
                  ['#4B8D10', '#83FFC0'],
                  ['#8D104B', '#FF83D8'],
                  ['#7F610F', '#FFE899'],
                  ['#FB7371', '#FF997C'],
                  ['#8D3510', '#FFA783'],
                  ['#651180', '#D099FF'],
                  ['#4B8D10', '#83FFC0'],
                  ['#8D104B', '#FF83D8'],
                  ['#7F610F', '#FFE899'],
                  ['#FB7371', '#FF997C'],
                  ['#8D3510', '#FFA783'],
                  ['#651180', '#D099FF'],
                  ['#4B8D10', '#83FFC0']
                ]
                const colorItem = colors[params.dataIndex]
                return new this.$echarts.graphic.LinearGradient(0, 0, 0, 1, [{
                  offset: 0,
                  color: colorItem[1]
                }, {
                  offset: 1,
                  color: colorItem[0]
                }])
              }
            }
          }
        ],
        dataZoom: [
          // 滑动条
          {
            show: false, // 是否显示滑动条，不影响使用
            startValue: this.startValue4, // 从头开始。
            endValue: this.endValue4 // 一次性展示6个。
          }
        ]
      }

      option && this.myChart4.setOption(option)
      this.update4()
    },
    update4 () {
      this.setInterval4 = setInterval(() => {
        this.startValue4++
        this.endValue4++
        if (this.endValue4 > 13) {
          this.startValue4 = 0
          this.endValue4 = 3
        }
        this.myChart4.setOption({
          dataZoom: [
            // 滑动条
            {
              show: false, // 是否显示滑动条，不影响使用
              startValue: this.startValue4, // 从头开始。
              endValue: this.endValue4 // 一次性展示6个。
            }
          ]
        })
      }, 2000)
    },
    canvas5 () {
      var chartDom = document.getElementById('chart5')
      this.myChart5 = this.$echarts.init(chartDom)
      const option = {
        color: ['#80FFA5', '#00DDFF', '#37A2FF', '#FF0087', '#FFBF00'],
        title: {
          text: '心率',
          left: 'center',
          textStyle: {
            color: '#fff',
            fontSize: 12
          }
        },
        legend: {
          show: false
        },
        grid: {
          top: '20%',
          left: '3%',
          right: '10%',
          bottom: '1%',
          containLabel: true
        },
        label: {
          show: true
        },
        xAxis: {
          type: 'category',
          boundaryGap: false,
          data: ['少于6小时', '6-7小时', '7-8小时', '多于8小时', '少于6小时', '6-7小时', '7-8小时', '多于8小时', '少于6小时', '6-7小时', '7-8小时'],
          axisLabel: {
            show: true,
            color: '#fff',
            fontSize: 10,
            interval: 0
          },
          axisLine: {
            show: true,
            lineStyle: {
              color: '#fff'
            }
          },
          axisTick: {
            // y轴刻度线
            show: false
          }
        },
        yAxis: {
          type: 'value',
          axisLine: {
            show: true,
            lineStyle: {
              color: '#ffff'
            }
          },
          axisLabel: {
            show: true,
            color: '#fff',
            fontSize: 10
          },
          splitLine: {
            show: false,
            lineStyle: {
              color: '#fff'
            }
          }
        },
        series: [
          {
            type: 'line',

            itemStyle: {
              fontSize: 10,
              color: '#fff', // 折线点的颜色
              borderColor: 'rgba(0,0,0,0)', // 拐点边框颜色
              borderWidth: 2 // 拐点边框大小
            },
            label: {
              show: true,
              position: 'top',
              color: 'rgba(255, 255, 255, 1)'
            },
            areaStyle: {
              opacity: 0.8,
              color: new this.$echarts.graphic.LinearGradient(0, 0, 0, 1, [
                {
                  offset: 0,
                  color: 'rgba(229,135,58,0.4314)'
                },
                {
                  offset: 1,
                  color: 'rgba(1,17,59,0)'
                }
              ])
            },
            data: [220, 302, 181, 234, 220, 302, 181, 234, 220, 302, 181]
          }
        ],
        dataZoom: [
          // 滑动条
          {
            show: false, // 是否显示滑动条，不影响使用
            startValue: this.startValue5, // 从头开始。
            endValue: this.endValue5 // 一次性展示6个。
          }
        ]
      }

      option && this.myChart5.setOption(option)
      this.update5()
    },
    update5 () {
      this.setInterval5 = setInterval(() => {
        this.startValue5++
        this.endValue5++
        if (this.endValue5 > 10) {
          this.startValue5 = 0
          this.endValue5 = 2
        }
        this.myChart5.setOption({
          dataZoom: [
            // 滑动条
            {
              show: false, // 是否显示滑动条，不影响使用
              startValue: this.startValue5, // 从头开始。
              endValue: this.endValue5 // 一次性展示6个。
            }
          ]
        })
      }, 2000)
    },
    canvas6 () {
      var chartDom = document.getElementById('chart6')
      this.myChart6 = this.$echarts.init(chartDom)
      const option = {
        title: {
          text: '跌倒',
          left: 'center',
          textStyle: {
            color: '#fff',
            fontSize: 12
          }
        },
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'shadow'
          }
        },
        legend: {
          show: false,
          orient: 'horizontal',
          // x: '80%',
          y: 'top',
          x: 'right',
          textStyle: {
            fontSize: 12,
            color: '#FFFFFF'
          },
          padding: [25, 34, 0, 0]
        },
        grid: {
          show: false,
          top: '30%', // 一下数值可为百分比也可为具体像素值
          right: '4%',
          bottom: '20%',
          left: '13%'
        },
        xAxis: {
          type: 'category',
          data: ['12:00', '14:00', '16:00', '18:00', '10:00', '8:00', '12:00', '14:00', '16:00', '18:00', '10:00', '8:00', '12:00', '14:00', '16:00', '18:00'],
          axisLabel: {
            show: true,
            color: '#fff',
            fontSize: 10,
            interval: 0
          },
          axisLine: {
            show: true,
            lineStyle: {
              color: ['#fff']
            },
            textStyle: {
              color: '#04B4D0',
              fontSize: 10
            }
          },
          axisTick: {
            // y轴刻度线
            show: false
          }
        },
        yAxis: {
          type: 'value',
          axisLine: {
            show: true,
            lineStyle: {
              color: '#447DC3'
            }
          },
          axisLabel: {
            show: true,
            color: '#fff',
            fontSize: 10
          },
          splitLine: {
            show: false,
            lineStyle: {
              color: '#447DC3'
            }
          }
        },
        series: [
          {
            name: '跌倒',
            data: [120, 200, 150, 80, 70, 120, 200, 150, 80, 70, 120, 200, 150, 80],
            type: 'bar',
            barWidth: 14,
            itemStyle: { // 左面
              color: params => {
                const colors = [
                  ['#FB7371', '#FF997C'],
                  ['#8D3510', '#FFA783'],
                  ['#651180', '#D099FF'],
                  ['#4B8D10', '#83FFC0'],
                  ['#8D104B', '#FF83D8'],
                  ['#7F610F', '#FFE899'],
                  ['#FB7371', '#FF997C'],
                  ['#8D3510', '#FFA783'],
                  ['#651180', '#D099FF'],
                  ['#4B8D10', '#83FFC0'],
                  ['#8D104B', '#FF83D8'],
                  ['#7F610F', '#FFE899'],
                  ['#FB7371', '#FF997C'],
                  ['#8D3510', '#FFA783'],
                  ['#651180', '#D099FF'],
                  ['#4B8D10', '#83FFC0']
                ]
                const colorItem = colors[params.dataIndex]
                return new this.$echarts.graphic.LinearGradient(0, 0, 0, 1, [{
                  offset: 0,
                  color: colorItem[1]
                }, {
                  offset: 1,
                  color: colorItem[0]
                }])
              }
            }
          }
        ],
        dataZoom: [
          // 滑动条
          {
            show: false, // 是否显示滑动条，不影响使用
            startValue: this.startValue6, // 从头开始。
            endValue: this.endValue6 // 一次性展示6个。
          }
        ]
      }

      option && this.myChart6.setOption(option)
      this.update6()
    },
    update6 () {
      this.setInterval6 = setInterval(() => {
        this.startValue6++
        this.endValue6++
        if (this.endValue6 > 13) {
          this.startValue6 = 0
          this.endValue6 = 3
        }
        this.myChart6.setOption({
          dataZoom: [
            // 滑动条
            {
              show: false, // 是否显示滑动条，不影响使用
              startValue: this.startValue6, // 从头开始。
              endValue: this.endValue6 // 一次性展示6个。
            }
          ]
        })
      }, 2000)
    },
    back () {
      this.$router.go(-1)
    },
    openOldSee () {
      this.showOldSee = true
    },
    closeOldSee () {
      this.showOldSee = false
    },
    openOldInfo () {
      this.showOldInfo = true
    },
    closeOldInfo () {
      this.showOldInfo = false
    },
    openAbnormalAlarm () {
      this.showAbnormalAlarm = true
    },
    closeAbnormalAlarm () {
      this.showAbnormalAlarm = false
    },
    openEquipmentOperationStatus (name) {
      this.statusName = name
      this.showEquipmentOperationStatus = true
    },
    closeEquipmentOperationStatus () {
      this.showEquipmentOperationStatus = false
      this.statusName = ''
    },
    openFind (name) {
      this.findName = name
      this.showFind = true
    },
    closeFind () {
      this.showFind = false
    }
  }
}
</script>

<style lang="less" scoped>
.sub-page {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 100%;
  background-image: url('../../../assets/img/zhdp/background.png');
  background-size: 100% 100%;
  background-repeat: no-repeat;

  .sub-page-left {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 506px;
    height: 100%;
  }

  .sub-page-center {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: calc(100% - 1032px);
    height: 100%;

  }

  .sub-page-right {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 506px;
    height: 100%;
  }

  .sub-page-item {
    position: relative;
    overflow: hidden;
    width: 100%;
    height: 33%;
    padding: 10px;
    padding-right: 0;
    background-color: rgba(2, 27, 99, .6);
    box-sizing: border-box;

    .button {
      position: absolute;
      top: 16px;
      right: 0;
      height: 60px;
    }

    .title {
      height: 36px;

      .title-top {
        position: relative;
        width: 87px;
        height: 6px;
        background-color: #03BCD7;
        transform: skew(30deg);

        &::before {
          position: absolute;
          top: 0;
          left: -4px;
          content: '';
          width: 10px;
          height: 6px;
          background-color: #273c7c;
          transform: skew(-50deg);
        }
      }

      .title-bottom {
        position: relative;
        width: 400px;
        height: 30px;
        padding-left: 14px;
        transform: skew(30deg);
        background: linear-gradient(97deg, #0177CD 0%, #024073 100%);
        box-sizing: border-box;

        &::before {
          position: absolute;
          top: 0;
          left: -8px;
          content: '';
          width: 12px;
          height: 10px;
          background-color: #273c7c;
          transform: skew(-58deg);
        }

        .title-text {
          height: 30px;
          line-height: 30px;
          color: #fff;
          font-size: 18px;
          font-weight: bold;
          transform: skew(-30deg);

          .span {
            margin-left: 16px;
            font-size: 14px;
          }

          .span1 {
            cursor: pointer;
          }
        }

        .hide {
          position: absolute;
          top: 0;
          right: 34px;
          width: 14px;
          height: 30px;
          background-color: #021B63;
        }

        .hide1 {
          width: 6px;
          right: 24px;
        }

        .hide2 {
          width: 6px;
          right: 14px;
        }

        .hide3 {
          width: 6px;
          right: 4px;
        }
      }
    }

    .chart {
      width: 100%;
      height: calc(100% - 36px);
    }

    .charts-box {
      display: flex;
      width: 100%;
      height: calc(100% - 40px);
    }

    .chart-box {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-evenly;
      width: 176px;
      height: 176px;
      margin: 20px auto 0;
      background-color: #021B63;
      border: 30px solid #013E6E;
      border-radius: 50%;
      box-sizing: border-box;
      cursor: pointer;

      .text {
        width: 80px;
        text-align: center;
        color: #fff;
        font-size: 16px;
      }
    }

    .chart-box1 {
      width: calc(100% - 180px);
      height: 100%;
    }

    .el-button {
      color: #fff;
      border: none;
      background-color: #017CDB;
    }

    .scroll {
      overflow: hidden;
      width: calc(100% - 10px);
      height: calc(100% - 65px);
      margin-top: 20px;
      margin-left: 10px;
      padding-right: 10px;
      box-sizing: border-box;

      .scroll-box {
        display: flex;
        flex-direction: column;

        animation: move linear 10s infinite;
      }

      @keyframes move {
        0% {
          transform: translateY(0);
        }

        100% {
          transform: translateY(-520px);
        }
      }

      // &::-webkit-scrollbar-thumb {
      //   padding-left: 10px;
      //   background-color: #03BCD7;
      //   border-radius: 3px;
      // }

      .scroll-list {
        position: relative;
        width: 100%;
        height: 42px;
        margin-bottom: 10px;
        background: linear-gradient(180deg, #094273 0%, #125F9E 100%);
        border: 1px solid #017CDB;
        border-radius: 6px;
        box-sizing: border-box;

        .scroll-list-hide {
          position: absolute;
          top: -1px;
          left: 26px;
          width: calc(100% - 52px);
          height: 4px;
          background-color: #021B63;
          border: 1px solid #017CDB;
          border-top: none;
        }

        .scroll-cell {
          display: flex;
          align-items: center;
          justify-content: space-between;
          height: 38px;
          padding: 0 10px;
          box-sizing: border-box;

          .scroll-cell-text {
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            color: #01EEFD;
            font-size: 14px;
          }

          .date {
            font-size: 12px;
          }
        }
      }
    }

    .line {
      display: flex;
      justify-content: space-between;
      margin-top: 20px;
      padding-right: 20px;
      box-sizing: border-box;

      .line-item {
        display: flex;
        align-items: center;

        .line-img {
          width: 90px;
          height: 69px;
        }

        .line-info {
          display: flex;
          flex-direction: column;
          justify-content: center;
          margin-left: 7px;

          .line-info-text {
            display: flex;
            align-items: center;
            color: #828C99;
            font-size: 16px;

            .green {
              margin-left: 10px;
              margin-bottom: 2px;
              color: #44D7B6;
            }
          }

          .line-info-total {
            color: #00F2F8;
            font-size: 36px;
          }
        }
      }
    }

    .label {
      display: flex;
      align-items: center;
      width: 100%;
      height: 33px;
      margin-top: 14px;
      margin-bottom: 23px;
      padding-left: 24px;
      box-sizing: border-box;

      .label-item {
        overflow: hidden;
        position: relative;
        width: 127px;
        height: 33px;
        line-height: 33px;
        padding: 0 20px;
        text-align: center;
        background-color: #036C79;
        transform: skew(30deg);
        color: #fff;
        font-size: 18px;
        font-weight: bold;

        &::before {
          position: absolute;
          top: 0;
          left: -20px;
          content: '';
          width: 30px;
          height: 16px;
          background-color: #021B63;
          transform: skew(-50deg);
        }

        &::after {
          position: absolute;
          bottom: 0;
          right: -20px;
          content: '';
          width: 30px;
          height: 16px;
          background-color: #021B63;
          transform: skew(-50deg);
        }

        &:first-child {
          margin-right: 20px;
        }

        .span {
          transform: skew(-30deg);
        }
      }
    }

    .echarts {
      width: calc(100% - 10px);
      height: 246px;
    }
  }

  .sub-page-item1 {
    width: 100%;
    // height: calc(100% - 596px);
    height: 33%;
  }

  .sub-page-item2 {
    width: 100%;
    // height: 220px;
    height: 33%;

    .echarts1 {
      width: 100%;
      height: 100%;

      .warn {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 114px;
        height: 114px;
        margin: 20px auto 0;
        border-radius: 50%;
        border: 20px #FF0000 solid;
        background-color: #02195E;
        box-sizing: border-box;

        .img {
          width: 31px;
          height: 28px;
        }
      }
    }

    .echarts-box {
      display: flex;
      align-items: center;

      .echarts-box-item {
        flex: 1;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        height: 100%;
        color: #fff;
        font-size: 16px;
        cursor: pointer;

        .image {
          width: 50px;
          height: 79px;
          margin-bottom: 10px;
        }
      }
    }
  }

  .sub-page-item3 {
    width: 100%;
    // height: calc(100% - 480px);
    height: 33%;

    .charts {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      position: relative;
      width: 100%;
      height: calc(100% - 50px);
      margin-top: 10px;

      .charts-title {
        position: absolute;
        left: 0;
        top: 0;
        color: #fff;
        font-size: 14px;
      }

      .charts-title1 {
        left: 50%;
      }

      .charts-title2 {
        top: 52%;
      }

      .charts-item {
        width: 50%;
        height: 50%;
      }

      .charts-item1 {
        width: 100%;
        height: 50%;
      }
    }

    .labels {
      display: flex;
      justify-content: space-between;
      width: 100%;
      height: calc(100% - 50px);
      margin-top: 10px;

      .labels-item {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        width: 90px;
        height: calc(100% - 10px);
        padding: 30px 0;
        background: linear-gradient(30deg, rgba(16, 61, 125, 0.051) 0%, rgba(0, 97, 237, 0.7216) 100%);
        border-radius: 6px;
        border: 2px solid #18CDFD;
        box-sizing: border-box;
        cursor: pointer;

        .image {
          width: 50px;
          height: 79px;
        }

        .info {
          display: flex;
          flex-direction: column;
          align-items: center;
          width: 70px;
          color: #fff;
          font-size: 14px;
        }
      }
    }
  }

  .sub-page-item4 {
    position: relative;
    width: 100%;
    height: 50%;

    .sub-page-image-position {
      position: absolute;
      left: 50%;
      top: 50%;
      z-index: 1;
      transform: translate(-50%, -50%);
      width: 424px;
      height: 424px;
    }

    .sub-page-image {
      width: 424px;
      height: 424px;

      // background-image: url('../../../assets/img/zhdp/background2.png');
      // background-size: 100% 100%;
      // background-repeat: no-repeat;
      // border-radius: 50%;
      // transform: rotate(50deg);
      // transform-style: preserve-3d; /* 保留3D效果 */
      animation: rotate 10s infinite linear;
      /* 设置旋转动画 */
    }

    @keyframes rotate {
      from {
        transform: rotate(0deg);
        /* 从0度开始旋转 */
      }

      to {
        transform: rotate(360deg);
        /* 旋转到360度 */
      }
    }

    .sub-page-box {
      position: absolute;
      left: 0;
      z-index: 88;
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 100%;
      height: calc(100% - 60px);
      margin-top: 20px;
      padding: 0 37px;
      box-sizing: border-box;

      .sub-page-column {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        width: 100%;
        height: calc(100% - 60px);

        .sub-page-column-item {
          width: calc(50% - 20px);
          height: calc(50% - 10px);
          transform-style: preserve-3d;
          perspective: 1600px;

          .sub-page-column-item1 {
            width: 100%;
            height: 100%;
            // transform: rotateY(20deg);
            // background-image: url('../../../assets/img/zhdp/background1.png');
            background: linear-gradient(30deg, rgba(16, 61, 125, 0.051) 0%, rgba(0, 97, 237, 0.7216) 100%);
            background-size: 100% 100%;
            background-repeat: no-repeat;
            // transform: rotateY(36deg) translateZ(650px);
          }

          .sub-page-column-text {
            display: flex;
            flex-direction: column;
            justify-content: center;
            width: calc(100% - 82px);
            height: 63px;
            color: #4AEAE1;
            font-size: 16px;

            span {
              width: 100%;
              white-space: nowrap;
            }

            .span {
              text-align: right;
            }
          }

          .sub-page-column-item2 {
            transform: rotateY(45deg);
          }

          .sub-page-column-item3 {
            transform: rotateY(-45deg);
          }
        }

        .background1 {
          background-image: url('../../../assets/img/zhdp/overview1.png');
          background-size: 100% 100%;
          background-repeat: no-repeat;
        }

        .background2 {
          background-image: url('../../../assets/img/zhdp/overview2.png');
          background-size: 100% 100%;
          background-repeat: no-repeat;
        }

        .background3 {
          background-image: url('../../../assets/img/zhdp/overview3.png');
          background-size: 100% 100%;
          background-repeat: no-repeat;
        }

        .background4 {
          background-image: url('../../../assets/img/zhdp/overview4.png');
          background-size: 100% 100%;
          background-repeat: no-repeat;
        }

        .background5 {
          display: flex;
          flex-direction: row-reverse;
          padding-right: 82px;
          padding-left: 0;
          background-image: url('../../../assets/img/zhdp/overview5.png');
          background-size: 100% 100%;
          background-repeat: no-repeat;
        }

        .background6 {
          background-image: url('../../../assets/img/zhdp/overview6.png');
          background-size: 100% 100%;
          background-repeat: no-repeat;
        }

        .background7 {
          background-image: url('../../../assets/img/zhdp/overview7.png');
          background-size: 100% 100%;
          background-repeat: no-repeat;
        }

        .background8 {
          background-image: url('../../../assets/img/zhdp/overview8.png');
          background-size: 100% 100%;
          background-repeat: no-repeat;
        }
      }

      .sub-page-info {

        width: 300px;
        height: 42px;
        padding: 0 17px;
        border: 1px solid #017CDB;
        border-radius: 6px;
        background: linear-gradient(180deg, #094273 0%, #125F9E 100%);
        box-sizing: border-box;
        color: #fff;
        font-size: 16px;

        .el-carousel {
          overflow: hidden;
          width: 100%;
          height: 100%
        }

        /deep/.el-carousel__item {
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: space-between;
          width: 100%;
          height: 42px;
        }

        /deep/.el-carousel__indicator {
          display: none;
        }
      }

      .overview-icon {
        width: 30px;
        height: 298px;
        margin: 0 26px;
      }

      .sub-page-charts {
        width: 160px;
        height: 100%;
        margin-left: 40px;

        .sub-page-charts-box {
          display: flex;
          align-items: center;
          width: 100%;
          height: 25%;
          margin-top: -20px;
          margin-bottom: 20px;

          .sub-page-charts-item {
            width: 90px;
            height: 100%;
          }

          .sub-page-charts-title {
            margin-left: 10px;
            color: #4AEAE1;
            font-size: 14px;
            white-space: nowrap;
          }
        }
      }
    }
  }

  .sub-page-item5 {
    position: relative;
    height: 49%;
    padding-right: 10px;

    .flex-box {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      width: 100%;
    }

    .title {
      width: 30%;

      .title-bottom {
        width: 95%;
      }
    }

    .sub-page-map {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      height: calc(100% - 40px);
      margin-top: 10px;

      .sub-page-item-box {
        width: 30%;
        height: 100%;
        padding: 0 12px;
        border: 1px solid #18CDFD;
        background: linear-gradient(60deg, rgba(16, 61, 125, 0.051) 0%, rgba(0, 97, 237, 0.7216) 100%);
        border-radius: 6px;
        box-sizing: border-box;

        .sub-page-item-head {
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: space-between;
          width: 100%;
          height: 35px;
          border-bottom: 1px solid #FFFFFF;
          box-sizing: border-box;

          span {
            flex: 1;
            color: #fff;
            font-size: 16px;
            text-align: center;
          }
        }

        .sub-page-item-scroll {
          overflow-y: scroll;
          width: 100%;
          height: calc(100% - 35px);

          &::-webkit-scrollbar {
            width: 0;
          }

          .sub-page-item-scroll-item {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;
            width: 100%;
            height: 35px;

            span {
              flex: 1;
              color: #fff;
              font-size: 16px;
              text-align: center;
            }
          }
        }
      }
    }
  }

}
</style>
