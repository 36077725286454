<template>
  <div class="sub">
    <div class="sub-left">
      <div class="sub-left-item">
        <div class="sub-title">
          <span>人口情况</span>
        </div>
        <div class="sub-tab">
          <div class="sub-tab-item" :class="{ 'sub-tab-active': tabIndex1 === 0 }" @click="tabClick1(0)">
            <span>常规人群</span>
          </div>
          <div class="sub-tab-item" :class="{ 'sub-tab-active': tabIndex1 === 1 }" @click="tabClick1(1)">
            <span>特殊人群</span>
          </div>
        </div>
        <div class="sub-box">
          <div class="sub-box-item">
            <img src="../../../assets/img/zkimg1.png" class="sub-box-img">
            <div class="sub-box-info">
              <span class="yellow">78，364</span>
              <span>出生人数/人</span>
            </div>
          </div>
          <div class="sub-box-item">
            <img src="../../../assets/img/zkimg2.png" class="sub-box-img">
            <div class="sub-box-info">
              <span class="yellow">78，364</span>
              <span>死亡人数/人</span>
            </div>
          </div>
          <div class="sub-box-item">
            <img src="../../../assets/img/zkimg3.png" class="sub-box-img">
            <div class="sub-box-info">
              <span class="yellow">78，364</span>
              <span>出生率</span>
            </div>
          </div>
          <div class="sub-box-item">
            <img src="../../../assets/img/zkimg4.png" class="sub-box-img">
            <div class="sub-box-info">
              <span class="yellow">78，364</span>
              <span>85岁以上老人占比</span>
            </div>
          </div>
        </div>
      </div>
      <div class="sub-left-item">
        <div class="sub-title">
          <span>医疗卫生机构</span>
        </div>
        <div class="sub-tab">
          <div class="sub-tab-item" :class="{ 'sub-tab-active': tabIndex2 === 0 }" @click="tabClick2(0)">
            <span>医疗机构</span>
          </div>
          <div class="sub-tab-item" :class="{ 'sub-tab-active': tabIndex2 === 1 }" @click="tabClick2(1)">
            <span>医养机构</span>
          </div>
        </div>
        <div class="sub-box">
          <div class="sub-box-item">
            <img src="../../../assets/img/zkimg5.png" class="sub-box-img">
            <div class="sub-box-info">
              <span class="yellow">78，364</span>
              <span>医疗机构总数/家</span>
            </div>
          </div>
          <div class="sub-box-item">
            <img src="../../../assets/img/zkimg5.png" class="sub-box-img">
            <div class="sub-box-info">
              <span class="yellow">78，364</span>
              <span>公立医院总数/家</span>
            </div>
          </div>
          <div class="sub-box-item">
            <img src="../../../assets/img/zkimg5.png" class="sub-box-img">
            <div class="sub-box-info">
              <span class="yellow">78，364</span>
              <span>基层医疗机构/家</span>
            </div>
          </div>
          <div class="sub-box-item">
            <img src="../../../assets/img/zkimg5.png" class="sub-box-img">
            <div class="sub-box-info">
              <span class="yellow">78，364</span>
              <span>村卫生室/家</span>
            </div>
          </div>
        </div>
      </div>
      <div class="sub-left-item1">
        <div class="sub-title">
          <span>医疗资源</span>
        </div>
        <div class="sub-tab">
          <div class="sub-tab-item" :class="{ 'sub-tab-active': tabIndex3 === 0 }" @click="tabClick3(0)">
            <span>床位</span>
          </div>
          <div class="sub-tab-item" :class="{ 'sub-tab-active': tabIndex3 === 1 }" @click="tabClick3(1)">
            <span>人数</span>
          </div>
          <div class="sub-tab-item" :class="{ 'sub-tab-active': tabIndex3 === 2 }" @click="tabClick3(2)">
            <span>设备</span>
          </div>
        </div>
        <div class="sub-tip">
          <span>今日D5A手术达到25例次，创历史新高！</span>
        </div>
        <div class="sub-box">
          <div class="sub-box-item">
            <img src="../../../assets/img/zkimg5.png" class="sub-box-img">
            <div class="sub-box-info">
              <span>DSA:2台 日均7.6人次</span>
            </div>
          </div>
          <div class="sub-box-item">
            <img src="../../../assets/img/zkimg5.png" class="sub-box-img">
            <div class="sub-box-info">
              <span>B超:5台 日均7.6人次</span>
            </div>
          </div>
          <div class="sub-box-item">
            <img src="../../../assets/img/zkimg5.png" class="sub-box-img">
            <div class="sub-box-info">
              <span>磁共振:5台 日均7.6人次</span>
            </div>
          </div>
          <div class="sub-box-item">
            <img src="../../../assets/img/zkimg5.png" class="sub-box-img">
            <div class="sub-box-info">
              <span>B超:5台 日均7.6人次</span>
            </div>
          </div>
          <div class="sub-box-item">
            <img src="../../../assets/img/zkimg5.png" class="sub-box-img">
            <div class="sub-box-info">
              <span>救护车:5台 日均7.6人次</span>
            </div>
          </div>
          <div class="sub-box-item">
            <img src="../../../assets/img/zkimg5.png" class="sub-box-img">
            <div class="sub-box-info">
              <span>AED:5台 日均7.6人次</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="sub-center">
      <div class="sub-map" id="map"></div>
    </div>
    <div class="sub-right">
      <div class="sub-left-item">
        <div class="sub-title">
          <span>医疗健康服务</span>
        </div>
        <div class="sub-tab">
          <div class="sub-tab-item" :class="{ 'sub-tab-active': tabIndex4 === 0 }" @click="tabClick4(0)">
            <span>诊疗服务</span>
          </div>
          <div class="sub-tab-item" :class="{ 'sub-tab-active': tabIndex4 === 1 }" @click="tabClick4(1)">
            <span>公共卫生</span>
          </div>
        </div>
        <div class="sub-tip">
          <span>今日入院12人，出院8人，当前在院患者占床位75.8%</span>
        </div>
        <div class="sub-box">
          <div class="sub-box-item">
            <img src="../../../assets/img/zkimg6.png" class="sub-box-img">
            <div class="sub-box-info">
              <span class="yellow">78，364</span>
              <span>总诊疗人次</span>
            </div>
          </div>
          <div class="sub-box-item">
            <img src="../../../assets/img/zkimg5.png" class="sub-box-img">
            <div class="sub-box-info">
              <span class="yellow">78，364</span>
              <span>出院人次</span>
            </div>
          </div>
          <div class="sub-box-item">
            <img src="../../../assets/img/zkimg3.png" class="sub-box-img">
            <div class="sub-box-info">
              <span class="yellow">78，364</span>
              <span>医师日均诊疗次数</span>
            </div>
          </div>
          <div class="sub-box-item">
            <img src="../../../assets/img/zkimg4.png" class="sub-box-img">
            <div class="sub-box-info">
              <span class="yellow">78，364</span>
              <span>医师日均住院次数</span>
            </div>
          </div>
        </div>
      </div>
      <div class="sub-left-item">
        <div class="sub-title">
          <span>疾病图谱</span>
        </div>
        <div class="sub-content">
          <div class="sub-content-tab">
            <div class="sub-content-tab-sort">
              <div class="sub-content-tab-item">
                <span>甲乙类</span>
              </div>
              <div class="sub-content-tab-item">
                <span>丙类</span>
              </div>
            </div>
            <div class="sub-content-tab-sort">
              <div class="sub-content-tab-item" :class="{ 'sub-content-tab-active': tabIndex5 === 0 }" @click="tabClick5(0)">
                <span>传染病</span>
              </div>
              <div class="sub-content-tab-item" :class="{ 'sub-content-tab-active': tabIndex5 === 1 }" @click="tabClick5(1)">
                <span>非传染病</span>
              </div>
              <div class="sub-content-tab-item" :class="{ 'sub-content-tab-active': tabIndex5 === 2 }" @click="tabClick5(2)">
                <span>癌症</span>
              </div>
            </div>
          </div>
          <div class="sub-content-box">
            <div class="sub-content-chart" id="echarts1"></div>
            <div class="sub-content-table">
              <div class="thead">
                <span class="thead-item">序号</span>
                <span class="thead-item">疾病名称</span>
                <span class="thead-item">发病人次</span>
              </div>
              <div class="tscroll">
                <div class="list" v-for="(item, index) in 10" :key="index">
                  <span>01</span>
                  <span>肺结核</span>
                  <span>1893</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="sub-left-item">
        <div class="sub-title">
          <span>疾病图谱</span>
        </div>
        <div class="sub-content">
          <div class="sub-content-tab">
            <div class="sub-content-tab-sort sub-content-tab-sort1">
              <div class="sub-content-tab-item">
                <span>甲乙类</span>
              </div>
              <div class="sub-content-tab-item">
                <span>丙类</span>
              </div>
            </div>
            <div class="sub-content-tab-sort">
              <div class="sub-content-tab-item" :class="{ 'sub-content-tab-active': tabIndex6 === 0 }" @click="tabClick6(0)">
                <span>卫生费用</span>
              </div>
              <div class="sub-content-tab-item" :class="{ 'sub-content-tab-active': tabIndex6 === 1 }" @click="tabClick6(1)">
                <span>均次费用</span>
              </div>
              <div class="sub-content-tab-item" :class="{ 'sub-content-tab-active': tabIndex6 === 2 }" @click="tabClick6(2)">
                <span>医疗收入</span>
              </div>
            </div>
          </div>
          <div class="sub-content-box">
            <div class="sub-content-chart" id="echarts2"></div>
            <div class="sub-content-status">
              <div class="status-title">
                <span>医疗总收入：25894万元</span>
              </div>
              <div class="status-scroll">
                <div class="status-scroll-item">
                  <div class="status-scroll-sort">
                    <div class="status-scroll-top">
                      <span class="block"></span>
                      <span>药品收入/万元</span>
                    </div>
                    <div class="status-scroll-bottom">
                      <span>32% 9732</span>
                    </div>
                  </div>
                  <div class="status-scroll-sort">
                    <div class="status-scroll-top">
                      <span class="block"></span>
                      <span>检测收入/万元</span>
                    </div>
                    <div class="status-scroll-bottom">
                      <span>32% 9732</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- <div class="sub-position">
      <div class="sub-position-line">
        <span>李明明</span>
        <span>男</span>
        <span>86岁</span>
        <span>15803335684</span>
      </div>
      <div class="sub-position-line">
        <span>时间  2022-03-03  18:56:6</span>
      </div>
      <div class="sub-position-label">
        <span>安全防护报警</span>
      </div>
      <div class="sub-position-line sub-position-line1">
        <span>位置：石家庄 桥西区 友谊街道238号 </span>
      </div>
    </div> -->
  </div>
</template>

<script>
import { mapState } from 'vuex'
import geoJson from './dlzz.json'
import bdrcx from '@/assets/json/bdrcx.json'
export default {
  data () {
    return {
      tabIndex1: 0,
      tabIndex2: 0,
      tabIndex3: 0,
      tabIndex4: 0,
      tabIndex5: 0,
      tabIndex6: 0,
      myChart1: null,
      myChart2: null,
      myChart3: null,
      list: [
        { name: '东里庄村村民委员会', value: 20057.34, itemStyle: { color: '#eb8146' } }
      ]
    }
  },
  computed: {
    ...mapState(['loginInfo'])
  },
  mounted () {
    this.$nextTick(() => {
      this.canvas1()
      this.canvas2()
      this.canvas3()
    })
    window.addEventListener('resize', this.getresize)
  },
  beforeDestroy () {
    // 页面销毁时销毁监听事件
    window.removeEventListener('resize', this.getresize)
  },
  methods: {
    tabClick1 (index) {
      this.tabIndex1 = index
    },
    tabClick2 (index) {
      this.tabIndex2 = index
    },
    tabClick3 (index) {
      this.tabIndex3 = index
    },
    tabClick4 (index) {
      this.tabIndex4 = index
    },
    tabClick5 (index) {
      this.tabIndex5 = index
    },
    tabClick6 (index) {
      this.tabIndex6 = index
    },
    async getData () {
      const { data: res } = await this.$http.get('https://echarts.apache.org/examples/data/asset/geo/HK.json')
      console.log('res', res)
    },
    getresize () {
      setTimeout(() => {
        this.myChart1.resize()
        this.myChart2.resize()
        this.myChart3.resize()
      }, 500)
    },
    canvas1 () {
      var chartDom = document.getElementById('echarts1')
      this.myChart1 = this.$echarts.init(chartDom)
      const option = {
        color: ['#EE6666', '#FAC858'],
        tooltip: {
          trigger: 'item',
          formatter: '{b}: {d}%'
        },
        legend: {
          show: false
        },
        grid: {
          show: false,
          top: '10%', // 一下数值可为百分比也可为具体像素值
          right: '10%',
          bottom: '10%',
          left: '20%'
        },
        series: [
          {
            name: '评估长者',
            type: 'pie',
            radius: '60%',
            data: [
              { value: 35, name: '甲乙类' },
              { value: 65, name: '丙类' }
            ],
            center: ['30%', '60%'],
            label: { // 删除指示线
              show: false
            }
          }
        ]
      }

      option && this.myChart1.setOption(option)
    },
    canvas2 () {
      var chartDom = document.getElementById('echarts2')
      this.myChart2 = this.$echarts.init(chartDom)
      const option = {
        color: ['#EE6666', '#FAC858'],
        tooltip: {
          trigger: 'item',
          formatter: '{b}: {d}%'
        },
        legend: {
          show: false
        },
        grid: {
          show: false,
          top: '10%', // 一下数值可为百分比也可为具体像素值
          right: '10%',
          bottom: '10%',
          left: '20%'
        },
        series: [
          {
            name: '评估长者',
            type: 'pie',
            radius: '60%',
            data: [
              { value: 35, name: '药品收入' },
              { value: 65, name: '药品' }
            ],
            center: ['30%', '60%'],
            label: { // 删除指示线
              show: false
            }
          }
        ]
      }

      option && this.myChart2.setOption(option)
    },
    canvas3 () {
      var chartDom = document.getElementById('map')
      this.myChart3 = this.$echarts.init(chartDom)
      if (this.loginInfo.jgbh.indexOf('130629') > -1) {
        this.$echarts.registerMap('HK', bdrcx)
      } else {
        this.$echarts.registerMap('HK', geoJson)
      }

      const option = {
        layoutCenter: ['50%', '50%'], // 位置
        layoutSize: '100%', // 大小
        aspectScale: 0.80, // 宽高比
        tooltip: {
          trigger: 'item',
          formatter: (item) => {
            var arr = this.list
            let str = ''
            for (var i in arr) {
              if (arr[i].name === item.name) {
                str += ` <div class="sub-position" style="min-width: 300px;padding: 10px 15px;background-color: #024B85;border: 1px solid #03BCD7;">
                  <div class="sub-position-line" style="margin: 10px 0;color: #fff;font-size: 16px;">
                    <span>李明明</span>
                    <span>男</span>
                    <span>86岁</span>
                    <span>15803335684</span>
                  </div>
                  <div class="sub-position-label" style="width: 110px;
      margin: 10px 0;
      padding: 4px 8px;
      text-align: center;
      border-radius: 6px;
      background-color: #CD0A0A;
      color: #fff;
      font-size: 14px;">
                    <span>安全防护报警</span>
                  </div>
                  <div class="sub-position-line" style="margin: 10px 0;color: #fff;font-size: 16px;">
                    <span>时间  2022-03-03  18:56:6</span>
                  </div>
                  <div class="sub-position-line sub-position-line1" style="margin: 10px 0;color: #03BCD7;font-size: 16px;">
                    <span>位置：石家庄 桥西区 友谊街道238号 </span>
                  </div>
                </div>`
              }
            }
            return str
          }
        },
        series: [
          {
            name: '东里庄镇',
            type: 'map',
            map: 'HK',
            label: {
              show: true,
              color: '#fff'
            },
            itemStyle: {
              // 默认的区块样式
              borderColor: '#fff',
              areaColor: '#20C1ED',
              // 高亮样式
              emphasis: {
                areaColor: '#0F6ABB'
              }
            },
            // 选中样式
            select: {
              label: {
                // 选中区域的label(文字)样式
                color: '#fff'
              },

              itemStyle: {
                color: '#fff',
                // 选中区域红色
                areaColor: '#0F6ABB',
                // 选中区域边框
                borderColor: '#fff',
                borderWidth: 3
              }

            },
            data: [
              { name: '东里庄村村民委员会', value: 20057.34, itemStyle: { color: '#eb8146' } },
              { name: '南小吾村村民委员会', value: 20057.34, itemStyle: { color: '#eb8146' } }
            ],
            // 自定义名称映射
            nameMap: {
              东里庄村村民委员会: '东里庄村村民委员会',
              南小吾村村民委员会: '南小吾村村民委员会'
            }
          }
        ]
      }

      option && this.myChart3.setOption(option)
    }
    // canvas3 () {
    //   var chartDom = document.getElementById('map')
    //   this.myChart3 = this.$echarts.init(chartDom)
    //   this.$echarts.registerMap('HK', geoJson)
    //   const option = {
    //     tooltip: {
    //       trigger: 'item',
    //       formatter: (item) => {
    //         var arr = this.list
    //         let str = ''
    //         for (var i in arr) {
    //           if (arr[i].name === item.name) {
    //             str += ` <div class="sub-position" style="min-width: 300px;padding: 10px 15px;background-color: #024B85;border: 1px solid #03BCD7;">
    //               <div class="sub-position-line" style="margin: 10px 0;color: #fff;font-size: 16px;">
    //                 <span>李明明</span>
    //                 <span>男</span>
    //                 <span>86岁</span>
    //                 <span>15803335684</span>
    //               </div>
    //               <div class="sub-position-label" style="width: 110px;
    //   margin: 10px 0;
    //   padding: 4px 8px;
    //   text-align: center;
    //   border-radius: 6px;
    //   background-color: #CD0A0A;
    //   color: #fff;
    //   font-size: 14px;">
    //                 <span>安全防护报警</span>
    //               </div>
    //               <div class="sub-position-line" style="margin: 10px 0;color: #fff;font-size: 16px;">
    //                 <span>时间  2022-03-03  18:56:6</span>
    //               </div>
    //               <div class="sub-position-line sub-position-line1" style="margin: 10px 0;color: #03BCD7;font-size: 16px;">
    //                 <span>位置：石家庄 桥西区 友谊街道238号 </span>
    //               </div>
    //             </div>`
    //           }
    //         }
    //         return str
    //       }
    //     },
    //     series: [
    //       {
    //         name: '香港18区人口密度',
    //         type: 'map',
    //         geoIndex: 0,
    //         data: [
    //           { name: '乌审旗', value: 20057.34, itemStyle: { color: '#eb8146' } }
    //         ],
    //         // 自定义名称映射
    //         nameMap: {
    //           乌审旗: '乌审旗'
    //         }
    //       }
    //     ],
    //     geo: [
    //       { // 绘制geo地图
    //         map: 'HK', // 定义的数据名称
    //         aspectScale: 0.85,
    //         zoom: 1.2,
    //         top: '9%', //* **********重点**********距离顶部的位置，每层向下一个百分比
    //         left: '21%',
    //         roam: false,
    //         z: 5, //* ********重点*********可以看成是css的z-index，数字越大图层越高，我这里设置了5个，然后页面有点卡，根据实际需要来添加，3个其实就能看出效果了
    //         regions: [],
    //         label: {
    //           show: true,
    //           color: '#fff'
    //         },
    //         itemStyle: {
    //           areaColor: '#20C1ED', //* ***重点****每个图层的背景色，根据需要来调，由亮变暗，下面的几个颜色也一样
    //           borderColor: '#195175',
    //           borderWidth: 2,
    //           shadowColor: '#0f4c74',
    //           shadowOffsetX: 0,
    //           shadowOffsetY: 4,
    //           shadowBlur: 10
    //         },
    //         tooltip: {
    //           trigger: 'item',
    //           formatter: (item) => {
    //             var arr = this.list
    //             let str = ''
    //             for (var i in arr) {
    //               if (arr[i].name === item.name) {
    //                 str += ` <div class="sub-position" style="min-width: 300px;padding: 10px 15px;background-color: #024B85;border: 1px solid #03BCD7;">
    //               <div class="sub-position-line" style="margin: 10px 0;color: #fff;font-size: 16px;">
    //                 <span>李明明</span>
    //                 <span>男</span>
    //                 <span>86岁</span>
    //                 <span>15803335684</span>
    //               </div>
    //               <div class="sub-position-label" style="width: 110px;
    //   margin: 10px 0;
    //   padding: 4px 8px;
    //   text-align: center;
    //   border-radius: 6px;
    //   background-color: #CD0A0A;
    //   color: #fff;
    //   font-size: 14px;">
    //                 <span>安全防护报警</span>
    //               </div>
    //               <div class="sub-position-line" style="margin: 10px 0;color: #fff;font-size: 16px;">
    //                 <span>时间  2022-03-03  18:56:6</span>
    //               </div>
    //               <div class="sub-position-line sub-position-line1" style="margin: 10px 0;color: #03BCD7;font-size: 16px;">
    //                 <span>位置：石家庄 桥西区 友谊街道238号 </span>
    //               </div>
    //             </div>`
    //               }
    //             }
    //             return str
    //           }
    //         }
    //       },
    //       { // 绘制geo地图
    //         map: 'HK', // 定义的数据名称
    //         aspectScale: 0.85,
    //         zoom: 1.2,
    //         top: '10.5%', //* **********重点**********距离顶部的位置，每层向下一个百分比
    //         left: '22%',
    //         roam: false,
    //         z: 4, //* ********重点*********可以看成是css的z-index，数字越大图层越高，我这里设置了5个，然后页面有点卡，根据实际需要来添加，3个其实就能看出效果了
    //         regions: [],
    //         itemStyle: {
    //           areaColor: '#20C1ED', //* ***重点****每个图层的背景色，根据需要来调，由亮变暗，下面的几个颜色也一样
    //           borderColor: '#195175',
    //           borderWidth: 2,
    //           shadowColor: '#0f4c74',
    //           shadowOffsetX: 0,
    //           shadowOffsetY: 4,
    //           shadowBlur: 10
    //         }
    //       }
    //     ]
    //   }

    //   option && this.myChart3.setOption(option)
    // }
  }
}
</script>

<style lang="less" scoped>
.sub {
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: 100%;
  background-image: url('../../../assets/img/zk1.png');
  background-size: 100% 100%;
  background-repeat: no-repeat;

  .sub-left {
    width: 25%;
    height: 100%;

    .sub-left-item {
      width: 100%;
      height: calc(30% - 10px);
      margin-bottom: 10px;
      padding: 5px;
      background-color: rgba(2, 27, 99, .6);
      box-sizing: border-box;
    }

    .sub-left-item1 {
      width: 100%;
      height: 40%;
      background-color: rgba(2, 27, 99, .6);

      .sub-tip {
        width: 90%;
        height: 30px;
        line-height: 30px;
        margin: 10px auto;
        text-align: center;
        border-radius: 8px;
        background-color: rgba(3, 188, 215, .3);
        color: #fff;
        font-size: 16px;
      }

      .sub-box {
        height: calc(100% - 125px);

        .sub-box-item .sub-box-info {
          font-size: 14px;
        }
      }
    }

    .sub-title {
      width: 70%;
      height: 35px;
      line-height: 40px;
      padding-left: 30px;
      background-image: url('../../../assets/img/zk2.png');
      background-size: 100% 100%;
      background-repeat: no-repeat;
      box-sizing: border-box;
      color: #fff;
      font-size: 20px;
      font-weight: bold;
    }

    .sub-tab {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      width: 100%;
      height: 40px;
      padding-right: 40px;
      box-sizing: border-box;

      .sub-tab-item {
        margin: 0 10px;
        padding: 2px 0;
        border-bottom: 3px solid transparent;
        box-sizing: border-box;
        color: #fff;
        font-size: 16px;
        cursor: pointer;
      }

      .sub-tab-active {
        border-bottom-color: #03BCD7;
        color: #03BCD7;
      }
    }

    .sub-box {
      display: flex;
      flex-wrap: wrap;
      width: 100%;
      height: calc(100% - 75px);
      padding: 0 20px;
      box-sizing: border-box;

      .sub-box-item {
        display: flex;
        align-items: center;
        width: calc(50% - 10px);
        margin: 0 5px;

        .sub-box-img {
          width: 48px;
          height: 48px;
        }

        .sub-box-info {
          display: flex;
          flex-direction: column;
          width: calc(100% - 58px);
          margin-left: 10px;
          color: #fff;
          font-size: 16px;

          .yellow {
            color: #DD9F23;
          }
        }
      }
    }
  }

  .sub-center {
    display: flex;
    align-items: center;
    justify-content: center;
    width: calc(50% - 20px);
    height: 100%;
    background-color: #021B63;

    .sub-map {
      width: 100%;
      height: 100%;
    }
  }

  .sub-right {
    width: 25%;
    height: 100%;

    .sub-left-item {
      width: 100%;
      height: calc(33.333% - 10px);
      margin-bottom: 10px;
      padding: 5px;
      background-color: rgba(2, 27, 99, .6);
      box-sizing: border-box;

      .sub-tip {
        width: 98%;
        height: 30px;
        line-height: 30px;
        margin: 10px auto;
        text-align: center;
        border-radius: 8px;
        background-color: rgba(3, 188, 215, .3);
        color: #fff;
        font-size: 16px;
      }

      .sub-title {
        width: 70%;
        height: 35px;
        line-height: 40px;
        padding-left: 30px;
        background-image: url('../../../assets/img/zk2.png');
        background-size: 100% 100%;
        background-repeat: no-repeat;
        box-sizing: border-box;
        color: #fff;
        font-size: 20px;
        font-weight: bold;
      }

      .sub-tab {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        width: 100%;
        height: 40px;
        padding-right: 40px;
        box-sizing: border-box;

        .sub-tab-item {
          margin: 0 10px;
          padding: 2px 0;
          border-bottom: 3px solid transparent;
          box-sizing: border-box;
          color: #fff;
          font-size: 16px;
          cursor: pointer;
        }

        .sub-tab-active {
          border-bottom-color: #03BCD7;
          color: #03BCD7;
        }
      }

      .sub-box {
        display: flex;
        flex-wrap: wrap;
        width: 100%;
        height: calc(100% - 125px);
        padding: 0 10px;
        box-sizing: border-box;

        .sub-box-item {
          display: flex;
          align-items: center;
          width: calc(50% - 10px);
          margin: 0 5px;

          .sub-box-img {
            width: 48px;
            height: 48px;
          }

          .sub-box-info {
            display: flex;
            flex-direction: column;
            width: calc(100% - 58px);
            margin-left: 10px;
            color: #fff;
            font-size: 16px;

            .yellow {
              color: #DD9F23;
            }
          }
        }
      }

      .sub-content {
        width: 100%;
        height: calc(100% - 40px);
        margin-top: 5px;

        .sub-content-tab {
          display: flex;
          align-items: center;
          justify-content: space-between;
          width: 100%;
          height: 40px;

          .sub-content-tab-sort {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 55%;
            height: 40px;

            .sub-content-tab-item {
              margin: 0 10px;
              padding: 2px 0;
              white-space: nowrap;
              border-bottom: 3px solid transparent;
              box-sizing: border-box;
              color: #fff;
              font-size: 16px;
              cursor: pointer;
            }

            .sub-content-tab-active {
              border-bottom-color: #03BCD7;
              color: #03BCD7;
            }
          }

          .sub-content-tab-sort1 {
            width: 45%;
          }
        }

        .sub-content-box {
          display: flex;
          width: 100%;
          height: calc(100% - 40px);

          .sub-content-chart {
            width: 45%;
            height: 100%;
          }

          .sub-content-table {
            width: 55%;
            height: 100%;
            background-color: rgba(3, 188, 215, .1);

            .thead {
              display: flex;
              align-items: center;
              width: 100%;
              height: 30px;
              background-color: rgba(3, 188, 215, .3);

              .thead-item {
                flex: 1;
                text-align: center;
                color: #fff;
                font-size: 14px;
              }
            }

            .tscroll {
              overflow-y: scroll;
              width: 100%;
              height: calc(100% - 30px);

              &::-webkit-scrollbar {
                width: 0;
              }

              .list {
                display: flex;
                width: 100%;
                margin: 5px 0;
                color: #fff;
                font-size: 14px;

                >span {
                  flex: 1;
                  text-align: center;
                }
              }
            }
          }

          .sub-content-status {
            width: 55%;
            height: 100%;

            .status-title {
              width: 100%;
              height: 40px;
              line-height: 40px;
              color: #fff;
              font-size: 14px;
            }

            .status-scroll {
              width: 100%;
              height: calc(100% - 40px);

              .status-scroll-item {
                display: flex;
                align-items: center;

                .status-scroll-sort {
                  width: calc(50% - 10px);

                  .status-scroll-top {
                    display: flex;
                    align-items: center;
                    color: #fff;
                    font-size: 14px;

                    .block {
                      width: 8px;
                      height: 8px;
                      margin-right: 5px;
                      background-color: #FAC858;
                    }
                  }

                  .status-scroll-bottom {
                    width: calc(100% - 4px);
                    margin-left: 4px;
                    padding: 9px;
                    border-left: 1px #FAC858 dashed;
                    box-sizing: border-box;
                    color: #FAC858;
                    font-size: 14px;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
// .sub-position {
//     position: absolute;
//     top: 50%;
//     left: 50%;
//     min-width: 300px;
//     padding: 10px 15px;
//     background-color: #024B85;
//     border: 1px solid #03BCD7;

//     .sub-position-line {
//       margin: 10px 0;
//       color: #fff;
//       font-size: 16px;
//     }

//     .sub-position-line1 {
//       color: #03BCD7;
//     }

//     .sub-position-label {
//       width: 110px;
//       margin: 10px 0;
//       padding: 4px 8px;
//       text-align: center;
//       border-radius: 6px;
//       background-color: #CD0A0A;
//       color: #fff;
//       font-size: 14px;
//     }
//   }
</style>
