<template>
  <div class="popup">
    <div class="popup-box">
      <div class="popup-head">
        <span>{{ selecType }}</span>
        <el-button type="primary" size="mini">导出</el-button>
      </div>
      <div class="popup-table">
        <el-table :data="tableData" height="100%">
          <el-table-column prop="name" label="姓名">
          </el-table-column>
          <el-table-column prop="xb" label="性别">
            <template slot-scope="scope">
              <span>{{ scope.row.xb === '1' ? '男' : '女' }}</span>
            </template>
          </el-table-column>
          <el-table-column prop="age" label="年龄">
          </el-table-column>
          <el-table-column prop="time" label="随访时间">
          </el-table-column>
          <el-table-column prop="glys" label="管理医生">
          </el-table-column>
          <!-- <el-table-column prop="address" label="操作">
            <template>
              <el-button type="primary" size="small">查看</el-button>
            </template>
          </el-table-column> -->
        </el-table>
      </div>
      <div class="popup-pagination">
        <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="query.pageNum"
          background :page-size="query.pageSize" layout="total, prev, pager, next, jumper"
          :total="query.total"></el-pagination>
      </div>
      <div class="popup-button">
        <el-button size="small" @click="close">取消</el-button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
export default {
  props: {
    selecType: {
      type: String,
      default: ''
    },
    selecLyType: {
      type: String,
      default: ''
    },
    lytype: {
      type: String,
      default: ''
    },
    mxtype: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      params: {
        jgbh: '',
        jbmc: '',
        ssjgbhmc: '',
        usercode: '',
        username: '',
        jgjb: '',
        ssjgbh: '',
        type: '',
        sjjgbh: '',
        lytype: this.lytype,
        mxtype: this.mxtype,
        start: 0,
        length: 10
      },
      // 分页数据
      query: {
        pageNum: 1,
        pageSize: 10,
        total: 0
      },
      tableData: []
    }
  },
  computed: {
    ...mapState(['loginInfo'])
  },
  created () {
    console.log(this.selecType, this.selecLyType, this.lytype, this.mxtype)
    this.params.jgbh = this.loginInfo.jgbh || ''
    this.params.jbmc = this.loginInfo.jgmc || ''
    this.params.ssjgbhmc = this.loginInfo.label || ''
    this.params.usercode = this.loginInfo.usercode || ''
    this.params.username = this.loginInfo.username || ''
    this.params.jgjb = this.loginInfo.value || ''
    this.params.ssjgbh = this.loginInfo.jgbh || ''
    this.params.lytype = this.lytype || ''
    this.params.mxtype = this.mxtype || ''
    this.getInfo()
  },
  methods: {
    async getInfo () {
      // 接口文档 https://app.apifox.com/project/2726972/apis/api-149816824
      try {
        this.params.start = this.query.pageNum === 1 ? 0 : (this.query.pageNum - 1) * 10
        this.params.length = this.query.pageSize
        const { data: res } = await this.$http.post('/api/jkct/app/zhdpetsfrwtx', this.params)
        console.log('智慧公卫-儿童随访提醒明细', res)
        if (res.code !== 0) {
          return this.$message({
            message: res.msg || '暂无信息',
            type: 'warning'
          })
        }
        if (res.data.length < 1) {
          return this.$message({
            message: '暂无信息',
            type: 'warning'
          })
        }
        this.tableData = res.data[0][this.lytype][this.mxtype + 'list']
        // this.tableData = res.data
        this.query.total = res.data[0][this.lytype][this.mxtype]
      } catch (err) {
        console.log('智慧公卫-儿童随访提醒明细', err)
      }
    },
    handleSizeChange (val) {
      // console.log(`每页 ${val} 条`)
    },
    handleCurrentChange (val) {
      this.query.pageNum = val
      this.getInfo()
    },
    close () {
      this.$emit('close')
    }
  }
}
</script>

<style lang="less" scoped>
.popup {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 999;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, .8);

  .popup-box {
    width: 60%;
    height: 70%;
    background-color: #1a3673cc;
    border: 1px solid;
    border-image: linear-gradient(270deg, rgba(64, 154, 222, 0), rgba(19, 255, 255, 1), rgba(64, 154, 222, 0)) 1 1;

    .popup-head {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      height: 40px;
      padding: 0 10px;
      background: linear-gradient(270deg, rgba(10, 29, 87, 0) 0%, rgba(0, 97, 194, .47) 47%);
      color: #fff;
      font-size: 20px;
      box-sizing: border-box;
    }

    .popup-table {
      width: 100%;
      height: calc(100% - 160px);

      /deep/.el-table__cell {
        text-align: center;
        color: #fff;
        font-size: 16px;
        font-weight: 400;
      }

      /deep/.el-table th.el-table__cell {
        background-color: #1a3673cc;
      }

      /deep/.el-table tr {
        background-color: #1a3673cc;
      }

      /deep/.el-table--border .el-table__cell {
        border-color: transparent;
      }

      /deep/.el-table {
        background-color: #1a3673cc;
      }

      /deep/.el-table th.el-table__cell.is-leaf,
      /deep/.el-table td.el-table__cell {
        border-color: transparent;
      }

      /deep/.el-table__body tr.current-row>td {
        background-color: transparent;
      }

      /deep/.el-table--enable-row-hover .el-table__body tr:hover>td {
        background-color: transparent;
      }

      /deep/.el-table__empty-text {
        color: #fff;
      }
    }

    .popup-pagination {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 60px;

      /deep/.el-pagination__total {
        color: #fff;
        font-size: 16px;
        font-weight: 400;
      }

      /deep/.el-pagination__jump {
        color: #fff;
        font-size: 16px;
      }

      /deep/.el-input__inner {
        margin: 0;
      }
    }

    .popup-button {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 60px;
    }
  }
}
</style>
