<template>
  <div class="popup">
    <div class="popup-box">
      <div class="popup-head">
        <div class="left">
          <span class="el-icon-caret-left icon"></span>
          <span>体检异常结果</span>
          <span class="el-icon-caret-right icon"></span>
        </div>
        <div class="close" @click="close">
          <span class="el-icon-close"></span>
        </div>
      </div>
      <div class="popup-search">
        <div class="left">
          <div class="item">
            <span>体检日期</span>
            <el-date-picker type="date" placeholder="选择日期">
            </el-date-picker>
          </div>
          <div class="item">
            <span>姓名</span>
            <el-input placeholder=""></el-input>
          </div>
          <div class="item">
            <span>身份证号</span>
            <el-input placeholder=""></el-input>
          </div>
          <div class="item">
            <span>人群分类</span>
            <el-select v-model="value" placeholder="">
              <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
              </el-option>
            </el-select>
          </div>
        </div>
      </div>
      <div class="popup-table">
        <el-table :data="tableData" height="100%" style="width: 100%">
          <el-table-column type="selection" width="55">
          </el-table-column>
          <el-table-column type="index" label="序号">
          </el-table-column>
          <el-table-column prop="name" label="体检日期">
          </el-table-column>
          <el-table-column prop="date" label="姓名">
          </el-table-column>
          <el-table-column prop="date1" label="年龄">
          </el-table-column>
          <el-table-column prop="date1" label="性别">
          </el-table-column>
          <el-table-column prop="status" label="身份证号">
          </el-table-column>
          <el-table-column prop="status" label="人群分类">
          </el-table-column>
          <el-table-column prop="status" label="生化">
          </el-table-column>
          <el-table-column prop="status" label="血常规">
          </el-table-column>
          <el-table-column prop="status" label="尿常规">
          </el-table-column>
          <el-table-column prop="status" label="心电图">
          </el-table-column>
          <el-table-column prop="status" label="B超">
          </el-table-column>
          <el-table-column label="操作" width="120">
            <!-- slot-scope="scope" -->
            <template>
              <div class="flex">
                <span>查看</span>
                <span>编辑</span>
              </div>
            </template>
          </el-table-column>
        </el-table>
      </div>

      <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="currentPage"
        background :page-size="10" layout="total, prev, pager, next, jumper" :total="total">
      </el-pagination>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      value: '',
      options: [{
        value: '',
        label: ''
      }],
      tableData: [],
      total: 400,
      currentPage: 0
    }
  },
  methods: {
    handleSizeChange (val) {
      console.log(`每页 ${val} 条`)
    },
    handleCurrentChange (val) {
      console.log(`当前页: ${val}`)
    },
    close () {
      this.$emit('close')
    }
  }
}
</script>

<style lang="less" scoped>
.popup {
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: 9999;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: calc(100% - 102px);
  background-color: rgba(0, 0, 0, .69);

  .popup-box {
    width: calc(100% - 76px);
    height: 80%;
    padding: 0 30px;
    background-color: #0D3A80;
    border: 18px solid #1774D6;
    border-radius: 50px;
    box-sizing: border-box;

    .popup-head {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      height: 90px;

      .left {
        display: flex;
        flex-direction: row;
        align-items: center;
        color: #fff;
        font-size: 24px;
        font-weight: 600;

        .icon {
          color: #1B5C9A;
          font-size: 30px;
        }
      }

      .close {
        color: #FFFFFF;
        font-size: 40px;
        cursor: pointer;
      }
    }

    .popup-search {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      height: 60px;

      .left {
        display: flex;
        flex-direction: row;
        align-items: center;

        .item {
          margin-right: 50px;
          color: #fff;
          font-size: 20px;

          span {
            margin-right: 10px;
          }

          .el-input {
            width: 200px;
          }
        }
      }
    }

    .popup-table {
      width: 100%;
      height: calc(100% - 240px);
      background-color: red;
    }
  }

  .el-table {
    margin-top: 20px;
    margin-bottom: 20px;
    background-color: #0D3A80;
    border: 4px solid #073F73;
    box-sizing: border-box;

    &::before {
      background-color: transparent;
    }

    // 表头首行
    /deep/th.el-table__cell.is-leaf {
      border-bottom: none;
    }

    /deep/td.el-table__cell {
      border-bottom: 1px solid #01EEFD;
    }

    // 表头背景颜色
    /deep/th.el-table__cell {
      background-color: #073F73;
    }

    /deep/.el-table__cell {
      text-align: center;
      color: #fff;
    }

    /deep/tr {
      background-color: #081C38;
    }

    /deep/.el-table__body tr:hover>td.el-table__cell {
      background-color: transparent !important;
    }

    /deep/.el-checkbox__inner {
      background-color: transparent;
    }

    /deep/.is-indeterminate .el-checkbox__inner,
    /deep/.is-checked .el-checkbox__inner {
      background-color: #04B4D0;
    }

    /deep/.el-table__body-wrapper::-webkit-scrollbar {
      width: 0;
    }

    /deep/.el-table__empty-text {
      color: #fff;
    }

    .flex {
      display: flex;
      flex-direction: row;
      justify-content: space-evenly;
      width: 100%;
      color: #fff;

      .item {
        font-size: 14px;
        cursor: pointer;
      }

      .icon {
        color: #fff;
        font-size: 24px;
        cursor: pointer;
      }
    }
  }

  .green {
    color: #00FF88;
  }

  .organ {
    color: #FF6600;
  }

  .red {
    color: #FF0000;
  }

  // 页码样式修改
  .el-pagination {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    /deep/.el-pagination__total,
    /deep/.el-pagination__jump {
      color: #01EEFD;

      .el-input__inner {
        color: #fff;
        background-color: transparent;
      }
    }

    /deep/.el-pager li:not(.disabled).active {
      background-color: #01EEFD;
    }
  }
}
</style>
