<template>
  <div class="sub">
    <div class="sub-left">
      <div class="sub-left-item">
        <div class="sub-title">
          <span>影像分类统计</span>
        </div>
        <div class="sub-box" id="echarts1">
        </div>
      </div>
      <div class="sub-left-item1">
        <div class="sub-title">
          <span>影像部位统计</span>
        </div>
        <div class="sub-box" id="echarts2">
        </div>
      </div>
    </div>
    <div class="sub-center">
      <div class="sub-top">
        <div class="sub-title">
          <span>数据概况</span>
        </div>
        <div class="sub-text">
          <span>累计影像数</span>
        </div>
        <div class="sub-label">
          <div class="sub-label-item">
            <span>7</span>
          </div>
          <div class="sub-label-item">
            <span>7</span>
          </div>
          <div class="sub-label-item">
            <span>7</span>
          </div>
          <div class="sub-label-item">
            <span>7</span>
          </div>
          <div class="sub-label-item">
            <span>7</span>
          </div>
          <div class="sub-label-item">
            <span>7</span>
          </div>
          <div class="sub-label-item">
            <span>7</span>
          </div>
        </div>
        <div class="sub-card">
          <div class="sub-card-item">
            <span>今日累计</span>
            <span class="red">1,322</span>
          </div>
          <div class="sub-card-item">
            <span>本月累计</span>
            <span class="red">1,322</span>
          </div>
          <div class="sub-card-item">
            <span>本年累计</span>
            <span class="red">1,322</span>
          </div>
        </div>
      </div>
      <div class="sub-bottom">
        <div class="sub-title">
          <span>本年上传量及诊断量趋势</span>
        </div>
        <div class="sub-bottom-chart" id="echarts4"></div>
      </div>
    </div>
    <div class="sub-right">
      <div class="sub-left-item">
        <div class="sub-title">
          <span>影像存储技术</span>
        </div>
        <div class="sub-box">
          <div class="sub-label">
            <div class="sub-label-item">
              <span>7</span>
            </div>
            <div class="sub-label-item">
              <span>7</span>
            </div>
            <div class="sub-label-item">
              <span>7</span>
            </div>
            <div class="sub-label-item">
              <span>7</span>
            </div>
            <div class="sub-label-item">
              <span>7</span>
            </div>
            <div class="sub-label-item">
              <span>7</span>
            </div>
            <div class="sub-label-item">
              <span>7</span>
            </div>
          </div>
          <div class="sub-chart" id="echarts5"></div>
        </div>
      </div>

      <div class="sub-left-item sub-left-item1">
        <div class="sub-title">
          <span>本年专家诊断量排名</span>
        </div>
        <div class="sub-chart" id="echarts6">
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      tabIndex1: 0,
      tabIndex2: 0,
      tabIndex3: 0,
      tabIndex4: 0,
      tabIndex5: 0,
      tabIndex6: 0,
      myChart1: null,
      myChart2: null,
      myChart3: null,
      myChart4: null,
      myChart5: null,
      myChart6: null,
      myChart7: null,
      list: [
        { name: '乌审旗', value: 20057.34, itemStyle: { color: '#eb8146' } }
      ]
    }
  },
  mounted () {
    this.$nextTick(() => {
      this.canvas1()
      this.canvas2()
      // this.canvas3()
      this.canvas4()
      this.canvas5()
      this.canvas6()
      // this.canvas7()
    })
    window.addEventListener('resize', this.getresize)
  },
  beforeDestroy () {
    // 页面销毁时销毁监听事件
    window.removeEventListener('resize', this.getresize)
  },
  methods: {
    tabClick1 (index) {
      this.tabIndex1 = index
    },
    tabClick2 (index) {
      this.tabIndex2 = index
    },
    tabClick3 (index) {
      this.tabIndex3 = index
    },
    tabClick4 (index) {
      this.tabIndex4 = index
    },
    tabClick5 (index) {
      this.tabIndex5 = index
    },
    tabClick6 (index) {
      this.tabIndex6 = index
    },
    async getData () {
      const { data: res } = await this.$http.get('https://echarts.apache.org/examples/data/asset/geo/HK.json')
      console.log('res', res)
    },
    getresize () {
      setTimeout(() => {
        this.myChart1.resize()
        this.myChart2.resize()
        // this.myChart3.resize()
        this.myChart4.resize()
        this.myChart5.resize()
        this.myChart6.resize()
        // this.myChart7.resize()
      }, 500)
    },
    canvas1 () {
      var chartDom = document.getElementById('echarts1')
      this.myChart1 = this.$echarts.init(chartDom)
      const option = {
        title: {
          text: '单位：万个',
          top: 10,
          left: 10,
          textStyle: {
            color: '#fff',
            fontSize: 12
          }
        },
        tooltip: {
          trigger: 'item'
        },
        legend: {
          show: false,
          left: 'right',
          textStyle: {
            color: '#fff'
          }
        },
        grid: {
          show: true,
          top: '20%',
          right: '2%',
          bottom: '10%',
          left: '15%'
        },
        yAxis: {
          type: 'value',
          axisLabel: {
            color: '#fff'
          },
          splitLine: {
            show: true
          }
        },
        xAxis: {
          type: 'category',
          data: ['核医学类', '介入类', '超声类', '磁共振类', '放射类'],
          axisLabel: {
            color: '#fff',
            formatter: function (value) {
              return value.length > 3 ? value.slice(0, 3) + '...' : value
            }
          }
        },
        series: [
          {
            name: '已完成',
            type: 'bar',
            barWidth: '8',
            data: [
              { value: 100, itemStyle: { color: '#0037E4' } },
              { value: 100, itemStyle: { color: '#E4D400' } },
              { value: 100, itemStyle: { color: '#00E444' } },
              { value: 100, itemStyle: { color: '#E40035' } },
              { value: 100, itemStyle: { color: '#CA7520' } }
            ],
            label: {
              show: true,
              position: 'top',
              color: '#fff'
            }
          }
        ]
      }

      option && this.myChart1.setOption(option)
    },
    canvas2 () {
      var chartDom = document.getElementById('echarts2')
      this.myChart2 = this.$echarts.init(chartDom)
      const option = {
        color: ['#03BCD7', '#5871A7'],
        title: {
          text: '单位：万个',
          top: 10,
          left: 10,
          textStyle: {
            color: '#fff',
            fontSize: 12
          }
        },
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'cross',
            label: {
              backgroundColor: '#6a7985'
            }
          }
        },
        legend: {
          show: false,
          left: 'right',
          textStyle: {
            color: '#fff'
          }
        },
        grid: {
          left: '3%',
          right: '4%',
          bottom: '10%',
          containLabel: true
        },
        xAxis: [
          {
            type: 'category',
            boundaryGap: false,
            data: ['脑血管', '胸肺', '眼部', '乳腺', '肝脏', '肠胃', '肝脏', '骨骼', '其他'],
            axisLabel: {
              color: '#fff',
              rotate: 45
            },
            axisLine: {
              show: true,
              lineStyle: {
                color: '#fff'
              }
            }
          }
        ],
        yAxis: [
          {
            type: 'value',
            axisLabel: {
              textStyle: {
                color: '#fff'
              }
            },
            axisLine: {
              show: true,
              lineStyle: {
                color: '#fff'
              }
            }
          }
        ],
        series: [
          {
            name: '完成',
            type: 'line',
            data: [120, 132, 101, 134, 90, 230, 210, 101, 134]
          }
        ]
      }

      option && this.myChart2.setOption(option)
    },
    canvas3 () {
      // var chartDom = document.getElementById('echarts3')
      // this.myChart3 = this.$echarts.init(chartDom)
      // this.$echarts.registerMap('HK', geoJson)
      // const option = {
      //   tooltip: {
      //     trigger: 'item',
      //     formatter: (item) => {
      //       var arr = this.list
      //       let str = ''
      //       for (var i in arr) {
      //         if (arr[i].name === item.name) {
      //           str += ` <div class="sub-position" style="min-width: 300px;padding: 10px 15px;background-color: #024B85;border: 1px solid #03BCD7;">
      //             <div class="sub-position-line" style="margin: 10px 0;color: #fff;font-size: 16px;">
      //               <span>李明明</span>
      //               <span>男</span>
      //               <span>86岁</span>
      //               <span>15803335684</span>
      //             </div>
      //             <div class="sub-position-label" style="width: 110px;
      // margin: 10px 0;
      // padding: 4px 8px;
      // text-align: center;
      // border-radius: 6px;
      // background-color: #CD0A0A;
      // color: #fff;
      // font-size: 14px;">
      //               <span>安全防护报警</span>
      //             </div>
      //             <div class="sub-position-line" style="margin: 10px 0;color: #fff;font-size: 16px;">
      //               <span>时间  2022-03-03  18:56:6</span>
      //             </div>
      //             <div class="sub-position-line sub-position-line1" style="margin: 10px 0;color: #03BCD7;font-size: 16px;">
      //               <span>位置：石家庄 桥西区 友谊街道238号 </span>
      //             </div>
      //           </div>`
      //         }
      //       }
      //       return str
      //     }
      //   },
      //   series: [
      //     {
      //       name: '香港18区人口密度',
      //       type: 'map',
      //       map: 'HK',
      //       label: {
      //         show: true,
      //         color: '#fff'
      //       },
      //       itemStyle: {
      //         // 默认的区块样式
      //         borderColor: '#fff',
      //         areaColor: '#20C1ED',
      //         // 高亮样式
      //         emphasis: {
      //           areaColor: '#0F6ABB'
      //         }
      //       },
      //       // 选中样式
      //       select: {
      //         label: {
      //           // 选中区域的label(文字)样式
      //           color: '#fff'
      //         },

      //         itemStyle: {
      //           color: '#fff',
      //           // 选中区域红色
      //           areaColor: '#0F6ABB',
      //           // 选中区域边框
      //           borderColor: '#fff',
      //           borderWidth: 3
      //         }

      //       },
      //       data: [
      //         { name: '乌审旗', value: 20057.34, itemStyle: { color: '#eb8146' } }
      //       ],
      //       // 自定义名称映射
      //       nameMap: {
      //         乌审旗: '乌审旗'
      //       }
      //     }
      //   ]
      // }

      // option && this.myChart3.setOption(option)
    },
    canvas4 () {
      var chartDom = document.getElementById('echarts4')
      this.myChart4 = this.$echarts.init(chartDom)
      const option = {
        color: ['#E48F00', '#12CB8D', '#E4D400'],
        title: {
          text: '单位：次',
          top: 10,
          left: 10,
          textStyle: {
            color: '#fff',
            fontSize: 12
          }
        },
        tooltip: {
          trigger: 'item'
        },
        legend: {
          show: true,
          left: 'right',
          textStyle: {
            color: '#fff'
          }
        },
        grid: {
          show: true,
          top: '20%',
          right: '10%',
          bottom: '10%',
          left: '10%'
        },
        yAxis: {
          type: 'value',
          axisLabel: {
            color: '#fff'
          },
          splitLine: {
            show: false
          }
        },
        xAxis: {
          type: 'category',
          data: ['1月', '2月', '3月', '4月', '5月', '6月', '7月', '8月', '9月', '10月', '11月', '12月'],
          axisLabel: {
            color: '#fff'
          }
        },
        series: [
          {
            name: '上传量',
            type: 'bar',
            barWidth: '10',
            data: [13168, 13168, 13168, 13168, 13168, 13168, 13168, 13168, 13168],
            label: {
              show: false
            }
          },
          {
            name: '诊断量',
            type: 'bar',
            barWidth: '10',
            data: [13168, 13168, 13168, 13168, 13168, 13168, 13168, 13168, 13168],
            label: {
              show: false
            }
          },
          {
            name: '完成率',
            type: 'line',
            barWidth: '10',
            data: [13168, 13168, 13168, 13168, 13168, 13168, 13168, 13168, 13168],
            label: {
              show: false
            }
          }
        ]
      }

      option && this.myChart4.setOption(option)
    },
    canvas5 () {
      var chartDom = document.getElementById('echarts5')
      this.myChart5 = this.$echarts.init(chartDom)
      const option = {
        tooltip: {
          trigger: 'item',
          formatter: (params) => {
            return params.name + ' ' + params.value + '%'
          }
        },
        legend: {
          show: false
        },
        // grid: {
        //   show: false,
        //   top: '10%', // 一下数值可为百分比也可为具体像素值
        //   right: '10%',
        //   bottom: '10%',
        //   left: '20%'
        // },
        series: [
          {
            name: '覆盖兼有人群',
            type: 'pie',
            radius: '40%',
            data: [
              { value: 20, name: 'CR' },
              { value: 10, name: 'dr' },
              { value: 30, name: 'CT' },
              { value: 30, name: 'MR' },
              { value: 10, name: '其他' }
            ],
            center: ['50%', '50%'],
            label: { // 删除指示线
              show: true,
              textStyle: {
                color: '#fff'
              },
              formatter: (params) => {
                return params.name + ' ' + params.value + '%'
              }
            }
          }
        ]
      }

      option && this.myChart5.setOption(option)
    },
    canvas6 () {
      var chartDom = document.getElementById('echarts6')
      this.myChart6 = this.$echarts.init(chartDom)
      const option = {
        grid: {
          containLabel: true,
          top: '15%', // 一下数值可为百分比也可为具体像素值
          right: '21%',
          bottom: '0%',
          left: '8%'
        },
        label: {
          show: true,
          color: '#fff',
          fontSize: 14,
          position: 'right',
          height: 100,
          formatter: (params) => {
            return params.value + '次'
          }
        },
        xAxis: [
          // x轴数据设置
          {
            type: 'value',
            splitLine: {
              show: false
            },
            axisLine: {
              lineStyle: {
                color: 'rgba(0,0,0,0)'
              }
            }
          }
        ],
        yAxis: [
          {
            type: 'category',
            offset: 0,
            axisLine: {
              show: false,
              // 这是x轴文字颜色
              lineStyle: {
                color: '#fff'
              }
            },
            data: ['专家一', '专家二', '专家三', '专家四', '专家五', '专家六', '专家七', '专家八'],
            axisTick: {
              show: false
            }
          }
        ],
        series: [
          {
            data: [200, 200, 200, 200, 200, 200, 200, 200],
            type: 'bar',
            barWidth: 12,
            label: {
              show: true
            },
            itemStyle: {
              color: params => {
                const colors = [
                  ['#E4D400', '#E4D400'],
                  ['#E40035', '#E40035'],
                  ['#CD6701', '#CD6701'],
                  ['#01CD0F', '#01CD0F'],
                  ['#0177CD', '#0177CD'],
                  ['#E4D400', '#E4D400'],
                  ['#E40035', '#E40035'],
                  ['#CD6701', '#CD6701'],
                  ['#01CD0F', '#01CD0F'],
                  ['#0177CD', '#0177CD']
                ]
                const colorItem = colors[params.dataIndex]
                return new this.$echarts.graphic.LinearGradient(1, 0, 0, 0, [{
                  offset: 0,
                  color: colorItem[1]
                }, {
                  offset: 1,
                  color: colorItem[0]
                }])
              },
              borderRadius: [10, 10, 10, 10]
            },
            showBackground: true,
            backgroundStyle: {
              color: '#fff',
              borderRadius: [10, 10, 10, 10]
            }
          }
        ]
      }

      option && this.myChart6.setOption(option)
    },
    canvas7 () {
      var chartDom = document.getElementById('echarts7')
      this.myChart7 = this.$echarts.init(chartDom)
      const option = {
        title: {
          text: '血糖管理率',
          top: 10,
          left: 10,
          textStyle: {
            color: '#fff',
            fontSize: 14
          }
        },
        color: ['#0599D9', '#5772A1'],
        tooltip: {
          trigger: 'item',
          formatter: (params) => {
            return params.seriesName + '<br/>' + params.name + ':' + params.value + '%'
          }
        },
        legend: {
          show: true,
          top: '5%',
          left: 'right',
          textStyle: {
            color: '#fff'
          }
        },
        grid: {
          show: true,
          top: '20%', // 一下数值可为百分比也可为具体像素值
          right: '10%',
          bottom: '10%',
          left: '20%'
        },
        yAxis: {
          type: 'value',
          axisLabel: {
            formatter: '{value}%',
            textStyle: {
              color: '#fff'
            }
          }
        },
        xAxis: {
          type: 'category',
          data: ['1季度', '2季度', '3季度', '4季度'],
          axisLabel: {
            textStyle: {
              color: '#fff'
            }
          }
        },
        series: [
          {
            name: '完成',
            type: 'bar',
            stack: 'total',
            barWidth: '10',
            data: [20, 40, 60, 80],
            label: {
              show: false
            }
          },
          {
            name: '未完成',
            type: 'bar',
            stack: 'total',
            barWidth: '10',
            data: [20, 40, 60, 80],
            label: {
              show: false
            },
            itemStyle: {
              borderRadius: [16, 16, 0, 0]
            }
          }
        ]
      }

      option && this.myChart7.setOption(option)
    }
  }
}
</script>

<style lang="less" scoped>
.sub {
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: 100%;
  background-image: url('../../../assets/img/zk1.png');
  background-size: 100% 100%;
  background-repeat: no-repeat;

  .sub-left {
    width: 25%;
    height: 100%;

    .sub-left-item {
      width: 100%;
      height: calc(52% - 10px);
      margin-bottom: 10px;
      padding: 5px;
      background-color: rgba(2, 27, 99, .6);
      box-sizing: border-box;
    }

    .sub-left-item1 {
      width: 100%;
      height: 48%;
      background-color: rgba(2, 27, 99, .6);
    }

    .sub-title {
      width: 70%;
      height: 35px;
      line-height: 40px;
      padding-left: 30px;
      background-image: url('../../../assets/img/zk2.png');
      background-size: 100% 100%;
      background-repeat: no-repeat;
      box-sizing: border-box;
      color: #fff;
      font-size: 20px;
      font-weight: bold;
    }

    .sub-box {
      width: 100%;
      height: calc(100% - 35px);
      padding: 0 20px;
      box-sizing: border-box;

      .sub-status {
        display: flex;
        align-items: center;
        overflow: hidden;
        width: 100%;
        height: 34px;
        margin: 10px 0;
        border-radius: 17px;

        .sub-blue {
          flex: 4;
          height: 34px;
          line-height: 34px;
          padding-left: 20px;
          background-color: #02B9E1;
          box-sizing: border-box;
          color: #fff;
          font-size: 16px;
          font-weight: bold;
        }

        .sub-yellow {
          flex: 3;
          height: 34px;
          line-height: 34px;
          padding-left: 14px;
          background-color: #FF9606;
          box-sizing: border-box;
          color: #fff;
          font-size: 16px;
          font-weight: bold;
        }

        .sub-green {
          flex: 2;
          height: 34px;
          line-height: 34px;
          text-align: center;
          background-color: #59E992;
          box-sizing: border-box;
          color: #fff;
          font-size: 16px;
          font-weight: bold;
        }

        .sub-gray {
          flex: 2;
          height: 34px;
          line-height: 34px;
          text-align: center;
          background-color: #596CA6;
          box-sizing: border-box;
          color: #fff;
          font-size: 16px;
          font-weight: bold;
        }
      }

      .sub-text {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        margin: 5px 0;
        padding: 0 12px;
        box-sizing: border-box;
        color: #fff;
        font-size: 16px;
        font-weight: bold;

        .blue {
          color: #02B9E1;
        }

        .yellow {
          color: #FF9606;
        }

        .green {
          color: #59E992;
        }

        .gray {
          color: #596CA6;
        }
      }

      .sub-label {
        display: flex;
        align-items: center;
        width: 100%;
        height: 48px;
        margin: 10px 0;

        .sub-label-item {
          width: 34px;
          height: 48px;
          line-height: 48px;
          margin: 0 2px;
          text-align: center;
          border-radius: 3px;
          background: linear-gradient(180deg, #FFFFFF 0%, #021A5C 100%);
          color: #fff;
          font-size: 30px;
          font-weight: 800;
        }
      }

      .sub-chart {
        width: 100%;
        height: calc(100% - 68px);
      }
    }
  }

  .sub-center {
    width: calc(50% - 20px);
    height: 100%;

    .sub-top {
      width: 100%;
      height: calc(45% - 10px);
      margin-bottom: 10px;
      padding: 10px 26px;
      background-color: #021B63;
      box-sizing: border-box;

      .sub-title {
        width: 40%;
        height: 35px;
        line-height: 40px;
        padding-left: 30px;
        background-image: url('../../../assets/img/zk2.png');
        background-size: 100% 100%;
        background-repeat: no-repeat;
        box-sizing: border-box;
        color: #fff;
        font-size: 20px;
        font-weight: bold;
      }

      .sub-text {
        width: 100%;
        margin-top: 10px;
        margin-bottom: 10px;
        text-align: center;
        color: #fff;
        font-size: 16px;
      }

      .sub-label {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 48px;

        .sub-label-item {
          width: 34px;
          height: 48px;
          line-height: 48px;
          margin: 0 2px;
          text-align: center;
          border-radius: 3px;
          background: linear-gradient(180deg, #FFFFFF 0%, #021A5C 100%);
          color: #fff;
          font-size: 30px;
          font-weight: 800;
        }
      }

      .sub-card {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-evenly;
        height: calc(100% - 110px);

        .sub-card-item {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          color: #fff;
          font-size: 18px;

          .red {
            margin-top: 10px;
            color: #FA6400;
          }
        }
      }
    }

    .sub-bottom {
      width: 100%;
      height: 55%;
      padding: 5px;
      background-color: rgba(2, 27, 99, .6);
      box-sizing: border-box;

      .sub-title {
        width: 40%;
        height: 35px;
        line-height: 40px;
        padding-left: 30px;
        background-image: url('../../../assets/img/zk2.png');
        background-size: 100% 100%;
        background-repeat: no-repeat;
        box-sizing: border-box;
        color: #fff;
        font-size: 20px;
        font-weight: bold;
      }

      .sub-text {
        display: flex;
        align-items: center;
        width: 100%;
        height: 50px;
        padding-left: 40px;
        box-sizing: border-box;

        .sub-text-item {
          margin-right: 50px;
          color: #fff;
          font-size: 16px;
        }

        .yellow {
          color: #DD9F23;
        }
      }

      .sub-bottom-chart {
        width: 100%;
        height: calc(100% - 35px);
      }
    }
  }

  .sub-right {
    width: 25%;
    height: 100%;

    .sub-left-item {
      width: 100%;
      height: calc(45% - 10px);
      margin-bottom: 10px;
      padding: 5px;
      background-color: rgba(2, 27, 99, .6);
      box-sizing: border-box;

      .sub-title {
        width: 70%;
        height: 35px;
        line-height: 40px;
        padding-left: 30px;
        background-image: url('../../../assets/img/zk2.png');
        background-size: 100% 100%;
        background-repeat: no-repeat;
        box-sizing: border-box;
        color: #fff;
        font-size: 20px;
        font-weight: bold;
      }

      .sub-text {
        display: flex;
        align-items: center;
        width: 100%;
        height: 50px;
        padding-left: 20px;
        box-sizing: border-box;

        .sub-text-item {
          margin-right: 20px;
          color: #fff;
          font-size: 16px;
        }

        .yellow {
          color: #DD9F23;
        }

        .blue {
          color: #03BCD7;
        }
      }

      .sub-table {
        display: flex;
        width: 100%;
        height: calc(100% - 85px);

        .sub-table-left {
          width: calc(60% - 10px);
          height: 100%;
          margin-right: 10px;
          background-color: rgba(3, 188, 215, .1);
        }

        .sub-table-right {
          width: 40%;
          height: 100%;
          margin-right: 10px;
          background-color: rgba(3, 188, 215, .1);
        }

        .sub-table-head {
          width: 100%;
          height: 30px;
          line-height: 30px;
          text-align: center;
          background-color: rgba(3, 188, 215, .3);
          color: #fff;
          font-size: 14px;
        }

        .sub-table-box {
          overflow-y: scroll;
          width: 100%;
          height: calc(100% - 60px);

          &::-webkit-scrollbar {
            width: 0;
          }
        }

        .sub-table-item {
          display: flex;
          align-items: center;
          width: 100%;
          color: #fff;
          font-size: 14px;

          >span {
            flex: 1;
            text-align: center;
          }
        }

        .sub-table-item1 {
          height: 30px;
        }
      }

      .sub-chart {
        display: flex;
        width: 100%;
        height: calc(100% - 35px);

        .sub-chart-item {
          width: 50%;
          height: 100%;
        }
      }

      .sub-box {
        width: 100%;
        height: calc(100% - 35px);
        padding: 0 20px;
        box-sizing: border-box;

        .sub-status {
          display: flex;
          align-items: center;
          overflow: hidden;
          width: 100%;
          height: 34px;
          margin: 10px 0;
          border-radius: 17px;

          .sub-blue {
            flex: 4;
            height: 34px;
            line-height: 34px;
            padding-left: 20px;
            background-color: #02B9E1;
            box-sizing: border-box;
            color: #fff;
            font-size: 16px;
            font-weight: bold;
          }

          .sub-yellow {
            flex: 3;
            height: 34px;
            line-height: 34px;
            padding-left: 14px;
            background-color: #FF9606;
            box-sizing: border-box;
            color: #fff;
            font-size: 16px;
            font-weight: bold;
          }

          .sub-green {
            flex: 2;
            height: 34px;
            line-height: 34px;
            text-align: center;
            background-color: #59E992;
            box-sizing: border-box;
            color: #fff;
            font-size: 16px;
            font-weight: bold;
          }

          .sub-gray {
            flex: 2;
            height: 34px;
            line-height: 34px;
            text-align: center;
            background-color: #596CA6;
            box-sizing: border-box;
            color: #fff;
            font-size: 16px;
            font-weight: bold;
          }
        }

        .sub-text {
          display: flex;
          align-items: center;
          justify-content: space-between;
          width: 100%;
          margin: 5px 0;
          padding: 0 12px;
          box-sizing: border-box;
          color: #fff;
          font-size: 16px;
          font-weight: bold;

          .blue {
            color: #02B9E1;
          }

          .yellow {
            color: #FF9606;
          }

          .green {
            color: #59E992;
          }

          .gray {
            color: #596CA6;
          }
        }

        .sub-label {
          display: flex;
          align-items: center;
          width: 100%;
          height: 48px;
          margin: 10px 0;

          .sub-label-item {
            width: 34px;
            height: 48px;
            line-height: 48px;
            margin: 0 2px;
            text-align: center;
            border-radius: 3px;
            background: linear-gradient(180deg, #FFFFFF 0%, #021A5C 100%);
            color: #fff;
            font-size: 30px;
            font-weight: 800;
          }
        }

        .sub-chart {
          width: 100%;
          height: calc(100% - 68px);
        }
      }
    }

    .sub-left-item1 {
      height: 55%;
      margin-bottom: 0;
    }
  }
}

// .sub-position {
//     position: absolute;
//     top: 50%;
//     left: 50%;
//     min-width: 300px;
//     padding: 10px 15px;
//     background-color: #024B85;
//     border: 1px solid #03BCD7;

//     .sub-position-line {
//       margin: 10px 0;
//       color: #fff;
//       font-size: 16px;
//     }

//     .sub-position-line1 {
//       color: #03BCD7;
//     }

//     .sub-position-label {
//       width: 110px;
//       margin: 10px 0;
//       padding: 4px 8px;
//       text-align: center;
//       border-radius: 6px;
//       background-color: #CD0A0A;
//       color: #fff;
//       font-size: 14px;
//     }
//   }</style>
