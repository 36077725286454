<template>
  <div class="sub">
    <div class="sub-left">
      <div class="sub-left-item sub-left-item1">
        <div class="sub-title">
          <span>两慢病易患人数</span>
        </div>
        <div class="sub-label sub-label1">
          <div class="sub-label-item">
            <img src="../../../assets/img/chronicDiseaseMonitor/label1.png" alt="" class="sub-label-img">
            <div class="sub-label-text">
              <div class="sub-label-top">
                <span>血压异常(人)</span>
              </div>
              <div class="sub-label-bottom">
                <span>今年</span>
                <span class="font">6530</span>
                <span>昨日</span>
                <span class="font red">+38</span>
              </div>
            </div>
          </div>
          <div class="sub-label-item">
            <img src="../../../assets/img/chronicDiseaseMonitor/label1.png" alt="" class="sub-label-img">
            <div class="sub-label-text">
              <div class="sub-label-top">
                <span>血糖异常(人)</span>
              </div>
              <div class="sub-label-bottom">
                <span>今年</span>
                <span class="font">6530</span>
                <span>昨日</span>
                <span class="font red">+38</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="sub-left-item">
        <div class="sub-title">
          <span>两慢病患者人群分布图</span>
        </div>
        <div class="sub-box">
          <div class="sub-tab">
            <div class="sub-tab-item sub-tab-active">
              <span>慢</span>
            </div>
            <div class="sub-tab-item">
              <span>高</span>
            </div>
            <div class="sub-tab-item">
              <span>糖</span>
            </div>
          </div>
          <div class="sub-chart" id="echarts4"></div>
        </div>
      </div>
      <div class="sub-left-item">
        <div class="sub-title">
          <span>两慢病患者健康指数风险分层</span>
        </div>
        <div class="sub-box">
          <div class="sub-tab">
            <div class="sub-tab-item sub-tab-active">
              <span>慢</span>
            </div>
            <div class="sub-tab-item">
              <span>高</span>
            </div>
            <div class="sub-tab-item">
              <span>糖</span>
            </div>
          </div>
          <div class="sub-content">
            <div class="sub-content-left" id="echarts1"></div>
            <div class="sub-content-right">
              <div class="sub-content-list">
                <div class="sub-content-item">
                  <span>很高危</span>
                </div>
                <div class="sub-content-item">
                  <span>(0,300)</span>
                </div>
                <div class="sub-content-item">
                  <span>(0.63%)</span>
                </div>
                <div class="sub-content-item">
                  <span>153人</span>
                </div>
              </div>
              <div class="sub-content-list">
                <div class="sub-content-item">
                  <span>高危</span>
                </div>
                <div class="sub-content-item">
                  <span>(0,300)</span>
                </div>
                <div class="sub-content-item">
                  <span>(0.63%)</span>
                </div>
                <div class="sub-content-item">
                  <span>153人</span>
                </div>
              </div>
              <div class="sub-content-list">
                <div class="sub-content-item">
                  <span>中危</span>
                </div>
                <div class="sub-content-item">
                  <span>(0,300)</span>
                </div>
                <div class="sub-content-item">
                  <span>(0.63%)</span>
                </div>
                <div class="sub-content-item">
                  <span>153人</span>
                </div>
              </div>
              <div class="sub-content-list">
                <div class="sub-content-item">
                  <span>低危</span>
                </div>
                <div class="sub-content-item">
                  <span>(0,300)</span>
                </div>
                <div class="sub-content-item">
                  <span>(0.63%)</span>
                </div>
                <div class="sub-content-item">
                  <span>153人</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="sub-left-item">
        <div class="sub-title">
          <span>两慢病并发症情况</span>
        </div>
        <div class="sub-box" id="echarts2">
        </div>
      </div>
    </div>
    <div class="sub-center">
      <div class="sub-center-item sub-center-item3">
        <div class="sub-label">
          <span>常住人口</span>
          <span class="color">548568</span>
          <span>人</span>
        </div>
        <div class="sub-label">
          <span>户籍人口</span>
          <span class="color">548568</span>
          <span>人</span>
        </div>
      </div>
      <div class="sub-center-item sub-center-item1">
        <div class="sub-title">
          <span>两慢病患者基本情况</span>
        </div>
        <div class="sub-box sub-box1">
          <div class="sub-box-left">
            <div class="sub-label-item">
              <img src="../../../assets/img/chronicDiseaseMonitor/label1.png" alt="" class="sub-label-img">
              <div class="sub-label-text">
                <div class="sub-label-top">
                  <span>总人数</span>
                </div>
                <div class="sub-label-bottom">
                  <span>61286</span>
                  <span class="font">人</span>
                </div>
              </div>
            </div>
            <div class="sub-label-item">
              <img src="../../../assets/img/chronicDiseaseMonitor/label1.png" alt="" class="sub-label-img">
              <div class="sub-label-text">
                <div class="sub-label-top">
                  <span>当年新发现人数</span>
                </div>
                <div class="sub-label-bottom">
                  <span>61286</span>
                  <span class="font">人</span>
                </div>
              </div>
            </div>
            <div class="sub-label-item">
              <img src="../../../assets/img/chronicDiseaseMonitor/label1.png" alt="" class="sub-label-img">
              <div class="sub-label-text">
                <div class="sub-label-top">
                  <span>年初登记人数</span>
                </div>
                <div class="sub-label-bottom">
                  <span>61286</span>
                  <span class="font">人</span>
                </div>
              </div>
            </div>
            <div class="sub-label-item">
              <img src="../../../assets/img/chronicDiseaseMonitor/label1.png" alt="" class="sub-label-img">
              <div class="sub-label-text">
                <div class="sub-label-top">
                  <span>平均年龄</span>
                </div>
                <div class="sub-label-bottom">
                  <span>61286</span>
                  <span class="font">岁</span>
                </div>
              </div>
            </div>
          </div>
          <div class="sub-box-right">
            <div class="sub-box-chart">
              <div class="sub-box-chart1">
                <div class="sub-box-content">
                  <span>499</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="sub-center-item sub-center-item2">
        <div class="sub-title">
          <span>两慢病患者基本情况</span>
        </div>
        <div class="sub-position">
          <div class="sub-position-item sub-position-active">
            <span>健康管理系数</span>
          </div>
          <div class="sub-position-item">
            <span>健康指数</span>
          </div>
          <div class="sub-position-item">
            <span>管理负荷系数</span>
          </div>
        </div>
        <div class="sub-box">
          <div class="sub-box-card">
            <div class="sub-card green">
              <div class="sub-card-item">
                <span>高血压患者总人数</span>
              </div>
              <div class="sub-card-item">
                <span class="size">56613</span>
                <span>人</span>
              </div>
            </div>
            <div class="sub-card yellow">
              <div class="sub-card-item">
                <span>糖尿病患者总人数</span>
              </div>
              <div class="sub-card-item">
                <span class="size">56613</span>
                <span>人</span>
              </div>
            </div>
            <div class="sub-card blue">
              <div class="sub-card-item">
                <span>健康管理系数</span>
              </div>
              <div class="sub-card-item">
                <span class="size">56613</span>
                <span>人</span>
              </div>
            </div>
          </div>
          <div class="sub-box-chart" id="map"></div>
        </div>
      </div>
    </div>
    <div class="sub-right">
      <div class="sub-left-item">
        <div class="sub-title">
          <span>高血压患者健康管理情况</span>
        </div>
        <div class="sub-box sub-box1">
          <div class="sub-label-item">
            <img src="../../../assets/img/chronicDiseaseMonitor/label3.png" alt="" class="sub-label-img">
            <div class="sub-label-text">
              <div class="sub-label-top">
                <span>发现率</span>
              </div>
              <div class="sub-label-bottom">
                <span>10.9%</span>
              </div>
            </div>
          </div>
          <div class="sub-label-item">
            <img src="../../../assets/img/chronicDiseaseMonitor/label4.png" alt="" class="sub-label-img">
            <div class="sub-label-text">
              <div class="sub-label-top">
                <span>签约率</span>
              </div>
              <div class="sub-label-bottom">
                <span>10.9%</span>
              </div>
            </div>
          </div>
          <div class="sub-label-item">
            <img src="../../../assets/img/chronicDiseaseMonitor/label4.png" alt="" class="sub-label-img">
            <div class="sub-label-text">
              <div class="sub-label-top">
                <span>管理率</span>
              </div>
              <div class="sub-label-bottom">
                <span>10.9%</span>
              </div>
            </div>
          </div>
          <div class="sub-label-item">
            <img src="../../../assets/img/chronicDiseaseMonitor/label5.png" alt="" class="sub-label-img">
            <div class="sub-label-text">
              <div class="sub-label-top">
                <span>体检率</span>
              </div>
              <div class="sub-label-bottom">
                <span>10.9%</span>
              </div>
            </div>
          </div>
          <div class="sub-label-item">
            <img src="../../../assets/img/chronicDiseaseMonitor/label6.png" alt="" class="sub-label-img">
            <div class="sub-label-text">
              <div class="sub-label-top">
                <span>血压控制率</span>
              </div>
              <div class="sub-label-bottom">
                <span>10.9%</span>
              </div>
            </div>
          </div>
          <div class="sub-label-item">
            <img src="../../../assets/img/chronicDiseaseMonitor/label7.png" alt="" class="sub-label-img">
            <div class="sub-label-text">
              <div class="sub-label-top">
                <span>规范管理率</span>
              </div>
              <div class="sub-label-bottom">
                <span>10.9%</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="sub-left-item">
        <div class="sub-title">
          <span>两慢病患者就诊情况</span>
        </div>
        <div class="sub-tab">
          <div class="sub-tab-item sub-tab-active">
            <span>慢</span>
          </div>
          <div class="sub-tab-item">
            <span>高</span>
          </div>
          <div class="sub-tab-item">
            <span>糖</span>
          </div>
        </div>
        <div class="sub-box sub-box1">
          <div class="sub-box-head"></div>
          <div class="sub-box-footer">
            <div class="sub-footer">
              <div class="sub-footer-top">
                <div class="sub-footer-green" style="--left: 70%"></div>
                <div class="sub-footer-blue" style="--right: 30%"></div>
              </div>
              <div class="sub-footer-item">
                <div class="sub-footer-box green"></div>
                <div class="sub-footer-text">
                  <span>基层</span>
                </div>
                <div class="sub-footer-text">
                  <span>75.83%</span>
                </div>
                <div class="sub-footer-text">
                  <span>402356人次</span>
                </div>
              </div>
              <div class="sub-footer-item">
                <div class="sub-footer-box blue"></div>
                <div class="sub-footer-text">
                  <span>基层</span>
                </div>
                <div class="sub-footer-text">
                  <span>75.83%</span>
                </div>
                <div class="sub-footer-text">
                  <span>402356人次</span>
                </div>
              </div>
            </div>
            <div class="sub-footer">
              <div class="sub-footer-top">
                <div class="sub-footer-green" style="--left: 70%"></div>
                <div class="sub-footer-blue" style="--right: 30%"></div>
              </div>
              <div class="sub-footer-item">
                <div class="sub-footer-box green"></div>
                <div class="sub-footer-text">
                  <span>基层</span>
                </div>
                <div class="sub-footer-text">
                  <span>75.83%</span>
                </div>
                <div class="sub-footer-text">
                  <span>402356人次</span>
                </div>
              </div>
              <div class="sub-footer-item">
                <div class="sub-footer-box blue"></div>
                <div class="sub-footer-text">
                  <span>基层</span>
                </div>
                <div class="sub-footer-text">
                  <span>75.83%</span>
                </div>
                <div class="sub-footer-text">
                  <span>402356人次</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="sub-left-item sub-left-item1">
        <div class="sub-title">
          <span>两慢病患者就诊费用分析</span>
        </div>
        <div class="sub-tab">
          <div class="sub-tab-item sub-tab-active">
            <span>慢</span>
          </div>
          <div class="sub-tab-item">
            <span>高</span>
          </div>
          <div class="sub-tab-item">
            <span>糖</span>
          </div>
        </div>
        <div class="sub-box sub-box1">
          <div class="sub-box-item">
            <div class="sub-box-text">
              <span>门诊均次费用</span>
            </div>
            <div class="sub-box-text">
              <span>120.02元</span>
            </div>
            <div class="sub-box-text">
              <span>比去年下降</span>
              <span class="blue">206</span>
              <span>元</span>
            </div>
          </div>
          <div class="sub-box-item">
            <div class="sub-box-text">
              <span>医保支出费用</span>
            </div>
            <div class="sub-box-text">
              <span>120.02元</span>
            </div>
            <div class="sub-box-text">
              <span>比去年下降</span>
              <span class="red">206</span>
              <span>元</span>
            </div>
          </div>
        </div>
      </div>
      <div class="sub-left-item">
        <div class="sub-title">
          <span>病种就诊次数排名（人次）</span>
        </div>
        <div class="sub-box">
          <div class="sub-list">
            <div class="sub-list-label">
              <span>1</span>
            </div>
            <div class="sub-list-text">
              <span>高血压</span>
            </div>
            <div class="sub-list-box">
              <div class="sub-list-box1" style="--size: 100%"></div>
            </div>
            <div class="sub-list-text">
              <span>124568</span>
            </div>
          </div>
          <div class="sub-list">
            <div class="sub-list-label">
              <span>2</span>
            </div>
            <div class="sub-list-text">
              <span>糖尿病</span>
            </div>
            <div class="sub-list-box">
              <div class="sub-list-box1" style="--size: 80%"></div>
            </div>
            <div class="sub-list-text">
              <span>124568</span>
            </div>
          </div>
          <div class="sub-list">
            <div class="sub-list-label">
              <span>3</span>
            </div>
            <div class="sub-list-text">
              <span>冠状动脉粥</span>
            </div>
            <div class="sub-list-box">
              <div class="sub-list-box1" style="--size: 60%"></div>
            </div>
            <div class="sub-list-text">
              <span>124568</span>
            </div>
          </div>
          <div class="sub-list">
            <div class="sub-list-label">
              <span>4</span>
            </div>
            <div class="sub-list-text">
              <span>血液透析状</span>
            </div>
            <div class="sub-list-box">
              <div class="sub-list-box1" style="--size: 40%"></div>
            </div>
            <div class="sub-list-text">
              <span>124568</span>
            </div>
          </div>
          <div class="sub-list">
            <div class="sub-list-label">
              <span>5</span>
            </div>
            <div class="sub-list-text">
              <span>急性上呼吸</span>
            </div>
            <div class="sub-list-box">
              <div class="sub-list-box1" style="--size: 20%"></div>
            </div>
            <div class="sub-list-text">
              <span>124568</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import geoJson from './dlzz.json'
import bdrcx from '@/assets/json/bdrcx.json'
export default {
  data () {
    return {
      myChart1: null,
      myChart2: null,
      myChart3: null,
      myChart4: null,
      list: [
        { name: '东里庄村村民委员会', value: 20057.34, itemStyle: { color: '#eb8146' } }
      ],
      datas2: ['心脏疾病', '脑血管病', '肾脏疾病', '冠心病', '癫疯', '慢阻肺'],
      datass2: [120, 200, 150, 80, 70, 110],
      datas4: ['20', '30', '40', '50', '60', '70'],
      datass4: [20, 30, 150, 80, 70, 110]
    }
  },
  computed: {
    ...mapState(['loginInfo'])
  },
  mounted () {
    this.$nextTick(() => {
      this.canvas1()
      this.canvas2()
      this.canvas3()
      this.canvas4()
    })
    window.addEventListener('resize', this.getresize)
  },
  beforeDestroy () {
    // 页面销毁时销毁监听事件
    window.removeEventListener('resize', this.getresize)
  },
  methods: {
    getresize () {
      setTimeout(() => {
        this.myChart1.resize()
        this.myChart2.resize()
        this.myChart3.resize()
        this.myChart4.resize()
      }, 500)
    },
    canvas1 () {
      var chartDom = document.getElementById('echarts1')
      this.myChart1 = this.$echarts.init(chartDom)
      const option = {
        color: ['#EE6666', '#FAC858'],
        tooltip: {
          trigger: 'item',
          formatter: '{b}: {d}%'
        },
        legend: {
          show: false
        },
        grid: {
          show: false,
          top: '10%', // 一下数值可为百分比也可为具体像素值
          right: '10%',
          bottom: '10%',
          left: '20%'
        },
        series: [
          {
            name: '评估长者',
            type: 'pie',
            radius: '60%',
            data: [
              { value: 35, name: '甲乙类' },
              { value: 65, name: '丙类' }
            ],
            center: ['30%', '60%'],
            label: { // 删除指示线
              show: false
            }
          }
        ]
      }

      option && this.myChart1.setOption(option)
    },
    canvas2 () {
      var chartDom = document.getElementById('echarts2')
      this.myChart2 = this.$echarts.init(chartDom)
      const option = {
        legend: {
          y: 'top',
          x: 'right',
          data: ['高血压患者', '糖尿病患者'],
          textStyle: {
            color: '#fff'
          }
        },
        grid: {
          containLabel: true,
          top: '20%', // 一下数值可为百分比也可为具体像素值
          right: '8%',
          bottom: '8%',
          left: '8%'
        },
        label: {
          show: true,
          color: '#fff',
          fontSize: 14,
          position: 'top',
          y: 'top',
          x: 'right',
          height: 100,
          offset: [0, 0]
        },
        xAxis: [
          // x轴数据设置
          {
            type: 'category',
            splitLine: {
              show: false
            },
            axisLine: {
              lineStyle: {
                color: '#fff'
              }
            },
            axisLabel: {
              interval: 0,
              rotate: 10
            },
            data: this.datas2
          }
        ],
        yAxis: [
          {
            type: 'value',
            name: '人数',
            nameTextStyle: {
              color: '#fff',
              nameLocation: 'start'
            },
            offset: 0,
            axisLine: {
              show: true,
              lineStyle: {
                color: '#fff'
              },
              axisLabel: {
                show: true,
                color: '#fff',
                fontSize: 16
              }
            },
            axisTick: {
              show: false
            }
          }
        ],
        series: [
          {
            name: '高血压患者',
            data: this.datass2,
            type: 'bar',
            barWidth: 8,
            label: {
              show: false,
              textStyle: {
                color: '#3FEBF8',
                fontSize: 9
              },
              rotate: 45
            },
            itemStyle: {
              color: '#91CC75'
            },
            backgroundStyle: {
              color: '#91CC75'
            }
          },
          {
            name: '糖尿病患者',
            data: this.datass2,
            type: 'bar',
            barWidth: 8,
            label: {
              show: false,
              textStyle: {
                color: '#3FEBF8',
                fontSize: 9
              },
              rotate: 45
            },
            itemStyle: {
              color: '#FA750D'
            },
            backgroundStyle: {
              color: '#FA750D'
            },
            barGap: 0.5
          }
        ]
      }

      option && this.myChart2.setOption(option)
    },
    canvas3 () {
      var chartDom = document.getElementById('map')
      this.myChart3 = this.$echarts.init(chartDom)
      if (this.loginInfo.jgbh.indexOf('130629') > -1) {
        this.$echarts.registerMap('HK', bdrcx)
      } else {
        this.$echarts.registerMap('HK', geoJson)
      }
      const option = {
        layoutCenter: ['50%', '50%'], // 位置
        layoutSize: '100%', // 大小
        aspectScale: 0.80, // 宽高比
        tooltip: {
          trigger: 'item',
          formatter: (item) => {
            var arr = this.list
            let str = ''
            for (var i in arr) {
              if (arr[i].name === item.name) {
                str += ` <div class="sub-position" style="min-width: 300px;padding: 10px 15px;background-color: #024B85;border: 1px solid #03BCD7;">
                  <div class="sub-position-line" style="margin: 10px 0;color: #fff;font-size: 16px;">
                    <span>李明明</span>
                    <span>男</span>
                    <span>86岁</span>
                    <span>15803335684</span>
                  </div>
                  <div class="sub-position-label" style="width: 110px;
      margin: 10px 0;
      padding: 4px 8px;
      text-align: center;
      border-radius: 6px;
      background-color: #CD0A0A;
      color: #fff;
      font-size: 14px;">
                    <span>安全防护报警</span>
                  </div>
                  <div class="sub-position-line" style="margin: 10px 0;color: #fff;font-size: 16px;">
                    <span>时间  2022-03-03  18:56:6</span>
                  </div>
                  <div class="sub-position-line sub-position-line1" style="margin: 10px 0;color: #03BCD7;font-size: 16px;">
                    <span>位置：石家庄 桥西区 友谊街道238号 </span>
                  </div>
                </div>`
              }
            }
            return str
          }
        },
        series: [
          {
            name: '东里庄镇',
            type: 'map',
            map: 'HK',
            label: {
              show: true,
              color: '#fff'
            },
            itemStyle: {
              // 默认的区块样式
              borderColor: '#fff',
              areaColor: '#20C1ED',
              // 高亮样式
              emphasis: {
                areaColor: '#0F6ABB'
              }
            },
            // 选中样式
            select: {
              label: {
                // 选中区域的label(文字)样式
                color: '#fff'
              },

              itemStyle: {
                color: '#fff',
                // 选中区域红色
                areaColor: '#0F6ABB',
                // 选中区域边框
                borderColor: '#fff',
                borderWidth: 3
              }

            },
            data: [
              // { name: '东里庄村村民委员会', value: 20057.34, itemStyle: { color: '#eb8146' } },
              // { name: '南小吾村村民委员会', value: 20057.34, itemStyle: { color: '#eb8146' } }
            ],
            // 自定义名称映射
            nameMap: {
              东里庄村村民委员会: '东里庄村村民委员会',
              南小吾村村民委员会: '南小吾村村民委员会'
            }
          }
        ]
      }

      option && this.myChart3.setOption(option)
    },
    canvas4 () {
      var chartDom = document.getElementById('echarts4')
      this.myChart4 = this.$echarts.init(chartDom)
      const option = {
        legend: {
          y: 'top',
          x: 'right',
          data: ['男', '女'],
          textStyle: {
            color: '#fff'
          }
        },
        grid: {
          containLabel: true,
          top: '20%', // 一下数值可为百分比也可为具体像素值
          right: '12%',
          bottom: '2%',
          left: '8%'
        },
        label: {
          show: true,
          color: '#fff',
          fontSize: 14,
          position: 'top',
          height: 100,
          offset: [0, 0]
        },
        xAxis: [
          // x轴数据设置
          {
            type: 'category',
            name: '年龄',
            nameTextStyle: {
              color: '#fff',
              nameLocation: 'start'
            },
            splitLine: {
              show: false
            },
            axisLine: {
              lineStyle: {
                color: '#fff'
              }
            },

            data: this.datas4
          }
        ],
        yAxis: [
          {
            type: 'value',
            name: '人数',
            nameTextStyle: {
              color: '#fff',
              nameLocation: 'start'
            },
            offset: 0,
            axisLine: {
              show: true,
              lineStyle: {
                color: '#fff'
              },
              axisLabel: {
                show: true,
                color: '#fff',
                fontSize: 16
              }
            },
            axisTick: {
              show: false
            }
          }
        ],
        series: [
          {
            name: '男',
            data: this.datass4,
            type: 'bar',
            barWidth: 8,
            label: {
              show: false,
              textStyle: {
                color: '#3FEBF8',
                fontSize: 9
              },
              rotate: 45
            },
            itemStyle: {
              color: '#03BCD7'
            },
            backgroundStyle: {
              color: '#91CC75'
            }
          },
          {
            name: '女',
            data: this.datass4,
            type: 'bar',
            barWidth: 8,
            label: {
              show: false,
              textStyle: {
                color: '#3FEBF8',
                fontSize: 9
              },
              rotate: 45
            },
            itemStyle: {
              color: '#C32DC9'
            },
            backgroundStyle: {
              color: '#FA750D'
            },
            barGap: 0.5
          }
        ]
      }

      option && this.myChart4.setOption(option)
    }
  }
}
</script>

<style lang="less" scoped>
.sub {
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: 100%;
  background-image: url('../../../assets/img/zk1.png');
  background-size: 100% 100%;
  background-repeat: no-repeat;

  .sub-left {
    width: 25%;
    height: 100%;

    .sub-left-item {
      width: 100%;
      height: calc(27% - 10px);
      margin-top: 10px;
      padding: 5px;
      background-color: rgba(2, 27, 99, .6);
      box-sizing: border-box;
    }

    .sub-left-item1 {
      width: 100%;
      height: 19%;
      margin-top: 0;
    }

    .sub-title {
      width: 70%;
      height: 35px;
      line-height: 40px;
      padding-left: 30px;
      background-image: url('../../../assets/img/zk2.png');
      background-size: 100% 100%;
      background-repeat: no-repeat;
      box-sizing: border-box;
      color: #fff;
      font-size: 20px;
      font-weight: bold;
    }

    .sub-box {
      width: 100%;
      height: calc(100% - 35px);

      .sub-tab {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 42px;

        .sub-tab-item {
          padding: 2px 7px;
          background-color: #fff;
          color: #333333;
          font-size: 16px;
        }

        .sub-tab-active {
          background-color: #03BCD7;
          color: #fff;
        }
      }

      .sub-chart {
        width: 100%;
        height: calc(100% - 42px);
      }

      .sub-content {
        display: flex;
        flex-direction: row;
        width: 100%;
        height: calc(100% - 42px);

        .sub-content-left {
          width: 40%;
          height: 100%;
        }

        .sub-content-right {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: space-evenly;
          width: 60%;
          height: 100%;

          .sub-content-list {
            display: flex;
            flex-direction: row;
            align-items: center;
            width: 100%;

            .sub-content-item {
              flex: 1;
              color: #fff;
              font-size: 14px;
            }
          }
        }
      }
    }

    .sub-label {
      width: 100%;
      height: calc(100% - 35px);
      padding: 0 20px;
      box-sizing: border-box;

      .sub-label-item {
        display: flex;
        flex-direction: row;
        align-items: center;
        width: 48%;

        .sub-label-img {
          width: 45px;
          height: 40px;
        }

        .sub-label-text {
          width: calc(100% - 50px);
          margin-left: 5px;

          .sub-label-top {
            color: #fff;
            font-size: 14px;
          }

          .sub-label-bottom {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            width: 100%;
            margin-top: 12px;
            color: #fff;
            font-size: 14px;

            .font {
              font-size: 20px;
            }

            .red {
              color: #FF0000;
            }
          }
        }
      }
    }

    .sub-label1 {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
    }
  }

  .sub-center {
    width: calc(50% - 20px);
    height: 100%;

    .sub-center-item {
      position: relative;
      width: 100%;
      height: calc(15% - 10px);
      margin-bottom: 10px;
      background-color: #021B63;
    }

    .sub-center-item1 {
      height: calc(32% - 10px);
    }

    .sub-center-item2 {
      height: 53%;
      margin-bottom: 0;
    }

    .sub-center-item3 {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-evenly;

      .sub-label {
        color: #fff;
        font-size: 18px;

        .color {
          margin-left: 13px;
          margin-right: 13px;
          color: #FFFF01;
          font-size: 28px;
        }
      }
    }

    .sub-title {
      width: 40%;
      height: 35px;
      line-height: 40px;
      padding-left: 30px;
      background-image: url('../../../assets/img/zk2.png');
      background-size: 100% 100%;
      background-repeat: no-repeat;
      box-sizing: border-box;
      color: #fff;
      font-size: 20px;
      font-weight: bold;
    }

    .sub-box {
      width: 100%;
      height: calc(100% - 35px);

      .sub-box-left {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        width: 70%;
        height: 100%;

        .sub-label-item {
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: center;
          width: 50%;

          .sub-label-img {
            width: 45px;
            height: 40px;
          }

          .sub-label-text {
            margin-left: 5px;

            .sub-label-top {
              color: #fff;
              font-size: 18px;
            }

            .sub-label-bottom {
              display: flex;
              flex-direction: row;
              justify-content: space-between;
              width: 100%;
              color: #fff;
              font-size: 28px;

              .font {
                margin-left: 16px;
                font-size: 20px;
              }
            }
          }
        }
      }

      .sub-box-right {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 30%;
        height: 100%;

        .sub-box-chart {
          position: relative;
          width: 100px;
          height: 100px;
          padding: 20px;
          border: 9px dashed #E56103;
          border-radius: 50%;
          box-sizing: border-box;

          .sub-box-chart1 {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            display: flex;
            align-items: center;
            justify-content: center;
            width: 100%;
            height: 100%;
            border: 5px solid #021B63;
            border-radius: 50%;
          }

          .sub-box-content {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 60px;
            height: 60px;
            border-radius: 50%;
            background: linear-gradient(180deg, #021B63 50%, #FF7E0C 100%);
            color: #fff;
            font-size: 20px;
          }
        }
      }

      .sub-box-card {
        display: flex;
        flex-direction: row;
        align-items: center;
        width: 100%;
        height: 110px;
        padding-left: 27px;
        box-sizing: border-box;

        .sub-card {
          display: flex;
          flex-direction: column;
          justify-content: space-evenly;
          align-items: center;
          width: 150px;
          height: 68px;
          margin-left: 9px;
          margin-right: 9px;

          .sub-card-item {
            color: #fff;
            font-size: 14px;

            .size {
              margin-right: 12px;
              font-size: 22px;
            }
          }
        }

        .green {
          background: linear-gradient( 89deg, #11B38A 0%, #021B63 100%);
        }

        .yellow {
          background: linear-gradient( 89deg, #D35C0E 0%, #021B63 100%);
        }

        .blue {
          background: linear-gradient( 89deg, #1279D8 0%, #021B63 100%);
        }
      }

      .sub-box-chart {
        width: 100%;
        height: calc(100% - 110px);
      }
    }

    .sub-box1 {
      display: flex;
      flex-direction: row;
    }

    .sub-position {
      position: absolute;
      top: 6px;
      right: 15px;
      display: flex;
      flex-direction: row;
      align-items: center;

      .sub-position-item {
        margin-left: 5px;
        margin-right: 5px;
        padding: 1px 10px;
        background-color: #84898A;
        color: #fff;
        font-size: 20px;
        cursor: pointer;
      }

      .sub-position-active {
        background-color: #03BCD7;
      }
    }
  }

  .sub-right {
    width: 25%;
    height: 100%;

    .sub-left-item {
      position: relative;
      width: 100%;
      height: calc(27% - 10px);
      margin-top: 10px;
      padding: 5px;
      background-color: rgba(2, 27, 99, .6);
      box-sizing: border-box;
    }

    .sub-left-item1 {
      width: 100%;
      height: 19%;
    }

    .sub-title {
      width: 70%;
      height: 35px;
      line-height: 40px;
      padding-left: 30px;
      background-image: url('../../../assets/img/zk2.png');
      background-size: 100% 100%;
      background-repeat: no-repeat;
      box-sizing: border-box;
      color: #fff;
      font-size: 20px;
      font-weight: bold;
    }

    .sub-tab {
      position: absolute;
      top: 10px;
      right: 10px;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;

      .sub-tab-item {
        padding: 2px 7px;
        background-color: #fff;
        color: #333333;
        font-size: 16px;
        cursor: pointer;
      }

      .sub-tab-active {
        background-color: #03BCD7;
        color: #fff;
      }
    }

    .sub-box {
      width: 100%;
      height: calc(100% - 35px);

      .sub-label-item {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        width: 33.3%;

        .sub-label-img {
          width: 45px;
          height: 40px;
        }

        .sub-label-text {
          margin-left: 5px;

          .sub-label-top {
            color: #fff;
            font-size: 14px;
          }

          .sub-label-bottom {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            width: 100%;
            margin-top: 4px;
            color: #0176CB;
            font-size: 18px;
          }
        }
      }

      .sub-box-item {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-evenly;
        width: 50%;
        height: 100%;

        .sub-box-text {
          color: #fff;
          font-size: 14px;

          .blue {
            color: #03BCD7;
          }

          .red {
            color: #FF0000;
          }
        }
      }

      .sub-list {
        display: flex;
        flex-direction: row;
        align-items: center;
        width: 100%;
        margin-top: 8px;
        padding-left: 10px;
        padding-right: 10px;
        box-sizing: border-box;

        .sub-list-label {
          width: 32px;
          margin-left: 5px;
          margin-right: 5px;
          padding-top: 2px;
          padding-bottom: 2px;
          text-align: center;
          background-color: #0E3D76;
          color: #fff;
          font-size: 16px;
          font-weight: bold;
        }

        .sub-list-text {
          width: 120px;
          margin-left: 5px;
          margin-right: 5px;
          color: #fff;
          font-size: 16px;
        }

        .sub-list-box {
          width: calc(100% - 42px - 130px - 10px);
          height: 10px;
          margin-left: 5px;
          margin-right: 5px;

          .sub-list-box1 {
            width: var(--size);
            height: 100%;
            background-color: #09EAFC;
          }
        }
      }

      .sub-box-head {
        width: 200px;
        height: 34px;
        margin-top: 10px;
        margin-bottom: 10px;
        line-height: 34px;
        background: linear-gradient( 89deg, #11B38A 0%, #021B63 100%);
        text-align: center;
        color: #fff;
        font-size: 14px;
      }

      .sub-box-footer {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        width: 100%;
        height: calc(100% - 54px);
        border-top: 1px solid #03BCD7;
        box-sizing: border-box;

        .sub-footer {
          display: flex;
          flex-direction: column;
          justify-content: space-evenly;
          width: 47%;
          height: 100%;

          .sub-footer-top {
            display: flex;
            flex-direction: row;
            align-items: center;
            width: 100%;
            height: 22px;

            .sub-footer-green {
              width: var(--left);
              height: 100%;
              background-color: #10CC9A;
            }

            .sub-footer-blue {
              width: var(--right);
              height: 100%;
              background-color: #2788F9;
            }
          }

          .sub-footer-item {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;
            width: 100%;

            .sub-footer-box {
              width: 10px;
              height: 10px;
            }

            .green {
              background-color: #10CC9A;
            }

            .blue {
              background-color: #2788F9;
            }

            .sub-footer-text {
              color: #fff;
              font-size: 14px;
            }
          }
        }
      }
    }

    .sub-box1 {
      display: flex;
      flex-wrap: wrap;
      padding: 0 20px;
      box-sizing: border-box;
    }

    .sub-box {
      padding: 0 20px;
      box-sizing: border-box;
    }
  }
}
</style>
