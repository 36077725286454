<template>
  <div class="sub-page">
    <div class="sub-page-top">
      <div class="sub-item">
        <div class="position position1"></div>
        <div class="position position2"></div>
        <div class="position position3"></div>
        <div class="position position4"></div>
        <div class="sub-item-box">
          <span>8人</span>
          <span>目前老人数量</span>
        </div>
      </div>

      <div class="sub-item">
        <div class="position position1"></div>
        <div class="position position2"></div>
        <div class="position position3"></div>
        <div class="position position4"></div>
        <div class="sub-item-box">
          <span>8人</span>
          <span>离开床位人数</span>
        </div>
      </div>

      <div class="sub-item">
        <div class="position position1"></div>
        <div class="position position2"></div>
        <div class="position position3"></div>
        <div class="position position4"></div>
        <div class="sub-item-box">
          <span>8人</span>
          <span>心率异常</span>
        </div>
      </div>

      <div class="sub-item">
        <div class="position position1"></div>
        <div class="position position2"></div>
        <div class="position position3"></div>
        <div class="position position4"></div>
        <div class="sub-item-box">
          <span>8人</span>
          <span>呼吸异常</span>
        </div>
      </div>

    </div>
    <div class="sub-page-center">
      <div class="sub-label" v-for="(item, index) in 8" :key="index">
        <div class="position position1"></div>
        <div class="position position2"></div>
        <div class="position position3"></div>
        <div class="position position4"></div>
        <div class="sub-label-box">
          <div class="label-top">
            <div class="name" :class="{ 'name1': index > 3 }">
              <div class="text">
                <span>李明明</span>
              </div>
              <div class="status">
                <span>离线</span>
              </div>
            </div>
            <div class="test">
              <div class="test-item">
                <div class="test-item-top">
                  <span>心率</span>
                </div>
                <div class="test-item-bottom">
                  <img src="../../../assets/img/znjc/xinlv.png" class="img">
                  <span class="font red">次/分</span>
                </div>
              </div>
              <div class="test-item">
                <div class="test-item-top">
                  <span>心率</span>
                </div>
                <div class="test-item-bottom">
                  <span class="font">无</span>
                </div>
              </div>
              <div class="test-item">
                <div class="test-item-top">
                  <span>呼吸</span>
                </div>
                <div class="test-item-bottom">
                  <img src="../../../assets/img/znjc/huxi.png" class="img">
                  <span class="font red">次/分</span>
                </div>
              </div>
            </div>
          </div>
          <div class="label-table">
            <div class="label-tr">
              <div class="label-td">
                <span>年龄</span>
              </div>
              <div class="label-td">
                <span>32</span>
              </div>
              <div class="label-td">
                <span>性别</span>
              </div>
              <div class="label-td">
                <span>男</span>
              </div>
            </div>
            <div class="label-tr">
              <div class="label-td">
                <span>联系方式</span>
              </div>
              <div class="label-td label-td1">
                <span></span>
              </div>
            </div>
          </div>
          <div class="label-charts">
            <Echarts :index="index + ''"></Echarts>
          </div>
        </div>
      </div>
    </div>
    <div class="sub-page-bottom">
      <div class="sub-pagination">
        <div class="sub-text">
          <span>共8条</span>
        </div>
        <el-input-number v-model="num" size="mini"></el-input-number>
        <div class="sub-text">
          <span>前往</span>
        </div>
        <el-input v-model="input" size="mini"></el-input>
        <div class="sub-text sub-text1">
          <span>页</span>
        </div>
      </div>
      <div class="sub-tab">
        <div class="sub-tab-item" :class="{ 'sub-tab-active': index === tabIndex }" v-for="(item, index) in tabs" :key="index" @click="tabClick(index)">
          <div class="sub-tab-box" :class="{ 'red': index > 1, 'sub-tab-box-active': index === tabIndex }">
            <span>{{ item }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Echarts from './echarts.vue'
export default {
  components: {
    Echarts
  },
  data () {
    return {
      options: [{
        value: '1',
        label: '1'
      }],
      value: '',
      num: 1,
      input: 1,
      tabIndex: 0,
      tabs: Object.freeze(['全部', '离开床位', '心率异常', '呼吸异常'])
    }
  },
  methods: {
    tabClick (index) {
      this.tabIndex = index
    }
  }
}
</script>

<style lang="less" scoped>
.sub-page {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  height: 100%;
  padding: 20px 150px;
  box-sizing: border-box;

  .sub-page-top {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 85px;

    .sub-item {
      position: relative;
      width: 313px;
      height: 100%;
      padding: 0 1px;
      background-color: #021B63;
      box-sizing: border-box;

      .position {
        position: absolute;
        z-index: 1;
        width: 28px;
        height: 25px;
        background-color: #18CDFD;
      }

      .position1 {
        top: 0;
        left: 0;
      }

      .position2 {
        top: 0;
        right: 0;
      }

      .position3 {
        bottom: 0;
        left: 0;
      }

      .position4 {
        bottom: 0;
        right: 0;
      }

      .sub-item-box {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        position: absolute;
        top: 1px;
        left: 1px;
        z-index: 88;
        width: calc(100% - 2px);
        height: calc(100% - 2px);
        padding: 18px 20px;
        background-color: #021B63;
        color: #fff;
        font-size: 16px;
        box-sizing: border-box;
      }
    }

  }

  .sub-page-center {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    align-content: space-between;
    // height: calc(100% - 225px);
    height: 640px;
    margin: 10px 0;

    .sub-label {
      position: relative;
      width: 388px;
      height: 284px;
      background-color: #021B63;

      .position {
        position: absolute;
        z-index: 1;
        width: 28px;
        height: 25px;
        background-color: #18CDFD;
      }

      .position1 {
        top: 0;
        left: 0;
      }

      .position2 {
        top: 0;
        right: 0;
      }

      .position3 {
        bottom: 0;
        left: 0;
      }

      .position4 {
        bottom: 0;
        right: 0;
      }

      .sub-label-box {
        display: flex;
        flex-direction: column;
        position: absolute;
        top: 1px;
        left: 1px;
        z-index: 88;
        width: calc(100% - 2px);
        height: calc(100% - 2px);
        background-color: #021B63;
        color: #fff;
        font-size: 16px;
        box-sizing: border-box;

        .label-top {
          display: flex;
          width: 100%;
          height: 53px;

          .name {
            display: flex;
            position: relative;
            width: 114px;
            height: 100%;
            padding-left: 19px;
            background-color: #F56C6C;
            box-sizing: border-box;

            .text {
              height: 53px;
              line-height: 53px;
              color: #fff;
              font-size: 18px;
            }

            .status {
              position: absolute;
              bottom: 6px;
              right: 13px;
              color: #fff;
              font-size: 12px;
            }
          }

          .name1 {
            background-color: #E6A23C;
          }

          .test {
            display: flex;
            width: calc(100% - 114px);
            height: 100%;

            .test-item {
              display: flex;
              flex-direction: column;
              align-items: center;
              justify-content: center;
              flex: 1;
              height: 53px;

              .test-item-top {
                color: #fff;
                font-size: 14px;
              }

              .test-item-bottom {
                display: flex;
                align-items: center;
                margin-top: 4px;

                .img {
                  width: 20px;
                  height: 16px;
                }

                .font {
                  color: #fff;
                  font-size: 12px;
                }

                .red {
                  margin-left: 5px;
                  color: #FF0000;
                }
              }
            }
          }
        }

        .label-table {
          width: 100%;
          height: 60px;
          border-top: 1px solid #F56C6C;
          border-left: 1px solid #F56C6C;
          box-sizing: border-box;

          .label-tr {
            display: flex;
            width: 100%;
            height: 30px;
            border-bottom: 1px solid #F56C6C;
            box-sizing: border-box;

            .label-td {
              flex: 1;
              height: 30px;
              line-height: 30px;
              text-align: center;
              border-right: 1px solid #F56C6C;
              box-sizing: border-box;
              color: #fff;
              font-size: 12px;
            }

            .label-td1 {
              flex: 3;
            }
          }
        }

        .label-charts {
          width: 100%;
          height: calc(100% - 113px);
        }
      }
    }
  }

  .sub-page-bottom {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    height: 120px;

    .sub-pagination {
      display: flex;
      align-items: center;

      .sub-text {
        margin-left: 20px;
        margin-right: 5px;
        color: #fff;
        font-size: 16px;
      }

      .sub-text1 {
        margin-left: 10px;
      }

      .el-input-number {
        width: 87px;
      }

      .el-input {
        width: 40px;
      }

      /deep/.el-input__inner {
        color: #0691B6;
      }

      /deep/.el-icon-minus:before {
        content: '\e6de';
      }

      /deep/.el-icon-plus:before {
        content: '\e6e0';
      }
    }

    .sub-tab {
      display: flex;
      align-items: center;

      .sub-tab-item {
        width: 130px;
        height: 44px;
        margin-right: 20px;
        transform: skew(-15deg);
        background: rgba(24,205,253,0.0784);
        border: 1px solid #03BCD7;
        box-sizing: border-box;
        cursor: pointer;

        .sub-tab-box {
          width: 100%;
          height: 44px;
          line-height: 44px;
          text-align: center;
          color: #01EEFD;
          font-size: 14px;
          transform: skew(15deg);
        }

        .red {
          color: #FF0000;
        }

        .sub-tab-box-active {
          color: #fff;
        }
      }

      .sub-tab-active {
        background-color: #03BCD7;
      }

    }
  }

}
</style>
