<template>
  <div class="sub-page">
    <div class="sub-left">
      <div class="sub-item">
        <div class="sub-title">
          <span>护理人员数量</span>
        </div>
        <div class="sub-box" id="chart1"></div>
      </div>
      <div class="sub-item">
        <div class="sub-title">
          <span>服务设施分析</span>
        </div>
        <div class="sub-box">
          <div class="sub-text">
            <span>总设施数  3，624</span>
          </div>
          <div class="sub-box-item">
            <img src="../../../assets/img/zhdp/serve1.png" class="sub-box-img">
            <span>适老化设施</span>
            <span>500</span>
          </div>
          <div class="sub-box-item">
            <img src="../../../assets/img/zhdp/serve2.png" class="sub-box-img">
            <span>公共服务设施</span>
            <span>500</span>
          </div>
          <div class="sub-box-item">
            <img src="../../../assets/img/zhdp/serve3.png" class="sub-box-img">
            <span>房间基本设施</span>
            <span>500</span>
          </div>
        </div>
      </div>
      <div class="sub-item">
        <div class="sub-title">
          <span>服务项排行TOP5</span>
        </div>
        <div class="sub-box" id="chart4"></div>
      </div>
    </div>
    <div class="sub-center">
      <div class="sub-center-item">
        <div class="sub-title">
          <span>数据概况</span>
        </div>
        <div class="sub-center-box">
          <div class="sub-center-item-box">
            <div class="sub-center-lable">
              <span>105，415</span>
              <div class="sub-center-content">
                <span>服务中</span>
              </div>
            </div>
            <div class="sub-center-lable">
              <span>105，415</span>
              <div class="sub-center-content">
                <span>待服务</span>
              </div>
            </div>
          </div>
          <div class="sub-center-item-box sub-center-item-box1">
            <div class="sub-center-lable">
              <span>105，415</span>
              <div class="sub-center-content">
                <span>已服务</span>
              </div>
            </div>
          </div>
          <div class="sub-center-item-box">
            <div class="sub-center-lable">
              <span>105，415</span>
              <div class="sub-center-content">
                <span>已服务</span>
              </div>
            </div>
            <div class="sub-center-lable">
              <span>105，415</span>
              <div class="sub-center-content">
                <span>已取消</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="sub-center-item">
        <div class="sub-title">
          <span>服务项目配置情况</span>
        </div>
        <div class="sub-center-box" id="chart6"></div>
      </div>
    </div>
    <div class="sub-right">
      <div class="sub-item">
        <div class="sub-title">
          <span>告警类型占比</span>
        </div>
        <div class="sub-box">
          <div class="sub-number">
            <span>服务总时长</span>
            <div class="total">
              <div class="total1"></div>
              <div class="total2"></div>
              <div class="text">
                <span>4</span>
              </div>
            </div>
            <div class="total">
              <div class="total1"></div>
              <div class="total2"></div>
              <div class="text">
                <span>4</span>
              </div>
            </div>
            <div class="total">
              <div class="total1"></div>
              <div class="total2"></div>
              <div class="text">
                <span>4</span>
              </div>
            </div>
            <div class="total">
              <div class="total1"></div>
              <div class="total2"></div>
              <div class="text">
                <span>4</span>
              </div>
            </div>
            <div class="total">
              <div class="total1"></div>
              <div class="total2"></div>
              <div class="text">
                <span>4</span>
              </div>
            </div>
          </div>
          <div class="sub-label">
            <img src="../../../assets/img/zhdp/serve4.png" alt="" class="sub-label-img">
            <div class="sub-label-text">
              <span>享受服务老人</span>
              <span>34，345</span>
            </div>
          </div>
          <div class="sub-label">
            <img src="../../../assets/img/zhdp/serve5.png" alt="" class="sub-label-img">
            <div class="sub-label-text">
              <span>服务商</span>
              <span>34，345</span>
            </div>
          </div>
        </div>
      </div>
      <div class="sub-item">
        <div class="sub-title">
          <span>预警类别占比</span>
        </div>
        <div class="sub-box" id="chart3"></div>
      </div>
      <div class="sub-item">
        <div class="sub-title">
          <span>回访率</span>
        </div>
        <div class="sub-box" id="chart8"></div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      myChart1: null,
      setInterval1: null,
      data1: [
        { value: 12.6, name: '高级' },
        { value: 15.8, name: '中级' },
        { value: 49.8, name: '初级' }
      ],
      color1: ['#E10E0E', '#0E5DE1', '#D9E10E'],
      myChart2: null,
      setInterval2: null,
      data2: [
        { value: 300, name: '旅居老人1' },
        { value: 200, name: '旅居老人2' }
      ],
      color2: ['#0EE18D', '#5490D1'],
      myChart3: null,
      setInterval3: null,
      datas3: [
        { value: 45, name: '服务内容不符' },
        { value: 25, name: '延后签入' },
        { value: 15, name: '行为异常' },
        { value: 10, name: '其他' }
      ],
      colors3: ['#E10E0E', '#0E5DE1', '#D9E10E', '#0EE18D', '#E14D0E'],
      myChart4: null,
      setInterval4: null,
      datas4: ['钟点工', '送餐', '居家保洁', '助医', '护理'],
      datass4: [120, 200, 150, 80, 70],
      myChart5: null,
      setInterva5: null,
      myChart6: null,
      setInterval6: null,
      startValue6: 0,
      endValue6: 3,
      myChart7: null,
      setInterval7: null,
      datas7: [
        { value: 40, name: '健康告警' },
        { value: 10, name: 'SOS告警' },
        { value: 10, name: '围栏告警' },
        { value: 10, name: '离线告警' },
        { value: 30, name: '跌倒告警' }
      ],
      colors7: ['#E10E0E', '#0E5DE1', '#D9E10E', '#0EE18D', '#E14D0E'],
      myChart8: null,
      setInterval8: null,
      datas8: [
        { value: 70, name: '已回访' },
        { value: 10, name: '回访中' },
        { value: 10, name: '待回访' }
      ],
      colors8: ['#0E5DE1', '#E10E0E', '#0EE18D'],
      myChart9: null,
      setInterval9: null,
      startValue9: 0,
      endValue9: 2
    }
  },
  mounted () {
    this.add()
  },
  beforeDestroy () {
    this.remove()
  },
  methods: {
    add () {
      this.canvas1()
      // this.canvas2()
      this.canvas3()
      this.canvas4()
      // this.canvas5()
      this.canvas6()
      // this.canvas7()
      this.canvas8()
      // this.canvas9()
      window.addEventListener('resize', this.getresize)
    },
    remove () {
      window.removeEventListener('resize', this.getresize)
      clearInterval(this.setInterva1)
      // clearInterval(this.setInterva2)
      clearInterval(this.setInterva3)
      clearInterval(this.setInterva4)
      // clearInterval(this.setInterva5)
      clearInterval(this.setInterva6)
      // clearInterval(this.setInterva7)
      clearInterval(this.setInterva8)
      // clearInterval(this.setInterva9)
    },
    getresize () {
      setTimeout(() => {
        this.myChart1.resize()
        // this.myChart2.resize()
        this.myChart3.resize()
        this.myChart4.resize()
        // this.myChart5.resize()
        this.myChart6.resize()
        // this.myChart7.resize()
        this.myChart8.resize()
        // this.myChart9.resize()
      }, 500)
    },
    changeInfo1 () {
      const scroll1 = document.querySelector('#chart1')
      scroll1.addEventListener('mouseenter', () => this.noUpdate1())
      scroll1.addEventListener('mouseleave', () => this.update1())
    },
    canvas1 () {
      const chartDom = document.getElementById('chart1')
      this.myChart1 = this.$echarts.init(chartDom)
      this.myChart1.on('click', res => { })
      const option = {
        color: this.color1,
        title: {
          text: '护理人员总数  31，624',
          textStyle: {
            color: '#fff',
            fontSize: 16
          }
        },
        grid: {
          containLabel: true,
          top: '2%',
          right: '28%',
          bottom: '0%',
          left: '8%'
        },
        tooltip: {
          trigger: 'item',
          formatter: params => {
            return params.seriesName + '<br/>' + params.data.name + ' : ' + params.data.value.toFixed(0) + '%'
          }
        },
        legend: {
          show: true,
          orient: 'vertical',
          y: 'center',
          x: 'left',
          align: 'left',
          textStyle: {
            fontSize: 12,
            color: '#FFFFFF'
          },
          formatter: (name) => {
            let percentage = ''
            try {
              const total = this.data1.reduce(function (sum, seriesItem) {
                return sum + seriesItem.value
              }, 0)
              const target = this.data1.find(function (seriesItem) {
                return seriesItem.name === name
              })
              percentage = ((target.value / total) * 100).toFixed(0) + '%'
            } catch (error) {

            }
            return name + ' ' + percentage
          }
        },
        series: [
          {
            name: '护理人员数量',
            type: 'pie',
            radius: ['40%', '70%'],
            data: this.data1,
            label: {
              show: true,
              formatter: '{b}{c}%',
              fontSize: 13,
              color: '#FFFFFF'
            }
          }
        ]
      }

      option && this.myChart1.setOption(option)
      this.update1()
    },
    update1 () {
      this.setInterval1 = setInterval(() => {
        this.data1.unshift(this.data1.pop())
        this.color1.unshift(this.color1.pop())
        this.myChart1.setOption({
          color: this.color1,
          series: {
            data: this.data1
          }
        })
      }, 2000)
    },
    noUpdate1 () {
      clearInterval(this.setInterval1)
    },
    changeInfo2 () {
      const scroll1 = document.querySelector('#chart2')
      scroll1.addEventListener('mouseenter', () => this.noUpdate2())
      scroll1.addEventListener('mouseleave', () => this.update2())
    },
    canvas2 () {
      const chartDom = document.getElementById('chart2')
      this.myChart2 = this.$echarts.init(chartDom)
      this.myChart2.on('click', res => { })
      const option = {
        color: this.color2,
        title: {
          text: '旅居老人\n23459',
          left: 'center',
          top: 'center',
          textStyle: {
            fontSize: 11,
            color: '#FFFFFF'
          }
        },
        tooltip: {
          trigger: 'item'
        },
        legend: {
          show: false,
          y: 'bottom',
          x: 'left',
          textStyle: {
            fontSize: 12,
            color: '#FFFFFF'
          }
        },
        series: [
          {
            name: '旅居老人',
            type: 'pie',
            radius: ['50%', '80%'],
            data: this.data2,
            label: {
              show: false,
              formatter: '{b}{c}人',
              fontSize: 13,
              color: '#FFFFFF'
            }
          }
        ]
      }

      option && this.myChart2.setOption(option)
      this.update2()
    },
    update2 () {
      this.setInterval2 = setInterval(() => {
        this.data2.unshift(this.data2.pop())
        this.color2.unshift(this.color2.pop())
        this.myChart2.setOption({
          color: this.color2,
          series: {
            data: this.data2
          }
        })
      }, 2000)
    },
    noUpdate2 () {
      clearInterval(this.setInterval2)
    },
    changeInfo3 () {
      const scroll3 = document.querySelector('#chart3')
      scroll3.addEventListener('mouseenter', () => this.noUpdate3())
      scroll3.addEventListener('mouseleave', () => this.update3())
    },
    canvas3 () {
      var chartDom = document.getElementById('chart3')
      this.myChart3 = this.$echarts.init(chartDom)
      const option = {
        color: this.colors3,
        tooltip: {
          trigger: 'item',
          formatter: params => {
            return params.seriesName + '<br/>' + params.data.name + ' : ' + params.data.value.toFixed(0) + '%'
          }
        },
        legend: {
          show: true,
          orient: 'vertical',
          y: 'center',
          x: 'left',
          textStyle: {
            fontSize: 14,
            color: '#FFFFFF'
          },
          formatter: (name) => {
            const total = this.datas3.reduce(function (sum, seriesItem) {
              return sum + seriesItem.value
            }, 0)
            const target = this.datas3.find(function (seriesItem) {
              return seriesItem.name === name
            })
            const percentage = ((target.value / total) * 100).toFixed(0) + '%'
            return name + ' ' + percentage
          }
        },
        series: [
          {
            name: '预警类别占比',
            type: 'pie',
            radius: [40, 100],
            center: ['60%', '50%'],
            roseType: 'area',
            itemStyle: {
              // borderRadius: 8
            },
            data: this.datas3,
            label: {
              show: false
            }
          }
        ]
      }

      option && this.myChart3.setOption(option)
      this.update3()
    },
    update3 () {
      this.setInterval3 = setInterval(() => {
        this.datas3.unshift(this.datas3.pop())
        this.colors3.unshift(this.colors3.pop())
        this.myChart3.setOption({
          color: this.color3,
          series: {
            data: this.datas3
          }
        })
      }, 2000)
    },
    noUpdate3 () {
      clearInterval(this.setInterval3)
    },
    changeInfo4 () {
      const scroll4 = document.querySelector('#chart4')
      scroll4.addEventListener('mouseenter', () => this.noUpdate4())
      scroll4.addEventListener('mouseleave', () => this.update4())
    },
    canvas4 () {
      var chartDom = document.getElementById('chart4')
      this.myChart4 = this.$echarts.init(chartDom)
      this.myChart4.on('click', res => { })
      const option = {
        grid: {
          containLabel: true,
          top: '2%', // 一下数值可为百分比也可为具体像素值
          right: '14%',
          bottom: '0%',
          left: '8%'
        },
        label: {
          show: true,
          color: '#fff',
          fontSize: 14,
          position: 'right',
          height: 100,
          offset: [0, 0]
        },
        xAxis: [
          // x轴数据设置
          {
            type: 'value',
            splitLine: {
              show: false
            },
            axisLine: {
              lineStyle: {
                color: '#0F3569'
              }
            }
          }
        ],
        yAxis: [
          {
            type: 'category',
            offset: 0,
            axisLine: {
              show: false,
              // 这是x轴文字颜色
              lineStyle: {
                color: '#fff'
              },
              axisLabel: {
                show: true,
                color: '#fff',
                fontSize: 16
              }
            },
            data: this.datas4,
            axisTick: {
              show: false
            }
          }
        ],
        series: [
          {
            data: this.datass4,
            type: 'bar',
            barWidth: 12,
            label: {
              show: true
            },
            itemStyle: {
              color: params => {
                const colors = [
                  ['#E14D0E', '#E14D0E'],
                  ['#E10E0E', '#E10E0E'],
                  ['#D9E10E', '#D9E10E'],
                  ['#0E5DE1', '#0E5DE1'],
                  ['#0EE18D', '#0EE18D']
                ]
                const colorItem = colors[params.dataIndex]
                return new this.$echarts.graphic.LinearGradient(1, 0, 0, 0, [{
                  offset: 0,
                  color: colorItem[1]
                }, {
                  offset: 1,
                  color: colorItem[0]
                }])
              },
              borderRadius: [10, 10, 10, 10]
            },
            showBackground: false,
            backgroundStyle: {
              color: 'rgba(180, 180, 180, 0.2)'
            }
          }
        ]
      }

      option && this.myChart4.setOption(option)
      this.update4()
    },
    update4 () {
      this.setInterval4 = setInterval(() => {
        this.datas4.unshift(this.datas4.pop())
        this.datass4.unshift(this.datass4.pop())
        this.myChart4.setOption({
          yAxis: {
            data: this.datas4
          },
          series: {
            data: this.datass4
          }
        })
      }, 2000)
    },
    noUpdate4 () {
      clearInterval(this.setInterval4)
    },
    canvas5 () {
      var app = {}

      var chartDom = document.getElementById('chart5')
      this.myChart5 = this.$echarts.init(chartDom)
      var option

      const categories = (function () {
        let now = new Date()
        const res = []
        let len = 10
        while (len--) {
          res.unshift(now.toLocaleTimeString().replace(/^\D*/, ''))
          now = new Date(+now - 2000)
        }
        return res
      })()
      const categories2 = (function () {
        const res = []
        let len = 10
        while (len--) {
          res.push(10 - len - 1)
        }
        return res
      })()
      const data = (function () {
        const res = []
        let len = 10
        while (len--) {
          res.push(Math.round(Math.random() * 1000))
        }
        return res
      })()
      const data2 = (function () {
        const res = []
        let len = 0
        while (len < 10) {
          res.push(+(Math.random() * 10 + 5).toFixed(1))
          len++
        }
        return res
      })()
      option = {
        grid: {
          containLabel: true,
          top: '12%',
          right: '2%',
          bottom: '5%',
          left: '2%'
        },
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'cross',
            label: {
              backgroundColor: '#283b56'
            }
          }
        },
        legend: {
          textStyle: {
            fontSize: 16,
            color: '#FFFFFF'
          }
        },
        dataZoom: {
          show: false,
          start: 0,
          end: 100
        },
        xAxis: [
          {
            type: 'category',
            boundaryGap: true,
            data: categories,
            axisLine: {
              textStyle: {
                color: '#04B4D0',
                fontSize: 13
              },
              lineStyle: {
                color: '#fff',
                fontSize: 12
              }
            },
            axisLabel: {
              show: true,
              color: '#fff',
              fontSize: 13,
              interval: 0
            }
          },
          {
            type: 'category',
            boundaryGap: true,
            data: categories2,
            axisLine: {
              lineStyle: {
                color: '#fff',
                fontSize: 12
              }
            },
            axisLabel: {
              show: true,
              color: '#fff',
              fontSize: 13,
              interval: 0
            }
          }
        ],
        yAxis: [
          {
            type: 'value',
            scale: true,
            max: 30,
            min: 0,
            boundaryGap: [0.2, 0.2],
            axisLine: {
              show: true,
              lineStyle: {
                color: '#fff',
                fontSize: 12
              }
            }
          },
          {
            type: 'value',
            scale: true,
            max: 1200,
            min: 0,
            boundaryGap: [0.2, 0.2],
            axisLine: {
              lineStyle: {
                color: '#fff',
                fontSize: 12
              }
            },
            label: {
              show: true,
              formatter: '{c}%',
              fontSize: 13,
              color: '#FFFFFF'
            }
          }
        ],
        series: [
          {
            name: '工单数',
            type: 'bar',
            xAxisIndex: 1,
            yAxisIndex: 1,
            data: data,
            barWidth: '19'
          },
          {
            name: '入住率',
            type: 'line',
            data: data2,
            barWidth: '19'
          }
        ]
      }
      app.count = 11
      option && this.myChart5.setOption(option)
      this.update5(data, data2, categories, categories2, app)
    },
    update5 (data, data2, categories, categories2, app) {
      this.setInterva5 = setInterval(() => {
        const axisData = new Date().toLocaleTimeString().replace(/^\D*/, '')
        data.shift()
        data.push(Math.round(Math.random() * 1000))
        data2.shift()
        data2.push(+(Math.random() * 10 + 5).toFixed(1))
        categories.shift()
        categories.push(axisData)
        categories2.shift()
        categories2.push(app.count++)
        this.myChart5.setOption({
          xAxis: [
            { data: categories },
            { data: categories2 }
          ],
          series: [
            { data: data },
            { data: data2 }
          ]
        })
      }, 2100)
    },
    canvas6 () {
      const chartDom = document.getElementById('chart6')
      this.myChart6 = this.$echarts.init(chartDom)
      const option = {
        title: {
          text: '平均年龄：76岁',
          left: '20%',
          top: 'top',
          textStyle: {
            color: '#fff',
            fontSize: 16
          }
        },
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'shadow'
          }
        },
        legend: {
          orient: 'horizontal',
          // x: '80%',
          y: 'top',
          x: 'center',
          textStyle: {
            fontSize: 12,
            color: '#FFFFFF'
          }
          // padding: [25, 34, 0, 0]
        },
        dataZoom: [
          {
            show: false,
            startValue: this.startValue3,
            endValue: this.endValue3
          }
        ],
        grid: {
          show: false,
          top: '15%', // 一下数值可为百分比也可为具体像素值
          right: '4%',
          bottom: '12%',
          left: '8%'
        },
        xAxis: {
          type: 'category',
          data: ['保洁维护', '送餐服务', '室内维修', '紧急呼叫', '聊天陪伴', '走失定位'],
          axisLabel: {
            show: true,
            color: '#fff',
            fontSize: 13,
            interval: 0
          },
          axisLine: {
            show: true,
            lineStyle: {
              color: '#fff'
            },
            textStyle: {
              color: '#04B4D0',
              fontSize: 13
            }
          },
          axisTick: {
            // y轴刻度线
            show: false
          }
        },
        yAxis: {
          type: 'value',
          axisLine: {
            show: true,
            lineStyle: {
              color: '#fff'
            }
          },
          axisLabel: {
            show: true,
            color: '#fff',
            fontSize: 14
          },
          splitLine: {
            show: true,
            lineStyle: {
              color: '#fff'
            }
          }
        },
        series: [
          {
            name: '有',
            data: [120, 200, 150, 80, 70, 110],
            type: 'bar',
            barWidth: '19',
            itemStyle: {
              barBorderRadius: [10, 10, 10, 10]
            }
          },
          {
            name: '无',
            data: [120, 200, 150, 80, 70, 110],
            type: 'bar',
            barWidth: '19',
            itemStyle: {
              barBorderRadius: [10, 10, 10, 10]
            }
          },
          {
            name: '不清楚',
            data: [120, 200, 150, 80, 70, 110],
            type: 'bar',
            barWidth: '19',
            itemStyle: {
              barBorderRadius: [10, 10, 10, 10]
            }
          }
        ]
      }

      option && this.myChart6.setOption(option)
      this.update6()
    },
    update6 () {
      this.setInterval6 = setInterval(() => {
        this.startValue6++
        this.endValue6++
        if (this.endValue6 > 5) {
          this.startValue6 = 0
          this.endValue6 = 3
        }
        this.myChart6.setOption({
          dataZoom: [
            // 滑动条
            {
              show: false, // 是否显示滑动条，不影响使用
              startValue: this.startValue6, // 从头开始。
              endValue: this.endValue6 // 一次性展示6个。
            }
          ]
        })
      }, 2000)
    },
    changeInfo7 () {
      const scroll1 = document.querySelector('#chart7')
      scroll1.addEventListener('mouseenter', () => this.noUpdate7())
      scroll1.addEventListener('mouseleave', () => this.update7())
    },
    canvas7 () {
      var chartDom = document.getElementById('chart7')
      this.myChart7 = this.$echarts.init(chartDom)
      const option = {
        color: this.colors7,
        grid: {
          containLabel: true,
          top: '2%',
          right: '28%',
          bottom: '0%',
          left: '8%'
        },
        tooltip: {
          trigger: 'item',
          formatter: params => {
            return params.seriesName + '<br/>' + params.data.name + ' : ' + params.data.value.toFixed(0) + '%'
          }
        },
        legend: {
          show: true,
          orient: 'vertical',
          y: 'center',
          x: 'right',
          align: 'left',
          textStyle: {
            fontSize: 12,
            color: '#FFFFFF'
          },
          formatter: (name) => {
            const total = this.datas7.reduce(function (sum, seriesItem) {
              return sum + seriesItem.value
            }, 0)
            const target = this.datas7.find(function (seriesItem) {
              return seriesItem.name === name
            })
            const percentage = ((target.value / total) * 100).toFixed(0) + '%'
            return name + ' ' + percentage
          }
        },
        series: [
          {
            name: '各类型老人占比',
            type: 'pie',
            radius: ['40%', '70%'],
            data: this.datas7,
            label: {
              show: false,
              formatter: '{c}%',
              fontSize: 13,
              color: '#FFFFFF'
            }
          }
        ]
      }

      option && this.myChart7.setOption(option)
      this.update7()
    },
    update7 () {
      this.setInterval7 = setInterval(() => {
        this.datas7.unshift(this.datas7.pop())
        this.colors7.unshift(this.colors7.pop())
        this.myChart7.setOption({
          color: this.colors7,
          series: {
            data: this.datas7
          }
        })
      }, 2000)
    },
    changeInfo8 () {
      const scroll1 = document.querySelector('#chart8')
      scroll1.addEventListener('mouseenter', () => this.noUpdate8())
      scroll1.addEventListener('mouseleave', () => this.update8())
    },
    canvas8 () {
      var chartDom = document.getElementById('chart8')
      this.myChart8 = this.$echarts.init(chartDom)
      const option = {
        color: this.colors8,
        title: {
          text: '报警总数:100',
          textStyle: {
            color: '#fff',
            fontSize: 14
          },
          left: 'center',
          top: 'center'
        },
        tooltip: {
          trigger: 'item',
          formatter: params => {
            return params.seriesName + '<br/>' + params.data.name + ' : ' + params.data.value.toFixed(0) + '%'
          }
        },
        legend: {
          show: true,
          y: 'bottom',
          x: 'center',
          textStyle: {
            fontSize: 12,
            color: '#FFFFFF'
          },
          formatter: (name) => {
            const total = this.datas8.reduce(function (sum, seriesItem) {
              return sum + seriesItem.value
            }, 0)
            const target = this.datas8.find(function (seriesItem) {
              return seriesItem.name === name
            })
            const percentage = ((target.value / total) * 100).toFixed(0)
            return name + ' ' + percentage
          }
        },
        series: [
          {
            name: '回访率',
            type: 'pie',
            radius: ['50%', '70%'],
            // center: ['50%', '40%'],
            data: this.datas8,
            label: {
              show: true,
              formatter: '{b}{c}%',
              fontSize: 13,
              color: '#FFFFFF'
            }
          }
        ]
      }

      option && this.myChart8.setOption(option)
      this.update8()
    },
    update8 () {
      this.setInterval8 = setInterval(() => {
        this.datas8.unshift(this.datas8.pop())
        this.colors8.unshift(this.colors8.pop())
        this.myChart8.setOption({
          color: this.colors8,
          series: {
            data: this.datas8
          }
        })
      }, 2000)
    },
    canvas9 () {
      const chartDom = document.getElementById('chart9')
      this.myChart9 = this.$echarts.init(chartDom)
      const option = {
        color: ['#0077F9', '#E10E0E', '#E14D0E', '#0EE18D', '#D9E10E'],
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'shadow'
          }
        },
        legend: {
          orient: 'horizontal',
          // x: '80%',
          y: 'top',
          x: 'center',
          textStyle: {
            fontSize: 12,
            color: '#FFFFFF'
          }
          // padding: [25, 34, 0, 0]
        },
        dataZoom: [
          {
            show: false,
            startValue: this.startValue9,
            endValue: this.endValue9
          }
        ],
        grid: {
          show: false,
          top: '15%', // 一下数值可为百分比也可为具体像素值
          right: '4%',
          bottom: '10%',
          left: '10%'
        },
        xAxis: {
          type: 'category',
          data: ['跌倒', '走失', '噎食', '自杀'],
          axisLabel: {
            show: true,
            color: '#fff',
            fontSize: 13,
            interval: 0
          },
          axisLine: {
            show: true,
            lineStyle: {
              color: '#fff'
            },
            textStyle: {
              color: '#04B4D0',
              fontSize: 13
            }
          },
          axisTick: {
            // y轴刻度线
            show: false
          }
        },
        yAxis: {
          type: 'value',
          axisLine: {
            show: true,
            lineStyle: {
              color: '#fff'
            }
          },
          axisLabel: {
            show: true,
            color: '#fff',
            fontSize: 14
          },
          splitLine: {
            show: true,
            lineStyle: {
              color: '#fff'
            }
          }
        },
        series: [
          {
            name: '无',
            data: [120, 200, 150, 80],
            type: 'bar',
            barWidth: '10'
          },
          {
            name: '发生过1次',
            data: [120, 200, 150, 80],
            type: 'bar',
            barWidth: '10'
          },
          {
            name: '发生过2次',
            data: [120, 200, 150, 80],
            type: 'bar',
            barWidth: '10'
          },
          {
            name: '发生过3次',
            data: [120, 200, 150, 80],
            type: 'bar',
            barWidth: '10'
          },
          {
            name: '未统计',
            data: [120, 200, 150, 80],
            type: 'bar',
            barWidth: '10'
          }
        ]
      }

      option && this.myChart9.setOption(option)
      this.update9()
    },
    update9 () {
      this.setInterval9 = setInterval(() => {
        this.startValue9++
        this.endValue9++
        if (this.endValue9 > 4) {
          this.startValue9 = 0
          this.endValue9 = 2
        }
        this.myChart9.setOption({
          dataZoom: [
            {
              show: false,
              startValue: this.startValue9,
              endValue: this.endValue9
            }
          ]
        })
      }, 2000)
    }
  }
}
</script>

<style lang="less" scoped>
.sub-page {
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: 100%;

  .sub-left {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 25%;
    height: 100%;
  }

  .sub-center {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: calc(50% - 20px);
  }

  .sub-right {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 25%;
  }

  .sub-item {
    width: 100%;
    height: calc(33.3% - 6px);
    padding: 0 10px;
    background-color: #0F3569;
    border: 1px solid #1F72D3;
    box-sizing: border-box;
  }

  .sub-title {
    width: 100%;
    height: 40px;
    line-height: 40px;
    color: #fff;
    font-size: 20px;
  }

  .sub-box {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    height: calc(100% - 40px);

    .sub-text {
      width: 100%;
      height: 40px;
      line-height: 40px;
      color: #fff;
      font-size: 16px;
    }

    .sub-number {
      display: flex;
      align-items: center;
      width: 100%;
      color: #fff;
      font-size: 16px;

      .total {
        position: relative;
        width: 32px;
        height: 44px;
        margin: 0 4px;
      }

      .total2(1) {
        margin-left: 18px;
      }

      .total1 {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 21px;
        border-radius: 6px;
        background-color: #114F84;
      }

      .total2 {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 21px;
        border-radius: 6px;
        background-color: #114F84;
      }

      .text {
        position: absolute;
        top: 0;
        width: 100%;
        height: 100%;
        line-height: 44px;
        text-align: center;
        color: #fff;
        font-size: 28px;
      }
    }

    .sub-label {
      display: flex;
      justify-content: center;
      width: 50%;

      .sub-label-img {
        width: 30px;
        height: 30px;
        margin-right: 7px;
      }

      .sub-label-text {
        display: flex;
        flex-direction: column;
        align-items: center;
        color: #fff;
        font-size: 16px;
      }
    }

    .sub-box-item {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 33.3%;
      color: #fff;
      font-size: 16px;

      .sub-box-img {
        width: 26px;
        height: 26px;
      }
    }

    .sub-box-chart {
      width: 50%;
      height: 50%;
    }
  }

  .sub-center-item {
    width: 100%;
    height: calc(33.3% - 6px);
    padding: 0 10px;
    box-sizing: border-box;
  }

  .sub-center-item:nth-child(1) {
    height: calc(66.6% - 3px);
    background-image: url('../../../assets/img/zhdp/serve6.png');
    background-repeat: no-repeat;
    background-size: 100% 100%;
  }

  .sub-center-item:nth-child(2) {
    background-color: #0F3569;
    border: 1px solid #1F72D3;
  }

  .sub-center-box {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    align-content: space-evenly;
    width: 100%;
    height: calc(100% - 40px);
    padding-bottom: 50px;
    box-sizing: border-box;

    .sub-center-item-box {
      display: flex;
      width: 100%;

      .sub-center-lable {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 50%;
        color: #fff;
        font-size: 20px;

        .sub-center-content {
          width: 145px;
          height: 76px;
          line-height: 76px;
          text-align: center;
          margin-top: 10px;
          border-radius: 48%;
          background: radial-gradient(ellipse at center, rgba(0,22,54,0.102) 0%, rgba(6, 253, 163, .5) 100%);
          box-shadow:0 30px 0 10px rgba(6, 253, 163, .04);
          color: #fff;
          font-size: 20px;
        }
      }
    }

    .sub-center-item-box1 {
      width: 100%;
      justify-content: center;

      .sub-center-lable {
        .sub-center-content {
          width: 219px;
          height: 114px;
          line-height: 114px;
        }
      }
    }
  }
}
</style>
