<template>
  <div class="sub">
    <div class="sub-left">
      <div class="sub-left-item">
        <div class="sub-title">
          <span>今日统计</span>
        </div>
        <div class="sub-box">
          <div class="sub-box-item">
            <span>-</span>
            <span>诊断量</span>
          </div>
          <div class="sub-box-item">
            <span>-</span>
            <span>诊断量</span>
          </div>
          <div class="sub-box-item">
            <span>-</span>
            <span>阳性量</span>
          </div>
          <div class="sub-box-item">
            <span>-</span>
            <span>危及量</span>
          </div>
        </div>
      </div>
      <div class="sub-left-item">
        <div class="sub-title">
          <span>诊断区域分布</span>
        </div>
        <div class="sub-box">
          <div class="sub-chart" id="echarts1"></div>
        </div>
      </div>
      <div class="sub-left-item">
        <div class="sub-title">
          <span>工作量统计</span>
        </div>
        <div class="sub-box" id="echarts2">
        </div>
      </div>
      <div class="sub-left-item1">
        <div class="sub-title">
          <span>诊断分类统计</span>
        </div>
        <div class="sub-box" id="echarts6">
        </div>
      </div>
    </div>
    <div class="sub-center">
      <div class="sub-top">
        <div class="sub-title">
          <span>累计数量</span>
        </div>
        <div class="sub-label">
          <div class="sub-label-text">
            <span>累计</span>
          </div>
          <div class="sub-label-item">
            <span>7</span>
          </div>
          <div class="sub-label-item">
            <span>7</span>
          </div>
          <div class="sub-label-item">
            <span>7</span>
          </div>
          <div class="sub-label-item">
            <span>7</span>
          </div>
          <div class="sub-label-item">
            <span>7</span>
          </div>
          <div class="sub-label-item">
            <span>7</span>
          </div>
          <div class="sub-label-item">
            <span>7</span>
          </div>
          <div class="sub-label-text">
            <span>例</span>
          </div>
        </div>
        <div class="sub-select">
          <span>视角范围</span>
          <el-select size="mini" v-model="value" placeholder="请选择">
            <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
        </div>
        <div class="sub-map" id="echarts3"></div>
        <div class="sub-map" id="echarts5"></div>
      </div>
    </div>
    <div class="sub-right">
      <div class="sub-left-item">
        <div class="sub-title">
          <span>实时消息</span>
        </div>
        <div class="sub-box">
          <div class="sub-box-item">
            <span>15:00:03 古城营镇卫生中心申请诊断</span>
          </div>
          <div class="sub-box-item">
            <span>15:00:03 古城营镇卫生中心申请诊断</span>
          </div>
          <div class="sub-box-item red">
            <span>15:00:03 古城营镇卫生中心申请诊断</span>
          </div>
        </div>
      </div>
      <div class="sub-left-item">
        <div class="sub-title">
          <span>超时预警</span>
        </div>
        <div class="sub-chart">
          <div class="sub-chart-item" id="echarts4"></div>
          <div class="sub-chart-item" id="echarts7"></div>
          <div class="sub-chart-item" id="echarts8"></div>
        </div>
      </div>

      <div class="sub-left-item sub-left-item2">
        <div class="sub-label">
          <div class="sub-title">
            <span>年龄分布</span>
          </div>
          <div class="sub-chart" id="echarts9"></div>
        </div>
        <div class="sub-label">
          <div class="sub-title">
            <span>病例分布</span>
          </div>
          <div class="sub-chart" id="echarts10"></div>
        </div>
      </div>

      <div class="sub-left-item">
        <div class="sub-title">
          <span>时效统计</span>
        </div>
        <div class="sub-chart" id="echarts11"></div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import geoJson from './hk.json'
import bdrcx from '@/assets/json/bdrcx.json'
export default {
  data () {
    return {
      tabIndex1: 0,
      tabIndex2: 0,
      tabIndex3: 0,
      tabIndex4: 0,
      tabIndex5: 0,
      tabIndex6: 0,
      myChart1: null,
      myChart2: null,
      myChart3: null,
      myChart4: null,
      myChart5: null,
      myChart6: null,
      myChart7: null,
      list: [
        { name: '乌审旗', value: 20057.34, itemStyle: { color: '#eb8146' } }
      ],
      options: [{
        value: '1',
        label: '全国'
      }],
      value: '1'
    }
  },
  computed: {
    ...mapState(['loginInfo'])
  },
  mounted () {
    this.$nextTick(() => {
      this.canvas1()
      this.canvas2()
      this.canvas3()
      this.canvas4()
      this.canvas5()
      this.canvas6()
      this.canvas7()
      this.canvas8()
      this.canvas9()
      this.canvas10()
      this.canvas11()
    })
    window.addEventListener('resize', this.getresize)
  },
  beforeDestroy () {
    // 页面销毁时销毁监听事件
    window.removeEventListener('resize', this.getresize)
  },
  methods: {
    tabClick1 (index) {
      this.tabIndex1 = index
    },
    tabClick2 (index) {
      this.tabIndex2 = index
    },
    tabClick3 (index) {
      this.tabIndex3 = index
    },
    tabClick4 (index) {
      this.tabIndex4 = index
    },
    tabClick5 (index) {
      this.tabIndex5 = index
    },
    tabClick6 (index) {
      this.tabIndex6 = index
    },
    async getData () {
      const { data: res } = await this.$http.get('https://echarts.apache.org/examples/data/asset/geo/HK.json')
      console.log('res', res)
    },
    getresize () {
      setTimeout(() => {
        this.myChart1.resize()
        this.myChart2.resize()
        this.myChart3.resize()
        this.myChart4.resize()
        this.myChart5.resize()
        this.myChart6.resize()
        this.myChart7.resize()
        this.myChart8.resize()
        this.myChart9.resize()
        this.myChart10.resize()
        this.myChart11.resize()
      }, 500)
    },
    canvas1 () {
      var chartDom = document.getElementById('echarts1')
      this.myChart1 = this.$echarts.init(chartDom)
      const option = {
        color: ['#FF7600', '#14BE42'],
        tooltip: {
          trigger: 'item'
        },
        legend: {
          show: true,
          top: '5%',
          left: 'right',
          textStyle: {
            color: '#fff'
          }
        },
        grid: {
          show: true,
          top: '24%', // 一下数值可为百分比也可为具体像素值
          right: '10%',
          bottom: '18%',
          left: '14%'
        },
        yAxis: {
          type: 'value',
          axisLabel: {
            textStyle: {
              color: '#fff'
            }
          }
        },
        xAxis: {
          type: 'category',
          data: ['乡镇卫生院', '村卫生院', '社康服务中心', '远程会诊'],
          axisLabel: {
            textStyle: {
              color: '#fff'
            },
            formatter: function (value) {
              return value.length > 4 ? value.slice(0, 4) + '...' : value
            }
          }
        },
        series: [
          {
            name: '会诊医院',
            type: 'bar',
            stack: 'total',
            barWidth: '12',
            data: [13168, 13168, 13168, 13168],
            label: {
              show: false
            }
          },
          {
            name: '申请医院',
            type: 'bar',
            stack: 'total',
            barWidth: '12',
            data: [13168, 13168, 13168, 13168],
            label: {
              show: false
            },
            itemStyle: {
              // borderRadius: [16, 16, 0, 0]
            }
          }
        ]
      }

      option && this.myChart1.setOption(option)
    },
    canvas2 () {
      var chartDom = document.getElementById('echarts2')
      this.myChart2 = this.$echarts.init(chartDom)
      const option = {
        color: ['#D12ED0', '#FF7600', '#91CC75'],
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'cross',
            label: {
              backgroundColor: '#6a7985'
            }
          }
        },
        legend: {
          show: true,
          left: 'right',
          textStyle: {
            color: '#fff'
          }
        },
        grid: {
          left: '3%',
          right: '4%',
          bottom: '3%',
          containLabel: true
        },
        xAxis: [
          {
            type: 'category',
            axisLine: {
              show: true,
              lineStyle: {
                color: '#fff'
              }
            },
            data: ['2:00', '3:00', '4:00', '5:00', '6:00', '7:00', '8:00', '9:00', '10:00'],
            axisLabel: {
              textStyle: {
                color: '#fff'
              }
            }
          }
        ],
        yAxis: [
          {
            type: 'value',
            axisLine: {
              show: true,
              lineStyle: {
                color: '#fff'
              }
            },
            axisLabel: {
              textStyle: {
                color: '#fff'
              }
            }
          }
        ],
        series: [
          {
            name: '赵**',
            type: 'line',
            stack: 'Total',
            smooth: true,
            lineStyle: {
              width: 0
            },
            showSymbol: false,
            areaStyle: {
              opacity: 0.8,
              color: new this.$echarts.graphic.LinearGradient(0, 0, 0, 1, [
                {
                  offset: 0,
                  color: '#91CC75'
                },
                {
                  offset: 1,
                  color: '#808080'
                }
              ])
            },
            emphasis: {
              focus: 'series'
            },
            data: [140, 232, 101, 264, 90, 340, 250, 90, 340]
          },
          {
            name: '张**',
            type: 'line',
            stack: 'Total',
            smooth: true,
            lineStyle: {
              width: 0
            },
            showSymbol: false,
            areaStyle: {
              opacity: 0.8,
              color: new this.$echarts.graphic.LinearGradient(0, 0, 0, 1, [
                {
                  offset: 0,
                  color: '#FF7600'
                },
                {
                  offset: 1,
                  color: '#808080'
                }
              ])
            },
            emphasis: {
              focus: 'series'
            },
            data: [140, 232, 101, 264, 90, 340, 250, 90, 340]
          },
          {
            name: '李**',
            type: 'line',
            stack: 'Total',
            smooth: true,
            lineStyle: {
              width: 0
            },
            showSymbol: false,
            areaStyle: {
              opacity: 0.8,
              color: new this.$echarts.graphic.LinearGradient(0, 0, 0, 1, [
                {
                  offset: 0,
                  color: '#D12ED0'
                },
                {
                  offset: 1,
                  color: '#CB12CA'
                }
              ])
            },
            emphasis: {
              focus: 'series'
            },
            data: [140, 232, 101, 264, 90, 340, 250, 90, 340]
          }
        ]
      }

      option && this.myChart2.setOption(option)
    },
    canvas3 () {
      var chartDom = document.getElementById('echarts3')
      this.myChart3 = this.$echarts.init(chartDom)
      if (this.loginInfo.jgbh.indexOf('130629') > -1) {
        this.$echarts.registerMap('HK', bdrcx)
      } else {
        this.$echarts.registerMap('HK', geoJson)
      }
      const option = {
        tooltip: {
          trigger: 'item',
          formatter: (item) => {
            var arr = this.list
            let str = ''
            for (var i in arr) {
              if (arr[i].name === item.name) {
                str += ` <div class="sub-position" style="min-width: 300px;padding: 10px 15px;background-color: #024B85;border: 1px solid #03BCD7;">
                  <div class="sub-position-line" style="margin: 10px 0;color: #fff;font-size: 16px;">
                    <span>李明明</span>
                    <span>男</span>
                    <span>86岁</span>
                    <span>15803335684</span>
                  </div>
                  <div class="sub-position-label" style="width: 110px;
      margin: 10px 0;
      padding: 4px 8px;
      text-align: center;
      border-radius: 6px;
      background-color: #CD0A0A;
      color: #fff;
      font-size: 14px;">
                    <span>安全防护报警</span>
                  </div>
                  <div class="sub-position-line" style="margin: 10px 0;color: #fff;font-size: 16px;">
                    <span>时间  2022-03-03  18:56:6</span>
                  </div>
                  <div class="sub-position-line sub-position-line1" style="margin: 10px 0;color: #03BCD7;font-size: 16px;">
                    <span>位置：石家庄 桥西区 友谊街道238号 </span>
                  </div>
                </div>`
              }
            }
            return str
          }
        },
        series: [
          {
            name: '香港18区人口密度',
            type: 'map',
            map: 'HK',
            label: {
              show: true,
              color: '#fff'
            },
            itemStyle: {
              // 默认的区块样式
              borderColor: '#fff',
              areaColor: '#20C1ED',
              // 高亮样式
              emphasis: {
                areaColor: '#0F6ABB'
              }
            },
            // 选中样式
            select: {
              label: {
                // 选中区域的label(文字)样式
                color: '#fff'
              },

              itemStyle: {
                color: '#fff',
                // 选中区域红色
                areaColor: '#0F6ABB',
                // 选中区域边框
                borderColor: '#fff',
                borderWidth: 3
              }

            },
            data: [
              { name: '乌审旗', value: 20057.34, itemStyle: { color: '#eb8146' } }
            ],
            // 自定义名称映射
            nameMap: {
              乌审旗: '乌审旗'
            }
          }
        ]
      }

      option && this.myChart3.setOption(option)
    },
    canvas4 () {
      var chartDom = document.getElementById('echarts4')
      this.myChart4 = this.$echarts.init(chartDom)
      const option = {
        series: [
          {
            type: 'gauge',
            axisLine: {
              lineStyle: {
                width: 10,
                color: [
                  [0.3, '#1DB152'],
                  [0.7, '#FBF04C'],
                  [1, '#D64625']
                ]
              }
            },
            pointer: {
              show: false,
              itemStyle: {
                color: 'auto'
              }
            },
            axisTick: {
              show: false,
              distance: -30,
              length: 8,
              lineStyle: {
                color: '#fff',
                width: 2
              }
            },
            splitLine: {
              show: false,
              distance: -30,
              length: 10,
              lineStyle: {
                color: '#fff',
                width: 4
              }
            },
            axisLabel: {
              color: '#fff',
              distance: 8,
              fontSize: 8
            },
            detail: {
              valueAnimation: true,
              formatter: '静态心电',
              color: '#fff',
              fontSize: 9
            },
            data: [
              {
                value: 70
              }
            ]
          }
        ]
      }

      option && this.myChart4.setOption(option)
    },
    canvas5 () {
      var chartDom = document.getElementById('echarts5')
      this.myChart5 = this.$echarts.init(chartDom)
      const option = {
        color: ['#D12ED0', '#FF7600', '#91CC75'],
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'cross',
            label: {
              backgroundColor: '#6a7985'
            }
          }
        },
        legend: {
          show: true,
          left: 'left',
          textStyle: {
            color: '#fff'
          }
        },
        grid: {
          left: '3%',
          right: '4%',
          bottom: '3%',
          containLabel: true
        },
        xAxis: [
          {
            type: 'category',
            axisLine: {
              show: true,
              lineStyle: {
                color: '#fff'
              }
            },
            data: ['1月', '2月', '3月', '4月', '5月', '6月', '7月', '8月', '9月', '10月', '11月', '12月'],
            axisLabel: {
              textStyle: {
                color: '#fff'
              }
            }
          }
        ],
        yAxis: [
          {
            type: 'value',
            axisLine: {
              show: true,
              lineStyle: {
                color: '#fff'
              }
            },
            axisLabel: {
              textStyle: {
                color: '#fff'
              }
            }
          }
        ],
        series: [
          {
            name: '2021',
            type: 'line',
            stack: 'Total',
            smooth: true,
            lineStyle: {
              width: 0
            },
            showSymbol: false,
            areaStyle: {
              opacity: 0.8,
              color: new this.$echarts.graphic.LinearGradient(0, 0, 0, 1, [
                {
                  offset: 0,
                  color: '#91CC75'
                },
                {
                  offset: 1,
                  color: '#808080'
                }
              ])
            },
            emphasis: {
              focus: 'series'
            },
            data: [140, 232, 101, 264, 90, 340, 250, 90, 340]
          },
          {
            name: '2022',
            type: 'line',
            stack: 'Total',
            smooth: true,
            lineStyle: {
              width: 0
            },
            showSymbol: false,
            areaStyle: {
              opacity: 0.8,
              color: new this.$echarts.graphic.LinearGradient(0, 0, 0, 1, [
                {
                  offset: 0,
                  color: '#FF7600'
                },
                {
                  offset: 1,
                  color: '#808080'
                }
              ])
            },
            emphasis: {
              focus: 'series'
            },
            data: [140, 232, 101, 264, 90, 340, 250, 90, 340]
          },
          {
            name: '2023',
            type: 'line',
            stack: 'Total',
            smooth: true,
            lineStyle: {
              width: 0
            },
            showSymbol: false,
            areaStyle: {
              opacity: 0.8,
              color: new this.$echarts.graphic.LinearGradient(0, 0, 0, 1, [
                {
                  offset: 0,
                  color: '#D12ED0'
                },
                {
                  offset: 1,
                  color: '#CB12CA'
                }
              ])
            },
            emphasis: {
              focus: 'series'
            },
            data: [140, 232, 101, 264, 90, 340, 250, 90, 340]
          }
        ]
      }

      option && this.myChart5.setOption(option)
    },
    canvas6 () {
      var chartDom = document.getElementById('echarts6')
      this.myChart6 = this.$echarts.init(chartDom)
      const option = {
        color: ['#91CC75'],
        grid: {
          containLabel: true,
          top: '15%', // 一下数值可为百分比也可为具体像素值
          right: '21%',
          bottom: '10%',
          left: '8%'
        },
        label: {
          show: true,
          color: '#fff',
          fontSize: 14,
          position: 'right',
          height: 100,
          formatter: (params) => {
            return params.value + '%'
          }
        },
        xAxis: [
          // x轴数据设置
          {
            type: 'value',
            axisLabel: {
              color: '#fff'
            },
            boundaryGap: false,
            axisLine: {
              show: true,
              lineStyle: {
                color: '#fff'
              }
            }
          }
        ],
        yAxis: [
          {
            type: 'category',
            offset: 0,
            axisLine: {
              show: true,
              // 这是x轴文字颜色
              lineStyle: {
                color: '#fff'
              }
            },
            data: ['静态心电', '动态心电', '动态血压'],
            axisTick: {
              show: false
            }
          }
        ],
        series: [
          {
            data: [200, 200, 200],
            type: 'bar',
            barWidth: 12,
            label: {
              show: false
            }
          }
        ]
      }

      option && this.myChart6.setOption(option)
    },
    canvas7 () {
      var chartDom = document.getElementById('echarts7')
      this.myChart7 = this.$echarts.init(chartDom)
      const option = {
        series: [
          {
            type: 'gauge',
            axisLine: {
              lineStyle: {
                width: 10,
                color: [
                  [0.3, '#1DB152'],
                  [0.7, '#FBF04C'],
                  [1, '#D64625']
                ]
              }
            },
            pointer: {
              show: false,
              itemStyle: {
                color: 'auto'
              }
            },
            axisTick: {
              show: false,
              distance: -30,
              length: 8,
              lineStyle: {
                color: '#fff',
                width: 2
              }
            },
            splitLine: {
              show: false,
              distance: -30,
              length: 10,
              lineStyle: {
                color: '#fff',
                width: 4
              }
            },
            axisLabel: {
              color: '#fff',
              distance: 8,
              fontSize: 8
            },
            detail: {
              valueAnimation: true,
              formatter: '动态心电',
              color: '#fff',
              fontSize: 9
            },
            data: [
              {
                value: 70
              }
            ]
          }
        ]
      }

      option && this.myChart7.setOption(option)
    },
    canvas8 () {
      var chartDom = document.getElementById('echarts8')
      this.myChart8 = this.$echarts.init(chartDom)
      const option = {
        series: [
          {
            type: 'gauge',
            axisLine: {
              lineStyle: {
                width: 10,
                color: [
                  [0.3, '#1DB152'],
                  [0.7, '#FBF04C'],
                  [1, '#D64625']
                ]
              }
            },
            pointer: {
              show: false,
              itemStyle: {
                color: 'auto'
              }
            },
            axisTick: {
              show: false,
              distance: -30,
              length: 8,
              lineStyle: {
                color: '#fff',
                width: 2
              }
            },
            splitLine: {
              show: false,
              distance: -30,
              length: 10,
              lineStyle: {
                color: '#fff',
                width: 4
              }
            },
            axisLabel: {
              color: '#fff',
              distance: 8,
              fontSize: 8
            },
            detail: {
              valueAnimation: true,
              formatter: '动态血压',
              color: '#fff',
              fontSize: 9
            },
            data: [
              {
                value: 70
              }
            ]
          }
        ]
      }

      option && this.myChart8.setOption(option)
    },
    canvas9 () {
      var chartDom = document.getElementById('echarts9')
      this.myChart9 = this.$echarts.init(chartDom)
      const option = {
        radar: {
          indicator: [
            { name: '0-19', max: 6500 },
            { name: '20-39', max: 16000 },
            { name: '40-59', max: 30000 },
            { name: '60-79', max: 38000 },
            { name: '80以上', max: 52000 }
          ],
          radius: '55%',
          name: {
            textStyle: { color: '#fff' }
          }
        },
        series: [
          {
            name: '年龄分布',
            type: 'radar',
            data: [
              {
                value: [4200, 3000, 20000, 35000, 50000, 18000],
                name: 'Allocated Budget'
              }
            ],
            lineStyle: {
              color: 'red'
            },
            symbol: 'none'
          }
        ]
      }

      option && this.myChart9.setOption(option)
    },
    canvas10 () {
      var chartDom = document.getElementById('echarts10')
      this.myChart10 = this.$echarts.init(chartDom)
      const option = {
        tooltip: {
          trigger: 'item',
          formatter: (params) => {
            return params.name + ' ' + params.value + '%'
          }
        },
        legend: {
          show: false
        },
        series: [
          {
            name: '病例分布',
            type: 'pie',
            radius: '50%',
            data: [
              { value: 5, name: '采集不良' },
              { value: 20, name: '正常' },
              { value: 10, name: '危急' },
              { value: 30, name: '阳性' }
            ],
            center: ['50%', '50%'],
            label: {
              show: true,
              color: '#fff',
              formatter: (params) => {
                return params.name + ' ' + params.value + '%'
              }
            }
          }
        ]
      }

      option && this.myChart10.setOption(option)
    },
    canvas11 () {
      var chartDom = document.getElementById('echarts11')
      this.myChart11 = this.$echarts.init(chartDom)
      const option = {
        tooltip: {
          trigger: 'item',
          formatter: (params) => {
            return params.name + ' ' + params.value + '%'
          }
        },
        legend: {
          show: false
        },
        series: [
          {
            name: '病例分布',
            type: 'pie',
            radius: ['50%', '30'],
            data: [
              { value: 5, name: '一个小时以内' },
              { value: 20, name: '半个小时以内' }
            ],
            center: ['50%', '50%'],
            label: {
              show: true,
              color: '#fff',
              formatter: (params) => {
                return params.name + ' ' + params.value + '%'
              }
            }
          }
        ]
      }

      option && this.myChart11.setOption(option)
    }
  }
}
</script>

<style lang="less" scoped>
.sub {
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: 100%;
  background-image: url('../../../assets/img/zk1.png');
  background-size: 100% 100%;
  background-repeat: no-repeat;

  .sub-left {
    width: 25%;
    height: 100%;

    .sub-left-item {
      width: 100%;
      height: calc(25% - 10px);
      margin-bottom: 10px;
      padding: 5px;
      background-color: rgba(2, 27, 99, .6);
      box-sizing: border-box;
    }

    .sub-left-item1 {
      width: 100%;
      height: 25%;
      margin-bottom: 0;
      background-color: rgba(2, 27, 99, .6);
    }

    .sub-title {
      width: 70%;
      height: 35px;
      line-height: 40px;
      padding-left: 30px;
      background-image: url('../../../assets/img/zk2.png');
      background-size: 100% 100%;
      background-repeat: no-repeat;
      box-sizing: border-box;
      color: #fff;
      font-size: 20px;
      font-weight: bold;
    }

    .sub-box {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-evenly;
      width: 100%;
      height: calc(100% - 35px);
      padding: 0 20px;
      box-sizing: border-box;

      .sub-box-item {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 70px;
        height: 70px;
        border-radius: 50%;
        background-color: #0E8DC6;
        outline: 4px solid #0E8DC6;
        border: 1px solid #fff;
        color: #fff;
        font-size: 14px;

        .blue {
          color: #18CDFD;
        }
      }

      .sub-chart {
        width: 100%;
        height: 100%;
      }
    }
  }

  .sub-center {
    width: calc(50% - 20px);
    height: 100%;

    .sub-top {
      width: 100%;
      height: 100%;
      padding: 10px 26px;
      background-color: rgba(2, 27, 99, .6);
      box-sizing: border-box;

      .sub-title {
        width: 40%;
        height: 35px;
        line-height: 40px;
        padding-left: 30px;
        background-image: url('../../../assets/img/zk2.png');
        background-size: 100% 100%;
        background-repeat: no-repeat;
        box-sizing: border-box;
        color: #fff;
        font-size: 20px;
        font-weight: bold;
      }

      .sub-label {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100px;

        .sub-label-text {
          margin-left: 10px;
          margin-right: 10px;
          color: #fff;
          font-size: 18px;
        }

        .sub-label-item {
          width: 34px;
          height: 48px;
          line-height: 48px;
          margin: 0 2px;
          text-align: center;
          border-radius: 3px;
          background: linear-gradient(180deg, #FFFFFF 0%, #021A5C 100%);
          color: #fff;
          font-size: 30px;
          font-weight: 800;
        }
      }

      .sub-select {
        display: flex;
        flex-direction: row;
        align-items: center;
        width: 100%;
        height: 80px;
        color: #fff;
        font-size: 16px;

        .el-select {
          margin-left: 10px;
        }
      }

      .sub-map {
        width: 100%;
        height: calc(50% - 110px);
      }
    }

    .sub-bottom {
      width: 100%;
      height: 40%;
      padding: 5px;
      background-color: rgba(2, 27, 99, .6);
      box-sizing: border-box;

      .sub-title {
        width: 40%;
        height: 35px;
        line-height: 40px;
        padding-left: 30px;
        background-image: url('../../../assets/img/zk2.png');
        background-size: 100% 100%;
        background-repeat: no-repeat;
        box-sizing: border-box;
        color: #fff;
        font-size: 20px;
        font-weight: bold;
      }

      .sub-bottom-chart {
        width: 100%;
        height: calc(100% - 40px);
      }
    }
  }

  .sub-right {
    width: 25%;
    height: 100%;

    .sub-left-item {
      width: 100%;
      height: calc(25% - 10px);
      margin-bottom: 10px;
      padding: 5px;
      background-color: rgba(2, 27, 99, .6);
      box-sizing: border-box;
    }

    .sub-left-item1 {
      width: 100%;
      height: 25%;
      margin-bottom: 0;
      background-color: rgba(2, 27, 99, .6);
    }

    .sub-left-item2 {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      padding: 0;
      background-color: rgba(2, 27, 99, 0);
    }

    .sub-title {
      width: 70%;
      height: 35px;
      line-height: 40px;
      padding-left: 30px;
      background-image: url('../../../assets/img/zk2.png');
      background-size: 100% 100%;
      background-repeat: no-repeat;
      box-sizing: border-box;
      color: #fff;
      font-size: 20px;
      font-weight: bold;
    }

    .sub-chart {
      display: flex;
      width: 100%;
      height: calc(100% - 35px);

      .sub-chart-item {
        width: 33%;
        height: 100%;
      }
    }

    .sub-box {
      overflow: hidden;
      width: 100%;
      height: calc(100% - 35px);

      .sub-box-item {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding-top: 5px;
        padding-bottom: 5px;
        color: #fff;
        font-size: 16px;
      }

      .red {
        color: #FF0000;
      }

    }

    .sub-label {
      width: calc(50% - 2px);
      height: 100%;
      background-color: rgba(2, 27, 99, .6);
    }
  }
}
</style>
