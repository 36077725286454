<template>
  <div class="popup">
    <div class="popup-box">
      <div class="popup-head">
        <span>{{ selecType }}</span>
        <el-button type="primary" size="mini">导出</el-button>
      </div>
      <div class="popup-table">
        <el-table :data="tableData" height="100%">
          <el-table-column prop="id" label="序号">
          </el-table-column>
          <el-table-column prop="name" label="姓名">
          </el-table-column>
          <el-table-column prop="xb" label="性别" :width="flexWidth('xb', tableData, '性别')">
            <template slot-scope="scope">
              <span>{{ scope.row.xb === '1' ? '男' : '女' }}</span>
            </template>
          </el-table-column>
          <el-table-column prop="age" label="年龄" :width="flexWidth('age', tableData, '年龄')">
          </el-table-column>
          <el-table-column prop="sfzhm" label="证件号码" :width="flexWidth('sfzhm', tableData, '证件号码')">
          </el-table-column>
          <el-table-column prop="nametage" label="人群分类">
          </el-table-column>
          <el-table-column prop="xzjdmc" label="服务机构" :width="flexWidth('xzjdmc', tableData, '服务机构')">
          </el-table-column>
          <el-table-column prop="address" label="服务类型">
          </el-table-column>
          <el-table-column prop="address" label="管理医生">
          </el-table-column>
          <el-table-column label="操作">
            <template>
              <el-button type="primary" size="small" @click="open">查看</el-button>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div class="popup-pagination">
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="query.pageNum"
          background
          :page-size="query.pageSize"
          layout="total, prev, pager, next, jumper"
          :total="query.total"></el-pagination>
      </div>
      <div class="popup-button">
        <el-button size="small" @click="close">取消</el-button>
      </div>
    </div>
    <!-- 新生儿家庭访视记录表 -->
    <NeonateForm v-if="showIndex === 0" @close="hide"></NeonateForm>
    <!-- 0-3岁男童生长发育监测图 -->
    <ZeroThree v-if="showIndex === 1" @close="hide"></ZeroThree>
    <!-- 3～6岁儿童健康检查记录表 -->
    <ThreeSix v-if="showIndex === 2" @close="hide"></ThreeSix>
    <!-- 中医药健康管理服务 -->
    <MedicalHealthServe v-if="showIndex === 3" @close="hide"></MedicalHealthServe>
    <!-- 儿童视觉健康档案表 -->
    <ChildrenForm v-if="showIndex === 4" @close="hide"></ChildrenForm>
    <!-- 1～8月龄儿童健康检查记录表 -->
    <OneEightHealthForm v-if="showIndex === 5" @close="hide"></OneEightHealthForm>
    <!-- 12～30月龄儿童健康检查记录表 -->
    <TwelveThirty v-if="showIndex === 6" @close="hide"></TwelveThirty>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import NeonateForm from './popup/neonate-form.vue'
import ZeroThree from './popup/zero-three.vue'
import ThreeSix from './popup/three-six.vue'
import MedicalHealthServe from './popup/medical-health-serve.vue'
import ChildrenForm from './popup/children-form.vue'
import OneEightHealthForm from './popup/one-eight-health-form.vue'
import TwelveThirty from './popup/twelve-thirty.vue'
export default {
  props: {
    selecType: {
      type: String,
      default: ''
    },
    selecLyType: {
      type: String,
      default: ''
    }
  },
  components: {
    NeonateForm,
    ZeroThree,
    ThreeSix,
    MedicalHealthServe,
    ChildrenForm,
    OneEightHealthForm,
    TwelveThirty
  },
  data () {
    return {
      params: {
        start: '0',
        length: '10',
        ssjgbh: '',
        type: 'etjkgl',
        lytype: '',
        sjjgbh: '',
        ycfj: ''
      },
      // 分页数据
      query: {
        pageNum: 1,
        pageSize: 10,
        total: 0
      },
      tableData: [],
      showIndex: -1
    }
  },
  computed: {
    ...mapState(['loginInfo'])
  },
  created () {
    // console.log(this.selecType, this.selecLyType)
    // this.params.ssjgbh = this.loginInfo.jgbh || ''
    // this.params.sjjgbh = this.loginInfo.sjjgbh || ''
    // this.params.lytype = this.selecLyType || ''
    // this.getInfo()
  },
  methods: {
    // el-table自适应宽度
    flexWidth (prop, tableData, title, num = 0) {
      if (tableData.length === 0) {
        return title.length * 30 + 'px'
      }
      let flexWidth = 0// 初始化表格列宽
      let columnContent = ''// 占位最宽的内容
      const canvas = document.createElement('canvas')
      const context = canvas.getContext('2d')
      context.font = '16px Microsoft YaHei'
      if ((prop === '') && title) { // 标题长内容少的，取标题的值,
        columnContent = title
      } else { // 获取该列中占位最宽的内容
        let index = 0
        for (let i = 0; i < tableData.length; i++) {
          const nowTemp = tableData[i][prop] + ''
          const maxTemp = tableData[index][prop] + ''
          const nowTempW = context.measureText(nowTemp).width
          const maxTempW = context.measureText(maxTemp).width
          if (nowTempW > maxTempW) {
            index = i
          }
        }
        columnContent = tableData[index][prop]
        // 比较占位最宽的值跟标题、标题为空的留出四个位置
        const columnW = context.measureText(columnContent).width
        const titleW = context.measureText(title).width
        if (columnW < titleW) {
          columnContent = title || '留七'
        }
      }
      // 计算最宽内容的列宽
      const width = context.measureText(columnContent)
      flexWidth = width.width + 38 + num
      return flexWidth + 'px'
    },
    async getInfo () {
      // 接口文档 https://app.apifox.com/project/2726972/apis/api-149665630
      try {
        this.params.start = this.query.pageNum === 1 ? '0' : (this.query.pageNum - 1) * 10 + ''
        this.params.length = this.query.pageSize + ''
        const { data: res } = await this.$http.get('/api/jkct/app/findzhgwyfrhlb', { params: this.params })
        console.log('智慧公卫儿童-中间总览数据点击弹窗接口', res)
        if (res.code !== 0) {
          return this.$message({
            message: res.msg || '暂无信息',
            type: 'warning'
          })
        }
        this.tableData = res.data
        this.query.total = res.recordsTotal
      } catch (err) {
        console.log('智慧公卫儿童-中间总览数据点击弹窗接口', err)
      }
    },
    handleSizeChange (val) {
      // console.log(`每页 ${val} 条`)
    },
    handleCurrentChange (val) {
      this.query.pageNum = val
      this.getInfo()
    },
    open () {
      const arr = ['新生儿', '0-3岁', '4-6岁', '0-3岁中医']
      console.log(arr.indexOf(this.selecType))
      this.showIndex = arr.indexOf(this.selecType)
    },
    hide () {
      console.log('关闭')
      this.showIndex = -1
    },
    close () {
      this.$emit('close')
    }
  }
}
</script>

<style lang="less" scoped>
.popup {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 999;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, .8);

  .popup-box {
    width: 80%;
    height: 70%;
    background-color: #1a3673cc;
    border: 1px solid;
    border-image: linear-gradient(270deg, rgba(64, 154, 222, 0), rgba(19, 255, 255, 1), rgba(64, 154, 222, 0)) 1 1;

    .popup-head {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      height: 40px;
      padding: 0 10px;
      // background: linear-gradient(270deg, rgba(10, 29, 87, 0) 0%, rgba(0, 97, 194, .47) 47%);
      background-color: #1a3673cc;
      color: #fff;
      font-size: 20px;
      box-sizing: border-box;
    }

    .popup-table {
      width: 100%;
      height: calc(100% - 160px);

      /deep/.el-table__cell {
        text-align: center;
        color: #fff;
        font-size: 16px;
        font-weight: 400;
      }

      /deep/.el-table th.el-table__cell {
        background-color: #1a3673cc;
      }

      /deep/.el-table tr {
        background-color: #1a3673cc;
      }

      /deep/.el-table--border .el-table__cell {
        border-color: transparent;
      }

      /deep/.el-table {
        background-color: #1a3673cc;
      }

      /deep/.el-table th.el-table__cell.is-leaf,
      /deep/.el-table td.el-table__cell {
        border-color: transparent;
      }

      /deep/.el-table__body tr.current-row>td {
        background-color: transparent;
      }

      /deep/.el-table--enable-row-hover .el-table__body tr:hover>td {
        background-color: transparent;
      }

      /deep/.el-table__empty-text {
        color: #fff;
      }
    }

    .popup-pagination {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 60px;

      /deep/.el-pagination__total, /deep/.el-pagination__jump {
        color: #fff;
      }

      /deep/.el-pagination__editor {
        margin-right: 20px;
      }
    }

    .popup-button {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 60px;
    }
  }
}
</style>
