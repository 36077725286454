<template>
  <div class="monitor">
    <div class="scroll">
      <div class="box">
        <div class="earch"></div>
        <div class="item item1">
          <div class="table table1">
            <div class="top">
              <div class="title title1">
                <span>体温</span>
              </div>
              <div class="icon icon1"></div>
            </div>
            <div class="scroll">
              <div class="scroll-box">
                <div class="cell cell1 blue" v-for="(item, index) in 20" :key="index" @click="openTwChart">
                  <span>张三</span>
                  <span>2022-12-10</span>
                  <span>类型：联网</span>
                  <span>测量人：张译</span>
                  <span>36.5°</span>
                </div>
              </div>

            </div>
          </div>
          <div class="line line1"></div>
        </div>
        <!-- <div class="item item2">
          <div class="table table2">
            <div class="top">
              <div class="title title2">
                <span>身高</span>
              </div>
              <div class="icon icon2"></div>
            </div>
            <div class="scroll">
              <div class="cell cell2" v-for="(item, index) in 6" :key="index" @click="openSgChart">
                <span>张三</span>
                <span>2022-12-10</span>
                <span>175cm</span>
              </div>
            </div>
          </div>
          <div class="line line2"></div>
        </div> -->
        <div class="item item2">
          <div class="table table2">
            <div class="top">
              <div class="title title2">
                <span>血氧</span>
              </div>
              <div class="icon icon2"></div>
            </div>
            <div class="scroll scroll2">
              <div class="scroll-box">
                <div class="cell cell2" v-for="(item, index) in 20" :key="index" @click="openSgChart">
                  <span>张三</span>
                  <span>2022-12-10</span>
                  <span>类型：联网</span>
                  <span>测量人：张译</span>
                  <span>83%</span>
                </div>
              </div>

            </div>
          </div>
          <div class="line line2"></div>
        </div>
        <div class="item item3">
          <div class="table table3">
            <div class="top">
              <div class="title title3">
                <span>体重</span>
              </div>
              <div class="icon icon3"></div>
            </div>
            <div class="scroll">
              <div class="scroll-box">
                <div class="cell cell3" v-for="(item, index) in 20" :key="index" @click="openTzChart">
                  <span>张三</span>
                  <span>2022-12-10</span>
                  <span>类型：联网</span>
                  <span>测量人：张译</span>
                  <span>75kg</span>
                </div>
              </div>

            </div>
          </div>
          <div class="line line3"></div>
        </div>
        <div class="item item4">
          <div class="table table4">
            <div class="top">
              <div class="title title4">
                <span>心率</span>
              </div>
              <div class="icon icon4"></div>
            </div>
            <div class="scroll">
              <div class="scroll-box">
                <div class="cell cell4" v-for="(item, index) in 20" :key="index" @click="openXlChart">
                  <span>张三</span>
                  <span>2022-12-10</span>
                  <span>类型：联网</span>
                  <span>测量人：张译</span>
                  <span>65次/分</span>
                </div>
              </div>

            </div>
          </div>
          <div class="line line4"></div>
        </div>
        <div class="item item5">
          <div class="table table5">
            <div class="top">
              <div class="title title5">
                <span>血压</span>
              </div>
              <div class="icon icon5"></div>
            </div>
            <div class="scroll">
              <div class="scroll-box">
                <div class="cell cell5" v-for="(item, index) in 20" :key="index" @click="openXyChart">
                  <span>张三</span>
                  <span>2022-12-10</span>
                  <span>类型：联网</span>
                  <span>测量人：张译</span>
                  <span>高压：120mmHg</span>
                  <span>低压：120mmHg</span>
                </div>
              </div>

            </div>
          </div>
          <div class="line line5"></div>
        </div>
        <div class="item item6">
          <div class="table table6">
            <div class="top">
              <div class="title title6">
                <span>血糖</span>
              </div>
              <div class="icon icon6"></div>
            </div>
            <div class="scroll">
              <div class="scroll-box">
                <div class="cell cell6" v-for="(item, index) in 20" :key="index" @click="openXtChart">
                  <span>张三</span>
                  <span>2022-12-10</span>
                  <span>类型：联网</span>
                  <span>测量人：张译</span>
                  <span>3.8mmol/L</span>
                </div>
              </div>

            </div>
          </div>
          <div class="line line6"></div>
        </div>
        <!-- <div class="item item7">
          <div class="table table7">
            <div class="top">
              <div class="title title7">
                <span>血氧</span>
              </div>
              <div class="icon icon7"></div>
            </div>
            <div class="scroll">
              <div class="cell cell7" v-for="(item, index) in 6" :key="index" @click="openYxChart">
                <span>张三</span>
                <span>2022-12-10</span>
                <span>83%</span>
              </div>
            </div>
          </div>
          <div class="line line7"></div>
        </div> -->
      </div>
    </div>
    <!-- <XyChart v-if="showXyChart" @close="closeXyChart"></XyChart>
    <XtChart v-if="showXtChart" @close="closeXtChart"></XtChart>
    <TwChart v-if="showTwChart" @close="closeTwChart"></TwChart>
    <XlChart v-if="showXlChart" @close="closeXlChart"></XlChart>
    <YxChart v-if="showYxChart" @close="closeYxChart"></YxChart>
    <SgChart v-if="showSgChart" @close="closeSgChart"></SgChart>
    <TzChart v-if="showTzChart" @close="closeTzChart"></TzChart> -->
  </div>
</template>

<script>
import { mapState } from 'vuex'
// import XyChart from './xyChart.vue'
// import XtChart from './xtChart.vue'
// import TwChart from './twChart.vue'
// import XlChart from './xlChart.vue'
// import YxChart from './yxChart.vue'
// import SgChart from './sgChart.vue'
// import TzChart from './tzChart.vue'
export default {
  components: {
    // XyChart,
    // XtChart,
    // TwChart,
    // XlChart,
    // YxChart,
    // SgChart,
    // TzChart
  },
  data () {
    return {
      showXyChart: false,
      showXtChart: false,
      showTwChart: false,
      showXlChart: false,
      showYxChart: false,
      showSgChart: false,
      showTzChart: false,
      list: []
    }
  },
  computed: {
    ...mapState(['loginInfo'])
  },
  created () {
    // this.getList()
  },
  methods: {
    // 智能监测
    async getList () {
      // 接口地址 https://app.apifox.com/project/2726972/apis/api-81591809
      try {
        const obj = {
          sfzh: '', // 身份证号
          sbbm: '', // 设备编码
          sbxh: '', // 设备型号 xy (血压） xt (血糖)
          ssjg: '', // 所属机构
          jcry: '', // 检测人员
          jcjg: '', // 检测机构
          jgcmhid: '', // 机构村id
          jgxzjdid: '', // 机构乡镇id
          lytype: '' // 检测界面 档案管理 传 jkdn, 体检管理列表 传 jktj,医防融合传 yfrh，体质辨识 zydd,诊疗服务 zlfw,数据上报 sjsb
        }
        const { data: res } = await this.$http.post('/api/jkct/app/findfzjc', obj)
        console.log('智能监测', res)
        if (res.code !== 0) return this.$message.error(res.msg || res.message)
        this.list = res.data
      } catch (error) {
        console.log('智能监测请求失败', error)
        this.$message.error(error.response.data.msg || '系统异常，请稍后重试')
      }
    },
    openXyChart () {
      this.showXyChart = true
    },
    closeXyChart () {
      this.showXyChart = false
    },
    openXtChart () {
      this.showXtChart = true
    },
    closeXtChart () {
      this.showXtChart = false
    },
    openTwChart () {
      this.showTwChart = true
    },
    closeTwChart () {
      this.showTwChart = false
    },
    openXlChart () {
      this.showXlChart = true
    },
    closeXlChart () {
      this.showXlChart = false
    },
    openYxChart () {
      this.showYxChart = true
    },
    closeYxChart () {
      this.showYxChart = false
    },
    openSgChart () {
      this.showSgChart = true
    },
    closeSgChart () {
      this.showSgChart = false
    },
    openTzChart () {
      this.showTzChart = true
    },
    closeTzChart () {
      this.showTzChart = false
    }
  }
}
</script>

<style lang="less" scoped>
.monitor {
  overflow: hidden;
  position: relative;
  width: calc(100% - 10px);
  height: 100%;

  .scroll {
    width: 100%;
    height: 100%;
  }

  .box {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    background-image: url('../../zhyl/img/ssjk/background6.png');
    background-size: 100% 100%;
  }

  .earch {
    width: 312px;
    height: 294px;
    background-image: url('../../zhyl/img/ssjk/earth.png');
    background-size: 100% 100%;
  }

  .item {
    position: absolute;
    display: flex;

    .table {
      position: absolute;
      width: 500px;
      height: 261px;
      box-sizing: border-box;
      background-size: 100% 100%;

      .top {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        height: 27px;
        margin-top: 30px;
        padding-bottom: 20px;
        padding-left: 20px;
        padding-right: 49px;
        box-sizing: border-box;

        .title {
          width: 142px;
          height: 27px;
          line-height: 27px;
          padding-left: 24px;
          box-sizing: border-box;
          color: #fff;
          font-size: 14px;
          font-weight: bold;
          background-size: 100% 100%;
        }

        .title1 {
          background-image: url('../../zhyl/img/ssjk/tableTitle1.png');
        }

        .title2 {
          background-image: url('../../zhyl/img/ssjk/tableTitle7.png');
        }

        .title3 {
          background-image: url('../../zhyl/img/ssjk/tableTitle3.png');
        }

        .title4 {
          background-image: url('../../zhyl/img/ssjk/tableTitle4.png');
        }

        .title5 {
          background-image: url('../../zhyl/img/ssjk/tableTitle5.png');
        }

        .title6 {
          background-image: url('../../zhyl/img/ssjk/tableTitle6.png');
        }

        .title7 {
          background-image: url('../../zhyl/img/ssjk/tableTitle7.png');
        }

        .icon {
          width: 26px;
          height: 26px;
          background-size: 100% 100%;
        }

        .icon1 {
          background-image: url('../../zhyl/img/ssjk/tableIcon1.png');
        }

        .icon2 {
          background-image: url('../../zhyl/img/ssjk/tableIcon7.png');
        }

        .icon3 {
          background-image: url('../../zhyl/img/ssjk/tableIcon3.png');
        }

        .icon4 {
          background-image: url('../../zhyl/img/ssjk/tableIcon4.png');
        }

        .icon5 {
          background-image: url('../../zhyl/img/ssjk/tableIcon5.png');
        }

        .icon6 {
          background-image: url('../../zhyl/img/ssjk/tableIcon6.png');
        }

        .icon7 {
          background-image: url('../../zhyl/img/ssjk/tableIcon7.png');
        }
      }

      .scroll {
        overflow: hidden;
        width: calc(100% - 34px);
        height: calc(100% - 70px);
        margin: 0 auto;

        .scroll-box {
          display: flex;
          flex-direction: column;
          animation: move linear 10s infinite;
        }

        @keyframes move {
          0% {
            transform: translateY(0);
          }

          100% {
            transform: translateY(-300px);
          }
        }

        .cell {
          display: flex;
          align-items: center;
          justify-content: space-between;
          width: 100%;
          height: 30px;
          margin-bottom: 10px;
          padding: 0 10px;
          box-sizing: border-box;
          background-size: 100% 100%;
          font-size: 14px;
          color: #fff;
          cursor: pointer;
        }

        .cell1 {
          background-image: url('../../zhyl/img/ssjk/tableCell1.png');
        }

        .cell2 {
          background-image: url('../../zhyl/img/ssjk/tableCell7.png');
        }

        .cell3 {
          background-image: url('../../zhyl/img/ssjk/tableCell3.png');
        }

        .cell4 {
          background-image: url('../../zhyl/img/ssjk/tableCell4.png');
        }

        .cell5 {
          background-image: url('../../zhyl/img/ssjk/tableCell5.png');
        }

        .cell6 {
          background-image: url('../../zhyl/img/ssjk/tableCell6.png');
        }

        .cell7 {
          background-image: url('../../zhyl/img/ssjk/tableCell7.png');
        }

        .blue {
          color: #01EEFD;
        }
      }

      .scroll2 {
        width: calc(100% - 34px);
      }
    }

    .table1 {
      top: 0;
      left: 0;
      background-image: url('../../zhyl/img/ssjk/table1.png');
    }

    .table2 {
      width: 500px;
      top: 0;
      left: 0;
      background-image: url('../../zhyl/img/ssjk/table7.png');
    }

    .table3 {
      top: 0px;
      right: 20px;
      background-image: url('../../zhyl/img/ssjk/table3.png');
    }

    .table4 {
      top: 0;
      left: 10px;
      background-image: url('../../zhyl/img/ssjk/table4.png');
    }

    .table5 {
      width: 680px;
      left: 0;
      bottom: 0;
      background-image: url('../../zhyl/img/ssjk/table5.png');
    }

    .table6 {
      width: 620px;
      bottom: 10px;
      right: 0;
      background-image: url('../../zhyl/img/ssjk/table6.png');
    }

    .table7 {
      bottom: 0;
      right: 0;
      background-image: url('../../zhyl/img/ssjk/table7.png');
    }

    .line {
      position: absolute;
      background-size: 100% 100%;
    }

    .line1 {
      top: 180px;
      right: 26px;
      width: 230px;
      height: 212px;
      background-image: url('../../zhyl/img/ssjk/tableLine1.png');
    }

    .line2 {
      left: 100px;
      bottom: 16px;
      width: 78px;
      height: 77px;
      transform: rotate(30deg);
      background-image: url('../../zhyl/img/ssjk/tableLine7.png');
    }

    .line3 {
      left: 60px;
      bottom: 80px;
      width: 240px;
      height: 220px;
      background-image: url('../../zhyl/img/ssjk/tableLine3.png');
    }

    .line4 {
      right: 0;
      top: 0;
      width: 201px;
      height: 90px;
      background-image: url('../../zhyl/img/ssjk/tableLine4.png');
    }

    .line5 {
      top: 18px;
      left: 190px;
      width: 98px;
      height: 89px;
      background-image: url('../../zhyl/img/ssjk/tableLine5.png');
      transform: rotate(-180deg);
    }

    .line6 {
      top: 80px;
      left: 46px;
      width: 152px;
      height: 119px;
      background-image: url('../../zhyl/img/ssjk/tableLine6.png');
    }

    .line7 {
      top: 0;
      left: 0;
      width: 281px;
      height: 257px;
      background-image: url('../../zhyl/img/ssjk/tableLine7.png');
    }
  }

  .item1 {
    left: 30px;
    top: 0px;
    width: 747px;
    height: 461px;
  }

  .item2 {
    left: 50%;
    top: 0px;
    width: 500px;
    height: 335.4px;
    transform: translateX(-50%);
  }

  .item3 {
    right: 10px;
    top: 20px;
    width: 814px;
    height: 473px;
  }

  .item4 {
    left: 40px;
    bottom: 200px;
    width: 701px;
    height: 261px;
  }

  .item5 {
    width: 680px;
    left: 50%;
    bottom: 0;
    width: 500px;
    height: 352px;
    transform: translateX(-50%);
  }

  .item6 {
    bottom: 250px;
    right: 0;
    width: 806px;
    height: 281px;
  }

  .item7 {
    bottom: 58px;
    right: 250px;
    width: 623px;
    height: 421px;
  }
}
</style>
