<template>
  <div class="page">
    <div class="head">
      <div class="left">
        <div class="back" @click="back">
          <span>返回</span>
        </div>
        <div class="tab">
          <div class="tab-item" :class="{ 'tab-active': index === SmartLargeScreenIndex, 'tab-item2': index === 0 }"
            v-for="(item, index) in tabs" :key="index" @click="tabClick(index)">
            <div class="tab-text">
              <span>{{ item }}</span>
            </div>
          </div>
        </div>
      </div>
      <div class="center">
        <span>智慧医防融合数据中心</span>
      </div>
      <div class="right" ref="scroll">
        <div class="tab">
          <div class="tab-item tab-item1" :id="'item' + (index + tabs.length)" :class="{ 'tab-active': index + tabs.length === SmartLargeScreenIndex }" v-for="(item, index) in tabs1" :key="index" @click="tabClick(index + tabs.length, 'right')">
            <div class="tab-text tab-text1">
              <span>{{ item }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="container">
      <router-view></router-view>
    </div>
    <Message v-if="showMessage" @close="closeMessage"></Message>
    <Spms v-if="showSpms"></Spms>
  </div>
</template>

<script>
import Message from './message.vue'
import Spms from './spms/spms.vue'
import { mapState } from 'vuex'
export default {
  components: {
    Message,
    Spms
  },
  data () {
    return {
      tabIndex: 0,
      // tabs: Object.freeze(['总控', '居家养老服务', '医防融合', '智慧体检']),
      // tabs1: Object.freeze(['辅助监测', '防走丢监护', '生命体征监测']),
      // urls: Object.freeze(['generalcontrol', 'homecareserve', 'medicalprevention', 'smarttest', 'auxiliarymonitoring', 'antilossmonitoring', 'vitalsignmonitoring'])
      tabs: ['总控', '居家养老', '机构养老', '服务监管', '实时监测'],
      tabs1: ['养老服务监测', '防走丢监护', '生命体征监测', '预警监测', '数据总览'],
      urls: ['generalcontrol', 'smarttest', 'medicalprevention', 'serviceMonitoring', 'data', 'homecareserve', 'antilossmonitoring', 'vitalsignmonitoring', 'earlywarnmonitor', 'datascreen'],
      showMessage: true,
      showSpms: false
    }
  },
  computed: {
    ...mapState(['SmartLargeScreenIndex', 'loginInfo', 'ShowHeadTab'])
  },
  mounted () {
    // console.log(this.ShowHeadTab)
    console.log(this.loginInfo.permissions)
    console.log(this.loginInfo.permissions.findIndex(item => item.name === '两慢病筛查'))
    if (this.loginInfo.usercode === 'dazxwsy2201' || this.loginInfo.usercode === 'rcxyfrh') {
      this.showMessage = false
    }
    if (this.loginInfo.usercode === 'xxsqlhj') {
      this.tabs = ['总控', '医防融合', '智慧体检', '智慧慢病']
      this.tabs1 = ['辅助监测', '防走丢监护', '智慧家签']
      this.urls = ['generalcontrols', 'medicalpreventions', 'smarttests', 'chronicdisease', 'auxiliarymonitorings', 'antilossmonitoring', 'homesignature']
      const title = this.ShowHeadTab.filter(item => item.text === '智慧查体')
      if (this.ShowHeadTab.length === 2 && title.length === 1) {
        const arr = this.ShowHeadTab.filter(item => item.text === '智慧大屏')
        if (arr.length > 0) {
          this.tabs = ['智慧学生查体']
          this.tabs1 = []
          this.urls = ['studenttest']
          this.$router.push('/zhdp/studenttest')
        }
      } else if (this.ShowHeadTab.length > 2 && title.length === 1) {
        this.tabs = ['总控', '医防融合', '智慧体检', '智慧慢病']
        this.tabs1 = ['辅助监测', '防走丢监护', '智慧家签', '智慧学生查体', '两慢病监测']
        this.urls = ['generalcontrols', 'medicalpreventions', 'smarttests', 'chronicdisease', 'auxiliarymonitorings', 'antilossmonitoring', 'homesignature', 'studenttest', 'chronicdiseasemonitor']
      }
    } else if (this.loginInfo.usercode === 'rcxyfrh') {
      this.tabs = ['总控', '医防融合', '智慧体检', '智慧慢病']
      this.tabs1 = ['辅助监测', '实时监控', '智慧家签', '远程心电', '远程会诊', '远程影像', '远程B超']
      this.urls = ['generalcontrols', 'medicalpreventions', 'smarttests', 'chronicdisease', 'auxiliarymonitorings', 'antilossmonitoring', 'homesignature', 'remoteecg', 'remotediagnosis', 'remoteimage', 'remoteb']
      if (this.loginInfo.permissions.findIndex(item => item.name === '两慢病筛查') > -1) {
        this.tabs = ['总控', '医防融合', '智慧体检', '智慧慢病', '两慢病监测']
        this.tabs1 = ['辅助监测', '实时监控', '智慧家签', '远程心电', '远程会诊', '远程影像', '远程B超']
        this.urls = ['generalcontrols', 'medicalpreventions', 'smarttests', 'chronicdisease', 'chronicdiseasemonitor', 'auxiliarymonitorings', 'antilossmonitoring', 'homesignature', 'remoteecg', 'remotediagnosis', 'remoteimage', 'remoteb']
      }
    } else if (this.ShowHeadTab.findIndex(item => item.text === '智慧养老') === -1) {
      this.tabs = ['总控', '医防融合', '智慧体检', '智慧慢病']
      this.tabs1 = ['辅助监测', '实时监控', '智慧家签', '远程心电', '远程会诊', '远程影像', '远程B超']
      this.urls = ['generalcontrols', 'medicalpreventions', 'smarttests', 'chronicdisease', 'auxiliarymonitorings', 'antilossmonitoring', 'homesignature', 'remoteecg', 'remotediagnosis', 'remoteimage', 'remoteb']
    } else {
      console.log(this.ShowHeadTab)
      if (this.ShowHeadTab.length === 1) {
        const arr = this.ShowHeadTab.filter(item => item.text === '智慧查体')
        if (arr.length > 0) {
          this.tabs = ['智慧学生查体']
          this.tabs1 = []
          this.urls = ['studenttest']
          this.$router.push('/zhdp/studenttest')
        }
      } else if (this.ShowHeadTab.length > 1) {
        const arr = this.ShowHeadTab.filter(item => item.text === '智慧查体')
        if (arr.length > 0) {
          this.tabs = ['总控', '居家养老', '机构养老', '服务监管', '实时监测', '智慧学生查体']
          this.tabs1 = ['养老服务监测', '防走丢监护', '生命体征监测', '预警监测', '数据总览']
          this.urls = ['generalcontrol', 'smarttest', 'medicalprevention', 'serviceMonitoring', 'data', 'studenttest', 'homecareserve', 'antilossmonitoring', 'vitalsignmonitoring', 'earlywarnmonitor', 'datascreen']
        }
      }
    }

    const index = window.sessionStorage.getItem('zhdpIndex')
    this.$store.commit('changeSmartLargeScreenIndex', !index ? 0 : parseInt(index))
  },
  methods: {
    back () {
      const list = ['runkanban', 'smarthealthcare', 'smartpublicguard', 'smartphysicalexamination', 'smartelderlycare', 'wisdomhomesignature', 'intelligentmonitoring', 'reservationservice', 'organrun', 'intelligenthardware', 'problemfeedback', 'zhdp']
      let index = window.sessionStorage.getItem('headIndex')
      index = index === null ? 0 : index
      console.log('--------', index)
      this.$router.push('/' + list[index])
      this.$store.commit('changeSmartLargeScreenIndex', index)
      console.log(window.sessionStorage.getItem('headTabActivePath'))
      this.$router.push(window.sessionStorage.getItem('headTabActivePath'))
    },
    tabClick (index, type) {
      if (this.SmartLargeScreenIndex === index) return
      if (this.urls[index] === 'medicalpreventions') {
        this.showSpms = true
        return
      }

      try {
        if (type === 'right') {
          console.log('11')
          this.$refs.scroll.scrollLeft = index > this.SmartLargeScreenIndex ? 200 : 0
          document.getElementById('item' + this.SmartLargeScreenIndex).scrollIntoView({
            behavior: 'smooth', block: 'end', inline: 'nearest'
          })
        }
      } catch (error) {

      }

      this.$store.commit('changeSmartLargeScreenIndex', index)

      this.$router.push('/zhdp/' + this.urls[index])
    },
    openMessage () {
      this.showMessage = true
    },
    closeMessage () {
      this.showMessage = false
    }
  }
}
</script>

<style lang="less" scoped>
.page {
  position: relative;
  width: 100%;
  height: 100%;
  background-color: #071224;

  .head {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 102px;
    padding-left: 23px;
    background-image: url('../../assets/img/zhdp/head.png');
    background-repeat: no-repeat;
    background-size: 100% 100%;
    box-sizing: border-box;

    .left {
      display: flex;
      align-items: center;
      width: 35%;

      .back {
        width: 46px;
        height: 46px;
        line-height: 46px;
        text-align: center;
        border-radius: 50%;
        background-color: #18CDFD;
        color: #fff;
        font-size: 14px;
        cursor: pointer;
      }
    }

    .center {
      width: 30%;
      height: 102px;
      line-height: 102px;
      letter-spacing: 3px;
      text-align: center;
      color: #fff;
      font-size: 30px;
    }

    .right {
      overflow-x: scroll;
      width: 35%;
      height: 48px;
      // padding-left: 40px;
      box-sizing: border-box;

      &::-webkit-scrollbar {
        width: 0;
        height: 0;
      }
    }

    .tab {
      display: flex;
      align-items: center;
      margin-left: 20px;

      .tab-item {
        min-width: 100px;
        height: 44px;
        line-height: 44px;
        margin: 0 5px;
        padding: 0 8px;
        text-align: center;
        border: 1px solid #03BCD7;
        background-color: rgba(3, 188, 215, .12);
        box-sizing: border-box;
        color: #01EEFD;
        font-size: 16px;
        transform: skew(15deg);
        cursor: pointer;

        .tab-text {
          transform: skew(-15deg);
        }

        .tab-text1 {
          transform: skew(15deg);
        }
      }

      .tab-item1 {
        transform: skew(-15deg);
      }

      .tab-item2 {
        padding: 0 20px;
        margin-left: 0;
      }

      .tab-active {
        background-color: #18CDFD;
        color: #fff;
      }

    }
  }

  .container {
    // position: relative;
    width: 100%;
    height: calc(100% - 102px);
    padding: 10px;
    box-sizing: border-box;
  }

  .scroll {
    overflow: hidden;
    width: 100%;
    height: 100%;
  }

}
</style>
