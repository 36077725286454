<template>
  <div class="sub-page">
    <div class="sub-top">
      <div class="sub-top-left">
        <div class="sub-title">
          <span>老人数据概览</span>
        </div>
        <div class="sub-box">
          <div class="sub-old">
            <div class="sub-old-item">
              <img src="../../../assets/img/zhdp/olds.png" class="sub-old-img">
              <div class="sub-old-text">
                <span>高龄老人（人）</span>
                <span>34，345</span>
              </div>
            </div>
            <div class="sub-old-item">
              <img src="../../../assets/img/zhdp/olds.png" class="sub-old-img">
              <div class="sub-old-text">
                <span>失独老人</span>
                <span>34，345</span>
              </div>
            </div>
            <div class="sub-old-item">
              <img src="../../../assets/img/zhdp/olds.png" class="sub-old-img">
              <div class="sub-old-text">
                <span>低保老人（人）</span>
                <span>34，345</span>
              </div>
            </div>
            <div class="sub-old-item">
              <img src="../../../assets/img/zhdp/olds.png" class="sub-old-img">
              <div class="sub-old-text">
                <span>失能老人（人）</span>
                <span>34，345</span>
              </div>
            </div>
            <div class="sub-old-item">
              <img src="../../../assets/img/zhdp/olds.png" class="sub-old-img">
              <div class="sub-old-text">
                <span>普通老人（人）</span>
                <span>34，345</span>
              </div>
            </div>
          </div>
          <div class="sub-chart1" id="chart1"></div>
          <div class="sub-chart2" id="chart2"></div>
        </div>
      </div>
      <div class="sub-top-center">
        <div class="sub-center-top">
          <div class="sub-title">
            <span>数据概况</span>
          </div>
          <div class="sub-center-box">
            <div class="sub-center-item">
              <span>老人总数（人）</span>
              <span>34，345</span>
            </div>
            <div class="sub-center-item">
              <span>服务机构(个）</span>
              <span>34，345</span>
            </div>
            <div class="sub-center-item">
              <span>累计工单</span>
              <span>34，345</span>
            </div>
            <div class="sub-center-item">
              <span>志愿者数（人）</span>
              <span>34，345</span>
            </div>
            <div class="sub-center-item">
              <span>服务数</span>
              <span>34，345</span>
            </div>
            <div class="sub-center-item">
              <span>服务人员数</span>
              <span>34，345</span>
            </div>
            <div class="sub-center-item">
              <span>津贴补助(元)</span>
              <span>34，345</span>
            </div>
            <div class="sub-center-item">
              <span>回访率</span>
              <span>90%</span>
            </div>
          </div>
        </div>
        <div class="sub-center-bottom">
          <div class="sub-title">
            <span>居家养老人数据统计</span>
          </div>
          <div class="sub-center-chart" id="echarts3"></div>
        </div>
      </div>
      <div class="sub-top-right">
        <div class="sub-title">
          <span>居家补贴</span>
        </div>
        <div class="sub-text">
          <span>居家援助补贴金额（万元）：123，134，345</span>
        </div>
        <div class="sub-text">
          <span>尊老金发放总额（万元）：123，134，345</span>
        </div>
        <div class="sub-right-chart" id="chart4"></div>
      </div>
    </div>
    <div class="sub-bottom">
      <div class="sub-bottom-left">
        <div class="sub-title">
          <span>志愿者及工单数据概况</span>
        </div>
        <div class="sub-bottom-box">
          <div class="sub-old-item">
            <img src="../../../assets/img/zhdp/olds2.png" class="sub-old-img">
              <div class="sub-old-text">
                <span>志愿者数（人）</span>
                <span>34，345</span>
              </div>
          </div>
          <div class="sub-old-item">
            <img src="../../../assets/img/zhdp/olds3.png" class="sub-old-img">
              <div class="sub-old-text">
                <span>工单数</span>
                <span>34，345</span>
              </div>
          </div>
          <div class="sub-old-item">
            <img src="../../../assets/img/zhdp/olds4.png" class="sub-old-img">
              <div class="sub-old-text">
                <span>未派单</span>
                <span>40</span>
              </div>
          </div>
          <div class="sub-old-item">
            <img src="../../../assets/img/zhdp/olds5.png" class="sub-old-img">
              <div class="sub-old-text">
                <span>服务中</span>
                <span>40</span>
              </div>
          </div>
          <div class="sub-old-item">
            <img src="../../../assets/img/zhdp/olds1.png" class="sub-old-img">
              <div class="sub-old-text">
                <span>已完成</span>
                <span>40</span>
              </div>
          </div>
        </div>
      </div>
      <div class="sub-bottom-center">
        <div class="sub-title">
          <span>本年服务总数分析</span>
        </div>
        <div class="sub-bottom-chart" id="echarts5"></div>
      </div>
      <div class="sub-bottom-right">
        <div class="sub-title">
          <span>服务商总数</span>
        </div>
        <div class="sub-bottom-chart" id="echarts6"></div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      myChart1: null,
      sexs: ['男（24，345）', '女（24，345）'],
      datas1: ['71%', '29%'],
      setInterval1: null,
      myChart2: null,
      setInterval2: null,
      datas2: [
        { value: 300, name: '低保户' },
        { value: 300, name: '失独老人' },
        { value: 300, name: '失能老人' },
        { value: 300, name: '高龄老人' },
        { value: 300, name: '普通老人' }
      ],
      colors: ['#E10E0E', '#0E5DE1', '#D9E10E', '#0EE18D', '#E14D0E'],
      myChart3: null,
      setInterval3: null,
      startValue3: 0,
      endValue3: 3,
      myChart4: null,
      setInterval4: null,
      datas4: [
        { value: 300, name: '离休干部' },
        { value: 300, name: '劳动模范' },
        { value: 300, name: '低保老人' },
        { value: 300, name: '一般老人' },
        { value: 300, name: '百岁老人' },
        { value: 300, name: '百岁老人' }
      ],
      colors4: ['#E10E0E', '#0E5DE1', '#D9E10E', '#0EE18D', '#E14D0E', '#FF00EE'],
      myChart5: null,
      setInterval5: null,
      startValue5: 0,
      endValue5: 3,
      myChart6: null,
      setInterval6: null,
      startValue6: 0,
      endValue6: 1
    }
  },
  mounted () {
    this.add()
  },
  beforeDestroy () {
    this.remove()
  },
  methods: {
    add () {
      this.$nextTick(() => {
        this.canvas1()
        // this.changeInfo1()
        this.canvas2()
        this.changeInfo2()
        this.canvas3()
        this.canvas4()
        this.changeInfo4()
        this.canvas5()
        this.canvas6()
      })
      window.addEventListener('resize', this.getresize)
    },
    remove () {
      window.removeEventListener('resize', this.getresize)
      clearInterval(this.setInterval1)
      clearInterval(this.setInterval2)
      clearInterval(this.setInterval3)
      clearInterval(this.setInterval4)
      clearInterval(this.setInterval5)
      clearInterval(this.setInterval6)
    },
    getresize () {
      setTimeout(() => {
        this.myChart1.resize()
        this.myChart2.resize()
        this.myChart3.resize()
        this.myChart4.resize()
        this.myChart5.resize()
        this.myChart6.resize()
      }, 500)
    },
    changeInfo1 () {
      const scroll1 = document.getElementById('chart1')
      scroll1.addEventListener('mouseenter', () => this.noUpdate1())
      scroll1.addEventListener('mouseleave', () => this.update1())
    },
    canvas1 () {
      var chartDom = document.getElementById('chart1')
      this.myChart1 = this.$echarts.init(chartDom)
      this.myChart1.on('click', res => {})
      const option = {
        grid: {
          containLabel: true,
          top: '2%', // 一下数值可为百分比也可为具体像素值
          right: '14%',
          bottom: '0%',
          left: '8%'
        },
        xAxis: {
          type: 'value',
          splitLine: {
            show: false
          },
          axisLine: {
            show: false,
            lineStyle: {
              color: '#0F3569'
            }
          },
          axisTick: {
            show: false
          }
        },
        yAxis: {
          type: 'category',
          data: ['女（24，345）', '男（24，345）'],
          axisLine: {
            show: false,
            lineStyle: {
              color: '#fff'
            }
          },
          axisTick: {
            show: false
          }
        },
        series: [
          {
            name: '2011',
            type: 'bar',
            data: [
              {
                value: 29,
                itemStyle: {
                  color: '#D9E10E'
                }
              },
              {
                value: 71,
                itemStyle: {
                  color: '#0E5DE1'
                }
              }],
            label: {
              show: true,
              position: 'right',
              formatter: '{c}%',
              fontSize: 13,
              color: '#FFFFFF'
            },
            showBackground: true,
            backgroundStyle: {
              color: '#699AED',
              borderRadius: [10, 10, 10, 10]
            },
            itemStyle: {
              borderRadius: [10, 10, 10, 10]
            }
          }
        ]
      }

      option && this.myChart1.setOption(option)
      // this.update1()
    },
    update1 () {
      this.setInterval1 = setInterval(() => {
        this.sexs.unshift(this.sexs.pop())
        this.datas1.unshift(this.datas1.pop())
        this.myChart1.setOption({
          yAxis: {
            data: this.sexs
          },
          series: {
            data: this.datas1
          }
        })
      }, 2000)
    },
    noUpdate1 () {
      clearInterval(this.setInterval1)
    },
    changeInfo2 () {
      const scroll1 = document.querySelector('#chart2')
      scroll1.addEventListener('mouseenter', () => this.noUpdate2())
      scroll1.addEventListener('mouseleave', () => this.update2())
    },
    canvas2 () {
      var chartDom = document.getElementById('chart2')
      this.myChart2 = this.$echarts.init(chartDom)
      const option = {
        color: this.colors,
        grid: {
          containLabel: true,
          top: '2%',
          right: '28%',
          bottom: '0%',
          left: '8%'
        },
        title: {
          text: '各类型老人占比',
          left: 'center',
          top: 'bottom',
          textStyle: {
            fontSize: 12,
            color: '#FFFFFF'
          }
        },
        tooltip: {
          trigger: 'item'
        },
        legend: {
          show: true,
          orient: 'vertical',
          y: 'bottom',
          x: 'right',
          textStyle: {
            fontSize: 12,
            color: '#FFFFFF'
          }
        },
        series: [
          {
            name: '各类型老人占比',
            type: 'pie',
            radius: ['40%', '70%'],
            data: this.datas2,
            label: {
              show: false,
              formatter: '{b}{c}%',
              fontSize: 13,
              color: '#FFFFFF'
            }
          }
        ]
      }

      option && this.myChart2.setOption(option)
      this.update2()
    },
    update2 () {
      this.setInterval2 = setInterval(() => {
        this.datas2.unshift(this.datas2.pop())
        this.colors.unshift(this.colors.pop())
        this.myChart2.setOption({
          color: this.colors,
          series: {
            data: this.datas2
          }
        })
      }, 2000)
    },
    noUpdate2 () {
      clearInterval(this.setInterval2)
    },
    canvas3 () {
      const chartDom = document.getElementById('echarts3')
      this.myChart3 = this.$echarts.init(chartDom)
      const option = {
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'shadow'
          }
        },
        legend: {
          orient: 'horizontal',
          // x: '80%',
          y: 'top',
          x: 'center',
          textStyle: {
            fontSize: 12,
            color: '#FFFFFF'
          }
          // padding: [25, 34, 0, 0]
        },
        dataZoom: [
          {
            show: false,
            startValue: this.startValue3,
            endValue: this.endValue3
          }
        ],
        grid: {
          show: false,
          top: '15%', // 一下数值可为百分比也可为具体像素值
          right: '4%',
          bottom: '10%',
          left: '5%'
        },
        xAxis: {
          type: 'category',
          data: ['60岁以下', '60-69', '70-79', '80-89', '90-99', '100及以上'],
          axisLabel: {
            show: true,
            color: '#fff',
            fontSize: 13,
            interval: 0
          },
          axisLine: {
            show: true,
            lineStyle: {
              color: '#fff'
            },
            textStyle: {
              color: '#04B4D0',
              fontSize: 13
            }
          },
          axisTick: {
            // y轴刻度线
            show: false
          }
        },
        yAxis: {
          type: 'value',
          name: '单位（万人）',
          nameTextStyle: {
            color: '#fff',
            nameLocation: 'start'
          },
          axisLine: {
            show: true,
            lineStyle: {
              color: '#fff'
            }
          },
          axisLabel: {
            show: true,
            color: '#fff',
            fontSize: 14
          },
          splitLine: {
            lineStyle: {
              color: '#fff'
            }
          },
          splitArea: {
            show: true,
            areaStyle: {
              color: ['rgba(0,0,0,0)', 'rgba(172, 172, 172, .07)']
            }
          }
        },
        series: [
          {
            name: '去年',
            data: [120, 200, 150, 80, 70, 110],
            type: 'bar',
            barWidth: '19'
          },
          {
            name: '今年',
            data: [120, 200, 150, 80, 70, 110],
            type: 'bar',
            barWidth: '19'
          }
        ]
      }

      option && this.myChart3.setOption(option)
      this.update3()
    },
    update3 () {
      this.setInterval3 = setInterval(() => {
        this.startValue3++
        this.endValue3++
        if (this.endValue3 > 5) {
          this.startValue3 = 0
          this.endValue3 = 3
        }
        this.myChart3.setOption({
          dataZoom: [
            // 滑动条
            {
              show: false, // 是否显示滑动条，不影响使用
              startValue: this.startValue3, // 从头开始。
              endValue: this.endValue3 // 一次性展示6个。
            }
          ]
        })
      }, 2000)
    },
    changeInfo4 () {
      const scroll4 = document.querySelector('#chart4')
      scroll4.addEventListener('mouseenter', () => this.noUpdate4())
      scroll4.addEventListener('mouseleave', () => this.update4())
    },
    canvas4 () {
      var chartDom = document.getElementById('chart4')
      this.myChart4 = this.$echarts.init(chartDom)
      const option = {
        color: this.colors4,
        grid: {
          containLabel: true,
          top: '2%',
          right: '28%',
          bottom: '0%',
          left: '8%'
        },
        title: {
          text: '各类别居家养老补贴金额占比',
          left: 'center',
          top: 'bottom',
          textStyle: {
            fontSize: 12,
            color: '#FFFFFF'
          }
        },
        tooltip: {
          trigger: 'item'
        },
        legend: {
          show: true,
          orient: 'vertical',
          y: 'bottom',
          x: 'right',
          textStyle: {
            fontSize: 12,
            color: '#FFFFFF'
          }
        },
        series: [
          {
            name: '高血压',
            type: 'pie',
            radius: ['40%', '70%'],
            data: this.datas4,
            label: {
              show: true,
              formatter: '{c}%',
              fontSize: 13,
              color: '#FFFFFF'
            }
          }
        ]
      }

      option && this.myChart4.setOption(option)
      this.update4()
    },
    update4 () {
      this.setInterval4 = setInterval(() => {
        this.datas4.unshift(this.datas4.pop())
        this.colors4.unshift(this.colors4.pop())
        this.myChart4.setOption({
          color: this.color4,
          series: {
            data: this.datas4
          }
        })
      }, 2000)
    },
    noUpdate4 () {
      clearInterval(this.setInterval4)
    },
    canvas5 () {
      const chartDom = document.getElementById('echarts5')
      this.myChart5 = this.$echarts.init(chartDom)
      const option = {
        title: {
          text: '总服务数   34，345',
          left: 'center',
          textStyle: {
            fontSize: 16,
            color: '#fff'
          }
        },
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'shadow'
          }
        },
        legend: {
          show: false,
          orient: 'horizontal',
          // x: '80%',
          y: 'top',
          x: 'center',
          textStyle: {
            fontSize: 12,
            color: '#FFFFFF'
          }
          // padding: [25, 34, 0, 0]
        },
        dataZoom: [
          {
            show: false,
            startValue: this.startValue5,
            endValue: this.endValue5
          }
        ],
        grid: {
          show: false,
          top: '15%', // 一下数值可为百分比也可为具体像素值
          right: '4%',
          bottom: '10%',
          left: '5%'
        },
        xAxis: {
          type: 'category',
          data: ['理发', '助医', '洗涤', '助浴', '个人护理', '康复训练', '送餐'],
          axisLabel: {
            show: true,
            color: '#fff',
            fontSize: 13,
            interval: 0
          },
          axisLine: {
            show: true,
            lineStyle: {
              color: '#fff'
            },
            textStyle: {
              color: '#04B4D0',
              fontSize: 13
            }
          },
          axisTick: {
            // y轴刻度线
            show: false
          }
        },
        yAxis: {
          type: 'value',
          axisLine: {
            show: true,
            lineStyle: {
              color: '#fff'
            }
          },
          axisLabel: {
            show: true,
            color: '#fff',
            fontSize: 14
          },
          splitLine: {
            lineStyle: {
              color: '#fff'
            }
          },
          splitArea: {
            show: true,
            areaStyle: {
              color: ['rgba(0,0,0,0)', 'rgba(172, 172, 172, .07)']
            }
          }
        },
        series: [
          {
            name: '服务',
            data: [120, 200, 150, 80, 70, 110, 110],
            type: 'bar',
            barWidth: '19'
          }
        ]
      }

      option && this.myChart5.setOption(option)
      this.update5()
    },
    update5 () {
      this.setInterval5 = setInterval(() => {
        this.startValue5++
        this.endValue5++
        if (this.endValue5 > 6) {
          this.startValue5 = 0
          this.endValue5 = 3
        }
        this.myChart5.setOption({
          dataZoom: [
            {
              show: false,
              startValue: this.startValue5,
              endValue: this.endValue5
            }
          ]
        })
      }, 2000)
    },
    canvas6 () {
      const chartDom = document.getElementById('echarts6')
      this.myChart6 = this.$echarts.init(chartDom)
      const option = {
        title: {
          text: '总服务商数  40',
          left: 'center',
          textStyle: {
            fontSize: 16,
            color: '#fff'
          }
        },
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'shadow'
          }
        },
        legend: {
          show: false,
          orient: 'horizontal',
          // x: '80%',
          y: 'top',
          x: 'center',
          textStyle: {
            fontSize: 12,
            color: '#FFFFFF'
          }
          // padding: [25, 34, 0, 0]
        },
        dataZoom: [
          {
            show: false,
            startValue: this.startValue6,
            endValue: this.endValue6
          }
        ],
        grid: {
          show: false,
          top: '15%', // 一下数值可为百分比也可为具体像素值
          right: '4%',
          bottom: '10%',
          left: '5%'
        },
        xAxis: {
          type: 'category',
          data: ['助餐类', '服务类', '商品类'],
          axisLabel: {
            show: true,
            color: '#fff',
            fontSize: 13,
            interval: 0
          },
          axisLine: {
            show: true,
            lineStyle: {
              color: '#fff'
            },
            textStyle: {
              color: '#04B4D0',
              fontSize: 13
            }
          },
          axisTick: {
            // y轴刻度线
            show: false
          }
        },
        yAxis: {
          type: 'value',
          axisLine: {
            show: true,
            lineStyle: {
              color: '#fff'
            }
          },
          axisLabel: {
            show: true,
            color: '#fff',
            fontSize: 14
          },
          splitLine: {
            lineStyle: {
              color: '#fff'
            }
          },
          splitArea: {
            show: true,
            areaStyle: {
              color: ['rgba(0,0,0,0)', 'rgba(172, 172, 172, .07)']
            }
          }
        },
        series: [
          {
            name: '服务',
            data: [120, 200, 150, 80, 70, 110],
            type: 'bar',
            barWidth: '19'
          }
        ]
      }

      option && this.myChart6.setOption(option)
      this.update6()
    },
    update6 () {
      this.setInterval6 = setInterval(() => {
        this.startValue6++
        this.endValue6++
        if (this.endValue6 > 3) {
          this.startValue6 = 0
          this.endValue6 = 1
        }
        this.myChart6.setOption({
          dataZoom: [
            {
              show: false,
              startValue: this.startValue6,
              endValue: this.endValue6
            }
          ]
        })
      }, 2000)
    }
  }
}
</script>

<style lang="less" scoped>
.sub-page {
  width: 100%;
  height: 100%;

  .sub-top {
    display: flex;
    justify-content: space-between;
    width: 100%;
    height: calc(100% - 384px);

    .sub-top-left {
      width: calc(30% - 5px);
      height: 100%;
      padding: 0 10px;
      background-color: #0F3569;
      border: 1px solid #1F72D3;
      box-sizing: border-box;
    }

    .sub-top-center {
      width: calc(40% - 10px);
      height: 100%;

      .sub-center-top {
        width: 100%;
        height: 172px;
        padding: 0 10px;
        background-color: #0F3569;
        border: 1px solid #1F72D3;
        box-sizing: border-box;

        .sub-center-box {
          display: flex;
          flex-wrap: wrap;
          align-content: space-evenly;
          width: 100%;
          height: calc(100% - 40px);

          .sub-center-item {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            width: 25%;
            color: #fff;
            font-size: 14px;
          }
        }
      }

      .sub-center-bottom {
        width: 100%;
        height: calc(100% - 182px);
        margin-top: 10px;
        padding: 0 10px;
        background-color: #0F3569;
        border: 1px solid #1F72D3;
        box-sizing: border-box;

        .sub-center-chart {
          width: 100%;
          height: calc(100% - 40px);
        }
      }
    }

    .sub-top-right {
      width: calc(30% - 5px);
      height: 100%;
      padding: 0 10px;
      background-color: #0F3569;
      border: 1px solid #1F72D3;
      box-sizing: border-box;

      .sub-text {
        width: 100%;
        height: 36px;
        line-height: 36px;
        padding-left: 24px;
        color: #fff;
        font-size: 16px;
        box-sizing: border-box;
      }

      .sub-right-chart {
        width: 100%;
        height: calc(100% - 112px);
      }
    }
    .sub-box {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      width: 100%;
      height: calc(100% - 40px);

      .sub-old {
        display: flex;
        flex-wrap: wrap;
        width: 100%;

        .sub-old-item {
          display: flex;
          width: 33%;
          margin: 5px 0;
        }

        .sub-old-img {
          width: 32px;
          height: 32px;
          margin-right: 5px;
        }

        .sub-old-text {
          display: flex;
          flex-direction: column;
          color: #fff;
          font-size: 16px;
        }
      }

      .sub-chart1 {
        width: 100%;
        height: 90px;
      }

      .sub-chart2 {
        width: 100%;
        height: calc(100% - 190px);
      }
    }
  }

  .sub-bottom {
    display: flex;
    justify-content: space-between;
    width: 100%;
    height: 374px;
    margin-top: 10px;

    .sub-bottom-left {
      width: calc(30% - 5px);
      height: 100%;
      padding: 0 10px;
      background-color: #0F3569;
      border: 1px solid #1F72D3;
      box-sizing: border-box;

      .sub-bottom-box {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        align-content: space-evenly;
        width: 100%;
        height: calc(100% - 40px);

        .sub-old-item {
          display: flex;
          justify-content: center;
          width: 33%;
          margin: 5px 0;
        }

        .sub-old-item:nth-child(1), .sub-old-item:nth-child(2) {
          width: 50%;
        }

        .sub-old-img {
          width: 32px;
          height: 32px;
          margin-right: 5px;
        }

        .sub-old-text {
          display: flex;
          flex-direction: column;
          color: #fff;
          font-size: 16px;
        }
      }
    }

    .sub-bottom-center {
      width: calc(40% - 10px);
      height: 100%;
      padding: 0 10px;
      background-color: #0F3569;
      border: 1px solid #1F72D3;
      box-sizing: border-box;
    }

    .sub-bottom-right {
      width: calc(30% - 5px);
      height: 100%;
      padding: 0 10px;
      background-color: #0F3569;
      border: 1px solid #1F72D3;
      box-sizing: border-box;
    }

    .sub-bottom-chart {
      width: 100%;
      height: calc(100% - 40px);
    }
  }

  .sub-title {
    width: 100%;
    height: 40px;
    line-height: 40px;
    color: #fff;
    font-size: 20px;
  }
}
</style>
