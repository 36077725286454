<template>
  <div class="form">
    <div class="form-box">
      <div class="form-close">
        <span class="el-icon-close"></span>
      </div>
      <div class="form-title">
        <span>12～36月龄儿童健康检查记录表</span>
      </div>
      <div class="form-head">
        <span>姓名：-</span>
        <span>编号：-</span>
      </div>
      <div class="form-scroll">
        <div class="table">
          <div class="left">
            <div class="item item1">
              <span>考核项</span>
            </div>
            <div class="item item1">
              <span>月龄</span>
            </div>
            <div class="item item1">
              <span>随访日期</span>
            </div>
            <div class="item item1">
              <span>是否失访</span>
            </div>
            <div class="item item1">
              <span>失访原因</span>
            </div>
            <div class="item item1">
              <span>中医药健康管理服务</span>
            </div>
            <div class="item item1">
              <span>体重:</span>
              <el-input size="mini" class="input"></el-input>
              <span>kg</span>
            </div>
            <div class="item item1">
              <span>身长:</span>
              <el-input size="mini" class="input"></el-input>
              <span>cm</span>
            </div>
            <div class="item item1">
              <span>头围:</span>
              <el-input size="mini" class="input"></el-input>
              <span>cm</span>
            </div>
            <div class="box">
              <div class="box-left">
                <span>体格检查</span>
              </div>
              <div class="box-right">
                <div class="box-item">
                  <span>面色</span>
                </div>
                <div class="box-item">
                  <span>皮肤</span>
                </div>
                <div class="box-item">
                  <span>前囟</span>
                </div>
                <div class="box-item">
                  <span>出牙/龋齿数（颗）</span>
                </div>
                <div class="box-item">
                  <span>眼睛</span>
                </div>
                <div class="box-item">
                  <span>耳外观</span>
                </div>
                <div class="box-item">
                  <span>听力</span>
                </div>
                <div class="box-item">
                  <span>口腔</span>
                </div>
                <div class="box-item">
                  <span>胸部</span>
                </div>
                <div class="box-item">
                  <span>腹部</span>
                </div>
                <div class="box-item">
                  <span>四肢部</span>
                </div>
                <div class="box-item">
                  <span>可疑佝偻病症状</span>
                </div>
                <div class="box-item">
                  <span>肛门/外生殖器</span>
                </div>
                <div class="box-item">
                  <span>血红蛋白值</span>
                </div>
              </div>
            </div>
            <div class="item item1">
              <span>户外活动</span>
            </div>
            <div class="item item1">
              <span>服用维生素D</span>
            </div>
            <div class="item item1 item2">
              <span>发育评估</span>
            </div>
            <div class="item item1">
              <span>两次随访间患病情况</span>
            </div>
            <div class="item item1 item2">
              <span>转诊建议：</span>
            </div>
            <div class="item item1 item2">
              <span>指导：</span>
            </div>
            <div class="item item1">
              <span>下次随访时间：</span>
            </div>
            <div class="item item1">
              <span>随访医生签名：</span>
            </div>
          </div>
          <div class="right">
            <div class="right-box">
              <div class="list" v-for="(item, index) in info" :key="index">
                <div class="cell cell1">
                  <span>考核项：{{ item.assessmentCount || '-' }}</span>
                  <span>缺项：{{ item.missingCount || '-' }}</span>
                  <span>完整率：{{ item.intactRate || '-' }}</span>
                </div>
                <div class="cell cell1">
                  <span>{{ item.ageCode || '-' }}</span>
                </div>
                <div class="cell cell1">
                  <el-date-picker size="small" v-model="item.followupVisitDate" type="date" placeholder="选择日期">
                  </el-date-picker>
                </div>
                <div class="cell cell1">
                  <el-radio-group v-model="item.isMiss">
                    <el-radio label="1">是</el-radio>
                    <el-radio label="2">否</el-radio>
                  </el-radio-group>
                </div>
                <div class="cell cell1">
                  <el-input v-model="item.missReason" size="small" placeholder=""></el-input>
                </div>
                <div class="cell cell1">
                  <el-checkbox-group v-model="item.healthManagementCode">
                    <el-checkbox label="1">中医饮食调养指导</el-checkbox>
                    <el-checkbox label="2">中医起居调养指导</el-checkbox>
                    <el-checkbox label="3">传授按摩四神聪穴大法</el-checkbox>
                  </el-checkbox-group>
                </div>
                <div class="cell cell2">
                  <el-input v-model="item.weight" size="small" class="input" placeholder=""></el-input>
                  <el-radio-group class="margin-left" v-model="radio">
                    <el-radio label="1">上</el-radio>
                    <el-radio label="2">中</el-radio>
                    <el-radio label="3">下</el-radio>
                  </el-radio-group>
                </div>
                <div class="cell cell2">
                  <el-input v-model="item.height" size="small" class="input" placeholder=""></el-input>
                  <el-radio-group class="margin-left" v-model="radio">
                    <el-radio label="1">上</el-radio>
                    <el-radio label="2">中</el-radio>
                    <el-radio label="3">下</el-radio>
                  </el-radio-group>
                </div>
                <div class="cell cell1">
                  <el-input v-model="item.headCircumference" size="small" placeholder=""></el-input>
                </div>
                <div class="cell cell2">
                  <el-radio-group v-model="item.faceCollectionType">
                    <el-radio label="1">红润</el-radio>
                    <el-radio label="2">黄染</el-radio>
                    <el-radio label="3">其他</el-radio>
                  </el-radio-group>
                </div>
                <div class="cell cell2">
                  <el-radio-group v-model="item.skinAbnormCode">
                    <el-radio label="1">未见异常</el-radio>
                    <el-radio label="2">异常</el-radio>
                  </el-radio-group>
                </div>
                <div class="cell cell3">
                  <el-radio-group v-model="item.bregmaCloseCode">
                    <el-radio label="1">闭合</el-radio>
                    <el-radio label="2">未闭</el-radio>
                  </el-radio-group>
                  <div class="flex">
                    <el-input v-model="item.bregmaHorizDiameter" size="small" class="input" placeholder=""></el-input>
                    <span>cm x</span>
                    <el-input v-model="item.bregmaVertDiameter" size="small" class="input" placeholder=""></el-input>
                    <span>cm</span>
                  </div>
                </div>
                <div class="cell cell2">
                  <el-input v-model="item.teethCount" size="small" placeholder=""></el-input>
                  <el-input v-model="item.cariesCount" size="small" placeholder=""></el-input>
                </div>
                <div class="cell cell2">
                  <el-radio-group v-model="item.eyeAbnormCode">
                    <el-radio label="1">未见异常</el-radio>
                    <el-radio label="2">异常</el-radio>
                  </el-radio-group>
                </div>
                <div class="cell cell2">
                  <el-radio-group v-model="item.earAbnormCode">
                    <el-radio label="1">未见异常</el-radio>
                    <el-radio label="2">异常</el-radio>
                  </el-radio-group>
                </div>
                <div class="cell cell1">
                  <el-input v-model="item.hearingScreenResultCode" size="small" placeholder=""></el-input>
                </div>
                <div class="cell cell2">
                  <el-radio-group v-model="item.oralAbnormValue">
                    <el-radio label="1">未见异常</el-radio>
                    <el-radio label="2">异常</el-radio>
                  </el-radio-group>
                </div>
                <div class="cell cell2">
                  <el-radio-group v-model="item.lungAbnormCode">
                    <el-radio label="1">未见异常</el-radio>
                    <el-radio label="2">异常</el-radio>
                  </el-radio-group>
                </div>
                <div class="cell cell2">
                  <el-radio-group v-model="item.abdomenAbnormCode">
                    <el-radio label="1">未见异常</el-radio>
                    <el-radio label="2">异常</el-radio>
                  </el-radio-group>
                </div>
                <div class="cell cell2">
                  <el-radio-group v-model="item.limbsAbnormCode">
                    <el-radio label="1">未见异常</el-radio>
                    <el-radio label="2">异常</el-radio>
                  </el-radio-group>
                </div>
                <div class="cell cell2">
                  <el-radio-group v-model="item.ricketsSymptomCode">
                    <el-radio label="1">无</el-radio>
                    <el-radio label="2">夜惊</el-radio>
                    <el-radio label="3">多汗</el-radio>
                    <el-radio label="4">烦躁</el-radio>
                  </el-radio-group>
                </div>
                <div class="cell cell2">
                  <el-radio-group v-model="item.anusAbnormCode">
                    <el-radio label="1">无</el-radio>
                    <el-radio label="2">肋串珠</el-radio>
                    <el-radio label="3">肋软骨沟</el-radio>
                    <el-radio label="4">鸡胸</el-radio>
                    <el-radio label="5">手足镯</el-radio>
                    <el-radio label="6">颅骨软化</el-radio>
                    <el-radio label="7">方颅</el-radio>
                  </el-radio-group>
                </div>
                <div class="cell cell1">
                  <el-input v-model="item.hgb" size="small" placeholder=""></el-input>
                </div>
                <div class="cell cell2">
                  <el-input v-model="item.outdoorActHours" size="small" class="input" placeholder=""></el-input>
                  <span>小时/日</span>
                </div>
                <div class="cell cell2">
                  <el-input v-model="item.vitamindDose" size="small" class="input" placeholder=""></el-input>
                  <span>IU/日</span>
                </div>
                <div class="cell cell1 cell14">
                  <el-checkbox-group v-model="item.physicalDevelopEvalCode">
                    <el-checkbox label="1">对很大声音没有反应</el-checkbox>
                    <el-checkbox label="2">逗引时不发音或不会微笑</el-checkbox>
                    <el-checkbox label="3">注视人脸，不追视移动人或物品</el-checkbox>
                    <el-checkbox label="4">俯卧时不会抬头</el-checkbox>
                  </el-checkbox-group>
                </div>
                <div class="cell cell1">
                  <el-checkbox-group v-model="item.sickVisitCode">
                    <el-checkbox label="1">无</el-checkbox>
                    <el-checkbox label="2">肺炎次</el-checkbox>
                    <el-checkbox label="3">腹泻次</el-checkbox>
                    <el-checkbox label="4">外伤次</el-checkbox>
                    <el-checkbox label="5">其他</el-checkbox>
                  </el-checkbox-group>
                </div>
                <div class="cell cell1 cell14">
                  <el-radio-group v-model="item.referralCode">
                    <el-radio label="1">无</el-radio>
                    <el-radio label="2">有</el-radio>
                  </el-radio-group>
                  <div class="flex">
                    <span>原因：</span>
                    <el-input v-model="item.referralReason" class="input1" size="small" placeholder=""></el-input>
                  </div>
                  <div class="flex">
                    <span>机构及科室：</span>
                    <el-input v-model="item.refertoDeptName" class="input1" size="small" placeholder=""></el-input>
                  </div>
                </div>
                <div class="cell cell1 cell14">
                  <el-checkbox-group v-model="item.oneAgeGuideCode">
                    <el-checkbox label="1">科学喂养</el-checkbox>
                    <el-checkbox label="2">生长发育</el-checkbox>
                    <el-checkbox label="3">疾病预防</el-checkbox>
                    <el-checkbox label="4">预防伤害</el-checkbox>
                    <el-checkbox label="5">口腔保健</el-checkbox>
                    <el-checkbox label="6">
                      <span>其他</span>
                      <el-input v-model="item.othersVisitDesc" class="input1" size="small" placeholder=""></el-input>
                    </el-checkbox>
                  </el-checkbox-group>
                </div>
                <div class="cell cell1">
                  <el-date-picker size="small" v-model="item.nextFollowupDate" type="date" placeholder="选择日期">
                  </el-date-picker>
                </div>
                <div class="cell cell1">
                  <el-select v-model="item.signPhotoUrl" size="small" placeholder="请选择">
                    <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
                    </el-option>
                  </el-select>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="form-button">
        <el-button type="primary" size="medium">确认</el-button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
export default {
  data () {
    return {
      radio: null,
      date: '',
      checkList: [],
      input: '',
      options: [{
        value: '',
        label: ''
      }],
      value: '',
      params: {
        sfzh: '',
        ssjgbh: '',
        jgbh: '',
        ssjlid: ''
      },
      info: null,
      show: true
    }
  },
  computed: {
    ...mapState(['loginInfo'])
  },
  created () {
    console.log('选择信息：', this.$parent.selectInfo)
    this.params.ssjgbh = this.loginInfo.jgbh || ''
    this.params.jgbh = this.loginInfo.jgbh || ''
    this.params.sfzh = this.$parent.selectInfo.sfzh || ''
    this.getInfo()
  },
  methods: {
    async getInfo () {
      // 接口文档 https://app.apifox.com/project/2726972/apis/api-147518788
      try {
        const { data: res } = await this.$http.post('/api/jkct/findetsrdsslyl', this.params)
        console.log('12-36月龄', res)
        if (res.code !== 0) {
          return this.$message({
            message: res.msg || '暂无信息',
            type: 'warning'
          })
        }
        if (res.data.length < 1) return
        for (let index = 0; index < res.data.length; index++) {
          console.log(res.data[index].oneAgeGuideCode.split(','))
          // eslint-disable-next-line no-prototype-builtins
          if (res.data[index].hasOwnProperty('healthManagementCode')) {
            res.data[index].healthManagementCode = res.data[index].healthManagementCode.split(',')
          } else {
            this.$set(res.data[index], 'healthManagementCode', [])
          }
          // eslint-disable-next-line no-prototype-builtins
          if (res.data[index].hasOwnProperty('physicalDevelopEvalCode')) {
            res.data[index].physicalDevelopEvalCode = res.data[index].physicalDevelopEvalCode.split(',')
          } else {
            this.$set(res.data[index], 'physicalDevelopEvalCode', [])
          }
          // eslint-disable-next-line no-prototype-builtins
          if (res.data[index].hasOwnProperty('sickVisitCode')) {
            res.data[index].sickVisitCode = res.data[index].sickVisitCode.split(',')
          } else {
            this.$set(res.data[index], 'sickVisitCode', [])
          }
          // eslint-disable-next-line no-prototype-builtins
          if (res.data[index].hasOwnProperty('oneAgeGuideCode')) {
            res.data[index].oneAgeGuideCode = res.data[index].oneAgeGuideCode.split(',')
          } else {
            this.$set(res.data[index], 'oneAgeGuideCode', [])
          }
        }
        console.log('-----', res.data)
        this.info = res.data
      } catch (err) {
        console.log('12-36月龄', err)
      }
    },
    close () {
      this.$parent.selectInfo = null
      this.$emit('close')
    }
  }
}
</script>

<style lang="less" scoped>
.form {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 888;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, .9);

  .form-box {
    position: relative;
    width: 80%;
    height: 95%;
    padding: 0 73px;
    border: 4px solid #21D6E0;
    box-sizing: border-box;

    .form-close {
      position: absolute;
      right: 30px;
      height: 50px;
      line-height: 50px;
      color: #fff;
      font-size: 20px;
      cursor: pointer;
    }

    .form-title {
      width: 100%;
      height: 60px;
      line-height: 60px;
      text-align: center;
      color: #01EEFD;
      font-size: 22px;
    }

    .form-head {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      height: 50px;
      color: #01EEFD;
      font-size: 22px;
    }

    .form-scroll {
      overflow-y: scroll;
      width: 100%;
      height: calc(100% - 180px);

      .table {
        display: flex;
        width: 100%;
        border: 1px solid #01EEFD;
        box-sizing: border-box;

        .left {
          display: flex;
          flex-direction: column;
          width: 20%;

          .item {
            display: flex;
            align-items: center;
            width: 100%;
            height: 80px;
            padding: 17px 0;
            border: 1px solid #01EEFD;
            box-sizing: border-box;
            color: #01EEFD;
            font-size: 14px;
          }

          .item1 {
            padding-left: 10px;
          }

          .item2 {
            height: 140px;
          }

          .input {
            width: 70px;
          }

          .el-input {
            margin-right: 20px;
          }

          .box {
            display: flex;
            width: 100%;

            .box-left {
              display: flex;
              align-items: center;
              justify-content: center;
              width: 40%;
              border: 1px solid #01EEFD;
              box-sizing: border-box;
              writing-mode: vertical-rl;
              letter-spacing: 5px;
              color: #01EEFD;
              font-size: 14px;
            }

            .box-right {
              display: flex;
              flex-direction: column;
              width: 60%;

              .box-item {
                display: flex;
                align-items: center;
                width: 100%;
                height: 80px;
                padding: 15px 0;
                padding-left: 15px;
                border: 1px solid #01EEFD;
                box-sizing: border-box;
                color: #01EEFD;
                font-size: 14px;
              }
            }
          }
        }

        .right {
          overflow-x: scroll;
          width: 80%;

          .right-box {
            display: flex;
          }

          .list {
            display: flex;
            flex-direction: column;
            width: 500px;
          }

          .el-input, .el-select {
            width: 80%;
          }

          .cell {
            width: 100%;
            height: 80px;
            padding: 0 10px;
            border: 1px solid #01EEFD;
            box-sizing: border-box;
            color: #01EEFD;
            font-size: 14px;
          }

          .cell1 {
            display: flex;
            flex-direction: column;
            justify-content: space-evenly;
            box-sizing: border-box;
          }

          .cell2 {
            display: flex;
            align-items: center;
          }

          .cell3 {
            display: flex;
            flex-direction: column;
            justify-content: space-evenly;
          }

          .cell14 {
            height: 140px;
          }

          .el-radio {
            margin-right: 15px;
            color: #fff;
          }

          .el-radio:last-child {
            margin-right: 0;
          }

          .el-checkbox {
            color: #fff;
          }

          .input {
            width: 55px;

            /deep/.el-input__inner {
              background-color: transparent;
              border-radius: 0;
              border: none;
              border-bottom: 1px solid #fff;
              color: #fff;
            }
          }

          .input1 {
            width: 150px;
          }

          .margin-left {
            margin-left: 10px;
          }

          .flex {
            display: flex;
            align-items: center;
          }
        }
      }
    }

    .form-button {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 70px;
    }
  }
}
</style>
