<!--
 * @Author: 小宝 zksukuo@yunkangda.com
 * @Date: 2024-02-04 13:32:06
 * @LastEditors: 小宝 zksukuo@yunkangda.com
 * @LastEditTime: 2024-06-13 09:32:18
 * @FilePath: \智慧公卫PC端旧项目\src\views\child-health-run\child-health-run.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <div class="page">
    <div class="close" @click="close">
      <span class="el-icon-arrow-left"></span>
    </div>
    <div class="head">
      <span>智慧学生查体监测平台</span>
    </div>
    <div class="content">
      <div class="left">
        <div class="item">
          <div class="title">
            <span>学校</span>
          </div>
          <div class="item-box" id="chart2">
          </div>
        </div>
        <div class="item item1">
          <div class="title" @click="showPop = true">
            <span>服务记录</span>
          </div>
          <!-- <div class="item-box" id="chart1">
          </div> -->
          <div class="item-box">
            <div class="scroll">
              <div class="scroll-box scroll-box1" :style="{ '--visitY': '-' + 40 * visits.length + 'px', '--visitS': visits.length + 's' }">
                <div class="list" v-for="(item, index) in visits" :key="index" @click="openVisitRecord">
                  <span class="flex flex2">{{ item.name + item.title }}</span>
                  <span class="flex">时间：{{ item.time || '-' }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- <div class="item item3">
          <div class="title title1">
            <span>随访</span>
            <div class="tab">
              <div class="tab-item" @click="tabChange1(0)">
                <span>检查记录</span>
                <span class="border" :class="{ 'active': 0 === tabIndex1 }"></span>
              </div>
              <div class="tab-item" @click="tabChange1(1)">
                <span>疫苗记录</span>
                <span class="border" :class="{ 'active': 1 === tabIndex1 }"></span>
              </div>
            </div>
          </div>
          <div class="item-box">
            <div class="thead">
              <span class="flex">姓名</span>
              <span class="flex flex2">检查内容</span>
              <span class="flex">时间</span>
            </div>
            <div class="scroll">
              <div class="scroll-box scroll-box1" :style="{ '--visitY': '-' + 40 * visits.length + 'px', '--visitS': visits.length + 's' }">
                <div class="list" v-for="(item, index) in visits" :key="index" @click="openVisitRecord">
                  <span class="flex">{{ item.name || '-' }}</span>
                  <span class="flex flex2">{{ item.title || '-' }}</span>
                  <span class="flex">{{ item.time || '-' }}</span>
                </div>
              </div>
            </div>
          </div>
        </div> -->
        <div class="item">
          <div class="title">
            <span>PPD结核实验菌素试验</span>
          </div>
          <div class="info">
            <img src="../../../assets/img/child7.png" class="img">
            <div class="info-box">
              <div class="info-box-item info-box-item1" @click="openLonely(lonelys[0])">
                <img src="../../../assets/img/child9.png" class="image">
                <span>{{ lonelys[0].name }}</span>
                <span class="color">{{ lonelys[0].value }}</span>
              </div>
              <div class="info-box-item info-box-item1" @click="openLonely(lonelys[1])">
                <img src="../../../assets/img/child10.png" class="image">
                <span>{{ lonelys[1].name }}</span>
                <span class="color color1">{{ lonelys[1].value }}</span>
              </div>
              <div class="info-box-item">
                <span @click="openLonely(lonelys[2])">{{ lonelys[2].name }}
                  <span class="color1">{{ lonelys[2].value }}</span>
                </span>
                <span @click="openLonely(lonelys[3])">{{ lonelys[3].name }}
                  <span class="color1">{{ lonelys[3].value }}</span>
                </span>
              </div>
            </div>
          </div>
        </div>
        <!-- <div class="item">
          <div class="title title1">
            <span>新生儿家庭访视</span>
            <div class="total">
              <span>服务总数：</span>
              <span class="number">2091</span>
            </div>
          </div>
          <div class="item-box">
            <div class="thead">
              <span class="flex">姓名</span>
              <span class="flex flex2">寻访情况</span>
              <span class="flex">寻访时间</span>
              <span class="flex">下次寻访时间</span>
            </div>
            <div class="scroll">
              <div class="scroll-box scroll-box2" :style="{ '--familyY': '-' + 40 * familys.length + 'px', '--familyS': familys.length + 's' }">
                <div class="list" v-for="(item, index) in familys" :key="index">
                  <span class="flex">XXXXX</span>
                  <span class="flex flex2">XXXXXXXXXXXXX</span>
                  <span class="flex">2024-02-03</span>
                  <span class="flex">2024-02-03</span>
                </div>
              </div>
            </div>
          </div>
        </div> -->
      </div>

      <div class="center">
        <div class="center-head">
          <div class="head-item" @click="openChildren(centers[6])">
            <span>{{ centers[6].title || '-' }}</span>
            <span class="num">{{ centers[6].value || '-' }}</span>
          </div>
          <div class="head-item" @click="openChildren(centers[7])">
            <span>{{ centers[7].title || '-' }}</span>
            <span class="num">{{ centers[7].value || '-' }}</span>
          </div>
          <div class="head-item" @click="openChildren(centers[8])">
            <span>{{ centers[8].title || '-' }}</span>
            <span class="num">{{ centers[8].value || '-' }}</span>
          </div>
        </div>
        <div class="center-box">
          <div class="container" :style="{ height: width + 'px' }">
            <div class="container-item" @click="openChildren(centers[2])">
              <div class="center-item-label">
                <div class="center-item-img"></div>
                <div class="center-item-font">
                  <span>{{ centers[2].value || '-' }}</span>
                </div>
                <span class="center-item-text">{{ centers[2].title || '-' }}</span>
              </div>
            </div>
            <div class="container-item" @click="openChildren(centers[3])">
              <div class="center-item-label">
                <div class="center-item-img"></div>
                <div class="center-item-font">
                  <span>{{ centers[3].value || '-' }}</span>
                </div>
                <span class="center-item-text">{{ centers[3].title || '-' }}</span>
              </div>
            </div>
            <div class="container-item" @click="openChildren(centers[4])">
              <div class="center-item-label">
                <div class="center-item-img"></div>
                <div class="center-item-font">
                  <span>{{ centers[4].value || '-' }}</span>
                </div>
                <span class="center-item-text">{{ centers[4].title || '-' }}</span>
              </div>
            </div>
            <div class="container-item" @click="openChildren(centers[5])">
              <div class="center-item-label">
                <div class="center-item-img"></div>
                <div class="center-item-font">
                  <span>{{ centers[5].value || '-' }}</span>
                </div>
                <span class="center-item-text">{{ centers[5].title || '-' }}</span>
              </div>
            </div>
            <div class="container-item" @click="openChildren(centers[9])">
              <div class="center-item-label">
                <div class="center-item-img"></div>
                <div class="center-item-font">
                  <span>{{ centers[9].value || '-' }}</span>
                </div>
                <span class="center-item-text">{{ centers[9].title || '-' }}</span>
              </div>
            </div>
            <div class="container-item" @click="openChildren(centers[10])">
              <div class="center-item-label">
                <div class="center-item-img"></div>
                <div class="center-item-font">
                  <span>{{ centers[10].value || '-' }}</span>
                </div>
                <span class="center-item-text">{{ centers[10].title || '-' }}</span>
              </div>
            </div>
            <div class="container-item" @click="openChildren(centers[11])">
              <div class="center-item-label">
                <div class="center-item-img"></div>
                <div class="center-item-font">
                  <span>{{ centers[11].value || '-' }}</span>
                </div>
                <span class="center-item-text">{{ centers[11].title || '-' }}</span>
              </div>
            </div>
            <div class="container-item" @click="openChildren(centers[0])">
              <div class="center-item-label">
                <div class="center-item-img"></div>
                <div class="center-item-font">
                  <span>{{ centers[0].value || '-' }}</span>
                </div>
                <span class="center-item-text">{{ centers[0].title || '-' }}</span>
              </div>
            </div>
            <div class="container-item" @click="openChildren(centers[1])">
              <div class="center-item-label">
                <div class="center-item-img"></div>
                <div class="center-item-font">
                  <span>{{ centers[1].value || '-' }}</span>
                </div>
                <span class="center-item-text">{{ centers[1].title || '-' }}</span>
              </div>
            </div>
          </div>
          <img src="../../../assets/img/child5.png" class="center-img">
        </div>
      </div>

      <div class="right">
        <div class="item item1">
          <div class="title">
            <span class="span">体检结果数据分析</span>
            <!-- <span class="span">0-3岁体检结果</span>
            <span class="span">3-6岁体检结果</span> -->
          </div>
          <div class="item-box" id="chart1">
          </div>
        </div>
        <div class="item item2">
          <div class="title title1">
            <span>异常占比</span>
          </div>
          <div class="item-box" id="chart7">
          </div>
        </div>
        <div class="item">
          <div class="title title1">
            <span>任务看板</span>
            <div class="tab">
              <div class="tab-item" @click="tabChange(0)">
                <span>小学</span>
                <span class="border" :class="{ 'active': 0 === tabIndex }"></span>
              </div>
              <div class="tab-item" @click="tabChange(1)">
                <span>初中</span>
                <span class="border" :class="{ 'active': 1 === tabIndex }"></span>
              </div>
              <div class="tab-item" @click="tabChange(2)">
                <span>高中</span>
                <span class="border" :class="{ 'active': 2 === tabIndex }"></span>
              </div>
            </div>
          </div>
          <div class="item-box">
            <div class="label">
              <div class="label-item" @click="labelChange(0)">
                <span class="label-item-text" :class="{ blue: 0 === labelIndex }">7天提醒</span>
                <span class="blue">{{ qttx }}</span>
              </div>
              <div class="label-item" @click="labelChange(1)">
                <span class="label-item-text" :class="{ blue: 1 === labelIndex }">14天提醒</span>
                <span class="blue">{{ ssttx }}</span>
              </div>
              <div class="label-item" @click="labelChange(2)">
                <span class="label-item-text" :class="{ blue: 2 === labelIndex }">本季度待体检</span>
                <span class="blue">{{ bjddsf }}</span>
              </div>
              <div class="label-item" @click="labelChange(3)">
                <span class="label-item-text" :class="{ blue: 3 === labelIndex }">应访未访</span>
                <span class="blue">{{ yfwf }}</span>
              </div>
              <div class="label-item" @click="labelChange(4)">
                <span class="label-item-text" :class="{ blue: 4 === labelIndex }">体检任务总数</span>
                <span class="blue">{{ sfrwzs }}</span>
              </div>
            </div>
            <div class="thead">
              <span class="flex">姓名</span>
              <span class="flex">性别</span>
              <span class="flex">年龄</span>
              <span class="flex flex2">体检时间</span>
              <span class="flex flex2">管理医生</span>
            </div>
            <div class="scroll scroll1">
              <div class="scroll-box scroll-box3"
                :style="{ '--taskY': '-' + 40 * tasks.length + 'px', '--taskS': tasks.length + 's' }">
                <div class="list" v-for="(item, index) in tasks" :key="index">
                  <span class="flex">{{ item.name || '-' }}</span>
                  <span class="flex">{{ item.xb == '1' ? '男' : '女' }}</span>
                  <span class="flex">{{ item.age || '-' }}</span>
                  <span class="flex flex2">{{ item.time || '-' }}</span>
                  <span class="flex flex2">{{ item.glys || '-' }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 体检结果 -->
    <Test v-if="showTest" :selecType="testType" :selecLyType="testLyType" @close="closeTest"></Test>
    <!-- 异常占比 -->
    <Abnormal v-if="showAbnormal" :selecType="abnormalType" :selecLyType="abnormalLyType" @close="closeAbnormal"></Abnormal>
    <!-- 孤独症筛查 -->
    <Lonely v-if="showLonely" :selecType="lonelyType" :selecLyType="lonelyLyType"  @close="closeLonely"></Lonely>
    <!-- 儿童 -->
    <Children v-if="showChildren" :selecType="childrenType" :selecLyType="childrenLyType" @close="closeChildren"></Children>
    <!-- 任务看板 -->
    <Task v-if="showTask" :selecType="taskType" :selecLyType="taskLyType" :lytype="lytype" :mxtype="mxtype" @close="closeTask"></Task>
    <!-- 随访 -->
    <VisitRecord v-if="showVisitRecord" @close="closeVisitRecord"></VisitRecord>
    <!-- 幼儿园明细 -->
    <Kindergarten v-if="showKindergarten" @close="closeKindergarten"></Kindergarten>
    <Pop v-if="showPop"></Pop>
    <!-- 0-3岁体检结果 3-6岁体检结果 -->
    <TestResul v-if="showTestResul" :selecType="selectTestResultType"></TestResul>
  </div>
</template>

<script>
import { mapState } from 'vuex'
// get方法
import axios from 'axios'
import Test from './test.vue'
import Abnormal from './abnormal.vue'
import Lonely from './lonely.vue'
import Children from './children.vue'
import Task from './task.vue'
import VisitRecord from './visit-record.vue'
import Kindergarten from './kindergarten.vue'
import Pop from './popup/pop.vue'
import TestResul from './popup/test-result.vue'
// 创建一个取消令牌
const source = axios.CancelToken.source()
export default {
  components: {
    Test,
    Abnormal,
    Lonely,
    Children,
    Task,
    VisitRecord,
    Kindergarten,
    Pop,
    TestResul
  },
  data () {
    return {
      setInterval: null,
      setIntervalTimer: null,
      myChart1: null,
      setInterval1: null,
      datas1: [],
      datass1: [],
      selectTestResultType: '',
      myChart2: null,
      setInterval2: null,
      datas2: ['祥辉', '金安', '和平', '青禾', '志学', '兴学'],
      datass2: [120, 200, 150, 80, 70, 110],
      myChart4: null,
      setInterval4: null,
      datas4: [],
      datass4: [],
      // datas4: ['听力异常', '视力异常', '贫血异常', '低体重', '消瘦', '超重', '肥胖', '心里行为异常', '心里行为高危', '中度生长迟缓(矮小)', '重度生长迟缓'],
      // datass4: [64, 91, 43, 46, 75, 33, 18, 98, 13, 49, 82],
      myChart7: null,
      setInterval7: null,
      datas7: [
        // { value: 9, name: '肥胖' },
        // { value: 21, name: '听力异常' },
        // { value: 10, name: '视力异常' },
        // { value: 8, name: '贫血异常' },
        // { value: 5, name: '心里行为异常' },
        // { value: 15, name: '低体重' },
        // { value: 15, name: '消瘦' },
        // { value: 15, name: '超重' },
        // { value: 15, name: '肥胖' },
        // { value: 15, name: '心里行为高危' },
        // { value: 15, name: '中度生长迟缓(矮小)' },
        // { value: 15, name: '重度生长迟缓' }
      ],
      colors7: ['#2F8EED', '#3FEBF8', '#6BE05F', '#FFD338', '#F48787', '#C267EC', '#0061C2', '#2F88ED', '#3F8BF8', '#6B805F', '#FF8338', '#F48887'],
      width: '',
      tabIndex: 0,
      tabIndex1: 0,
      labelIndex: 0,
      visits: [],
      familys: [],
      tasks: [],
      lonelys: [
        { name: '筛查总数', value: '' },
        { name: '异常总数', value: '' },
        { name: '男性', value: '' },
        { name: '女性', value: '' }
      ],
      qttx: '0',
      ssttx: '0',
      bjddsf: '0',
      yfwf: '0',
      sfrwzs: '0',
      centers: [
        { title: '内科', value: '' },
        { title: '外科', value: '' },
        { title: '脊柱侧弯', value: '' },
        { title: '眼科', value: '' },
        { title: '耳鼻喉科', value: '' },
        { title: '口腔科', value: '' },
        { title: '总人数', value: '' },
        { title: '检查数', value: '' },
        { title: '体检率', value: '' },
        { title: '形态指标', value: '' },
        { title: '血压', value: '' },
        { title: '肺功能检测', value: '' }
      ],
      source: null,
      showTest: false,
      testType: '',
      testLyType: '',
      showAbnormal: false,
      abnormalType: '',
      abnormalLyType: '',
      showLonely: false,
      lonelyType: '',
      lonelyLyType: '',
      showChildren: false,
      childrenType: '',
      childrenLyType: '',
      showTask: false,
      taskType: '',
      taskLyType: '新生儿',
      showVisitRecord: false,
      showKindergarten: false,
      selectPopInfo: '',
      showPop: false,
      showTestResul: false
    }
  },
  computed: {
    ...mapState(['loginInfo', 'ShowHeadTab']),
    lytype () {
      const list = ['xser', 'ldss', 'sdls']
      return list[this.tabIndex]
    },
    mxtype () {
      const list = ['qttx', 'ssttx', 'bjddsf', 'yfwf', 'sfrwzs']
      return list[this.labelIndex]
      // return 'qttx'
    }
  },
  created () {
    this.getVisitInfo()
    this.getLonelyInfo()
    this.getCenterInfo()
    this.getTestInfo()
    this.getTaskInfo()
    this.getErrInfo()
  },
  mounted () {
    this.add()
  },
  beforeDestroy () {
    this.remove()
  },
  methods: {
    add () {
      this.setInterval = setInterval(async () => {
        if (this.labelIndex === 4) {
          this.labelIndex = 0
        } else {
          this.labelIndex++
        }
        if (typeof this.source === 'function') {
          this.source('终止请求')
        }
        this.getTaskInfo()
      }, 10000)
      this.setIntervalTimer = setInterval(async () => {
        if (this.tabIndex === 2) {
          this.tabIndex = 0
        } else {
          this.tabIndex++
        }
        if (typeof this.source === 'function') {
          this.source('终止请求')
        }
        this.getTaskInfo()
      }, 50000)
      this.$nextTick(() => {
        // this.canvas7()
      })
      window.addEventListener('resize', this.getresize)
      const items = document.querySelectorAll('.container-item')
      console.log('items', items)
      const r = document.querySelector('.container').clientWidth / 2
      this.width = r * 2
      const count = items.length
      const pieceDeg = 360 / count
      console.log(items, r, count, pieceDeg)
      for (let i = 0; i < count; i++) {
        let t = i * pieceDeg
        t = (Math.PI / 180) * t
        const x = Math.sin(t) * r; const y = -Math.cos(t) * r
        items[i].style.transform = `translate(${x}px, ${y}px`
      }

      this.canvas2()
    },
    remove () {
      window.removeEventListener('resize', this.getresize)
      clearInterval(this.setInterva1)
      clearInterval(this.setInterva2)
      clearInterval(this.setIntervalTimer)
      clearInterval(this.setInterva7)
      clearInterval(this.setInterval)
    },
    getresize () {
      setTimeout(() => {
        this.myChart1.resize()
        this.myChart2.resize()
        // this.myChart4.resize()
        this.myChart7.resize()
      }, 500)
    },
    close () {
      if (this.ShowHeadTab.length === 1) {
        this.$router.go(-2)
      } else {
        this.$router.go(-1)
      }
    },
    changeInfo1 () {
      const scroll1 = document.querySelector('#chart1')
      scroll1.addEventListener('mouseenter', () => this.noUpdate1())
      scroll1.addEventListener('mouseleave', () => this.update1())
    },
    canvas1 () {
      var chartDom = document.getElementById('chart1')
      this.myChart1 = this.$echarts.init(chartDom)
      this.myChart1.on('click', res => {
        console.log('---------', res)
        console.log('-------', res.name)
        this.selectTestResultType = res.name
        this.showTestResul = true
      })
      const option = {
        grid: {
          containLabel: true,
          top: '12%', // 一下数值可为百分比也可为具体像素值
          right: '4%',
          bottom: '8%',
          left: '4%'
        },
        label: {
          show: true,
          color: '#fff',
          fontSize: 14,
          position: 'top',
          height: 100,
          offset: [0, 0]
        },
        xAxis: [
          // x轴数据设置
          {
            type: 'category',
            splitLine: {
              show: false
            },
            axisLine: {
              lineStyle: {
                color: '#fff'
              }
            },
            axisLabel: {
              fontSize: 10,
              rotate: 20 // X轴标签倾斜 45 度
            },
            data: this.datas1
          }
        ],
        yAxis: [
          {
            type: 'value',
            offset: 0,
            axisLine: {
              show: true,
              lineStyle: {
                color: '#fff'
              },
              axisLabel: {
                show: true,
                color: '#fff',
                fontSize: 16
              }
            },
            axisTick: {
              show: false
            }
          }
        ],
        series: [
          {
            data: this.datass1,
            type: 'bar',
            barWidth: 8,
            label: {
              show: true,
              textStyle: {
                color: '#fff'
              }
            },
            itemStyle: {
              color: '#3FEBF8'
            },
            backgroundStyle: {
              color: 'rgba(180, 180, 180, 0.2)'
            }
          }
        ]
      }

      option && this.myChart1.setOption(option)
      this.update1()
    },
    update1 () {
      this.setInterval1 = setInterval(() => {
        this.datas1.unshift(this.datas1.pop())
        this.datass1.unshift(this.datass1.pop())
        this.myChart1.setOption({
          xAxis: {
            data: this.datas1
          },
          series: {
            data: this.datass1
          }
        })
      }, 2000)
    },
    noUpdate1 () {
      clearInterval(this.setInterval1)
    },

    changeInfo2 () {
      const scroll2 = document.querySelector('#chart2')
      scroll2.addEventListener('mouseenter', () => this.noUpdate2())
      scroll2.addEventListener('mouseleave', () => this.update2())
    },
    canvas2 () {
      var chartDom = document.getElementById('chart2')
      this.myChart2 = this.$echarts.init(chartDom)
      this.myChart2.on('click', res => {
        console.log('----', res)
        this.selectPopInfo = res.name
        this.showPop = true
      })
      const option = {
        legend: {
          data: ['总人数', '已服务'],
          textStyle: {
            color: '#fff'
          }
        },
        grid: {
          containLabel: true,
          top: '20%', // 一下数值可为百分比也可为具体像素值
          right: '14%',
          bottom: '8%',
          left: '8%'
        },
        label: {
          show: true,
          color: '#fff',
          fontSize: 14,
          position: 'top',
          height: 100,
          offset: [0, 0]
        },
        xAxis: [
          // x轴数据设置
          {
            type: 'category',
            splitLine: {
              show: false
            },
            axisLine: {
              lineStyle: {
                color: '#fff'
              }
            },

            data: this.datas2
          }
        ],
        yAxis: [
          {
            type: 'value',
            offset: 0,
            axisLine: {
              show: true,
              lineStyle: {
                color: '#fff'
              },
              axisLabel: {
                show: true,
                color: '#fff',
                fontSize: 16
              }
            },
            axisTick: {
              show: false
            }
          }
        ],
        series: [
          {
            name: '总人数',
            data: this.datass2,
            type: 'bar',
            barWidth: 8,
            label: {
              show: true,
              textStyle: {
                color: '#3FEBF8',
                fontSize: 9
              },
              rotate: 45
            },
            itemStyle: {
              color: '#3FEBF8'
            },
            backgroundStyle: {
              color: 'rgba(180, 180, 180, 0.2)'
            }
          },
          {
            name: '已服务',
            data: this.datass2,
            type: 'bar',
            barWidth: 8,
            label: {
              show: true,
              textStyle: {
                color: '#3FEBF8',
                fontSize: 9
              },
              rotate: 45
            },
            itemStyle: {
              color: 'red'
            },
            backgroundStyle: {
              color: 'rgba(180, 180, 180, 0.2)'
            },
            barGap: 0.5
          }
        ]
      }
      option && this.myChart2.setOption(option)
      this.update2()
    },
    update2 () {
      this.setInterval2 = setInterval(() => {
        this.datas2.unshift(this.datas2.pop())
        this.datass2.unshift(this.datass2.pop())
        this.myChart2.setOption({
          xAxis: {
            data: this.datas2
          },
          series: [
            {
              data: this.datass2
            },
            {
              data: this.datass2
            }
          ]
        })
      }, 2000)
    },
    noUpdate2 () {
      clearInterval(this.setInterval2)
    },

    changeInfo4 () {
      const scroll4 = document.querySelector('#chart4')
      scroll4.addEventListener('mouseenter', () => this.noUpdate4())
      scroll4.addEventListener('mouseleave', () => this.update4())
    },
    canvas4 () {
      var chartDom = document.getElementById('chart4')
      this.myChart4 = this.$echarts.init(chartDom)
      this.myChart4.on('click', res => {

      })
      const option = {
        grid: {
          containLabel: true,
          top: '2%', // 一下数值可为百分比也可为具体像素值
          right: '14%',
          bottom: '2%',
          left: '8%'
        },
        label: {
          show: true,
          color: '#fff',
          fontSize: 14,
          position: 'right',
          height: 100,
          offset: [0, 0]
        },
        xAxis: [
          // x轴数据设置
          {
            type: 'value',
            splitLine: {
              show: false
            },
            axisLine: {
              lineStyle: {
                color: '#0F3569'
              }
            }
          }
        ],
        yAxis: [
          {
            type: 'category',
            offset: 0,
            axisLine: {
              show: false,
              // 这是x轴文字颜色
              lineStyle: {
                color: '#fff'
              },
              axisLabel: {
                show: true,
                color: '#fff',
                fontSize: 16
              }
            },
            data: this.datas4,
            axisTick: {
              show: false
            }
          }
        ],
        series: [
          {
            data: this.datass4,
            type: 'bar',
            barWidth: 8,
            // barCategoryGap: '1110%',
            // barGap: '10%',
            label: {
              show: true,
              textStyle: {
                color: '#3FEBF8'
              }
            },
            itemStyle: {
              color: '#3FEBF8'
              // borderRadius: [10, 10, 10, 10]
            },
            showBackground: false,
            backgroundStyle: {
              color: 'rgba(180, 180, 180, 0.2)'
            }
          }
        ]
      }

      option && this.myChart4.setOption(option)
      this.update4()
    },
    update4 () {
      this.setInterval4 = setInterval(() => {
        this.datas4.unshift(this.datas4.pop())
        this.datass4.unshift(this.datass4.pop())
        this.myChart4.setOption({
          yAxis: {
            data: this.datas4
          },
          series: {
            data: this.datass4
          }
        })
      }, 2000)
    },
    noUpdate4 () {
      clearInterval(this.setInterval4)
    },
    changeInfo7 () {
      const scroll1 = document.querySelector('#chart7')
      scroll1.addEventListener('mouseenter', () => this.noUpdate7())
      scroll1.addEventListener('mouseleave', () => this.update7())
    },
    canvas7 () {
      var chartDom = document.getElementById('chart7')
      this.myChart7 = this.$echarts.init(chartDom)
      this.myChart7.on('click', res => {

      })

      const option = {
        color: this.colors7,
        // grid: {
        //   containLabel: true,
        //   top: '2%',
        //   right: '40%',
        //   bottom: '0%',
        //   left: '1%'
        // },
        tooltip: {
          trigger: 'item',
          formatter: params => {
            return params.seriesName + '<br/>' + params.data.name + ' : ' + params.data.value.toFixed(0) + '%'
          }
        },
        legend: {
          // type: 'scroll',
          itemHeight: 5,
          show: true,
          orient: 'vertical',
          y: 'center',
          x: 'right',
          align: 'left',
          textStyle: {
            fontSize: 10,
            color: '#FFFFFF'
          },
          pageIconColor: '#aaa',
          pageTextStyle: {
            color: '#fff'
          },
          formatter: (name) => {
            const total = this.datas7.reduce(function (sum, seriesItem) {
              return sum + seriesItem.value
            }, 0)
            const target = this.datas7.find(function (seriesItem) {
              return seriesItem.name === name
            })
            const percentage = ((target.value / total) * 100).toFixed(0) + '%'
            return name + ' ' + percentage
          }
        },
        series: [
          {
            name: '异常占比',
            type: 'pie',
            radius: ['50%', '70%'],
            center: ['25%', '50%'],
            data: this.datas7,
            label: {
              show: false,
              formatter: '{c}%',
              fontSize: 13,
              color: '#FFFFFF'
            }
          }
        ]
      }

      this.myChart7.on('legendselectchanged', res => {
        console.log('异常占比：', res)
      })

      option && this.myChart7.setOption(option)
      this.update7()
    },
    update7 () {
      this.setInterval7 = setInterval(() => {
        this.datas7.unshift(this.datas7.pop())
        this.colors7.unshift(this.colors7.pop())
        this.myChart7.setOption({
          color: this.colors7,
          series: {
            data: this.datas7
          }
        })
      }, 2000)
    },
    async getVisitInfo () {
      // 接口文档 https://app.apifox.com/project/2726972/apis/api-147518788
      try {
        const params = {
          jgbh: this.loginInfo.jgbh || '',
          jbmc: this.loginInfo.jgmc || '',
          ssjgbhmc: this.loginInfo.label || '',
          usercode: this.loginInfo.usercode || '',
          username: this.loginInfo.username || '',
          jgjb: this.loginInfo.value || '',
          ssjgbh: this.loginInfo.jgbh || '',
          type: ''
          // "jgbh": "133100100100",
          // "jbmc": "consectetur",
          // "ssjgbhmc": "ut in sit",
          // "usercode": "97",
          // "username": "龚涛",
          // "jgjb": "culpa deserunt",
          // "ssjgbh": "133100100100",
          // "type": "in nisi"
        }
        const { data: res } = await this.$http.post('/api/jkct/app/zhdpetfw', params)
        console.log('智慧公卫儿童健康-辖区内儿童服务记录', res)
        if (res.code !== 0) {
          return this.$message({
            message: res.msg || '暂无信息',
            type: 'warning'
          })
        }
        this.visits = res.data
        // this.familys = res.data
        // this.tasks = res.data
      } catch (err) {
        console.log('智慧公卫儿童健康-辖区内儿童服务记录', err)
        this.$message({
          message: err.msg || '暂无信息',
          type: 'warning'
        })
      }
    },
    async getLonelyInfo () {
      // 接口文档 https://app.apifox.com/project/2726972/apis/api-147791626
      try {
        const params = {
          jgbh: this.loginInfo.jgbh || '',
          jbmc: this.loginInfo.jgmc || '',
          ssjgbhmc: this.loginInfo.label || '',
          usercode: this.loginInfo.usercode || '',
          username: this.loginInfo.username || '',
          jgjb: this.loginInfo.value || '',
          ssjgbh: this.loginInfo.jgbh || '',
          type: ''
          // "jgbh": "133100100100",
          // "jbmc": "consectetur",
          // "ssjgbhmc": "ut in sit",
          // "usercode": "97",
          // "username": "龚涛",
          // "jgjb": "culpa deserunt",
          // "ssjgbh": "133100100100",
          // "type": "in nisi"
        }
        const { data: res } = await this.$http.post('/api/jkct/app/zhdpgwetgdzsc', params)
        console.log('智慧公卫-儿童孤独症筛查', res)
        this.lonelys = res.data
      } catch (err) {
        console.log('智慧公卫-儿童孤独症筛查', err)
        this.$message({
          message: err.msg || '暂无信息',
          type: 'warning'
        })
      }
    },
    async getCenterInfo () {
      // 接口文档 https://app.apifox.com/project/2726972/apis/api-147704956
      try {
        const params = {
          jgbh: this.loginInfo.jgbh || '',
          jbmc: this.loginInfo.jgmc || '',
          ssjgbhmc: this.loginInfo.label || '',
          usercode: this.loginInfo.usercode || '',
          username: this.loginInfo.username || '',
          jgjb: this.loginInfo.value || '',
          ssjgbh: this.loginInfo.jgbh || '',
          type: ''
          // "jgbh": "133100100100",
          // "jbmc": "consectetur",
          // "ssjgbhmc": "ut in sit",
          // "usercode": "97",
          // "username": "龚涛",
          // "jgjb": "culpa deserunt",
          // "ssjgbh": "133100100100",
          // "type": "in nisi"
        }
        const { data: res } = await this.$http.post('/api/jkct/app/zhdpgwetzlsbccsj', params)
        console.log('智慧公卫儿童-中间总览数据 ', res)
        if (res.code !== 0) {
          return this.$message({
            message: res.msg || '暂无信息',
            type: 'warning'
          })
        }
        if (res.data.length < 1) {
          return this.$message({
            message: '暂无信息',
            type: 'warning'
          })
        }
        // this.centers = res.data
      } catch (err) {
        console.log('智慧公卫儿童-中间总览数据 ', err)
      }
    },
    async getTestInfo () {
      // 接口文档 https://app.apifox.com/project/2726972/apis/api-147704956
      try {
        const params = {
          jgbh: this.loginInfo.jgbh || '',
          jbmc: this.loginInfo.jgmc || '',
          ssjgbhmc: this.loginInfo.label || '',
          usercode: this.loginInfo.usercode || '',
          username: this.loginInfo.username || '',
          jgjb: this.loginInfo.value || '',
          ssjgbh: this.loginInfo.jgbh || '',
          type: ''
        }
        const { data: res } = await this.$http.post('/api/jkct/app/zhdpgwettjycjgfx', params)
        console.log('智慧公卫-儿童体检结果0-6岁体检结果', res)
        if (res.code !== 0) {
          return this.$message({
            message: res.msg || '暂无信息',
            type: 'warning'
          })
        }
        const list = res.data
        if (list.length > 0) {
          const colors = ['#ff4569', '#ff7839', '#ffce3d', '#a0ed44', '#42eca8', '#42a9ec', '#9a42ec']
          for (var i = 0; i < list.length; i++) {
            this.datas4.push(list[i].name)
            this.datass4.push(list[i].value)
            this.datas1.push(list[i].name)
            const obj = {
              value: list[i].value,
              itemStyle: { color: colors[i] }
            }
            this.datass1.push(obj)
          }
          // this.canvas4()
          this.canvas1()
        }
        this.tests = res.data
        console.log('--------', this.datas1)
      } catch (err) {
        console.log('智慧公卫-儿童体检结果0-6岁体检结果', err)
      }
    },
    async getErrInfo () {
      // 接口文档 https://doc.weixin.qq.com/doc/w3_AAMAewYpAFEuDDyb15EQdG0k4GrvY?scode=ACwAmwdjAAYidfjvMgAAMAewYpAFE
      try {
        const params = {
          jgbh: this.loginInfo.jgbh || '',
          jbmc: this.loginInfo.jgmc || '',
          ssjgbhmc: this.loginInfo.label || '',
          usercode: this.loginInfo.usercode || '',
          username: this.loginInfo.username || '',
          jgjb: this.loginInfo.value || '',
          ssjgbh: this.loginInfo.jgbh || '',
          type: ''
        }
        const { data: res } = await this.$http.post('/api/jkct/app/zhdpgwettjycjgfxzb', params)
        console.log('异常占比', res)
        if (res.code !== 0) {
          return this.$message({
            message: res.msg || '暂无信息',
            type: 'warning'
          })
        }
        const list = res.data
        if (list.length > 0) {
          const colors = ['#ff4569', '#ff7839', '#ffce3d', '#a0ed44', '#42eca8', '#42a9ec', '#9a42ec']
          for (var i = 0; i < list.length; i++) {
            const val = list[i].value.split('%')
            console.log(parseFloat(val[0]))
            const obj = {
              // value: parseInt(i + ''),
              value: parseFloat(val[0]),
              name: list[i].name
            }
            this.datas7.push(obj)
          }
          console.log('-----', this.datas7)
          this.colors7.splice(this.datas7.length)
          this.canvas7()
        }
      } catch (err) {
        console.log('异常占比', err)
      }
    },
    async getTaskInfo () {
      const that = this
      // 接口文档 https://app.apifox.com/project/2726972/apis/api-147714916
      try {
        const arr = ['qttx', 'ssttx', 'bjddsf', 'yfwf', 'sfrwzs']
        for (let i = 0; i < arr.length; i++) {
          this.$data[arr[i]] = 0
        }
        const params = {
          jgbh: this.loginInfo.jgbh || '',
          jbmc: this.loginInfo.jgmc || '',
          ssjgbhmc: this.loginInfo.label || '',
          usercode: this.loginInfo.usercode || '',
          username: this.loginInfo.username || '',
          jgjb: this.loginInfo.value || '',
          ssjgbh: this.loginInfo.jgbh || '',
          type: '',
          sjjgbh: this.loginInfo.sjjgbh || '',
          lytype: this.lytype,
          mxtype: this.mxtype,
          start: 0,
          length: 10
          // "jgbh":"133100100100","jbmc":"贤溪社区卫生服务中心","ssjgbhmc":"贤溪社区卫生服务中心","usercode":"xxsqlhj","username":"贤溪社区卫生服务中心","jgjb":"4","ssjgbh":"133100100100","sjjgbh":"133100100000","lytype":"xser","mxtype":"qttx","start":0,"length":10
        }
        const { data: res } = await this.$http.post('/api/jkct/app/zhdpetsfrwtx', params, {
          cancelToken: new axios.CancelToken(function executor (c) {
            that.source = c
          })
        })
        console.log('智慧公卫儿童健康-辖区内儿童服务提醒', res)
        if (res.code !== 0) {
          return this.$message({
            message: res.msg || '暂无信息',
            type: 'warning'
          })
        }
        if (res.data.length < 1) {
          if (this.tabIndex === 2) {
            this.tabIndex = 0
          } else {
            this.tabIndex++
          }
          return this.$message({ message: '暂无信息', type: 'warning' })
        }
        // eslint-disable-next-line no-prototype-builtins
        if (!res.data[0].hasOwnProperty(this.lytype)) {
          return this.$message({
            message: '暂无信息',
            type: 'warning'
          })
        }
        const obj = res.data[0][this.lytype]
        // console.log(obj.hasOwnProperty(this.mxtype + 'list'))
        this.tasks = res.data[0][this.lytype][this.mxtype + 'list']
        const key = arr[this.labelIndex]
        this.$data[key] = res.data[0][this.lytype][key]
        // this.qttx = res.data[0][this.lytype].qttx
        // this.ssttx = res.data[0][this.lytype].ssttx
        // this.bjddsf = res.data[0][this.lytype].bjddsf
        // this.yfwf = res.data[0][this.lytype].yfwf
        // this.sfrwzs = res.data[0][this.lytype].sfrwzs
      } catch (err) {
        console.log('智慧公卫儿童健康-辖区内儿童服务提醒', err)
      }
    },
    async getServeInfo () {
      // 接口文档 https://app.apifox.com/project/2726972/apis/api-147518788
      try {
        const params = {
          jgbh: this.loginInfo.jgbh || '',
          jbmc: this.loginInfo.jgmc || '',
          ssjgbhmc: this.loginInfo.label || '',
          usercode: this.loginInfo.usercode || '',
          username: this.loginInfo.username || '',
          jgjb: this.loginInfo.value || '',
          ssjgbh: this.loginInfo.jgbh || '',
          type: ''
        }
        const { data: res } = await this.$http.post('/api/jkct/app/zhdpetfw', params)
        console.log('智慧公卫儿童健康-辖区内儿童服务记录', res)
        if (res.code !== 0) {
          return this.$message({
            message: res.msg || '暂无信息',
            type: 'warning'
          })
        }
        if (res.data.length < 1) return this.$message({ message: '暂无信息', type: 'warning' })
      } catch (err) {
        console.log('智慧公卫儿童健康-辖区内儿童服务提醒', err)
      }
    },
    tabChange (index) {

    },
    tabChange1 (index) {

    },
    labelChange (index) {

    },
    openTest (item) {
      console.log(item)
      this.testType = item.name || ''
      const arr = this.tests.filter(obj => obj.name === item.name)
      if (arr.length < 1) return
      this.testLyType = arr[0].lytype || ''
      this.showTest = true
    },
    closeTest () {
      this.testType = ''
      this.testLyType = ''
      this.showTest = false
    },
    openAbnormal (item) {
      this.abnormalType = item.name || ''
      const arr = this.datas7.filter(obj => obj.name === item.name)
      this.abnormalLyType = arr[0].lytype || ''
      this.showAbnormal = true
    },
    closeAbnormal () {
      this.abnormalType = ''
      this.abnormalLyType = ''
      this.showAbnormal = false
    },
    openLonely (item) {

    },
    closeLonely () {
      this.lonelyType = ''
      this.lonelyLyType = ''
      this.showLonely = false
    },
    openChildren (item) {
      console.log(item)
      // if(item.title === '体检率') return
      // if(item.title === '4-6岁') return
      // if(item.title === '0-3岁中医') return
      // if(item.title === '0-3岁') return
      // if(item.title === '0-3岁家签') return
      // if(item.title === '新生儿') return
      // if(item.title === '4-6岁家签') return
      this.childrenType = item.title || ''
      this.childrenLyType = item.lytype || ''
      if (item.title === '总人数') {
        this.childrenLyType = 'etjkzrs'
      } else if (item.title === '检查数') {
        this.childrenLyType = 'etjkjcs'
      }
      this.showChildren = true
    },
    closeChildren () {
      this.childrenType = ''
      this.childrenLyType = ''
      this.showChildren = false
    },
    openTask (type) {
      this.taskType = type
      this.showTask = true
    },
    closeTask () {
      this.taskType = ''
      this.showTask = false
    },
    openVisitRecord () {

    },
    closeVisitRecord () {
      this.showVisitRecord = false
    },
    openKindergarten () {
      this.showKindergarten = true
    },
    closeKindergarten () {
      this.showKindergarten = false
    }
  }
}
</script>

<style lang="less" scoped>
// @import url('../../assets/fonts/font.css');

.page {
  width: 100%;
  height: 100%;
  background-image: url('../../../assets/img/child1.png');
  background-repeat: no-repeat;
  background-size: 100% 100%;

  div,
  span {
    font-family: Alimama_DongFangDaKai_Regular;
  }

  .close {
    position: absolute;
    top: 0;
    left: 0;
    height: 80px;
    padding-left: 30px;
    box-sizing: border-box;
    cursor: pointer;
    color: #fff;
    font-size: 30px;
    font-weight: 800;
  }

  .head {
    display: flex;
    justify-content: center;
    width: 100%;
    height: 60px;
    background-image: url('../../../assets/img/child2.png');
    background-repeat: no-repeat;
    background-size: 100% 100%;
    letter-spacing: 5px;
    color: #fff;
    font-size: 40px;
  }

  .content {
    display: flex;
    justify-content: space-between;
    width: 100%;
    height: calc(100% - 90px);
    padding: 0 34px;
    box-sizing: border-box;

    .left {
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      width: 25%;
      min-width: 478px;
      height: 100%;
    }

    .center {
      width: 49%;
      // min-width: 878px;
      height: 100%;

      .center-head {
        display: flex;
        align-items: center;
        width: 100%;
        height: 180px;

        .head-item {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          width: 50%;
          color: #fff;
          font-size: 42px;
          letter-spacing: 2px;
          cursor: pointer;

          .num {
            color: #3FEBF8;
            font-size: 60px;
          }
        }
      }

      .center-box {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: calc(100% - 180px);

        .center-img {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          width: 90%;
          height: 80%;
          object-fit: contain;
        }

        .container {
          width: 70%;
          // height: 70%;
          margin: 0 auto 20px;
          position: relative;
          display: flex;
          justify-content: center;
          align-items: center;
          border-radius: 50%;
          // animation: rotate linear infinite 20s;
          transform: rotate(-30deg);
          z-index: 888;
        }

        .container-item {
          width: 144px;
          height: 144px;
          text-align: center;
          position: absolute;
          cursor: pointer;
        }

        .center-item-label {
          display: flex;
          flex-direction: column;
          align-items: center;
          // animation: rotate 20s linear infinite reverse;
          transform: rotate(30deg);
          color: #fff;
          font-size: 26px;
        }

        .center-item-img {
          position: relative;
          width: 108px;
          height: 108px;
          line-height: 108px;
          text-align: center;
          border-radius: 50%;
          background-image: url('../../../assets/img/child6.png');
          background-repeat: no-repeat;
          background-size: 100% 100%;
          color: #3FEBF8;
          font-size: 30px;
        }

        .center-item-font {
          position: absolute;
          width: 108px;
          height: 108px;
          line-height: 108px;
          text-align: center;
          color: #3FEBF8;
          font-size: 30px;
        }

        @keyframes rotate {
          to {
            transform: rotate(360deg);
          }
        }
      }
    }

    .right {
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      width: 25%;
      min-width: 478px;
      height: 100%;
    }

    .item {
      width: 100%;
      height: 32%;
      padding: 15px;
      background-image: url('../../../assets/img/child3.png');
      background-repeat: no-repeat;
      background-size: 100% 100%;
      box-sizing: border-box;

      .title {
        display: flex;
        align-items: center;
        width: 100%;
        height: 39px;
        padding: 0 9px;
        background-image: url('../../../assets/img/child4.png');
        background-repeat: no-repeat;
        background-size: 100% 100%;
        box-sizing: border-box;
        color: #fff;
        font-size: 20px;
        letter-spacing: 2px;

        .span {
          margin-right: 10px;
        }

        .blue {
          margin-left: 45px;
          color: #3FEBF8;
          font-size: 14px;
          letter-spacing: 1px;
        }

        .total {
          display: flex;
          align-items: center;
          color: #fff;
          font-size: 14px;
          letter-spacing: 1px;

          .number {
            color: #3FEBF8;
            font-size: 24px;
          }
        }

        .tab {
          display: flex;
          align-items: center;

          .tab-item {
            display: flex;
            flex-direction: column;
            align-items: center;
            margin: 0 12px;
            color: #3FEBF8;
            font-size: 14px;
            cursor: pointer;

            .border {
              width: 32px;
              height: 3px;
              margin-top: 4px;
              border-radius: 2px;
            }

            .active {
              background-color: #3FEBF8;
            }
          }
        }
      }

      .title1 {
        justify-content: space-between;
      }

      .item-box {
        width: 100%;
        height: calc(100% - 39px);

        .thead {
          display: flex;
          align-items: center;
          justify-content: space-around;
          width: 100%;
          height: 40px;

          .flex {
            color: #3FEBF8;
          }
        }

        .flex {
          flex: 1;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
          text-align: center;
          color: #fff;
          font-size: 14px;
        }

        .flex2 {
          flex: 2;
        }

        .scroll {
          overflow: hidden;
          width: 100%;
          height: calc(100% - 10px);

          .scroll-box {
            display: flex;
            flex-direction: column;
          }

          .scroll-box1 {
            animation: move1 var(--visitS) infinite linear;
          }

          @keyframes move1 {
            0% {
              transform: translateY(0);
            }

            100% {
              transform: translateY(var(--visitY));
            }
          }

          .scroll-box2 {
            animation: move2 var(--familyS) infinite linear;
          }

          @keyframes move2 {
            0% {
              transform: translateY(0);
            }

            100% {
              transform: translateY(var(--familyY));
            }
          }

          .scroll-box3 {
            animation: move3 var(--taskS) infinite linear;
          }

          @keyframes move3 {
            0% {
              transform: translateY(0);
            }

            100% {
              transform: translateY(var(--taskY));
            }
          }

          .list {
            display: flex;
            align-items: center;
            justify-content: space-around;
            width: 100%;
            height: 40px;
            margin-bottom: 4px;
            background: rgba(0, 42, 66, 0.36);
            cursor: pointer;
          }
        }

        .scroll1 {
          height: calc(100% - 95px);
        }

        .label {
          display: flex;
          align-items: center;
          justify-content: space-between;
          width: 100%;
          height: 45px;

          .label-item {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: space-evenly;
            width: calc(20% - 3px);
            height: 38px;
            background-image: url('../../../assets/img/child11.png');
            background-repeat: no-repeat;
            background-size: 100% 100%;
            color: #fff;
            font-size: 12px;
            cursor: pointer;

            .blue {
              color: #3FEBF8;
            }

            .label-item-text {
              width: 100%;
              text-align: center;
              overflow: hidden;
              white-space: nowrap;
              text-overflow: ellipsis;
            }
          }

        }
      }

      .info {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        height: calc(100% - 39px);

        .img {
          width: 182px;
          height: 192px;
        }

        .info-box {
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          width: calc(100% - 199px);
          height: calc(100% - 34px);

          .info-box-item {
            display: flex;
            align-items: center;
            justify-content: space-between;
            width: 100%;
            height: 32%;
            padding: 0 17px;
            box-sizing: border-box;
            color: #fff;
            font-size: 14px;
            letter-spacing: 1px;
            cursor: pointer;

            .image {
              width: 40px;
              height: 40px;
            }

            .color {
              color: #3FEBF8;
              font-size: 30px;
            }

            .color1 {
              color: #FFD338;
            }
          }

          .info-box-item1 {
            background-image: url('../../../assets/img/child8.png');
            background-repeat: no-repeat;
            background-size: 100% 100%;
          }
        }

        .info-box-item:last-child {
          justify-content: space-around;
        }
      }
    }

    .item1 {
      height: 34%;
      padding-bottom: 0;
    }

    .item2 {
      height: 30%;
    }

    .item3 {
      height: 64%;
    }
  }
}
</style>
