<template>
  <div class="select">
    <div class="popup-content">
      <div class="popup-title">
        <span>设备运行状态-{{ statusName || '' }}</span>
      </div>
      <div class="popup-box">
        <div class="content">
          <div class="close" @click="close">
            <i class="el-icon-close"></i>
          </div>
          <div class="box">
            <div class="search">
              <span>异常级别</span>
              <el-select v-model="value" placeholder="请选择">
                <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
                </el-option>
              </el-select>
              <el-button>搜索</el-button>
              <el-button>清空</el-button>
            </div>
            <el-table :data="tableData" height="90%" :header-cell-style="{ background: '#024276' }" ref="table">
              <el-table-column type="index" label="序号" width="120">
              </el-table-column>
              <el-table-column prop="name" label="体检日期">
              </el-table-column>
              <el-table-column prop="sfzhm" label="姓名">
              </el-table-column>
              <el-table-column prop="sfzhm" label="年龄">
              </el-table-column>
              <el-table-column prop="xb" label="性别">
                <template slot-scope="scope">
                  <span>{{ scope.row.xb === '1' ? '男' : '女' }}</span>
                </template>
              </el-table-column>
              <el-table-column prop="age" label="身份证号">
              </el-table-column>
              <el-table-column prop="age" label="异常级别">
              </el-table-column>
              <el-table-column prop="age" label="乡镇/社区">
              </el-table-column>
              <el-table-column prop="age" label="村/街道">
              </el-table-column>
              <el-table-column prop="age" label="守护天数">
              </el-table-column>
              <el-table-column label="操作" width="120">
                <template>
                  <div class="flex">
                    <span @click="openUpdate">编辑</span>
                    <span>转诊</span>
                  </div>
                </template>
              </el-table-column>
            </el-table>
            <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
              :current-page="query1.pageNum" background :page-size="query1.pageSize"
              layout="total, prev, pager, next, jumper" :total="query1.total"></el-pagination>
          </div>
        </div>
      </div>
    </div>
    <Update v-if="showUpdate" @close="closeUpdate"></Update>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import Update from './update.vue'
export default {
  components: {
    Update
  },
  props: {
    statusName: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      tableData: [{}],
      options: [{
        value: '1',
        label: '1'
      }],
      value: '',
      showUpdate: false,
      // 分页数据
      query1: {
        pageNum: 1,
        pageSize: 10,
        total: 0
      },
      params: {
        // 分页起始
        start: '0',
        // 分页长度
        length: '10',
        ssjgbh: '',
        xzjdmcid: '',
        cwhmcid: '',
        flag: '',
        isqy: '',
        issglb: '',
        gxyjb: '',
        gxydj: '',
        tnbjb: '',
        gxtdj: '',
        name: '',
        sfzh: ''
      }
    }
  },
  computed: {
    ...mapState(['loginInfo'])
  },
  mounted () { },
  methods: {
    reset () {
      this.params.name = this.params.sfzh = ''
    },
    openUpdate () {
      this.showUpdate = true
    },
    closeUpdate () {
      this.showUpdate = false
    },
    openAdd () {
      // this.showAdd = true
      this.close('openAdd')
    },
    closeAdd () {
      this.showAdd = false
    },
    openDelete () {
      this.showDelete = true
    },
    closeDelete () {
      this.showDelete = false
    },
    handleSizeChange (val) {
      // console.log(`每页 ${val} 条`)
    },
    handleCurrentChange (val) {
      // console.log(`当前页: ${val}`)
      this.query1.pageNum = val
      // this.getList()
    },
    close (a) {
      this.$emit('close', a)
    }
  }
}
</script>

<style scoped lang="less">
.select {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 88;
  background-color: rgba(0, 0, 0, .74);

  .popup-content {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 90%;
    height: 665px;

    .popup-title {
      width: 342px;
      height: 54px;
      line-height: 68px;
      margin-bottom: 14px;
      background-image: url('../../zhyl/img/dialogTitleBg.png');
      background-size: 100% 100%;
      background-repeat: no-repeat;
      text-align: center;
      color: #fff;
      font-size: 20px;
    }

    .popup-box {
      width: 100%;
      height: 615px;
      padding: 10px;
      background-color: rgba(3, 27, 44, .8);
      border-radius: 10px;
      border: 4px solid rgba(1, 49, 88, .5);
      box-sizing: border-box;

      .content {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        position: relative;
        width: 100%;
        height: 100%;
        padding: 30px;
        border-radius: 10px;
        border: 4px solid rgba(1, 49, 88, .5);
        box-sizing: border-box;

        .close {
          display: flex;
          align-items: flex-end;
          position: absolute;
          top: 0;
          right: 0;
          width: 50px;
          height: 50px;
          background: linear-gradient(26.565deg, #2057a7 50%, transparent 0);
          transform: rotate(180deg);
          color: #fff;
          font-size: 24px;
          cursor: pointer;
        }

        .box {
          width: 100%;
          height: calc(100% - 50px);

          .search {
            display: flex;
            align-items: center;
            margin-bottom: 10px;
            color: #01EEFD;
            font-size: 18px;

            .el-select {
              margin-left: 10px;
              margin-right: 40px;
            }

            .el-input {
              width: 180px;
              margin-left: 10px;
              margin-right: 30px;
            }

            .el-button {
              margin-left: 30px;
              background-color: #04B4D0;
              color: #fff;
            }
          }

          ::v-deep.el-pagination__total,
          ::v-deep.el-pagination__jump {
            color: #01EEFD;

            .el-input__inner {
              color: #01EEFD;
              background-color: transparent;
            }
          }

          ::v-deep.el-pagination.is-background .el-pager li:not(.disabled).active {
            background-color: #01EEFD;
          }

          ::v-deep .el-table__body-wrapper {
            &::-webkit-scrollbar {
              width: 0;
            }
          }

          .el-pagination {
            display: flex;
            justify-content: center;
            margin-top: 10px;
          }

          ::v-deep .el-table th.gutter {
            display: none;
            width: 0;
          }

          ::v-deep .el-table colgroup col[name='gutter'] {
            display: none;
            width: 0;
          }

          ::v-deep .el-table__body {
            width: 100% !important;
          }

          .el-table {
            margin-top: 20px;
            background-color: #092B50;
            border: 4px #073F73 solid;

            .el-button {
              width: 90px;
              text-align: center;
              padding-left: 0;
              padding-right: 0;
              background-color: #0192B5;
              color: #fff;
            }

            .red {
              background-color: #FF0000;
            }
          }

          // 表头文字颜色
          /deep/.el-table__header-wrapper {
            thead {
              th {
                div {
                  color: #fff;
                  font-size: 17px;
                  font-weight: 400;
                }
              }
            }
          }

          /deep/.el-table__header .el-table__cell {
            background-color: #03BCD7 !important;
          }

          //表格内容颜色
          /deep/.el-table__body tbody tr {
            color: #fff;
            background-color: #092B50;
          }

          /deep/.el-table--striped .el-table__body tr.el-table__row--striped td.el-table__cell {
            background-color: #092B50;
          }

          /deep/.el-checkbox__input.is-checked .el-checkbox__inner {
            background-color: #01EEFD;
            border-color: #01EEFD;
          }

          /deep/.el-table tbody tr:hover>td {
            background-color: transparent !important
          }

          // 改变边框颜色
          .el-table--border,
          .el-table--group {
            border: 2px solid #ddd !important;
          }

          /**
  改变表格内竖线颜色
   */
          .el-table--border td,
          .el-table--border th,
          .el-table__body-wrapper .el-table--border.is-scrolling-left~.el-table__fixed {
            border-right: 1px solid #ddd !important;
          }

          /deep/.el-table td {
            border-bottom: 1px solid #01EEFD;
          }

          /deep/.el-table thead tr th {
            border-color: transparent;
          }

          /deep/ .el-table--border::after,
          /deep/ .el-table--group::after,
          /deep/ .el-table::before {
            background-color: #073F73;
          }

          /deep/.el-table--border {
            border: 1px solid #073F73 !important;
          }

          /deep/.el-table .cell {
            text-align: center;
            color: #fff;
          }

          /deep/.el-checkbox__inner {
            background-color: transparent;
          }

          .flex {
            display: flex;
            justify-content: space-evenly;
            width: 100%;

            span {
              cursor: pointer;
            }
          }
        }
      }
    }
  }
}
</style>
