<!--
 * @Author: 小宝 zksukuo@yunkangda.com
 * @Date: 2024-02-26 08:27:43
 * @LastEditors: 小宝 zksukuo@yunkangda.com
 * @LastEditTime: 2024-02-26 10:56:24
 * @FilePath: \智慧公卫PC端旧项目\src\views\child-health-run\visit-record.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <div class="visit">
    <div class="visit-box">
      <div class="visit-title">
        <span>新生儿家庭访视记录表</span>
      </div>
      <div class="visit-head">
        <div class="visit-head-item">
          <span>姓名：</span>
        </div>
        <div class="visit-head-item">
          <span>编号：111-11111</span>
        </div>
      </div>
      <div class="visit-scroll">
        <table class="table" border>
          <tr>
            <td class="td center" colspan="1">性别</td>
            <td class="td center" colspan="3">
              <el-radio-group v-model="radio">
                <el-radio label="1">男</el-radio>
                <el-radio label="2">女</el-radio>
                <el-radio label="9">未说明的性别</el-radio>
                <el-radio label="0">未知的性别</el-radio>
              </el-radio-group>
            </td>
            <td class="td center" colspan="1">出生日期</td>
            <td class="td center" colspan="3">
              <el-date-picker class="date" v-model="date" type="date" placeholder="选择日期">
              </el-date-picker>
            </td>
          </tr>
          <tr>
            <td class="td center" colspan="1">身份证号</td>
            <td class="td" colspan="3">
              <el-input class="input"></el-input>
            </td>
            <td class="td center" colspan="1">家庭住址</td>
            <td class="td" colspan="3">
              <el-input class="input"></el-input>
            </td>
          </tr>
          <tr>
            <td class="td center" colspan="1">父亲</td>
            <td class="td td1" colspan="1">
              <span>姓名</span>
              <el-input class="input input1"></el-input>
            </td>
            <td class="td td1" colspan="2">
              <span>职业</span>
              <el-input class="input input1"></el-input>
            </td>
            <td class="td td1" colspan="2">
              <span>联系电话</span>
              <el-input class="input input2"></el-input>
            </td>
            <td class="td td1 center" colspan="2">
              <span>出生日期</span>
              <el-date-picker class="date" v-model="date" type="date" placeholder="选择日期">
              </el-date-picker>
            </td>
          </tr>
          <tr>
            <td class="td center" colspan="1">母亲</td>
            <td class="td td1" colspan="1">
              <span>姓名</span>
              <el-input class="input input1"></el-input>
            </td>
            <td class="td td1" colspan="2">
              <span>职业</span>
              <el-input class="input input1"></el-input>
            </td>
            <td class="td td1" colspan="2">
              <span>联系电话</span>
              <el-input class="input input2"></el-input>
            </td>
            <td class="td td1 center" colspan="2">
              <span>出生日期</span>
              <el-date-picker class="date" v-model="date" type="date" placeholder="选择日期">
              </el-date-picker>
            </td>
          </tr>
          <tr>
            <td class="td td1" colspan="2">
              <span>出生孕周</span>
              <el-input class="input input3"></el-input>
              <span>周</span>
            </td>
            <td class="td td1" colspan="6">
              <span>母亲妊娠期患病情况</span>
              <el-radio-group v-model="radio" class="margin-left">
                <el-radio label="1">无</el-radio>
                <el-radio label="2">糖尿病</el-radio>
                <el-radio label="3">妊娠期高血压</el-radio>
                <el-radio label="4">其他</el-radio>
              </el-radio-group>
            </td>
          </tr>
          <tr>
            <td class="td td1" colspan="3">
              <span>助产机构名称：</span>
              <el-input class="input input2"></el-input>
            </td>
            <td class="td td1" colspan="5">
              <span>出生情况</span>
              <el-radio-group v-model="radio" class="margin-left">
                <el-radio label="1">顺产</el-radio>
                <el-radio label="2">胎头吸引</el-radio>
                <el-radio label="3">产钳</el-radio>
                <el-radio label="4">剖宫</el-radio>
                <el-radio label="5">剖宫</el-radio>
                <el-radio label="6">剖宫</el-radio>
                <el-radio label="7">剖宫</el-radio>
              </el-radio-group>
            </td>
          </tr>
          <tr>
            <td class="td td1" colspan="3">
              <span>新生儿窒息</span>
              <el-radio-group v-model="radio" class="margin-left">
                <el-radio label="1">无</el-radio>
                <el-radio label="2">有</el-radio>
              </el-radio-group>
              <span>(Apgar评分：1min 5min 不详）</span>
            </td>
            <td class="td td1" colspan="5">
              <span>畸型</span>
              <el-radio-group v-model="radio" class="margin-left">
                <el-radio label="1">无</el-radio>
                <el-radio label="2">有</el-radio>
              </el-radio-group>
            </td>
          </tr>
          <tr>
            <td class="td td1" colspan="8">
              <span>新生儿听力筛查：</span>
              <el-radio-group v-model="radio" class="margin-left">
                <el-radio label="1">通过</el-radio>
                <el-radio label="2">未通过</el-radio>
                <el-radio label="3">未筛查</el-radio>
                <el-radio label="4">不详</el-radio>
              </el-radio-group>
            </td>
          </tr>
          <tr>
            <td class="td td1" colspan="8">
              <span>新生儿疾病筛查：</span>
              <el-radio-group v-model="radio" class="margin-left">
                <el-radio label="1">未进行</el-radio>
                <el-radio label="2">检查均阴性</el-radio>
                <el-radio label="3">甲低</el-radio>
                <el-radio label="4">苯丙酮尿症</el-radio>
                <el-radio label="5">其他遗传代谢病</el-radio>
              </el-radio-group>
            </td>
          </tr>
          <tr>
            <td class="td td1" colspan="3">
              <span>新生儿出生体重</span>
              <el-input class="input input3"></el-input>
              <span>kg</span>
            </td>
            <td class="td td1" colspan="2">
              <span>目前体重</span>
              <el-input class="input input3"></el-input>
              <span>kg</span>
            </td>
            <td class="td td1" colspan="3">
              <span>出生身长</span>
              <el-input class="input input3"></el-input>
              <span>cm</span>
            </td>
          </tr>
          <tr>
            <td class="td td1" colspan="3">
              <span>喂养方式</span>
              <el-radio-group v-model="radio" class="margin-left">
                <el-radio label="1">纯母乳</el-radio>
                <el-radio label="2">混合</el-radio>
                <el-radio label="3">人工</el-radio>
              </el-radio-group>
            </td>
            <td class="td td1" colspan="2">
              <span>吃奶量</span>
              <el-input class="input input3"></el-input>
              <span>mL/次</span>
            </td>
            <td class="td td1" colspan="3">
              <span>吃奶次数</span>
              <el-input class="input input3"></el-input>
              <span>次/日</span>
            </td>
          </tr>
          <tr>
            <td class="td td1" colspan="3">
              <span>呕吐</span>
              <el-radio-group v-model="radio" class="margin-left">
                <el-radio label="1">无</el-radio>
                <el-radio label="2">有</el-radio>
              </el-radio-group>
            </td>
            <td class="td td1" colspan="2">
              <span>大便</span>
              <el-radio-group v-model="radio" class="margin-left">
                <el-radio label="1">糊状</el-radio>
                <el-radio label="2">稀</el-radio>
                <el-radio label="3">其他</el-radio>
              </el-radio-group>
            </td>
            <td class="td td1" colspan="3">
              <span>大便次数</span>
              <el-input class="input input3"></el-input>
              <span>次/日</span>
            </td>
          </tr>
          <tr>
            <td class="td td1" colspan="3">
              <span>体温</span>
              <el-input class="input input3"></el-input>
              <span>℃</span>
            </td>
            <td class="td td1" colspan="2">
              <span>心率</span>
              <el-input class="input input3"></el-input>
              <span>次/分钟</span>
            </td>
            <td class="td td1" colspan="3">
              <span>呼吸频率</span>
              <el-input class="input input3"></el-input>
              <span>次/分钟</span>
            </td>
          </tr>
          <tr>
            <td class="td td1" colspan="4">
              <span>面色</span>
              <el-radio-group v-model="radio" class="margin-left">
                <el-radio label="1">红润</el-radio>
                <el-radio label="2">黄染</el-radio>
                <el-radio label="3">其他</el-radio>
              </el-radio-group>
            </td>
            <td class="td td1" colspan="4">
              <span>黄疸部位</span>
              <el-radio-group v-model="radio" class="margin-left">
                <el-radio label="1">无</el-radio>
                <el-radio label="2">面部</el-radio>
                <el-radio label="3">躯干</el-radio>
                <el-radio label="4">四肢</el-radio>
                <el-radio label="5">手足</el-radio>
              </el-radio-group>
            </td>
          </tr>
          <tr>
            <td class="td td1" colspan="8">
              <span>前囟</span>
              <el-input class="input input3"></el-input>
              <span>cm</span>
              <el-input class="input input3"></el-input>
              <span>cm</span>
              <el-radio-group v-model="radio" class="margin-left">
                <el-radio label="1">正常</el-radio>
                <el-radio label="2">膨隆</el-radio>
                <el-radio label="3">凹陷</el-radio>
                <el-radio label="4">其他</el-radio>
              </el-radio-group>
            </td>
          </tr>
          <tr>
            <td class="td td1" colspan="4">
              <span>眼睛</span>
              <el-radio-group v-model="radio" class="margin-left">
                <el-radio label="1">未见异常</el-radio>
                <el-radio label="2">异常</el-radio>
              </el-radio-group>
            </td>
            <td class="td td1" colspan="4">
              <span>四肢活动度</span>
              <el-radio-group v-model="radio" class="margin-left">
                <el-radio label="1">未见异常</el-radio>
                <el-radio label="2">异常</el-radio>
              </el-radio-group>
            </td>
          </tr>
          <tr>
            <td class="td td1" colspan="4">
              <span>耳外观</span>
              <el-radio-group v-model="radio" class="margin-left">
                <el-radio label="1">未见异常</el-radio>
                <el-radio label="2">异常</el-radio>
              </el-radio-group>
            </td>
            <td class="td td1" colspan="4">
              <span>颈部包块</span>
              <el-radio-group v-model="radio" class="margin-left">
                <el-radio label="1">无</el-radio>
                <el-radio label="2">有</el-radio>
              </el-radio-group>
            </td>
          </tr>
          <tr>
            <td class="td td1" colspan="4">
              <span>鼻</span>
              <el-radio-group v-model="radio" class="margin-left">
                <el-radio label="1">未见异常</el-radio>
                <el-radio label="2">异常</el-radio>
              </el-radio-group>
            </td>
            <td class="td td1" colspan="4">
              <span>皮肤</span>
              <el-radio-group v-model="radio" class="margin-left">
                <el-radio label="1">未见异常</el-radio>
                <el-radio label="2">湿疹</el-radio>
                <el-radio label="3">糜烂</el-radio>
                <el-radio label="4">其他</el-radio>
              </el-radio-group>
            </td>
          </tr>
          <tr>
            <td class="td td1" colspan="4">
              <span>口腔</span>
              <el-radio-group v-model="radio" class="margin-left">
                <el-radio label="1">未见异常</el-radio>
                <el-radio label="2">异常</el-radio>
              </el-radio-group>
            </td>
            <td class="td td1" colspan="4">
              <span>肛门</span>
              <el-radio-group v-model="radio" class="margin-left">
                <el-radio label="1">未见异常</el-radio>
                <el-radio label="2">异常</el-radio>
              </el-radio-group>
            </td>
          </tr>
          <tr>
            <td class="td td1" colspan="4">
              <span>心肺听诊</span>
              <el-radio-group v-model="radio" class="margin-left">
                <el-radio label="1">未见异常</el-radio>
                <el-radio label="2">异常</el-radio>
              </el-radio-group>
            </td>
            <td class="td td1" colspan="4">
              <span>胸部</span>
              <el-radio-group v-model="radio" class="margin-left">
                <el-radio label="1">未见异常</el-radio>
                <el-radio label="2">异常</el-radio>
              </el-radio-group>
            </td>
          </tr>
          <tr>
            <td class="td td1" colspan="4">
              <span>腹部触诊</span>
              <el-radio-group v-model="radio" class="margin-left">
                <el-radio label="1">未见异常</el-radio>
                <el-radio label="2">异常</el-radio>
              </el-radio-group>
            </td>
            <td class="td td1" colspan="4">
              <span>脊柱</span>
              <el-radio-group v-model="radio" class="margin-left">
                <el-radio label="1">未见异常</el-radio>
                <el-radio label="2">异常</el-radio>
              </el-radio-group>
            </td>
          </tr>
          <tr>
            <td class="td td1" colspan="4">
              <span>外生殖器</span>
              <el-radio-group v-model="radio" class="margin-left">
                <el-radio label="1">未见异常</el-radio>
                <el-radio label="2">异常</el-radio>
              </el-radio-group>
            </td>
            <td class="td td1" colspan="4"></td>
          </tr>
          <tr>
            <td class="td td1" colspan="8">
              <span>脐带</span>
              <el-radio-group v-model="radio" class="margin-left">
                <el-radio label="1">未脱</el-radio>
                <el-radio label="2">脱落</el-radio>
                <el-radio label="3">脐部有渗出</el-radio>
                <el-radio label="4">其他</el-radio>
              </el-radio-group>
            </td>
          </tr>
          <tr>
            <td class="td td1" colspan="8">
              <span>转诊建议</span>
              <el-radio-group v-model="radio" class="margin-left">
                <el-radio label="1">无</el-radio>
                <el-radio label="2">有</el-radio>
              </el-radio-group>
              <span class="margin-left">原因：</span>
              <el-input class="input input2"></el-input>
              <span class="margin-left">机构及科室：</span>
              <el-input class="input input2"></el-input>
            </td>
          </tr>
          <tr>
            <td class="td td1" colspan="8">
              <span>脐带</span>
              <el-radio-group v-model="radio" class="margin-left">
                <el-radio label="1">喂养指导</el-radio>
                <el-radio label="2">发育指导</el-radio>
                <el-radio label="3">发育指导</el-radio>
                <el-radio label="4">预防伤害指导</el-radio>
                <el-radio label="5">口腔保健指导</el-radio>
                <el-radio label="6">其他</el-radio>
              </el-radio-group>
            </td>
          </tr>
          <tr>
            <td class="td td1" colspan="4">
              <span>本次访视日期</span>
              <el-date-picker class="date" v-model="date" type="date" placeholder="选择日期">
              </el-date-picker>
            </td>
            <td class="td td1" colspan="4">
              <span>下次随访地点</span>
              <el-select v-model="value" placeholder="请选择">
                <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
                </el-option>
              </el-select>
            </td>
          </tr>
          <tr>
            <td class="td td1" colspan="4">
              <span>下次随访日期</span>
              <el-date-picker class="date" v-model="date" type="date" placeholder="选择日期">
              </el-date-picker>
            </td>
            <td class="td td1" colspan="4">
              <span>随访医生签名</span>
            </td>
          </tr>
        </table>
      </div>
      <div class="visit-button">
        <el-button @click="close">取消</el-button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      radio: null,
      date: '',
      options: [],
      value: ''
    }
  },
  methods: {
    close () {
      this.$emit('close')
    }
  }
}
</script>

<style lang="less" scoped>
.visit {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1111;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, .8);

  .visit-box {
    width: 70%;
    height: 90%;
    padding: 0 20px;
    border-radius: 10px;
    background-color: #fff;
    box-sizing: border-box;

    .visit-title {
      width: 100%;
      height: 50px;
      line-height: 50px;
      text-align: center;
      color: #000;
      font-size: 22px;
    }

    .visit-head {
      display: flex;
      align-items: center;
      width: 100%;
      height: 40px;

      .visit-head-item {
        width: 33%;
        color: #000;
        font-size: 18px;
      }
    }

    .visit-scroll {
      overflow-y: scroll;
      width: 100%;
      height: calc(100% - 150px);

      .table {
        width: 100%;
        margin-bottom: 20px;
        border-collapse: collapse;

        .td {
          padding: 10px;
          color: #000;
          font-size: 16px;
        }

        /deep/.el-radio__label {
          color: #000;
          font-size: 16px;
        }

        .center {
          text-align: center;
        }

        .el-input {
          width: 90%;
        }

        .input {
          /deep/.el-input__inner {
            border-left: none;
            border-top: none;
            border-right: none;
            border-radius: 0;
            margin: 0 !important;
            box-sizing: border-box;
          }
        }

        .date {
          width: 200px;
        }

        .input1 {
          width: 100px;
        }

        .input2 {
          width: 180px;
        }

        .input3 {
          width: 70px;
        }

        .margin-left {
          margin-left: 10px;
        }
      }
    }

    .visit-button {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 60px;
    }
  }
}
</style>
