import { render, staticRenderFns } from "./abnormal.vue?vue&type=template&id=56b1a8fe&scoped=true&"
import script from "./abnormal.vue?vue&type=script&lang=js&"
export * from "./abnormal.vue?vue&type=script&lang=js&"
import style0 from "./abnormal.vue?vue&type=style&index=0&id=56b1a8fe&prod&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/_vue-loader@15.10.1@vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "56b1a8fe",
  null
  
)

export default component.exports