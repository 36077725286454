<template>
  <div class="subPage">
    <div class="list">
      <div class="list-item">
        <div class="position position1"></div>
        <div class="position position2"></div>
        <div class="position position3"></div>
        <div class="position position4"></div>
        <div class="box flex">
          <img src="../../../assets/img/zhdp/old.png" class="old">
          <div class="info">
            <span>入住床位数量:</span>
            <span>46</span>
            <span class="span">本月新增：0</span>
          </div>
        </div>
      </div>

      <div class="list-item">
        <div class="position position1"></div>
        <div class="position position2"></div>
        <div class="position position3"></div>
        <div class="position position4"></div>
        <div class="box flex">
          <img src="../../../assets/img/zhdp/old.png" class="old">
          <div class="info">
            <span>长者数量:</span>
            <span>46</span>
            <span class="span">本月新增：0</span>
          </div>
        </div>
      </div>

      <div class="list-item">
        <div class="position position1"></div>
        <div class="position position2"></div>
        <div class="position position3"></div>
        <div class="position position4"></div>
        <div class="box flex">
          <img src="../../../assets/img/zhdp/old.png" class="old">
          <div class="info">
            <span>入住床位数量:</span>
            <span>46</span>
            <span class="span">本月新增：0</span>
          </div>
        </div>
      </div>

      <div class="list-item">
        <div class="position position1"></div>
        <div class="position position2"></div>
        <div class="position position3"></div>
        <div class="position position4"></div>
        <div class="box flex">
          <img src="../../../assets/img/zhdp/old.png" class="old">
          <div class="info">
            <span>服务工单数量:</span>
            <span>46</span>
            <span class="span">本月新增：0</span>
          </div>
        </div>
      </div>

      <div class="list-item">
        <div class="position position1"></div>
        <div class="position position2"></div>
        <div class="position position3"></div>
        <div class="position position4"></div>
        <div class="box flex">
          <img src="../../../assets/img/zhdp/old.png" class="old">
          <div class="info">
            <span>政府救助长者:</span>
            <span>46</span>
            <span class="span">本月新增：0</span>
          </div>
        </div>
      </div>

      <div class="list-item">
        <div class="position position1"></div>
        <div class="position position2"></div>
        <div class="position position3"></div>
        <div class="position position4"></div>
        <div class="box flex">
          <img src="../../../assets/img/zhdp/old.png" class="old">
          <div class="info">
            <span>服务人员数量:</span>
            <span>46</span>
            <span class="span">本月新增：0</span>
          </div>
        </div>
      </div>

    </div>

    <div class="list">
      <div class="list-item list-item1">
        <div class="position position1"></div>
        <div class="position position2"></div>
        <div class="position position3"></div>
        <div class="position position4"></div>
        <div class="box">
          <div class="echarts" id="echarts">

          </div>
          <div class="echarts-title">
            <div class="icon">
              <div class="white"></div>
            </div>
            <div class="text">
              <span>评估长者</span>
            </div>
          </div>
        </div>
      </div>

      <div class="list-item list-item1">
        <div class="position position1"></div>
        <div class="position position2"></div>
        <div class="position position3"></div>
        <div class="position position4"></div>
        <div class="box">
          <div class="echarts" id="echarts1">

          </div>
          <div class="echarts-title">
            <div class="icon">
              <div class="white"></div>
            </div>
            <div class="text">
              <span>长者居住情况</span>
            </div>
          </div>
        </div>
      </div>

      <div class="list-item list-item1">
        <div class="position position1"></div>
        <div class="position position2"></div>
        <div class="position position3"></div>
        <div class="position position4"></div>
        <div class="box">
          <div class="echarts" id="echarts2">

          </div>
          <div class="echarts-title">
            <div class="icon">
              <div class="white"></div>
            </div>
            <div class="text">
              <span>养老方式</span>
            </div>
          </div>
        </div>
      </div>

      <div class="list-item list-item1">
        <div class="position position1"></div>
        <div class="position position2"></div>
        <div class="position position3"></div>
        <div class="position position4"></div>
        <div class="box">
          <div class="echarts" id="echarts3">

          </div>
          <div class="echarts-title">
            <div class="icon">
              <div class="white"></div>
            </div>
            <div class="text">
              <span>政府救助长者</span>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="list list1">
      <div class="list-left">
        <div class="list-left-line">
          <div class="list-item list-item1 list-item4">
          <div class="position position1"></div>
          <div class="position position2"></div>
          <div class="position position3"></div>
          <div class="position position4"></div>
          <div class="box">
            <div class="echarts echarts1" id="echarts4">

            </div>
            <div class="echarts-title echarts-title1">
              <div class="icon">
                <div class="white"></div>
              </div>
              <div class="text">
                <span>长者年龄分布</span>
              </div>
            </div>
          </div>
        </div>

        <div class="list-item list-item1 list-item4">
          <div class="position position1"></div>
          <div class="position position2"></div>
          <div class="position position3"></div>
          <div class="position position4"></div>
          <div class="box">
            <div class="echarts echarts1" id="echarts5">

            </div>
            <div class="echarts-title echarts-title1">
              <div class="icon">
                <div class="white"></div>
              </div>
              <div class="text">
                <span>服务长者数量8人次</span>
              </div>
            </div>
          </div>
        </div>
        </div>

        <div class="list-left-line">
          <div class="list-item list-item1 list-item4">
          <div class="position position1"></div>
          <div class="position position2"></div>
          <div class="position position3"></div>
          <div class="position position4"></div>
          <div class="box">
            <div class="echarts echarts1" id="echarts6">

            </div>
            <div class="echarts-title echarts-title1">
              <div class="icon">
                <div class="white"></div>
              </div>
              <div class="text">
                <span>服务项目排名</span>
              </div>
            </div>
          </div>
        </div>

        <div class="list-item list-item1 list-item4">
          <div class="position position1"></div>
          <div class="position position2"></div>
          <div class="position position3"></div>
          <div class="position position4"></div>
          <div class="box" >
            <div class="echarts echarts1 echarts2" id="echarts7">

            </div>
            <div class="echarts-title">
              <div class="icon">
                <div class="white"></div>
              </div>
              <div class="text">
                <span>养老服务能力</span>
              </div>
            </div>
          </div>
        </div>
        </div>

      </div>
      <div class="list-item list-item3">
        <div class="position position1"></div>
        <div class="position position2"></div>
        <div class="position position3"></div>
        <div class="position position4"></div>
        <div class="box box1">
          <div class="echarts-title echarts-title1 echarts-title2">
            <div class="icon">
              <div class="white"></div>
            </div>
            <div class="text">
              <span>长者安全监护处理完成率</span>
            </div>
          </div>
          <div class="box-item">
            <div class="box-item-echarts">
              <div class="echarts" id="echarts8"></div>
            </div>
            <div class="box-item-title">
              <span>SOS定位报警</span>
            </div>
          </div>
          <div class="box-item">
            <div class="box-item-echarts">
              <div class="echarts" id="echarts9"></div>
            </div>
            <div class="box-item-title">
              <span>电子围栏报警报警</span>
            </div>
          </div>
          <div class="box-item">
            <div class="box-item-echarts">
              <div class="echarts" id="echarts10"></div>
            </div>
            <div class="box-item-title">
              <span>跌倒报警</span>
            </div>
          </div>
          <div class="box-item">
            <div class="box-item-echarts">
              <div class="echarts" id="echarts11"></div>
            </div>
            <div class="box-item-title">
              <span>SOS安防报警</span>
            </div>
          </div>

          <div class="box-item">
            <div class="box-item-echarts">
              <div class="echarts" id="echarts12"></div>
            </div>
            <div class="box-item-title">
              <span>烟雾报警</span>
            </div>
          </div>
          <div class="box-item">
            <div class="box-item-echarts">
              <div class="echarts" id="echarts13"></div>
            </div>
            <div class="box-item-title">
              <span>燃气泄漏报警</span>
            </div>
          </div>
          <div class="box-item">
            <div class="box-item-echarts">
              <div class="echarts" id="echarts14"></div>
            </div>
            <div class="box-item-title">
              <span>水浸报警</span>
            </div>
          </div>
          <div class="box-item">
            <div class="box-item-echarts">
              <div class="echarts" id="echarts15"></div>
            </div>
            <div class="box-item-title">
              <span>门磁报警</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      myChart: null,
      myChart1: null,
      myChart2: null,
      myChart3: null,

      myChart4: null,
      myChart5: null,
      myChart6: null,
      myChart7: null,

      myChart8: null,
      myChart9: null,
      myChart10: null,
      myChart11: null,

      myChart12: null,
      myChart13: null,
      myChart14: null,
      myChart15: null
    }
  },
  mounted () {
    this.$nextTick(() => {
      this.canvas()
      this.canvas1()
      this.canvas2()
      this.canvas3()
      this.canvas4()
      this.canvas5()
      this.canvas6()
      this.canvas7()
      this.canvas8()
      this.canvas9()
      this.canvas10()
      this.canvas11()
      this.canvas12()
      this.canvas13()
      this.canvas14()
      this.canvas15()
    })
    window.addEventListener('resize', this.getresize)
  },
  beforeDestroy () {
    // 页面销毁时销毁监听事件
    window.removeEventListener('resize', this.getresize)
  },
  methods: {
    getresize () {
      setTimeout(() => {
        this.myChart.resize()
        this.myChart1.resize()
        this.myChart2.resize()
        this.myChart3.resize()
        this.myChart4.resize()
        this.myChart5.resize()
        this.myChart6.resize()
        this.myChart7.resize()
        this.myChart8.resize()
        this.myChart9.resize()
        this.myChart10.resize()
        this.myChart11.resize()
        this.myChart12.resize()
        this.myChart13.resize()
        this.myChart14.resize()
        this.myChart15.resize()
      }, 500)
    },
    canvas () {
      var chartDom = document.getElementById('echarts')
      this.myChart = this.$echarts.init(chartDom)
      const option = {
        title: {
          // text: 'Referer of a Website',
          // subtext: 'Fake Data',
          // left: 'center'
        },
        tooltip: {
          trigger: 'item'
        },
        legend: {
          // orient: 'right',
          // right: 'right',
          orient: 'vertical',
          x: 'right',
          y: 'center',
          textStyle: {
            fontSize: 12,
            color: '#FFFFFF'
          }
          // padding: [0, 37, 13, 0]
        },
        series: [
          {
            name: '评估长者',
            type: 'pie',
            radius: '50%',
            data: [
              { value: 1048, name: '能力完好1' },
              { value: 735, name: '能力完好2' },
              { value: 580, name: '能力完好3' },
              { value: 484, name: '能力完好4' },
              { value: 300, name: '能力完好5' }
            ],
            center: ['30%', '60%'],
            label: { // 删除指示线
              show: false
            }
          }
        ]
      }

      option && this.myChart.setOption(option)
    },
    canvas1 () {
      var chartDom = document.getElementById('echarts1')
      this.myChart1 = this.$echarts.init(chartDom)
      const option = {
        title: {
          // text: 'Referer of a Website',
          // subtext: 'Fake Data',
          // left: 'center'
        },
        tooltip: {
          trigger: 'item'
        },
        legend: {
          // orient: 'right',
          // right: 'right',
          orient: 'vertical',
          x: 'right',
          y: 'center',
          textStyle: {
            fontSize: 12,
            color: '#FFFFFF'
          }
          // padding: [0, 37, 13, 0]
        },
        series: [
          {
            name: '评估长者',
            type: 'pie',
            radius: '50%',
            data: [
              { value: 1048, name: '机构' },
              { value: 735, name: '空巢' },
              { value: 580, name: '孤寡' },
              { value: 484, name: '与子女同住' },
              { value: 300, name: '独居' },
              { value: 300, name: '与配偶同住' }
            ],
            center: ['30%', '60%'],
            label: { // 删除指示线
              show: false
            }
          }
        ]
      }

      option && this.myChart1.setOption(option)
    },
    canvas2 () {
      var chartDom = document.getElementById('echarts2')
      this.myChart2 = this.$echarts.init(chartDom)
      const option = {
        title: {
          // text: 'Referer of a Website',
          // subtext: 'Fake Data',
          // left: 'center'
        },
        tooltip: {
          trigger: 'item'
        },
        legend: {
          // orient: 'right',
          // right: 'right',
          orient: 'vertical',
          x: 'right',
          y: 'center',
          textStyle: {
            fontSize: 12,
            color: '#FFFFFF'
          }
          // padding: [0, 37, 13, 0]
        },
        series: [
          {
            name: '评估长者',
            type: 'pie',
            radius: '50%',
            data: [
              { value: 1048, name: '居家' },
              { value: 735, name: '机构' },
              { value: 580, name: '社区' }
            ],
            center: ['30%', '60%'],
            label: { // 删除指示线
              show: false
            }
          }
        ]
      }

      option && this.myChart2.setOption(option)
    },
    canvas3 () {
      var chartDom = document.getElementById('echarts3')
      this.myChart3 = this.$echarts.init(chartDom)
      const option = {
        title: {
          // text: 'Referer of a Website',
          // subtext: 'Fake Data',
          // left: 'center'
        },
        tooltip: {
          trigger: 'item'
        },
        legend: {
          // orient: 'right',
          // right: 'right',
          orient: 'vertical',
          x: 'right',
          y: 'center',
          textStyle: {
            fontSize: 12,
            color: '#FFFFFF'
          }
          // padding: [0, 37, 13, 0]
        },
        series: [
          {
            name: '评估长者',
            type: 'pie',
            radius: '50%',
            data: [
              { value: 1048, name: '享受定期抚恤辅助养老' },
              { value: 735, name: '城乡最低生活保障老人' },
              { value: 580, name: '农村 "五保"老人' },
              { value: 484, name: '与子女同住' },
              { value: 300, name: '失独老年人' },
              { value: 300, name: '城镇“三无”人员' }
            ],
            center: ['30%', '60%'], // 饼图左右上下位置    靠这里
            label: { // 删除指示线
              show: false
            }
          }
        ]
      }

      option && this.myChart3.setOption(option)
    },
    canvas4 () {
      var chartDom = document.getElementById('echarts4')
      this.myChart4 = this.$echarts.init(chartDom)
      const option = {
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'shadow'
          }
        },
        legend: {
          // orient: 'right',
          // right: 'right',
          // orient: 'vertical',
          x: 'right',
          y: 'top',
          textStyle: {
            fontSize: 12,
            color: '#FFFFFF'
          }
          // padding: [25, 34, 0, 0]
        },
        grid: {
          show: false,
          top: '32%', // 一下数值可为百分比也可为具体像素值
          right: '10%',
          bottom: '20%',
          left: '14%'
        },
        dataZoom: [
          {
            xAxisIndex: 0, // 这里是从X轴的0刻度开始
            disabled: false,
            zoomLock: true,
            preventDefaultMouseMove: false,
            filterMode: 'empty',
            type: 'inside',
            startValue: 0, // 从头开始。
            endValue: 3 // 一次性展示6个。
          }

        ],
        xAxis: {
          type: 'category',
          data: ['60岁以下', '60-69', '70-79', '80-89', '90-99', '100及以上'],
          axisLabel: {
            show: true,
            color: '#fff',
            fontSize: 13,
            interval: 0
            // rotate: -20
          },
          axisLine: {
            show: true,
            lineStyle: {
              color: ['#fff']
            },
            textStyle: {
              color: '#04B4D0',
              fontSize: 13
            }
          },
          axisTick: {
            // y轴刻度线
            show: false
          }
        },
        yAxis: {
          type: 'value',
          axisLine: {
            show: true,
            lineStyle: {
              color: ['#fff']
            }
          },
          axisLabel: {
            show: true,
            color: '#fff',
            fontSize: 14
          },
          splitLine: {
            lineStyle: {
              color: '#fff'
            }
          }
        },
        series: [
          {
            name: '男',
            data: [120, 200, 150, 80, 70, 110],
            type: 'bar',
            barWidth: '20%'
          },
          {
            name: '女',
            data: [120, 200, 150, 80, 70, 110],
            type: 'bar',
            barWidth: '20%'
          }
        ]
      }

      option && this.myChart4.setOption(option)
    },
    canvas5 () {
      var chartDom = document.getElementById('echarts5')
      this.myChart5 = this.$echarts.init(chartDom)
      const option = {
        grid: {
          show: false,
          top: '30%',
          right: '5%',
          bottom: '20%',
          left: '14%'
        },
        dataZoom: [
          {
            xAxisIndex: 0, // 这里是从X轴的0刻度开始
            disabled: false,
            zoomLock: true,
            preventDefaultMouseMove: false,
            filterMode: 'empty',
            type: 'inside',
            startValue: 0, // 从头开始。
            endValue: 6 // 一次性展示6个。
          }

        ],
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'shadow'
          }
        },
        legend: {
          orient: 'vertical',
          x: 'right',
          y: 'top',
          textStyle: {
            fontSize: 12,
            color: '#FFFFFF'
          }
          // padding: [12, 21, 0, 0]
        },
        xAxis: {
          type: 'category',
          data: ['6月', '7月', '8月', '9月', '10月', '11月', '12月', '1月', '2月', '3月', '4月', '5月'],
          axisTick: {
            // y轴刻度线
            show: false
          },
          axisLabel: {
            show: true,
            color: '#fff',
            fontSize: 13
          },
          axisLine: {
            show: true,
            lineStyle: {
              color: ['#04B4D0']
            },
            textStyle: {
              color: '#04B4D0',
              fontSize: 13
            }
          }
        },
        yAxis: {
          type: 'value',
          axisLabel: {
            show: true,
            color: '#fff',
            fontSize: 13
          },
          axisLine: {
            show: true,
            lineStyle: {
              color: ['#04B4D0']
            }
          }
        },
        series: [
          {
            name: '服务长者数量',
            data: [820, 932, 901, 934, 1290, 1330, 1320],
            type: 'line',
            smooth: true
          },
          {
            name: '服务长者次数',
            data: [720, 832, 901, 834, 1290, 330, 320],
            type: 'line',
            smooth: true
          }
        ]
      }

      option && this.myChart5.setOption(option)
    },
    canvas6 () {
      var chartDom = document.getElementById('echarts6')
      this.myChart6 = this.$echarts.init(chartDom)
      const option = {
        grid: {
          containLabel: true,
          top: '30%', // 一下数值可为百分比也可为具体像素值
          right: '14%',
          bottom: '0%',
          left: '8%'
        },
        label: {
          show: true,
          color: '#fff',
          fontSize: 14,
          position: 'right',
          height: 100,
          offset: [0, 0]
        },
        xAxis: [
          // x轴数据设置
          {
            type: 'value',
            axisLabel: {
              formatter: '{value} '
            },
            splitLine: {
              show: false
            },
            axisLine: {
              show: false,
              // 这是y轴文字颜色
              lineStyle: {
                color: '#021B63'
              }
            }
          }
        ],
        yAxis: [
          {
            type: 'category',
            offset: 0,
            axisLine: {
              show: false,
              // 这是x轴文字颜色
              lineStyle: {
                color: '#fff'
              }
            },
            data: ['助浴', '助医', '助急', '助洁', '助餐'],
            axisPointer: {
              type: 'shadow'
            },
            axisTick: {
              show: false
            }
          }
        ],
        series: [
          {
            data: [120, 200, 150, 80, 70],
            type: 'bar',
            barWidth: 20,
            label: {
              show: true
            },
            itemStyle: {
              color: new this.$echarts.graphic.LinearGradient(1, 0, 0, 0, [{
                // 四个数字分别对应 数组中颜色的开始位置，分别为 右，下，左，上。例如（1,0,0,0 ）代表从右边开始渐
                // 变。offset取值为0~1，0代表开始时的颜色，1代表结束时的颜色，柱子表现为这两种颜色的渐变。
                offset: 0,
                color: '#0569B7'
              }, {
                offset: 1,
                color: '#053E6C'
              }]),
              borderRadius: [10, 10, 10, 10]
            }
          }
        ]
      }

      option && this.myChart6.setOption(option)
    },
    canvas7 () {
      var chartDom = document.getElementById('echarts7')
      this.myChart7 = this.$echarts.init(chartDom)
      const option = {
        legend: {
          data: ['2021', '2022', '2023'],
          orient: 'vertical',
          x: 'right',
          y: 'bottom',
          textStyle: {
            fontSize: 12,
            color: '#FFFFFF'
          }
          // padding: [0, 37, 13, 0]
        },
        // grid: {
        //   top: '0%', // 一下数值可为百分比也可为具体像素值
        //   right: '14%',
        //   bottom: '10%',
        //   left: '8%'
        // },
        radar: {
          center: [140, 105],
          indicator: [
            { name: '助餐' },
            { name: '助洁' },
            { name: '助急' },
            { name: '助医' },
            { name: '助浴' }
          ],
          axisLine: {
            lineStyle: {
              color: '#fff'
            }
          },
          splitLine: {
            lineStyle: {
              color: 'rgba(250,250,250,.1)'
            }
          },
          splitArea: {
            areaStyle: {
              color: ['rgba(250,250,250,.1)', 'rgba(250,250,250,.2)']
            }
          }
        },
        series: [
          {
            name: 'Budget vs spending',
            type: 'radar',
            data: [
              {
                value: [4200, 3000, 20000, 35000, 50000, 18000],
                name: '2021',
                areaStyle: {
                  color: 'rgba(11, 62, 233, .4)'
                }
              },
              {
                value: [5000, 10000, 20000, 20000, 4200, 21000],
                name: '2022',
                areaStyle: {
                  color: 'rgba(45, 198, 0, .1)'
                }
              },
              {
                value: [5000, 14000, 28000, 26000, 42000, 21000],
                name: '2023',
                areaStyle: {
                  color: 'rgba(250, 205, 103, .1)'
                }
              }
            ]
          }
        ]
      }

      option && this.myChart7.setOption(option)
    },
    canvas8 () {
      var chartDom = document.getElementById('echarts8')
      this.myChart8 = this.$echarts.init(chartDom)
      const option = {
        color: ['#5470C6'],
        series: [
          {
            type: 'gauge',
            startAngle: 90,
            endAngle: -270,
            pointer: {
              show: false
            },
            progress: {
              show: true,
              overlap: false,
              roundCap: true,
              clip: false
            },
            axisLine: {
              lineStyle: {
                width: 15,
                color: [
                  [1, '#929FC6']
                ]
              }
            },
            splitLine: {
              show: false,
              distance: 0,
              length: 10
            },
            axisTick: {
              show: false
            },
            axisLabel: {
              show: false,
              distance: 50
            },
            data: [
              {
                value: 100,
                detail: {
                  valueAnimation: true,
                  offsetCenter: ['0%', '0%']
                }
              }
            ],
            detail: {
              fontSize: 12,
              color: 'rgba(24, 205, 253, 1)',
              fontWeight: '400',
              formatter: '{value}%'
            }
          }
        ]
      }

      option && this.myChart8.setOption(option)
    },
    canvas9 () {
      var chartDom = document.getElementById('echarts9')
      this.myChart9 = this.$echarts.init(chartDom)
      const option = {
        color: ['#91CC75'],
        series: [
          {
            type: 'gauge',
            startAngle: 90,
            endAngle: -270,
            pointer: {
              show: false
            },
            progress: {
              show: true,
              overlap: false,
              roundCap: true,
              clip: false
            },
            axisLine: {
              lineStyle: {
                width: 15,
                color: [
                  [1, '#929FC6']
                ]
              }
            },
            splitLine: {
              show: false,
              distance: 0,
              length: 10
            },
            axisTick: {
              show: false
            },
            axisLabel: {
              show: false,
              distance: 50
            },
            data: [
              {
                value: 83,
                detail: {
                  valueAnimation: true,
                  offsetCenter: ['0%', '0%']
                }
              }
            ],
            detail: {
              fontSize: 12,
              color: 'rgba(24, 205, 253, 1)',
              fontWeight: '400',
              formatter: '{value}%'
            }
          }
        ]
      }

      option && this.myChart9.setOption(option)
    },
    canvas10 () {
      var chartDom = document.getElementById('echarts10')
      this.myChart10 = this.$echarts.init(chartDom)
      const option = {
        color: ['#5470C6'],
        series: [
          {
            type: 'gauge',
            startAngle: 90,
            endAngle: -270,
            pointer: {
              show: false
            },
            progress: {
              show: true,
              overlap: false,
              roundCap: true,
              clip: false
            },
            axisLine: {
              lineStyle: {
                width: 15,
                color: [
                  [1, '#929FC6']
                ]
              }
            },
            splitLine: {
              show: false,
              distance: 0,
              length: 10
            },
            axisTick: {
              show: false
            },
            axisLabel: {
              show: false,
              distance: 50
            },
            data: [
              {
                value: 0,
                detail: {
                  valueAnimation: true,
                  offsetCenter: ['0%', '0%']
                }
              }
            ],
            detail: {
              fontSize: 12,
              color: 'rgba(24, 205, 253, 1)',
              fontWeight: '400',
              formatter: '{value}%'
            }
          }
        ]
      }

      option && this.myChart10.setOption(option)
    },
    canvas11 () {
      var chartDom = document.getElementById('echarts11')
      this.myChart11 = this.$echarts.init(chartDom)
      const option = {
        color: ['#EE6666'],
        series: [
          {
            type: 'gauge',
            startAngle: 90,
            endAngle: -270,
            pointer: {
              show: false
            },
            progress: {
              show: true,
              overlap: false,
              roundCap: true,
              clip: false
            },
            axisLine: {
              lineStyle: {
                width: 15,
                color: [
                  [1, '#929FC6']
                ]
              }
            },
            splitLine: {
              show: false,
              distance: 0,
              length: 10
            },
            axisTick: {
              show: false
            },
            axisLabel: {
              show: false,
              distance: 50
            },
            data: [
              {
                value: 100,
                detail: {
                  valueAnimation: true,
                  offsetCenter: ['0%', '0%']
                }
              }
            ],
            detail: {
              fontSize: 12,
              color: 'rgba(24, 205, 253, 1)',
              fontWeight: '400',
              formatter: '{value}%'
            }
          }
        ]
      }

      option && this.myChart11.setOption(option)
    },
    canvas12 () {
      var chartDom = document.getElementById('echarts12')
      this.myChart12 = this.$echarts.init(chartDom)
      const option = {
        color: ['#73C0DE'],
        series: [
          {
            type: 'gauge',
            startAngle: 90,
            endAngle: -270,
            pointer: {
              show: false
            },
            progress: {
              show: true,
              overlap: false,
              roundCap: true,
              clip: false
            },
            axisLine: {
              lineStyle: {
                width: 15,
                color: [
                  [1, '#929FC6']
                ]
              }
            },
            splitLine: {
              show: false,
              distance: 0,
              length: 10
            },
            axisTick: {
              show: false
            },
            axisLabel: {
              show: false,
              distance: 50
            },
            data: [
              {
                value: 100,
                detail: {
                  valueAnimation: true,
                  offsetCenter: ['0%', '0%']
                }
              }
            ],
            detail: {
              fontSize: 12,
              color: 'rgba(24, 205, 253, 1)',
              fontWeight: '400',
              formatter: '{value}%'
            }
          }
        ]
      }

      option && this.myChart12.setOption(option)
    },
    canvas13 () {
      var chartDom = document.getElementById('echarts13')
      this.myChart13 = this.$echarts.init(chartDom)
      const option = {
        color: ['#5470C6'],
        series: [
          {
            type: 'gauge',
            startAngle: 90,
            endAngle: -270,
            pointer: {
              show: false
            },
            progress: {
              show: true,
              overlap: false,
              roundCap: true,
              clip: false
            },
            axisLine: {
              lineStyle: {
                width: 15,
                color: [
                  [1, '#929FC6']
                ]
              }
            },
            splitLine: {
              show: false,
              distance: 0,
              length: 10
            },
            axisTick: {
              show: false
            },
            axisLabel: {
              show: false,
              distance: 50
            },
            data: [
              {
                value: 0,
                detail: {
                  valueAnimation: true,
                  offsetCenter: ['0%', '0%']
                }
              }
            ],
            detail: {
              fontSize: 12,
              color: 'rgba(24, 205, 253, 1)',
              fontWeight: '400',
              formatter: '{value}%'
            }
          }
        ]
      }

      option && this.myChart13.setOption(option)
    },
    canvas14 () {
      var chartDom = document.getElementById('echarts14')
      this.myChart14 = this.$echarts.init(chartDom)
      const option = {
        color: ['#5470C6'],
        series: [
          {
            type: 'gauge',
            startAngle: 90,
            endAngle: -270,
            pointer: {
              show: false
            },
            progress: {
              show: true,
              overlap: false,
              roundCap: true,
              clip: false
            },
            axisLine: {
              lineStyle: {
                width: 15,
                color: [
                  [1, '#929FC6']
                ]
              }
            },
            splitLine: {
              show: false,
              distance: 0,
              length: 10
            },
            axisTick: {
              show: false
            },
            axisLabel: {
              show: false,
              distance: 50
            },
            data: [
              {
                value: 0,
                detail: {
                  valueAnimation: true,
                  offsetCenter: ['0%', '0%']
                }
              }
            ],
            detail: {
              fontSize: 12,
              color: 'rgba(24, 205, 253, 1)',
              fontWeight: '400',
              formatter: '{value}%'
            }
          }
        ]
      }

      option && this.myChart14.setOption(option)
    },
    canvas15 () {
      var chartDom = document.getElementById('echarts15')
      this.myChart15 = this.$echarts.init(chartDom)
      const option = {
        color: ['#5470C6'],
        series: [
          {
            type: 'gauge',
            startAngle: 90,
            endAngle: -270,
            pointer: {
              show: false
            },
            progress: {
              show: true,
              overlap: false,
              roundCap: true,
              clip: false
            },
            axisLine: {
              lineStyle: {
                width: 15,
                color: [
                  [1, '#929FC6']
                ]
              }
            },
            splitLine: {
              show: false,
              distance: 0,
              length: 10
            },
            axisTick: {
              show: false
            },
            axisLabel: {
              show: false,
              distance: 50
            },
            data: [
              {
                value: 0,
                detail: {
                  valueAnimation: true,
                  offsetCenter: ['0%', '0%']
                }
              }
            ],
            detail: {
              fontSize: 12,
              color: 'rgba(24, 205, 253, 1)',
              fontWeight: '400',
              formatter: '{value}%'
            }
          }
        ]
      }

      option && this.myChart15.setOption(option)
    },
    back () {
      this.$router.go(-1)
    },
    tabClick (index) {
      this.tabIndex = index
    }
  }
}
</script>

<style lang="less" scoped>
.subPage {
  width: 100%;
  height: 100%;

  .list {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 4px;

    .list-left {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      width: calc(50% - 8px);
      height: 100%;
    }

    .list-left-line {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      height: calc(50% - 2px);
    }

    .list-item {
      position: relative;
      // width: 306px;
      width: 16%;
      height: 102px;
      background-color: #021B63;

      .position {
        position: absolute;
        z-index: 1;
        width: 28px;
        height: 25px;
        background-color: #18CDFD;
      }

      .position1 {
        top: 0;
        left: 0;
      }

      .position2 {
        top: 0;
        right: 0;
      }

      .position3 {
        bottom: 0;
        left: 0;
      }

      .position4 {
        bottom: 0;
        right: 0;
      }

      .box {
        position: absolute;
        left: 1px;
        top: 1px;
        z-index: 2;
        width: calc(100% - 2px);
        height: calc(100% - 2px);
        background-color: #021B63;

        .box-item {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: space-between;
          width: 25%;
          height: 180px;

          .box-item-echarts {
            width: 180px;
            height: 180px;
          }

          .box-item-title {
            color: #fff;
            font-size: 16px;
          }
        }
      }

      .box1 {
        display: flex;
        flex-wrap: wrap;
        padding: 0 10px;
        padding-top: 70px;
        box-sizing: border-box;
      }

      .flex {
        display: flex;
        align-items: center;
        padding: 13px 40px;
        box-sizing: border-box;

        .old {
          width: 30px;
          height: 34px;
        }

        .info {
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          margin-left: 35px;
          height: 100%;
          color: #fff;
          font-size: 18px;
        }
      }

      .echarts {
        width: 100%;
        height: 100%;
      }

      .echarts1 {
        width: 100%;
        height: calc(100% - 60px);
        margin-top: 60px;
      }

      .echarts2 {
        width: 100%;
        height: calc(100% - 40px);
        margin-top: 40px;
      }

      .echarts-title {
        position: absolute;
        top: 2px;
        left: 0;
        display: flex;
        align-items: center;
        height: 48px;
        padding: 0 20px;
        border: 1px solid #074392;
        border-radius: 6px;
        box-sizing: border-box;

        .icon {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 18px;
          height: 18px;
          border-radius: 50%;
          background-color: #fff;

          .white {
            width: 16px;
            height: 16px;
            border: 1px solid #074392;
            border-radius: 50%;
            box-sizing: border-box;
          }
        }

        .text {
          margin-left: 10px;
          color: #fff;
          font-size: 18px;
        }
      }

      .echarts-title1 {
        top: 0;
        left: 34px;
        max-width: 240px;
      }

      .echarts-title2 {
        max-width: 300px;
      }

    }

    .list-item1 {
      // width: 465px;
      width: calc(25% - 13px);
      height: 240px;
    }

    .list-item2 {
      margin-top: 4px;
    }

    .list-item3 {
      width: calc(50% - 8px);
      height: 100%;
    }

    .list-item4 {
      width: calc(50% - 9px);
      height: 100%;
    }

  }

  .list1 {
    height: calc(100% - 350px);
  }
}
</style>
