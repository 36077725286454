<template>
  <div class="update">
    <div class="update-content">
      <div class="update-title">
        <span>编辑</span>
      </div>
      <div class="update-box">
        <div class="content">
          <div class="close" @click="close">
            <i class="el-icon-close"></i>
          </div>
          <div class="box">
            <div class="item">
              <span>姓名：</span>
              <el-input placeholder="请输入"></el-input>
            </div>
            <div class="item">
              <span>年龄：</span>
              <el-input placeholder="请输入"></el-input>
            </div>
            <div class="item">
              <span>性别：</span>
              <el-select v-model="value" placeholder="请选择">
                <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
                </el-option>
              </el-select>
            </div>
            <div class="item">
              <span>身份证号：</span>
              <el-input placeholder="请输入"></el-input>
            </div>
            <div class="item">
              <span>异常级别：</span>
              <el-select v-model="value" placeholder="请选择">
                <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
                </el-option>
              </el-select>
            </div>
            <div class="item">
              <span>体检日期：</span>
              <el-date-picker v-model="value1" type="date" placeholder="选择日期">
              </el-date-picker>
            </div>
            <div class="item">
              <span>乡镇/社区：</span>
              <el-input placeholder="请输入"></el-input>
            </div>
            <div class="item">
              <span>村/街道：</span>
              <el-input placeholder="请输入"></el-input>
            </div>
          </div>
          <el-button>确认</el-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
export default {
  props: {
    statusName: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      tableData: [],
      options: [{
        value: '1',
        label: '男'
      }, {
        value: '2',
        label: '女'
      }],
      value: '',
      value1: ''
    }
  },
  computed: {
    ...mapState(['loginInfo'])
  },
  mounted () { },
  methods: {
    close (a) {
      this.$emit('close', a)
    }
  }
}
</script>

<style scoped lang="less">
.update {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 9999;
  background-color: rgba(0, 0, 0, .9);

  .update-content {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 880px;
    height: 560px;

    .update-title {
      width: 342px;
      height: 54px;
      line-height: 68px;
      margin-bottom: 14px;
      background-image: url('../../zhyl/img/dialogTitleBg.png');
      background-size: 100% 100%;
      background-repeat: no-repeat;
      text-align: center;
      color: #fff;
      font-size: 20px;
    }

    .update-box {
      width: 100%;
      height: 500px;
      padding: 10px;
      background-color: rgba(3, 27, 44, .8);
      border-radius: 10px;
      border: 4px solid rgba(1, 49, 88, .5);
      box-sizing: border-box;

      .content {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        position: relative;
        width: 100%;
        height: 100%;
        padding: 30px;
        border-radius: 10px;
        border: 4px solid rgba(1, 49, 88, .5);
        box-sizing: border-box;

        .close {
          display: flex;
          align-items: flex-end;
          position: absolute;
          top: 0;
          right: 0;
          width: 50px;
          height: 50px;
          background: linear-gradient(26.565deg, #2057a7 50%, transparent 0);
          transform: rotate(180deg);
          color: #fff;
          font-size: 24px;
          cursor: pointer;
        }

        .box {
          display: flex;
          flex-wrap: wrap;
          width: 100%;
          height: calc(100% - 100px);

          .item {
            display: flex;
            align-items: center;
            width: 50%;
            color: #01EEFD;
            font-size: 18px;

            .el-input, .el-select {
              width: 180px;
            }
          }
        }

        .el-button {
          width: 138px;
          height: 40px;
          line-height: 40px;
          margin: 0 auto;
          padding: 0;
          background-color: #1E58A6;
          border-radius: 8px;
          border-color: #1E58A6;
          color: #fff;
          font-size: 20px;
        }
      }
    }
  }
}
</style>
