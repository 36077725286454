<!--
 * @Author: 小宝 zksukuo@yunkangda.com
 * @Date: 2024-05-24 17:11:01
 * @LastEditors: 小宝 zksukuo@yunkangda.com
 * @LastEditTime: 2024-06-11 14:16:05
 * @FilePath: \智慧公卫PC端旧项目\src\views\child-health-run\popup\pop.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <div class="form">
    <div class="form-box">
      <div class="form-close" @click="close">
        <span class="el-icon-close"></span>
      </div>
      <div class="form-content">
        <div class="form-item">
          <div class="form-title">
            <span>用户相关信息</span>
          </div>
          <div class="form-chart" id="pop1"></div>
        </div>
        <div class="form-item">
          <div class="form-title">
            <span>人群分类</span>
          </div>
          <div class="form-chart" id="pop2"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
export default {
  data () {
    return {
      myChart1: null,
      setInterval1: null,
      colors1: ['#A0ED44', '#42ECA8', '#42A9EC', '#9A42EC', '#FF4569', '#FF7839', '#FFCE3D'],
      datas1: ['消瘦', '超重', '心理行为高危险', '生长迟缓（矮小）', '视力异常', '贫血异常', '低体重'],
      datass1: [120, 200, 150, 80, 70, 110, 100],
      datas: [],
      myChart7: null,
      setInterval7: null,
      datas7: [
        { value: 9, name: '总用户数' },
        { value: 21, name: '服务人数' },
        { value: 10, name: '男' },
        { value: 8, name: '女' }
      ],
      colors7: ['#EE6666', '#FAC858', '#5EFA58', '#4430FF'],
      list: [
        { label: '金安', value: '1331001001001' },
        { label: '和平', value: '1331001001002' },
        { label: '青禾', value: '1331001001003' },
        { label: '志学', value: '1331001001004' },
        { label: '兴学', value: '1331001001005' },
        { label: '祥辉', value: '1331001001006' }
      ],
      yeyjgbh: ''
    }
  },
  computed: {
    ...mapState(['loginInfo'])
  },
  mounted () {
    console.log(this.$parent.selectPopInfo)
    const list = this.list.filter(item => item.label === this.$parent.selectPopInfo)
    this.yeyjgbh = list[0].value
    this.getInfo()
  },
  beforeDestroy () {
    this.remove()
  },
  methods: {
    async getInfo () {
      // 接口文档 https://app.apifox.com/project/2726972/apis/api-182679988
      try {
        const params = {
          jgbh: this.loginInfo.jgbh || '',
          jbmc: this.loginInfo.jgmc || '',
          ssjgbhmc: this.loginInfo.label || '',
          usercode: this.loginInfo.usercode || '',
          username: this.loginInfo.username || '',
          jgjb: this.loginInfo.value || '',
          ssjgbh: this.loginInfo.jgbh || '',
          type: '',
          yeyjgbh: this.yeyjgbh
        }
        const { data: res } = await this.$http.post('/api/jkct/app/zhdpgwettjyeyxx', params)
        console.log('智慧公卫-幼儿园儿童体检结果', res)
        if (res.code !== 0) {
          return this.$message({
            message: res.msg || '暂无信息',
            type: 'warning'
          })
        }
        if (res.data === null) return this.$message({ message: '暂无信息', type: 'warning' })

        const data = res.data
        for (var i = 0; i < data.yclb.length; i++) {
          const obj = {
            value: data.yclb[i].value,
            itemStyle: { color: this.colors1[i] }
          }
          this.datas.push(obj)
          this.datas1.push(data.yclb[i].name)
        }
        this.$nextTick(() => {
          this.canvas1()
          this.canvas7()
        })
        this.datas7[0].value = res.data.yerzyhs
        this.datas7[1].value = res.data.yerfwrs
        this.datas7[2].value = res.data.yern
        this.datas7[3].value = res.data.yernv
      } catch (err) {
        console.log('智慧公卫-幼儿园儿童体检结果', err)
      }
    },
    close () {
      this.$parent.showPop = false
    },
    remove () {
      window.removeEventListener('resize', this.getresize)
      clearInterval(this.setInterva7)
      clearInterval(this.setInterva1)
    },
    getresize () {
      setTimeout(() => {
        this.myChart1.resize()
        this.myChart7.resize()
      }, 500)
    },
    changeInfo1 () {
      const scroll1 = document.querySelector('#pop2')
      scroll1.addEventListener('mouseenter', () => this.noUpdate1())
      scroll1.addEventListener('mouseleave', () => this.update1())
    },
    canvas1 () {
      var chartDom = document.getElementById('pop2')
      this.myChart1 = this.$echarts.init(chartDom)
      this.myChart1.on('click', res => {

      })
      const option = {
        grid: {
          containLabel: true,
          top: '12%', // 一下数值可为百分比也可为具体像素值
          right: '4%',
          bottom: '8%',
          left: '4%'
        },
        label: {
          show: true,
          color: '#fff',
          fontSize: 14,
          position: 'top',
          height: 100,
          offset: [0, 0]
        },
        xAxis: [
          // x轴数据设置
          {
            type: 'category',
            splitLine: {
              show: false
            },
            axisLine: {
              lineStyle: {
                color: '#03BCD7'
              }
            },
            axisLabel: {
              fontSize: 10
              // rotate: 20 // X轴标签倾斜 45 度
            },
            data: this.datas1
          }
        ],
        yAxis: [
          {
            type: 'value',
            offset: 0,
            axisLine: {
              show: true,
              lineStyle: {
                color: '#03BCD7'
              },
              axisLabel: {
                show: true,
                color: '#fff',
                fontSize: 16
              }
            },
            axisTick: {
              show: false
            },
            splitLine: {
              show: false // 不显示横线
            }
          }
        ],
        series: [
          {
            data: this.datas,
            type: 'bar',
            barWidth: 8,
            label: {
              show: true,
              textStyle: {
                color: '#fff'
              }
            },
            itemStyle: {
              color: '#3FEBF8'
            },
            backgroundStyle: {
              color: 'rgba(180, 180, 180, 0.2)'
            }
          }
        ]
      }

      option && this.myChart1.setOption(option)
      this.update1()
    },
    update1 () {
      this.setInterval1 = setInterval(() => {
        this.datas1.unshift(this.datas1.pop())
        this.datass1.unshift(this.datass1.pop())
        this.datas.unshift(this.datas.pop())
        this.myChart1.setOption({
          xAxis: {
            data: this.datas1
          },
          series: {
            data: this.datas
          }
        })
      }, 2000)
    },
    noUpdate1 () {
      clearInterval(this.setInterval1)
    },
    changeInfo7 () {
      const scroll1 = document.querySelector('#pop1')
      scroll1.addEventListener('mouseenter', () => this.noUpdate7())
      scroll1.addEventListener('mouseleave', () => this.update7())
    },
    canvas7 () {
      var chartDom = document.getElementById('pop1')
      this.myChart7 = this.$echarts.init(chartDom)
      this.myChart7.on('click', res => {

      })

      const option = {
        color: this.colors7,
        // grid: {
        //   containLabel: true,
        //   top: '2%',
        //   right: '40%',
        //   bottom: '0%',
        //   left: '1%'
        // },
        tooltip: {
          trigger: 'item'
          // formatter: params => {
          //   return params.seriesName + '<br/>' + params.data.name + ' : ' + params.data.value.toFixed(0) + '%'
          // }
        },
        legend: {
          // type: 'scroll',
          // itemHeight: 5,
          show: true,
          orient: 'vertical',
          y: 'center',
          x: 'left',
          align: 'left',
          // itemHeight: 5,
          textStyle: {
            fontSize: 10,
            color: '#FFFFFF'
          },
          pageIconColor: '#aaa',
          pageTextStyle: {
            color: '#fff'
          }
          // formatter: (name) => {
          //   const total = this.datas7.reduce(function (sum, seriesItem) {
          //     return sum + seriesItem.value
          //   }, 0)
          //   const target = this.datas7.find(function (seriesItem) {
          //     return seriesItem.name === name
          //   })
          //   const percentage = ((target.value / total) * 100).toFixed(0) + '%'
          //   return name + ' ' + percentage
          // }
        },
        series: [
          {
            name: '用户相关信息',
            type: 'pie',
            radius: '50%',
            // center: ['25%', '50%'],
            data: this.datas7,
            label: {
              show: true,
              formatter: '{c}人',
              fontSize: 13,
              color: '#FFFFFF'
            },
            emphasis: {
              itemStyle: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: 'rgba(0, 0, 0, 0.5)'
              }
            }
          }
        ]
      }

      this.myChart7.on('legendselectchanged', res => {
        console.log('用户相关信息：', res)
      })

      option && this.myChart7.setOption(option)
      this.update7()
    },
    update7 () {
      this.setInterval7 = setInterval(() => {
        this.datas7.unshift(this.datas7.pop())
        this.colors7.unshift(this.colors7.pop())
        this.myChart7.setOption({
          color: this.colors7,
          series: {
            data: this.datas7
          }
        })
      }, 2000)
    }
  }
}
</script>

<style lang="less" scoped>
.form {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 888;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, .9);

  .form-box {
    position: relative;
    width: 80%;
    height: 80%;
    padding: 10px;
    background-color: rgba(10, 71, 123, .27);
    border: 4px solid rgba(1, 124, 219, .27);
    border-radius: 10px;
    box-sizing: border-box;

    .form-close {
      position: absolute;
      right: 30px;
      height: 50px;
      line-height: 50px;
      color: #fff;
      font-size: 24px;
      cursor: pointer;
    }

    .form-content {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      width: 100%;
      height: 100%;
      padding: 50px 90px;
      border: 4px solid rgba(1, 124, 219, .27);
      border-radius: 10px;
      box-sizing: border-box;

      .form-item {
        width: 33%;
        height: calc(50% - 10px);
        background-color: rgba(17, 94, 156, .27);
        border: 4px solid rgba(1, 124, 219, .27);
        box-sizing: border-box;

        .form-title {
          max-width: 276px;
          width: 70%;
          height: 54px;
          line-height: 54px;
          padding-left: 40px;
          padding-top: 5px;
          background-image: url('../../../../assets/img/dialogTitleBg.png');
          background-repeat: no-repeat;
          background-size: 100% 100%;
          box-sizing: border-box;
          color: #fff;
          font-size: 20px;
        }

        .form-chart {
          width: 100%;
          height: calc(100% - 54px);
        }
      }

      .form-item:last-child {
        width: 100%;
      }
    }
  }
}
</style>
