<template>
  <div class="echarts">
    <div class="echarts" :id="'echarts' + index"></div>
  </div>
</template>

<script>
export default {
  props: {
    index: {
      type: String,
      default: '0'
    }
  },
  data () {
    return {
      subMyChart0: '',
      subMyChart1: '',
      subMyChart2: '',
      subMyChart3: '',
      subMyChart4: '',
      subMyChart5: ''
    }
  },
  mounted () {
    this.$nextTick(() => {
      this.subChart()
    })
    window.addEventListener('resize', this.getresize)
  },
  beforeDestroy () {
    this.$data['subMyChart' + this.index] = null
    window.removeEventListener('resize', this.getresize)
  },
  methods: {
    getresize () {
      this.subMyChart.resize()
    },
    subChart () {
      var chartDom = document.getElementById('echarts' + this.index)
      this.subMyChart = this.$echarts.init(chartDom)
      const option = {
        grid: {
          show: false,
          top: '40%',
          right: '15%',
          bottom: '10%',
          left: '14%'
        },
        dataZoom: [
          {
            xAxisIndex: 0, // 这里是从X轴的0刻度开始
            disabled: false,
            zoomLock: true,
            preventDefaultMouseMove: false,
            filterMode: 'empty',
            type: 'inside',
            startValue: 0, // 从头开始。
            endValue: 6 // 一次性展示6个。
          }

        ],
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'shadow'
          }
        },
        legend: {
          x: 'right',
          y: 'top',
          textStyle: {
            fontSize: 12,
            color: '#FFFFFF'
          }
          // padding: [12, 21, 0, 0]
        },
        xAxis: {
          type: 'category',
          data: ['', '', '', ''],
          axisTick: {
            // y轴刻度线
            show: false
          },
          axisLabel: {
            show: true,
            color: '#fff',
            fontSize: 13
          },
          axisLine: {
            show: true,
            lineStyle: {
              color: ['#04B4D0']
            },
            textStyle: {
              color: '#04B4D0',
              fontSize: 13
            }
          }
        },
        yAxis: [
          {
            name: '呼吸',
            type: 'value',
            axisLabel: {
              show: true,
              color: '#fff',
              fontSize: 13
            },
            axisLine: {
              show: true,
              lineStyle: {
                color: '#fff'
              }
            }
          },
          {
            name: '心率',
            type: 'value',
            axisLabel: {
              show: true,
              color: '#fff',
              fontSize: 13
            },
            axisLine: {
              show: true,
              lineStyle: {
                color: '#fff'
              }
            }
          }
        ],
        series: [
          {
            name: '呼吸',
            data: [820, 932, 901, 934],
            type: 'line',
            smooth: true
          },
          {
            name: '心率',
            data: [10, 15, 90, 60],
            type: 'line',
            yAxisIndex: 1,
            smooth: true
          }
        ]
      }

      option && this.subMyChart.setOption(option)
    }
  }
}
</script>

<style lang="less" scoped>
.echarts {
  width: 100%;
  height: 100%;
}
</style>
