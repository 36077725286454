<template>
  <div class="form">
    <div class="form-box">
      <div class="form-close" @click="close">
        <span class="el-icon-close"></span>
      </div>
      <div class="form-title">
        <span>中医药健康管理服务</span>
      </div>
      <div class="form-head" v-if="info">
        <span>姓名：{{ info.name || '-' }}</span>
        <span>编号：{{ info.childHealthExamFormNo || '-' }}</span>
      </div>
      <div class="form-scroll" v-if="info">
        <table cellpadding="0" cellspacing="0" border class="form-table">
          <tr>
            <td colspan="4" class="form-td form-td-text">
              <span>六到十八月龄</span>
            </td>
          </tr>
          <tr>
            <td colspan="1" class="form-td form-td-left">
              <span>月龄</span>
            </td>
            <td colspan="1" class="form-td form-td-left">
              <span>6月龄</span>
            </td>
            <td colspan="1" class="form-td form-td-left">
              <span>12月龄</span>
            </td>
            <td colspan="1" class="form-td form-td-left">
              <span>18月龄</span>
            </td>
          </tr>
          <tr>
            <td colspan="1" class="form-td form-td-left">
              <span>随访日期</span>
            </td>
            <td colspan="1" class="form-td form-td-text">
              <el-date-picker
                v-if="show"
                v-model="info.followupVisitDate6"
                size="small"
                type="date"
                placeholder="选择日期">
              </el-date-picker>
            </td>
            <td colspan="1" class="form-td form-td-text">
              <el-date-picker
                v-if="show"
                v-model="info.followupVisitDate12"
                size="small"
                type="date"
                placeholder="选择日期">
              </el-date-picker>
            </td>
            <td colspan="1" class="form-td form-td-text">
              <el-date-picker
                v-if="show"
                v-model="info.followupVisitDate18"
                size="small"
                type="date"
                placeholder="选择日期">
              </el-date-picker>
            </td>
          </tr>
          <tr>
            <td colspan="1" class="form-td form-td-left">
              <span>是否失访</span>
            </td>
            <td colspan="1" class="form-td form-td-left">
              <el-radio-group v-model="info.isMiss6">
                <el-radio label="1">是</el-radio>
                <el-radio label="2">否</el-radio>
              </el-radio-group>
            </td>
            <td colspan="1" class="form-td form-td-left">
              <el-radio-group v-model="info.isMiss12">
                <el-radio label="1">是</el-radio>
                <el-radio label="2">否</el-radio>
              </el-radio-group>
            </td>
            <td colspan="1" class="form-td form-td-left">
              <el-radio-group v-model="info.isMiss18">
                <el-radio label="1">是</el-radio>
                <el-radio label="2">否</el-radio>
              </el-radio-group>
            </td>
          </tr>
          <tr>
            <td colspan="1" class="form-td form-td-left">
              <span>失访原因</span>
            </td>
            <td colspan="1" class="form-td form-td-left">
              <el-input v-if="show" size="small" class="input" v-model="info.missReason6" placeholder=""></el-input>
            </td>
            <td colspan="1" class="form-td form-td-left">
              <el-input v-if="show" size="small" class="input" v-model="info.missReason12" placeholder=""></el-input>
            </td>
            <td colspan="1" class="form-td form-td-left">
              <el-input v-if="show" size="small" class="input" v-model="info.missReason18" placeholder=""></el-input>
            </td>
          </tr>
          <tr>
            <td colspan="1" class="form-td form-td-left">
              <span>中医药健康管理服务</span>
            </td>
            <td colspan="1" class="form-td form-td-left">
              <el-checkbox-group v-model="info.healthManagementName6">
                <el-checkbox label="1">中医饮食调养指导</el-checkbox>
                <el-checkbox label="2">中医起居调养指导</el-checkbox>
                <el-checkbox label="3">传授按摩四神聪穴大法</el-checkbox>
              </el-checkbox-group>
            </td>
            <td colspan="1" class="form-td form-td-left">
              <el-checkbox-group v-model="info.healthManagementName12">
                <el-checkbox label="1">中医饮食调养指导</el-checkbox>
                <el-checkbox label="2">中医起居调养指导</el-checkbox>
                <el-checkbox label="3">传授按摩四神聪穴大法</el-checkbox>
              </el-checkbox-group>
            </td>
            <td colspan="1" class="form-td form-td-left">
              <el-checkbox-group v-model="info.healthManagementName18">
                <el-checkbox label="1">中医饮食调养指导</el-checkbox>
                <el-checkbox label="2">中医起居调养指导</el-checkbox>
                <el-checkbox label="3">传授按摩四神聪穴大法</el-checkbox>
              </el-checkbox-group>
            </td>
          </tr>
          <tr>
            <td colspan="1" class="form-td form-td-left">
              <span>下次随访时间：</span>
            </td>
            <td colspan="1" class="form-td form-td-text">
              <el-date-picker
                v-if="show"
                v-model="info.nextFollowupDate6"
                size="small"
                type="date"
                placeholder="选择日期">
              </el-date-picker>
            </td>
            <td colspan="1" class="form-td form-td-text">
              <el-date-picker
                v-if="show"
                v-model="info.nextFollowupDate12"
                size="small"
                type="date"
                placeholder="选择日期">
              </el-date-picker>
            </td>
            <td colspan="1" class="form-td form-td-text">
              <el-date-picker
                v-if="show"
                v-model="info.nextFollowupDate18"
                size="small"
                type="date"
                placeholder="选择日期">
              </el-date-picker>
            </td>
          </tr>
          <tr>
            <td colspan="1" class="form-td form-td-left">
              <span>随访医生签名：</span>
            </td>
            <td colspan="1" class="form-td form-td-left">
              <el-input v-if="show" size="small" class="input" v-model="info.signPhotoUrl6" placeholder=""></el-input>
            </td>
            <td colspan="1" class="form-td form-td-left">
              <el-input v-if="show" size="small" class="input" v-model="info.signPhotoUrl12" placeholder=""></el-input>
            </td>
            <td colspan="1" class="form-td form-td-left">
              <el-input v-if="show" size="small" class="input" v-model="info.signPhotoUrl18" placeholder=""></el-input>
            </td>
          </tr>
          <tr>
            <td colspan="1" class="form-td form-td-left">
              <span>录入人：</span>
            </td>
            <td colspan="1" class="form-td form-td-left">
              <el-input v-if="show" size="small" class="input" v-model="info.creator6" placeholder=""></el-input>
            </td>
            <td colspan="1" class="form-td form-td-left">
              <el-input v-if="show" size="small" class="input" v-model="info.creator12" placeholder=""></el-input>
            </td>
            <td colspan="1" class="form-td form-td-left">
              <el-input v-if="show" size="small" class="input" v-model="info.creator18" placeholder=""></el-input>
            </td>
          </tr>
          <tr>
            <td colspan="1" class="form-td form-td-left">
              <span>录入时间：</span>
            </td>
            <td colspan="1" class="form-td form-td-text">
              <el-date-picker
                v-if="show"
                v-model="info.createTime6"
                size="small"
                type="date"
                placeholder="选择日期">
              </el-date-picker>
            </td>
            <td colspan="1" class="form-td form-td-text">
              <el-date-picker
                v-if="show"
                v-model="date"
                size="small"
                type="date"
                placeholder="选择日期">
              </el-date-picker>
            </td>
            <td colspan="1" class="form-td form-td-text">
              <el-date-picker
                v-if="show"
                v-model="date"
                size="small"
                type="date"
                placeholder="选择日期">
              </el-date-picker>
            </td>
          </tr>
          <tr>
            <td colspan="1" class="form-td form-td-left">
              <span>录入机构：</span>
            </td>
            <td colspan="1" class="form-td form-td-left">
              <el-input v-if="show" size="small" class="input" v-model="info.orgName6" placeholder=""></el-input>
            </td>
            <td colspan="1" class="form-td form-td-left">
              <el-input v-if="show" size="small" class="input" v-model="info.orgName12" placeholder=""></el-input>
            </td>
            <td colspan="1" class="form-td form-td-left">
              <el-input v-if="show" size="small" class="input" v-model="info.orgName18" placeholder=""></el-input>
            </td>
          </tr>
          <tr>
            <td colspan="4" class="form-td form-td-text">
              <span>二十四到三十六月龄</span>
            </td>
          </tr>
          <tr>
            <td colspan="1" class="form-td form-td-left">
              <span>月龄</span>
            </td>
            <td colspan="1" class="form-td form-td-left">
              <span>24月龄</span>
            </td>
            <td colspan="1" class="form-td form-td-left">
              <span>30月龄</span>
            </td>
            <td colspan="1" class="form-td form-td-left">
              <span>36月龄</span>
            </td>
          </tr>
          <tr>
            <td colspan="1" class="form-td form-td-left">
              <span>随访日期</span>
            </td>
            <td colspan="1" class="form-td form-td-text">
              <el-date-picker
                v-if="show"
                v-model="info.followupVisitDate24"
                size="small"
                type="date"
                placeholder="选择日期">
              </el-date-picker>
            </td>
            <td colspan="1" class="form-td form-td-text">
              <el-date-picker
                v-if="show"
                v-model="info.followupVisitDate30"
                size="small"
                type="date"
                placeholder="选择日期">
              </el-date-picker>
            </td>
            <td colspan="1" class="form-td form-td-text">
              <el-date-picker
                v-if="show"
                v-model="info.followupVisitDate36"
                size="small"
                type="date"
                placeholder="选择日期">
              </el-date-picker>
            </td>
          </tr>
          <tr>
            <td colspan="1" class="form-td form-td-left">
              <span>是否失访</span>
            </td>
            <td colspan="1" class="form-td form-td-left">
              <el-radio-group v-model="info.isMiss24">
                <el-radio label="1">是</el-radio>
                <el-radio label="2">否</el-radio>
              </el-radio-group>
            </td>
            <td colspan="1" class="form-td form-td-left">
              <el-radio-group v-model="info.isMiss30">
                <el-radio label="1">是</el-radio>
                <el-radio label="2">否</el-radio>
              </el-radio-group>
            </td>
            <td colspan="1" class="form-td form-td-left">
              <el-radio-group v-model="info.isMiss36">
                <el-radio label="1">是</el-radio>
                <el-radio label="2">否</el-radio>
              </el-radio-group>
            </td>
          </tr>
          <tr>
            <td colspan="1" class="form-td form-td-left">
              <span>失访原因</span>
            </td>
            <td colspan="1" class="form-td form-td-left">
              <el-input v-if="show" size="small" class="input" v-model="info.missReason24" placeholder=""></el-input>
            </td>
            <td colspan="1" class="form-td form-td-left">
              <el-input v-if="show" size="small" class="input" v-model="info.missReason30" placeholder=""></el-input>
            </td>
            <td colspan="1" class="form-td form-td-left">
              <el-input v-if="show" size="small" class="input" v-model="info.missReason36" placeholder=""></el-input>
            </td>
          </tr>
          <tr>
            <td colspan="1" class="form-td form-td-left">
              <span>中医药健康管理服务</span>
            </td>
            <td colspan="1" class="form-td form-td-left">
              <el-checkbox-group v-model="info.healthManagementName24">
                <el-checkbox label="1">中医饮食调养指导</el-checkbox>
                <el-checkbox label="2">中医起居调养指导</el-checkbox>
                <el-checkbox label="3">传授按摩四神聪穴大法</el-checkbox>
              </el-checkbox-group>
            </td>
            <td colspan="1" class="form-td form-td-left">
              <el-checkbox-group v-model="info.healthManagementName30">
                <el-checkbox label="1">中医饮食调养指导</el-checkbox>
                <el-checkbox label="2">中医起居调养指导</el-checkbox>
                <el-checkbox label="3">传授按摩四神聪穴大法</el-checkbox>
              </el-checkbox-group>
            </td>
            <td colspan="1" class="form-td form-td-left">
              <el-checkbox-group v-model="info.healthManagementName36">
                <el-checkbox label="1">中医饮食调养指导</el-checkbox>
                <el-checkbox label="2">中医起居调养指导</el-checkbox>
                <el-checkbox label="3">传授按摩四神聪穴大法</el-checkbox>
              </el-checkbox-group>
            </td>
          </tr>
          <tr>
            <td colspan="1" class="form-td form-td-left">
              <span>下次随访时间：</span>
            </td>
            <td colspan="1" class="form-td form-td-text">
              <el-date-picker
                v-if="show"
                v-model="info.nextFollowupDate24"
                size="small"
                type="date"
                placeholder="选择日期">
              </el-date-picker>
            </td>
            <td colspan="1" class="form-td form-td-text">
              <el-date-picker
                v-if="show"
                v-model="info.nextFollowupDate30"
                size="small"
                type="date"
                placeholder="选择日期">
              </el-date-picker>
            </td>
            <td colspan="1" class="form-td form-td-text">
              <el-date-picker
                v-if="show"
                v-model="info.nextFollowupDate36"
                size="small"
                type="date"
                placeholder="选择日期">
              </el-date-picker>
            </td>
          </tr>
          <tr>
            <td colspan="1" class="form-td form-td-left">
              <span>随访医生签名：</span>
            </td>
            <td colspan="1" class="form-td form-td-left">
              <el-input v-if="show" size="small" class="input" v-model="info.signPhotoUrl24" placeholder=""></el-input>
            </td>
            <td colspan="1" class="form-td form-td-left">
              <el-input v-if="show" size="small" class="input" v-model="info.signPhotoUrl30" placeholder=""></el-input>
            </td>
            <td colspan="1" class="form-td form-td-left">
              <el-input v-if="show" size="small" class="input" v-model="info.signPhotoUrl36" placeholder=""></el-input>
            </td>
          </tr>
          <tr>
            <td colspan="1" class="form-td form-td-left">
              <span>录入人：</span>
            </td>
            <td colspan="1" class="form-td form-td-left">
              <el-input v-if="show" size="small" class="input" v-model="info.creator24" placeholder=""></el-input>
            </td>
            <td colspan="1" class="form-td form-td-left">
              <el-input v-if="show" size="small" class="input" v-model="info.creator30" placeholder=""></el-input>
            </td>
            <td colspan="1" class="form-td form-td-left">
              <el-input v-if="show" size="small" class="input" v-model="info.creator36" placeholder=""></el-input>
            </td>
          </tr>
          <tr>
            <td colspan="1" class="form-td form-td-left">
              <span>录入时间：</span>
            </td>
            <td colspan="1" class="form-td form-td-text">
              <el-date-picker
                v-if="show"
                v-model="info.createTime24"
                size="small"
                type="date"
                placeholder="选择日期">
              </el-date-picker>
            </td>
            <td colspan="1" class="form-td form-td-text">
              <el-date-picker
                v-if="show"
                v-model="info.createTime30"
                size="small"
                type="date"
                placeholder="选择日期">
              </el-date-picker>
            </td>
            <td colspan="1" class="form-td form-td-text">
              <el-date-picker
                v-if="show"
                v-model="info.createTime36"
                size="small"
                type="date"
                placeholder="选择日期">
              </el-date-picker>
            </td>
          </tr>
          <tr>
            <td colspan="1" class="form-td form-td-left">
              <span>录入机构：</span>
            </td>
            <td colspan="1" class="form-td form-td-left">
              <el-input v-if="show" size="small" class="input" v-model="info.orgName24" placeholder=""></el-input>
            </td>
            <td colspan="1" class="form-td form-td-left">
              <el-input v-if="show" size="small" class="input" v-model="info.orgName30" placeholder=""></el-input>
            </td>
            <td colspan="1" class="form-td form-td-left">
              <el-input v-if="show" size="small" class="input" v-model="info.orgName36" placeholder=""></el-input>
            </td>
          </tr>
        </table>
      </div>
      <div class="form-button">
        <el-button type="primary" size="medium" @click="close">确认</el-button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
export default {
  data () {
    return {
      radio: null,
      date: '',
      checkList: [],
      input: '',
      show: true,
      params: {
        sfzh: '',
        ssjgbh: '',
        jgbh: '',
        ssjlid: ''
      },
      info: null
    }
  },
  computed: {
    ...mapState(['loginInfo'])
  },
  created () {
    console.log('选择信息：', this.$parent.selectInfo)
    this.params.ssjgbh = this.loginInfo.jgbh || ''
    this.params.jgbh = this.loginInfo.jgbh || ''
    this.params.sfzh = this.$parent.selectInfo.sfzh || ''
    this.getInfo()
  },
  methods: {
    async getInfo () {
      // 接口文档 https://app.apifox.com/project/2726972/apis/api-84409096
      try {
        const { data: res } = await this.$http.post('/api/jkct/findetzyyjkgl', this.params)
        console.log('中医健康管理', res)
        if (res.code !== 0) {
          return this.$message({
            message: res.msg || '暂无信息',
            type: 'warning'
          })
        }
        if (res.data === null) return
        // eslint-disable-next-line no-prototype-builtins
        if (res.data.hasOwnProperty('healthManagementName6')) {
          res.data.healthManagementName6 = res.data.healthManagementName6.split(',')
        } else {
          this.$set(res.data, 'healthManagementName6', [])
        }
        // eslint-disable-next-line no-prototype-builtins
        if (res.data.hasOwnProperty('healthManagementName12')) {
          res.data.healthManagementName12 = res.data.healthManagementName12.split(',')
        } else {
          this.$set(res.data, 'healthManagementName12', [])
        }
        // eslint-disable-next-line no-prototype-builtins
        if (res.data.hasOwnProperty('healthManagementName18')) {
          res.data.healthManagementName18 = res.data.healthManagementName18.split(',')
        } else {
          this.$set(res.data, 'healthManagementName18', [])
        }
        // eslint-disable-next-line no-prototype-builtins
        if (res.data.hasOwnProperty('healthManagementName24')) {
          res.data.healthManagementName24 = res.data.healthManagementName24.split(',')
        } else {
          this.$set(res.data, 'healthManagementName24', [])
        }
        // eslint-disable-next-line no-prototype-builtins
        if (res.data.hasOwnProperty('healthManagementName30')) {
          res.data.healthManagementName30 = res.data.healthManagementName30.split(',')
        } else {
          this.$set(res.data, 'healthManagementName30', [])
        }
        // eslint-disable-next-line no-prototype-builtins
        if (res.data.hasOwnProperty('healthManagementName36')) {
          res.data.healthManagementName36 = res.data.healthManagementName36.split(',')
        } else {
          this.$set(res.data, 'healthManagementName36', [])
        }
        this.info = res.data
      } catch (err) {
        console.log('中医健康管理', err)
      }
    },
    close () {
      this.$parent.selectInfo = null
      this.$emit('close')
    }
  }
}
</script>

<style lang="less" scoped>
.form {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 888;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, .9);

  .form-box {
    position: relative;
    width: 70%;
    height: 95%;
    padding: 0 73px;
    border: 4px solid #21D6E0;
    box-sizing: border-box;

    .form-close {
      position: absolute;
      right: 30px;
      height: 50px;
      line-height: 50px;
      color: #fff;
      font-size: 20px;
      cursor: pointer;
    }

    .form-title {
      width: 100%;
      height: 60px;
      line-height: 60px;
      text-align: center;
      color: #01EEFD;
      font-size: 22px;
    }

    .form-head {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      height: 50px;
      color: #01EEFD;
      font-size: 22px;
    }

    .form-scroll {
      overflow-y: scroll;
      width: 100%;
      height: calc(100% - 180px);

      .form-table {
        width: 100%;
        border-color: #01EEFD;

        .form-td {
          padding: 12px 0;
          box-sizing: border-box;
          color: #01EEFD;
          font-size: 14px;
          min-width: 170px;
        }

        .form-td-left {
          padding-left: 11px;
          box-sizing: border-box;
        }

        .form-td-text {
          text-align: center;
        }

        .el-radio {
          color: #fff;
        }

        .input {
          width: 90%;
        }

        .input1 {
          width: 100px;
        }

        .el-checkbox {
          color: #fff;
        }
      }
    }

    .form-button {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 70px;
    }
  }
}
</style>
