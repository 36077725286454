import { render, staticRenderFns } from "./auxiliaryMonitorings.vue?vue&type=template&id=2b6a8c62&scoped=true&"
import script from "./auxiliaryMonitorings.vue?vue&type=script&lang=js&"
export * from "./auxiliaryMonitorings.vue?vue&type=script&lang=js&"
import style0 from "./auxiliaryMonitorings.vue?vue&type=style&index=0&id=2b6a8c62&prod&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/_vue-loader@15.10.1@vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2b6a8c62",
  null
  
)

export default component.exports