<template>
  <div class="sub">
    <div class="sub-top">
      <div class="sub-top-left">
        <div class="sub-top-item">
          <div class="sub-top-label">
            <span>签约人数</span>
          </div>
          <span>1767330</span>
        </div>
        <div class="sub-top-item">
          <div class="sub-top-label">
            <span>续约人数</span>
          </div>
          <span>1767330</span>
        </div>
      </div>
      <div class="sub-top-right">
        <div class="sub-top-item">
          <div class="sub-top-label">
            <span>建档数量</span>
          </div>
          <span>1767330</span>
        </div>
        <div class="sub-top-item">
          <div class="sub-top-label sub-top-label1">
            <span>建档签约数量</span>
          </div>
          <span>1767330</span>
        </div>
      </div>
    </div>
    <div class="sub-bottom">
      <div class="sub-left">
        <div class="sub-left-top">
          <div class="sub-left-sort sub-left-sort1">
            <div class="sub-title">
              <span>各区县签约总数</span>
            </div>
            <div class="sub-left-chart" id="echarts1"></div>
          </div>
          <div class="sub-left-sort sub-left-sort2">
            <div class="sub-sort-item">
              <div class="sub-title">
                <span>重点人群签约数量</span>
              </div>
              <div class="sub-sort-chart" id="echarts2"></div>
            </div>
            <div class="sub-sort-item">
              <div class="sub-title">
                <span>履约完成率</span>
              </div>
              <div class="sub-sort-scroll">
                <div class="sub-sort-scroll-item" v-for="(item, index) in 10" :key="index">
                  <span class="sub-sort-scroll-span">01</span>
                  <span class="sub-sort-scroll-span sub-sort-scroll-span1">丛台区</span>
                  <span class="sub-sort-scroll-span">20%</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="sub-left-bottom">
          <div class="sub-left-bottom-left">
            <div class="sub-title">
              <span>随访总次数</span>
            </div>
            <div class="sub-table">
              <div class="sub-table-head">
                <span>序号</span>
                <span class="span">区县</span>
                <span>占比</span>
              </div>
              <div class="sub-table-scroll">
                <div class="sub-table-item" v-for="(item, index) in 10" :key="index">
                  <span>01</span>
                  <span class="span">丛台区</span>
                  <span>23%</span>
                </div>
              </div>
            </div>
          </div>
          <div class="sub-left-bottom-right">
            <div class="sub-title">
              <span>慢病人群签约数量</span>
            </div>
            <div class="sub-chart" id="echarts3"></div>
          </div>
        </div>
      </div>
      <div class="sub-right">
        <div class="sub-title">
          <span>市级服务包签约数量占比</span>
        </div>
        <div class="sub-scroll">
          <div class="sub-scroll-item" v-for="(item, index) in 10" :key="index">
            <span class="span">01</span>
            <span class="span">邢台市</span>
            <span class="span span1">签约次数:108次</span>
            <span class="span">占比:23%</span>
          </div>
        </div>
        <div class="sub-title">
          <span>续约总数及占比</span>
        </div>
        <div class="sub-table">
          <div class="sub-table-head">
            <span>序号</span>
            <span class="span">区县</span>
            <span>占比</span>
          </div>
          <div class="sub-table-scroll">
            <div class="sub-table-item" v-for="(item, index) in 10" :key="index">
              <span>01</span>
              <span class="span">丛台区</span>
              <span>23%</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      tabIndex1: 0,
      tabIndex2: 0,
      tabIndex3: 0,
      tabIndex4: 0,
      tabIndex5: 0,
      tabIndex6: 0,
      myChart1: null,
      myChart2: null,
      myChart3: null,
      myChart4: null,
      myChart5: null,
      myChart6: null,
      myChart7: null,
      list: [
        { name: '中西区', value: 20057.34, itemStyle: { color: '#eb8146' } },
        { name: '湾仔', value: 15477.48, itemStyle: { color: '#eb8146' } },
        { name: '东区', value: 31686.1, itemStyle: { color: '#eb8146' } },
        { name: '南区', value: 6992.6, itemStyle: { color: '#eb8146' } },
        { name: '油尖旺', value: 44045.49, itemStyle: { color: '#eb8146' } },
        { name: '深水埗', value: 40689.64, itemStyle: { color: '#eb8146' } },
        { name: '九龙城', value: 37659.78, itemStyle: { color: '#eb8146' } },
        { name: '黄大仙', value: 45180.97, itemStyle: { color: '#eb8146' } },
        { name: '观塘', value: 55204.26, itemStyle: { color: '#eb8146' } },
        { name: '葵青', value: 21900.9, itemStyle: { color: '#eb8146' } },
        { name: '荃湾', value: 4918.26, itemStyle: { color: '#eb8146' } },
        { name: '屯门', value: 5881.84, itemStyle: { color: '#eb8146' } },
        { name: '元朗', value: 4178.01, itemStyle: { color: '#eb8146' } },
        { name: '北区', value: 2227.92, itemStyle: { color: '#ffde33' } },
        { name: '大埔', value: 2180.98, itemStyle: { color: '#eb8146' } },
        { name: '沙田', value: 9172.94, itemStyle: { color: '#eb8146' } },
        { name: '西贡', value: 3368, itemStyle: { color: '#eb8146' } },
        { name: '离岛', value: 806.98, itemStyle: { color: '#eb8146' } }
      ]
    }
  },
  mounted () {
    this.$nextTick(() => {
      this.canvas1()
      this.canvas2()
      this.canvas3()
      // this.canvas4()
      // this.canvas5()
      // this.canvas6()
      // this.canvas7()
    })
    window.addEventListener('resize', this.getresize)
  },
  beforeDestroy () {
    // 页面销毁时销毁监听事件
    window.removeEventListener('resize', this.getresize)
  },
  methods: {
    tabClick1 (index) {
      this.tabIndex1 = index
    },
    tabClick2 (index) {
      this.tabIndex2 = index
    },
    tabClick3 (index) {
      this.tabIndex3 = index
    },
    tabClick4 (index) {
      this.tabIndex4 = index
    },
    tabClick5 (index) {
      this.tabIndex5 = index
    },
    tabClick6 (index) {
      this.tabIndex6 = index
    },
    async getData () {
      const { data: res } = await this.$http.get('https://echarts.apache.org/examples/data/asset/geo/HK.json')
      console.log('res', res)
    },
    getresize () {
      setTimeout(() => {
        this.myChart1.resize()
        this.myChart2.resize()
        this.myChart3.resize()
        // this.myChart4.resize()
        // this.myChart5.resize()
        // this.myChart6.resize()
        // this.myChart7.resize()
      }, 500)
    },
    canvas1 () {
      var chartDom = document.getElementById('echarts1')
      this.myChart1 = this.$echarts.init(chartDom)
      const option = {
        grid: {
          containLabel: true,
          top: '0%',
          right: '14%',
          bottom: '0%',
          left: '8%'
        },
        label: {
          show: true,
          color: '#01EEFD',
          fontSize: 14,
          position: 'right',
          height: 100,
          offset: [0, 0]
        },
        xAxis: [
          // x轴数据设置
          {
            type: 'value',
            splitLine: {
              show: false
            },
            axisLine: {
              show: false,
              lineStyle: {
                color: '#021B63'
              }
            }
          }
        ],
        yAxis: [
          {
            type: 'category',
            offset: 0,
            axisLine: {
              show: false,
              // 这是x轴文字颜色
              lineStyle: {
                color: '#fff'
              }
            },
            data: ['丛台区', '平山县', '平山县', '平山县', '平山县', '平山县'],
            axisTick: {
              show: false
            }
          }
        ],
        series: [
          {
            data: [120, 200, 150, 80, 70, 150, 80, 70],
            type: 'bar',
            barWidth: 26,
            label: {
              show: true,
              color: '#fff'
            },
            itemStyle: {
              color: '#0599D9',
              borderRadius: [15, 15, 15, 15]
            },
            showBackground: true,
            backgroundStyle: {
              color: 'rgba(180, 180, 180, 0.2)',
              borderRadius: [15, 15, 15, 15]
            }
          }
        ]
      }

      option && this.myChart1.setOption(option)
    },
    canvas2 () {
      var chartDom = document.getElementById('echarts2')
      this.myChart2 = this.$echarts.init(chartDom)
      const option = {
        color: ['#73C0DE', '#5470C6', '#91CC75', '#C500FF', '#FAC858', '#EE6666'],
        tooltip: {
          trigger: 'item'
        },
        legend: {
          show: false
        },
        series: [
          {
            name: '重点人群签约数量',
            type: 'pie',
            radius: '70%',
            data: [
              { value: 1048, name: '1' },
              { value: 735, name: '2' },
              { value: 580, name: '3' },
              { value: 300, name: '4' },
              { value: 580, name: '5' },
              { value: 300, name: '6' }
            ],
            center: ['50%', '50%'],
            label: { // 删除指示线
              show: false,
              textStyle: {
                color: '#fff'
              }
            }
          }
        ]
      }

      option && this.myChart2.setOption(option)
    },
    canvas3 () {
      var chartDom = document.getElementById('echarts3')
      this.myChart3 = this.$echarts.init(chartDom)
      const option = {
        color: ['#FAC858', '#73C0DE', '#5470C6', '#91CC75'],
        tooltip: {
          trigger: 'item'
        },
        legend: {
          show: true,
          orient: 'vertical',
          bottom: 'center',
          textStyle: {
            color: '#fff'
          }
        },
        series: [
          {
            name: '慢病人群签约数量',
            type: 'pie',
            radius: '70%',
            data: [
              { value: 1048, name: '冠心病' },
              { value: 735, name: '高血压' },
              { value: 580, name: '糖尿病' },
              { value: 300, name: '高血脂' }
            ],
            center: ['30%', '50%'],
            label: { // 删除指示线
              show: false,
              textStyle: {
                color: '#fff'
              }
            }
          }
        ]
      }

      option && this.myChart3.setOption(option)
    }
  }
}
</script>

<style lang="less" scoped>
.sub {
  width: 100%;
  height: 100%;
  background-color: #0E2244;

  .sub-top {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 100px;

    .sub-top-left,
    .sub-top-right {
      display: flex;
      align-items: center;
    }

    .sub-top-item {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin: 0 25px;
      letter-spacing: 6px;
      color: #FAC85A;
      font-size: 20px;

      .sub-top-label {
        background-color: #015FA5;
        border: 1px solid #03BCD7;
        margin-bottom: 5px;
        padding: 9px 40px;
        letter-spacing: 6px;
        color: #fff;
        font-size: 20px;
      }

      .sub-top-label1 {
        padding: 9px 5px;
      }
    }
  }

  .sub-bottom {
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding: 10px;
    height: calc(100% - 100px);
    box-sizing: border-box;

    .sub-left {
      width: calc(70% - 40px);
      height: 100%;

      .sub-left-top {
        display: flex;
        width: 100%;
        height: calc(50% - 20px);

        .sub-left-sort {
          width: 65%;
          height: 100%;
          padding: 30px;
          background-image: url('../../../assets/img/zkimg9.png');
          background-repeat: no-repeat;
          background-size: 100% 100%;
          box-sizing: border-box;
        }

        .sub-left-sort1 {
          width: calc(35% - 40px);
          height: 100%;
          margin-right: 40px;
          background-image: url('../../../assets/img/zkimg8.png');

          .sub-left-chart {
            width: 100%;
            height: calc(100% - 70px);
          }
        }

        .sub-left-sort2 {
          display: flex;
        }

        .sub-sort-item {
          width: 50%;
          height: 100%;

          .sub-sort-chart {
            width: 100%;
            height: calc(100% - 70px);
          }

          .sub-sort-scroll {
            overflow-y: scroll;
            width: 100%;
            height: calc(100% - 70px);

            &::-webkit-scrollbar {
              width: 0;
            }

            .sub-sort-scroll-item {
              display: flex;
              align-items: center;
              width: 100%;
              margin-bottom: 10px;
              padding: 6px 0;
              background-color: rgba(3, 188, 215, .5);
              border-radius: 2px;
              color: #fff;
              font-size: 16px;

              .sub-sort-scroll-span {
                width: 20%;
                text-align: center;
                word-wrap: break-word;
              }

              .sub-sort-scroll-span1 {
                flex: 40%;
              }
            }
          }
        }
      }

      .sub-left-bottom {
        display: flex;
        width: 100%;
        height: calc(50% - 20px);
        margin-top: 40px;
        padding: 30px;
        background-image: url('../../../assets/img/zkimg10.png');
        background-repeat: no-repeat;
        background-size: 100% 100%;
        box-sizing: border-box;

        .sub-left-bottom-left {
          width: 40%;
          height: 100%;

          .sub-table {
            width: 100%;
            height: calc(100% - 70px);
          }
        }

        .sub-left-bottom-right {
          width: calc(60% - 40px);
          height: 100%;
          margin-left: 40px;

          .sub-chart {
            width: 100%;
            height: calc(100% - 70px)
          }
        }
      }
    }

    .sub-right {
      width: 30%;
      height: 100%;
      margin-left: 40px;
      padding: 40px;
      padding-right: 20px;
      background-image: url('../../../assets/img/zkimg7.png');
      background-repeat: no-repeat;
      background-size: 100% 100%;
      box-sizing: border-box;
    }

    .sub-title {
      position: relative;
      width: 100%;
      height: 70px;
      line-height: 70px;
      padding-left: 10px;
      letter-spacing: 1px;
      box-sizing: border-box;
      color: #fff;
      font-size: 18px;

      &::after {
        position: absolute;
        left: 0;
        top: 50%;
        transform: translateY(-50%);
        content: '';
        width: 4px;
        height: 28px;
        background-color: #03BCD7;
      }
    }

    .sub-scroll {
      overflow-y: scroll;
      width: 100%;
      height: calc(50% - 70px);

      &::-webkit-scrollbar {
        width: 0;
      }

      .sub-scroll-item {
        display: flex;
        align-items: center;
        width: 100%;
        margin-bottom: 10px;
        padding: 6px 0;
        background-color: #03BCD7;
        color: #fff;
        font-size: 16px;

        .span {
          width: 20%;
          text-align: center;
          word-wrap: break-word;
        }

        .span1 {
          flex: 40%;
        }
      }
    }

    .sub-table {
      width: 100%;
      height: calc(50% - 70px);
      background-color: rgba(3, 188, 215, .1);

      .sub-table-head {
        display: flex;
        align-items: center;
        width: 100%;
        height: 30px;
        background-color: rgba(3, 188, 215, .3);

        >span {
          flex: 1;
          text-align: center;
          color: #fff;
          font-size: 14px;
        }

        .span {
          flex: 3;
        }
      }

      .sub-table-scroll {
        overflow-y: scroll;
        width: 100%;
        height: calc(100% - 30px);

        &::-webkit-scrollbar {
          width: 0;
        }

        .sub-table-item {
          display: flex;
          align-items: center;
          width: 100%;
          padding: 6px 0;
          color: #fff;
          font-size: 14px;

          >span {
            flex: 1;
            text-align: center;
            word-wrap: break-word;
          }

          .span {
            flex: 3;
          }
        }
      }
    }
  }
}
</style>
