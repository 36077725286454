<template>
  <div class="form">
    <div class="form-box">
      <div class="form-close" @click="close">
        <span class="el-icon-close"></span>
      </div>
      <div class="form-title">
        <span>新生儿家庭访视记录表</span>
      </div>
      <div class="form-head" v-if="info">
        <span>姓名：{{ info.name || '-' }}</span>
        <span>编号：{{ info.xsrbh || '-' }}</span>
      </div>
      <div class="form-scroll">
        <table cellpadding="0" cellspacing="0" border class="form-table" v-if="info">
          <tr>
            <td colspan="1" class="form-td form-td-left">
              <span>性别</span>
            </td>
            <td colspan="2" class="form-td form-td-left">
              <el-radio-group v-model="info.xb">
                <el-radio label="1">男</el-radio>
                <el-radio label="2">女</el-radio>
              </el-radio-group>
            </td>
            <td colspan="1" class="form-td form-td-text">
              <span>出生日期</span>
            </td>
            <td colspan="3" class="form-td form-td-text">
              <el-date-picker v-model="info.csrq" size="small" type="date" placeholder="选择日期">
              </el-date-picker>
            </td>
          </tr>
          <tr>
            <td colspan="1" class="form-td form-td-left">
              <span>身份证号{{ info.sfzh || '-' }}</span>
            </td>
            <td colspan="2" class="form-td form-td-left">
              <span>体检日期</span>
              <el-date-picker v-model="info.tjrq" size="small" type="date" placeholder="选择日期">
              </el-date-picker>
            </td>
            <td colspan="1" class="form-td form-td-text">
              <span>家庭住址</span>
            </td>
            <td colspan="3" class="form-td form-td-text">
              <el-input v-if="show" size="small" class="input" v-model="info.jtzz" placeholder=""></el-input>
            </td>
          </tr>
          <tr>
            <td colspan="1" class="form-td form-td-left">
              <span>父亲</span>
            </td>
            <td colspan="1" class="form-td form-td-left">
              <span>姓名：</span>
              <el-input v-if="show" size="small" class="input1" v-model="info.fqname" placeholder=""></el-input>
            </td>
            <td colspan="1" class="form-td form-td-left">
              <span>职业：</span>
              <el-input v-if="show" size="small" class="input1" v-model="info.fqzy" placeholder=""></el-input>
            </td>
            <td colspan="1" class="form-td form-td-text">
              <span>联系电话：</span>
            </td>
            <td colspan="1" class="form-td form-td-text">
              <el-input v-if="show" size="small" class="input" v-model="info.fqlxdh" placeholder=""></el-input>
            </td>
            <td colspan="1" class="form-td form-td-text">
              <span>出生日期：</span>
            </td>
            <td colspan="1" class="form-td form-td-text">
              <el-date-picker v-if="show" v-model="info.fqcsrq" size="small" type="date" placeholder="选择日期">
              </el-date-picker>
            </td>
          </tr>
          <tr>
            <td colspan="1" class="form-td form-td-left">
              <span>母亲</span>
            </td>
            <td colspan="1" class="form-td form-td-left">
              <span>姓名：</span>
              <el-input v-if="show" size="small" class="input1" v-model="info.mqname" placeholder=""></el-input>
            </td>
            <td colspan="1" class="form-td form-td-left">
              <span>职业：</span>
              <el-input v-if="show" size="small" class="input1" v-model="info.mqzy" placeholder=""></el-input>
            </td>
            <td colspan="1" class="form-td form-td-text">
              <span>联系电话：</span>
            </td>
            <td colspan="3" class="form-td form-td-text">
              <el-input v-if="show" size="small" class="input" v-model="info.mqlxdh" placeholder=""></el-input>
            </td>
          </tr>
          <tr>
            <td colspan="1" class="form-td form-td-left">
              <span>出生孕周</span>
              <el-input v-if="show" size="small" class="input2" v-model="info.csyz" placeholder=""></el-input>
              <span class="unit">周</span>
            </td>
            <td colspan="6" class="form-td form-td-left">
              <div class="form-td-flex">
                <span>母亲妊娠期患病情况：</span>
                <el-checkbox-group v-model="info.mqyzqhzqk">
                  <el-checkbox label="1">无</el-checkbox>
                  <el-checkbox label="2">糖尿病</el-checkbox>
                  <el-checkbox label="3">妊娠期高血压</el-checkbox>
                  <el-checkbox label="4">其他</el-checkbox>
                </el-checkbox-group>
              </div>
            </td>
          </tr>
          <tr>
            <td colspan="2" class="form-td form-td-left">
              <span>助产机构名称：</span>
              <el-input v-if="show" size="small" class="input3" v-model="info.zcjgmc" placeholder=""></el-input>
            </td>
            <td colspan="5" class="form-td form-td-left">
              <div class="form-td-flex">
                <span>出生情况：</span>
                <el-checkbox-group v-model="info.csqk">
                  <el-checkbox label="1">顺产</el-checkbox>
                  <el-checkbox label="2">胎头吸引</el-checkbox>
                  <el-checkbox label="3">产钳</el-checkbox>
                  <el-checkbox label="4">剖宫</el-checkbox>
                  <el-checkbox label="5">双多胎 </el-checkbox>
                  <el-checkbox label="6">臀位</el-checkbox>
                  <el-checkbox label="7">其他</el-checkbox>
                </el-checkbox-group>
              </div>
            </td>
          </tr>
          <tr>
            <td colspan="2" class="form-td form-td-left">
              <span>新生儿窒息：</span>
              <el-radio-group v-model="info.xsezx">
                <el-radio label="1">无</el-radio>
                <el-radio label="2">有</el-radio>
              </el-radio-group>
            </td>
            <td colspan="5" class="form-td form-td-left">
              <div class="form-td-flex">
                <span>畸形：</span>
                <el-radio-group v-model="info.jx">
                  <el-radio label="1">无</el-radio>
                  <el-radio label="2">有</el-radio>
                </el-radio-group>
              </div>
            </td>
          </tr>
          <tr>
            <td colspan="7" class="form-td form-td-left">
              <span>新生儿听力筛查：</span>
              <el-radio-group v-model="info.xsetlsc">
                <el-radio label="1">通过</el-radio>
                <el-radio label="2">未通过</el-radio>
                <el-radio label="3">未筛查</el-radio>
                <el-radio label="4">不详</el-radio>
              </el-radio-group>
            </td>
          </tr>
          <tr>
            <td colspan="7" class="form-td form-td-left">
              <span>新生儿疾病筛查：</span>
              <el-radio-group v-model="info.xsrjbsc">
                <el-radio label="1">未进行</el-radio>
                <el-radio label="2">检查均阴性</el-radio>
                <el-radio label="3">甲低</el-radio>
                <el-radio label="4">苯丙酮尿症</el-radio>
                <el-radio label="5">其他遗传代谢病</el-radio>
              </el-radio-group>
            </td>
          </tr>
          <tr>
            <td colspan="2" class="form-td form-td-left">
              <span>新生儿出生体重：</span>
              <el-input v-if="show" size="small" class="input2" v-model="info.cstz" placeholder=""></el-input>
              <span class="unit">kg</span>
            </td>
            <td colspan="2" class="form-td form-td-left">
              <span>目前体重：</span>
              <el-input v-if="show" size="small" class="input2" v-model="info.mqtz" placeholder=""></el-input>
              <span class="unit">kg</span>
            </td>
            <td colspan="3" class="form-td form-td-left">
              <span>出生身长：</span>
              <el-input v-if="show" size="small" class="input2" v-model="info.cssc" placeholder=""></el-input>
              <span class="unit">cm</span>
            </td>
          </tr>
          <tr>
            <td colspan="3" class="form-td form-td-left">
              <span>喂养方式：</span>
              <el-radio-group v-model="info.wyfs">
                <el-radio label="1">纯母乳</el-radio>
                <el-radio label="2">混合</el-radio>
                <el-radio label="3">人工</el-radio>
              </el-radio-group>
            </td>
            <td colspan="1" class="form-td form-td-left">
              <span>吃奶量：</span>
              <el-input v-if="show" size="small" class="input2" v-model="info.cnl" placeholder=""></el-input>
              <span class="unit">mL/次</span>
            </td>
            <td colspan="3" class="form-td form-td-left">
              <span>吃奶次数：</span>
              <el-input v-if="show" size="small" class="input2" v-model="info.cncs" placeholder=""></el-input>
              <span class="unit">次/日</span>
            </td>
          </tr>
          <tr>
            <td colspan="2" class="form-td form-td-left">
              <span>喂养方式：</span>
              <el-radio-group v-model="info.wyfs">
                <el-radio label="1">无</el-radio>
                <el-radio label="2">有</el-radio>
              </el-radio-group>
            </td>
            <td colspan="2" class="form-td form-td-left">
              <span>大便: </span>
              <el-radio-group v-model="info.dbxz">
                <el-radio label="1">糊状</el-radio>
                <el-radio label="2">稀</el-radio>
                <el-radio label="3">其他</el-radio>
              </el-radio-group>
            </td>
            <td colspan="3" class="form-td form-td-left">
              <span>大便次数：</span>
              <el-input v-if="show" size="small" class="input2" v-model="info.dbcs" placeholder=""></el-input>
              <span class="unit">次/日</span>
            </td>
          </tr>
          <tr>
            <td colspan="2" class="form-td form-td-left">
              <span>体温：</span>
              <el-input v-if="show" size="small" class="input2" v-model="info.tw" placeholder=""></el-input>
              <span class="unit">℃</span>
            </td>
            <td colspan="2" class="form-td form-td-left">
              <span>心率: </span>
              <el-input v-if="show" size="small" class="input2" v-model="info.xl" placeholder=""></el-input>
              <span class="unit"> 次/分钟</span>
            </td>
            <td colspan="3" class="form-td form-td-left">
              <span>呼吸频率：</span>
              <el-input v-if="show" size="small" class="input2" v-model="info.hxpl" placeholder=""></el-input>
              <span class="unit">次/分</span>
            </td>
          </tr>
          <tr>
            <td colspan="2" class="form-td form-td-left">
              <span>面色：</span>
              <el-radio-group v-model="info.ms">
                <el-radio label="1">红润</el-radio>
                <el-radio label="2">黄染</el-radio>
                <el-radio label="3">其他</el-radio>
              </el-radio-group>
            </td>
            <td colspan="5" class="form-td form-td-left">
              <span>黄疸部位：</span>
              <el-radio-group v-model="info.hdbw">
                <el-radio label="1">无</el-radio>
                <el-radio label="2">面部</el-radio>
                <el-radio label="3">躯干</el-radio>
                <el-radio label="4">四肢</el-radio>
                <el-radio label="5">手足</el-radio>
              </el-radio-group>
            </td>
          </tr>
          <tr>
            <td colspan="7" class="form-td form-td-left">
              <span>前囟：</span>
              <el-input v-if="show" size="small" class="input2" v-model="info.qcc" placeholder=""></el-input>
              <span class="unit">cm</span>
              <el-input v-if="show" size="small" class="input2" v-model="info.qck" placeholder=""></el-input>
              <span class="unit">cm</span>
              <el-radio-group v-model="info.qclx" class="margin-left">
                <el-radio label="1">正常</el-radio>
                <el-radio label="2">膨隆</el-radio>
                <el-radio label="3">凹陷</el-radio>
                <el-radio label="4">其他</el-radio>
              </el-radio-group>
            </td>
          </tr>
          <tr>
            <td colspan="2" class="form-td form-td-left">
              <span>眼睛：</span>
              <el-radio-group v-model="info.yj">
                <el-radio label="1">未见异常</el-radio>
                <el-radio label="2">异常</el-radio>
              </el-radio-group>
            </td>
            <td colspan="5" class="form-td form-td-left">
              <span>四肢活动度：</span>
              <el-radio-group v-model="info.szhdd">
                <el-radio label="1">未见异常</el-radio>
                <el-radio label="2">异常</el-radio>
              </el-radio-group>
            </td>
          </tr>
          <tr>
            <td colspan="2" class="form-td form-td-left">
              <span>耳外观：{{ info.ewg }}</span>
            </td>
            <td colspan="5" class="form-td form-td-left">
              <span>颈部包块：</span>
              <el-radio-group v-model="info.jbbk">
                <el-radio label="1">无</el-radio>
                <el-radio label="2">有</el-radio>
              </el-radio-group>
            </td>
          </tr>
          <tr>
            <td colspan="2" class="form-td form-td-left">
              <span>鼻：</span>
              <el-radio-group v-model="info.nose">
                <el-radio label="1">未见异常</el-radio>
                <el-radio label="2">异常</el-radio>
              </el-radio-group>
            </td>
            <td colspan="5" class="form-td form-td-left">
              <span>皮肤：</span>
              <el-radio-group v-model="info.skin">
                <el-radio label="1">未见异常</el-radio>
                <el-radio label="2">湿疹</el-radio>
                <el-radio label="3">糜烂</el-radio>
                <el-radio label="4">其他</el-radio>
              </el-radio-group>
            </td>
          </tr>
          <tr>
            <td colspan="2" class="form-td form-td-left">
              <span>口腔：</span>
              <el-radio-group v-model="info.oralCavity">
                <el-radio label="1">未见异常</el-radio>
                <el-radio label="2">异常</el-radio>
              </el-radio-group>
            </td>
            <td colspan="5" class="form-td form-td-left">
              <span>肛门：</span>
              <el-radio-group v-model="info.anus">
                <el-radio label="1">未见异常</el-radio>
                <el-radio label="2">异常</el-radio>
              </el-radio-group>
            </td>
          </tr>
          <tr>
            <td colspan="2" class="form-td form-td-left">
              <span>心肺听诊：</span>
              <el-radio-group v-model="info.xftz">
                <el-radio label="1">未见异常</el-radio>
                <el-radio label="2">异常</el-radio>
              </el-radio-group>
            </td>
            <td colspan="5" class="form-td form-td-left">
              <span>胸部：</span>
              <el-radio-group v-model="info.chest">
                <el-radio label="1">未见异常</el-radio>
                <el-radio label="2">异常</el-radio>
              </el-radio-group>
            </td>
          </tr>
          <tr>
            <td colspan="2" class="form-td form-td-left">
              <span>腹部触诊：</span>
              <el-radio-group v-model="info.fbcz">
                <el-radio label="1">未见异常</el-radio>
                <el-radio label="2">异常</el-radio>
              </el-radio-group>
            </td>
            <td colspan="5" class="form-td form-td-left">
              <span>脊柱：</span>
              <el-radio-group v-model="info.jz">
                <el-radio label="1">未见异常</el-radio>
                <el-radio label="2">异常</el-radio>
              </el-radio-group>
            </td>
          </tr>
          <tr>
            <td colspan="7" class="form-td form-td-left">
              <span>外生殖器：</span>
              <el-radio-group v-model="info.wszq">
                <el-radio label="1">未见异常</el-radio>
                <el-radio label="2">异常</el-radio>
              </el-radio-group>
            </td>
          </tr>
          <tr>
            <td colspan="7" class="form-td form-td-left">
              <span>脐带：</span>
              <el-radio-group v-model="info.qd">
                <el-radio label="1">未脱</el-radio>
                <el-radio label="2">脱落</el-radio>
                <el-radio label="3">脐部有渗出</el-radio>
                <el-radio label="4">其他</el-radio>
              </el-radio-group>
            </td>
          </tr>
          <tr>
            <td colspan="7" class="form-td form-td-left">
              <span>转诊建议：</span>
              <el-radio-group v-model="info.zzjy">
                <el-radio label="1">无</el-radio>
                <el-radio label="2">有</el-radio>
              </el-radio-group>
              <span class="margin-left">原因：</span>
              <el-input v-if="show" size="small" class="input3" v-model="input" placeholder=""></el-input>
              <span class="margin-left">机构及科室：</span>
              <el-input v-if="show" size="small" class="input3" v-model="input" placeholder=""></el-input>
            </td>
          </tr>
          <tr>
            <td colspan="7" class="form-td form-td-left">
              <div class="form-td-flex">
                <span>指导：</span>
                <el-checkbox-group v-model="info.gulde">
                  <el-checkbox label="1">喂养指导</el-checkbox>
                  <el-checkbox label="2">发育指导</el-checkbox>
                  <el-checkbox label="3">防病指导</el-checkbox>
                  <el-checkbox label="4">预防伤害指导</el-checkbox>
                  <el-checkbox label="5">口腔保健指导</el-checkbox>
                  <el-checkbox label="6">其他</el-checkbox>
                </el-checkbox-group>
              </div>
            </td>
          </tr>

          <tr>
            <td colspan="3" class="form-td form-td-left">
              <span>本次访视日期：</span>
              <el-date-picker v-if="show" v-model="info.bcfsn" size="small" type="date" placeholder="选择日期">
              </el-date-picker>
            </td>
            <td colspan="4" class="form-td form-td-left">
              <span>下次随访地点：</span>
              <el-select v-if="show" size="small" v-model="info.xcsfdd" placeholder="请选择">
                <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
                </el-option>
              </el-select>
            </td>
          </tr>
          <tr>
            <td colspan="3" class="form-td form-td-left">
              <span>下次访视日期：</span>
              <el-date-picker v-if="show" v-model="info.xcfsn" size="small" type="date" placeholder="选择日期">
              </el-date-picker>
            </td>
            <td colspan="4" class="form-td form-td-left">
              <span>随访医生签名：</span>
              <el-select v-if="show" size="small" v-model="info.signPhotoUrl" placeholder="请选择">
                <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
                </el-option>
              </el-select>
            </td>
          </tr>
        </table>
      </div>
      <div class="form-button">
        <el-button type="primary" size="medium" @click="close">确认</el-button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
export default {
  data () {
    return {
      radio: null,
      date: '',
      checkList: [],
      input: '',
      options: [{
        value: '',
        label: ''
      }],
      value: '',
      show: true,
      params: {
        sfzh: '',
        ssjgbh: '',
        jgbh: '',
        ssjlid: ''
      },
      info: null
    }
  },
  computed: {
    ...mapState(['loginInfo'])
  },
  created () {
    console.log('选择信息：', this.$parent.selectInfo)
    this.params.ssjgbh = this.loginInfo.jgbh || ''
    this.params.jgbh = this.loginInfo.jgbh || ''
    this.params.sfzh = this.$parent.selectInfo.sfzh || ''
    this.getInfo()
  },
  methods: {
    async getInfo () {
      // 接口文档 https://app.apifox.com/project/2726972/apis/api-84409096
      try {
        const { data: res } = await this.$http.post('/api/jkct/findetxsrfsb', this.params)
        console.log('新生儿访视表', res)
        if (res.code !== 0) {
          return this.$message({
            message: res.msg || '暂无信息',
            type: 'warning'
          })
        }
        if (res.data === null) return
        // eslint-disable-next-line no-prototype-builtins
        if (res.data.hasOwnProperty('mqyzqhzqk')) {
          res.data.mqyzqhzqk = res.data.mqyzqhzqk.split(',')
        } else {
          this.$set(res.data, 'mqyzqhzqk', [])
        }
        // eslint-disable-next-line no-prototype-builtins
        if (res.data.hasOwnProperty('csqk')) {
          res.data.csqk = res.data.csqk.split(',')
        } else {
          this.$set(res.data, 'csqk', [])
        }
        // eslint-disable-next-line no-prototype-builtins
        if (res.data.hasOwnProperty('gulde')) {
          res.data.gulde = res.data.gulde.split(',')
        } else {
          this.$set(res.data, 'gulde', [])
        }
        this.info = res.data
      } catch (err) {
        console.log('新生儿访视表', err)
      }
    },
    close () {
      this.$parent.selectInfo = null
      this.$emit('close')
    }
  }
}
</script>

<style lang="less" scoped>
.form {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 888;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, .9);

  .form-box {
    position: relative;
    width: 80%;
    height: 95%;
    padding: 0 73px;
    border: 4px solid #21D6E0;
    box-sizing: border-box;

    .form-close {
      position: absolute;
      right: 30px;
      height: 50px;
      line-height: 50px;
      color: #fff;
      font-size: 20px;
      cursor: pointer;
    }

    .form-title {
      width: 100%;
      height: 60px;
      line-height: 60px;
      text-align: center;
      color: #01EEFD;
      font-size: 22px;
    }

    .form-head {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      height: 50px;
      color: #01EEFD;
      font-size: 22px;
    }

    .form-scroll {
      overflow-y: scroll;
      width: 100%;
      height: calc(100% - 180px);

      .form-table {
        width: 100%;
        border-color: #01EEFD;

        .unit {
          margin-left: 20px;
        }

        .form-td {
          padding: 12px 0;
          box-sizing: border-box;
          color: #01EEFD;
          font-size: 14px;
          min-width: 170px;
        }

        .form-td-left {
          padding-left: 11px;
          box-sizing: border-box;
        }

        .form-td-flex {
          display: flex;
          align-items: center;
        }

        .form-td-text {
          text-align: center;
        }

        .el-radio {
          color: #fff;
        }

        .input {
          width: 90%;
        }

        .input1 {
          width: 100px;
        }

        .input2 {
          width: 70px;
        }

        .input3 {
          width: 200px;
        }

        .margin-left {
          margin-left: 10px;
        }

        .el-checkbox {
          color: #fff;
        }
      }
    }

    .form-button {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 70px;
    }
  }
}
</style>
