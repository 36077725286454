<template>
  <div class="sub-page">
    <div class="sub-page-left">
      <div class="sub-page-item">
        <div class="title">
          <div class="title-top"></div>
          <div class="title-bottom">
            <div class="title-text">
              <span>辖区内公卫服务记录</span>
            </div>
            <div class="hide"></div>
            <div class="hide hide1"></div>
            <div class="hide hide2"></div>
            <div class="hide hide3"></div>
          </div>
        </div>

        <div class="scroll">
          <div class="scroll-box">
            <div class="scroll-list" v-for="(item, index) in 20" :key="index">
            <!-- <div class="scroll-list-hide"></div> -->
            <div class="scroll-cell">
              <div class="scroll-cell-text">
                <span>某某小区进行了65位老人的推荐。</span>
              </div>
              <div class="scroll-cell-text date">
                <span>时间：2022-06-08</span>
              </div>
            </div>
          </div>
          </div>
        </div>
      </div>

      <div class="sub-page-item sub-page-item1">
        <div class="title">
          <div class="title-top"></div>
          <div class="title-bottom">
            <div class="title-text">
              <span>实时数据统计</span>
            </div>
            <div class="hide"></div>
            <div class="hide hide1"></div>
            <div class="hide hide2"></div>
            <div class="hide hide3"></div>
          </div>
        </div>
        <div class="line">
          <div class="line-item">
            <img src="../../../assets/img/zhdp/data.png" class="line-img">
            <div class="line-info">
              <div class="line-info-text">
                <span>总体检</span>
                <span class="green">↑</span>
              </div>
              <div class="line-info-total">
                <span>87人</span>
              </div>
            </div>
          </div>

          <div class="line-item">
            <img src="../../../assets/img/zhdp/data.png" class="line-img">
            <div class="line-info">
              <div class="line-info-text">
                <span>今日体检</span>
                <span class="green">↑</span>
              </div>
              <div class="line-info-total">
                <span>87人</span>
              </div>
            </div>
          </div>
        </div>
        <div class="label">
          <div class="label-item">
            <div class="span">
              <span>监测记录</span>
            </div>
          </div>
          <div class="label-item">
            <div class="span">
              <span>实时异常分析</span>
            </div>
          </div>
        </div>
        <div class="echarts" id="echarts6"></div>
      </div>
    </div>

    <div class="sub-page-center">
      <div class="sub-page-item sub-page-item4">
        <div class="title">
          <div class="title-top"></div>
          <div class="title-bottom">
            <div class="title-text">
              <span>总览</span>
            </div>
            <div class="hide"></div>
            <div class="hide hide1"></div>
            <div class="hide hide2"></div>
            <div class="hide hide3"></div>
          </div>
        </div>

        <div class="sub-page-box">
          <div class="sub-page-column">
            <div class="sub-page-column-item background1">
              <div class="sub-page-column-text">
                <span>1234</span>
                <span>总人口数</span>
              </div>
            </div>

            <div class="sub-page-column-item background2">
              <div class="sub-page-column-text">
                <span>1234</span>
                <span>老年人专档</span>
              </div>
            </div>

            <div class="sub-page-column-item background3">
              <div class="sub-page-column-text">
                <span>1234</span>
                <span>家签人群</span>
              </div>
            </div>

            <div class="sub-page-column-item background4">
              <div class="sub-page-column-text">
                <span>1234</span>
                <span>慢病人群</span>
              </div>
            </div>
          </div>
          <img src="../../../assets/img/zhdp/overview-icon.png" class="overview-icon">
          <div class="sub-page-column">
            <div class="sub-page-column-item background5">
              <div class="sub-page-column-text">
                <span class="span">1234</span>
                <span class="span">建档人数</span>
              </div>
            </div>

            <div class="sub-page-column-item background6">
              <div class="sub-page-column-text">
                <span class="span">1234</span>
                <span class="span">体检人数</span>
              </div>
            </div>

            <div class="sub-page-column-item background7">
              <div class="sub-page-column-text">
                <span class="span">1234</span>
                <span class="span">签约人数</span>
              </div>
            </div>

            <div class="sub-page-column-item background8">
              <div class="sub-page-column-text">
                <span class="span">1234</span>
                <span class="span">监测人数</span>
              </div>
            </div>
          </div>
          <div class="sub-page-charts">
            <div class="sub-page-charts-box">
              <div class="sub-page-charts-item" id="echarts9"></div>
              <div class="sub-page-charts-title">
                <span>建档率</span>
              </div>
            </div>
            <div class="sub-page-charts-box">
              <div class="sub-page-charts-item" id="echarts10"></div>
              <div class="sub-page-charts-title">
                <span>体检率</span>
              </div>
            </div>
            <div class="sub-page-charts-box">
              <div class="sub-page-charts-item" id="echarts11"></div>
              <div class="sub-page-charts-title">
                <span>签约率</span>
              </div>
            </div>
            <div class="sub-page-charts-box">
              <div class="sub-page-charts-item" id="echarts12"></div>
              <div class="sub-page-charts-title">
                <span>监测率</span>
              </div>
            </div>

          </div>
        </div>
      </div>

      <div class="sub-page-item sub-page-item5">
        <div class="title">
          <div class="title-top"></div>
          <div class="title-bottom">
            <div class="title-text">
              <span>总览</span>
            </div>
            <div class="hide"></div>
            <div class="hide hide1"></div>
            <div class="hide hide2"></div>
            <div class="hide hide3"></div>
          </div>
        </div>

        <div class="sub-page-map">
          <Map></Map>
        </div>
      </div>
    </div>

    <div class="sub-page-right">
      <div class="sub-page-item sub-page-item2">
        <div class="title">
          <div class="title-top"></div>
          <div class="title-bottom">
            <div class="title-text">
              <span>体检年度分析</span>
            </div>
            <div class="hide"></div>
            <div class="hide hide1"></div>
            <div class="hide hide2"></div>
            <div class="hide hide3"></div>
          </div>
        </div>
        <div class="echarts1" id="echarts4"></div>
      </div>
      <div class="sub-page-item sub-page-item2">
        <div class="title">
          <div class="title-top"></div>
          <div class="title-bottom">
            <div class="title-text">
              <span>体检异常结果分析</span>
            </div>
            <div class="hide"></div>
            <div class="hide hide1"></div>
            <div class="hide hide2"></div>
            <div class="hide hide3"></div>
          </div>
        </div>
        <div class="echarts1" id="echarts5"></div>
      </div>
      <div class="sub-page-item sub-page-item3">
        <div class="title">
          <div class="title-top"></div>
          <div class="title-bottom">
            <div class="title-text">
              <span>实时数据统计</span>
            </div>
            <div class="hide"></div>
            <div class="hide hide1"></div>
            <div class="hide hide2"></div>
            <div class="hide hide3"></div>
          </div>
        </div>
        <div class="charts">
          <div class="charts-title">
            <span>高血压</span>
          </div>
          <div class="charts-title charts-title1">
            <span>糖尿病</span>
          </div>
          <div class="charts-title charts-title2">
            <span>糖尿病</span>
          </div>
          <div class="charts-item" id="echarts8"></div>
          <div class="charts-item" id="echarts7"></div>
          <div class="charts-item charts-item1" id="echarts3"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Map from './map.vue'
export default {
  data () {
    return {
      myChart: null,
      myChart1: null,
      myChart2: null,
      myChart3: null,

      myChart4: null,
      myChart5: null,
      myChart6: null,
      myChart7: null,

      myChart8: null,
      myChart9: null,
      myChart10: null,
      myChart11: null,

      myChart12: null,
      myChart13: null,
      myChart14: null,
      myChart15: null,
      tableData: [{
        date: '2016-05-02',
        name: '王小虎',
        address: ''
      }]
    }
  },
  components: {
    Map
  },
  mounted () {
    this.$nextTick(() => {
      // this.canvas()
      // this.canvas1()
      // this.canvas2()
      this.canvas3()
      this.canvas4()
      this.canvas5()
      this.canvas6()
      this.canvas7()
      this.canvas8()
      this.canvas9()
      this.canvas10()
      this.canvas11()
      this.canvas12()
      // this.canvas13()
      // this.canvas14()
      // this.canvas15()
    })
    window.addEventListener('resize', this.getresize)
  },
  beforeDestroy () {
    // 页面销毁时销毁监听事件
    window.removeEventListener('resize', this.getresize)
  },
  methods: {
    getresize () {
      setTimeout(() => {
        this.myChart3.resize()
        this.myChart4.resize()
        this.myChart5.resize()
        this.myChart6.resize()
        this.myChart7.resize()
        this.myChart8.resize()
        this.myChart9.resize()
        this.myChart10.resize()
        this.myChart11.resize()
        this.myChart12.resize()
      }, 500)
    },
    canvas () {
      var chartDom = document.getElementById('echarts')
      this.myChart = this.$echarts.init(chartDom)
      const option = {
        title: {
          // text: 'Referer of a Website',
          // subtext: 'Fake Data',
          // left: 'center'
        },
        tooltip: {
          trigger: 'item'
        },
        legend: {
          // orient: 'right',
          // right: 'right',
          orient: 'vertical',
          x: '70%',
          y: 'bottom',
          textStyle: {
            fontSize: 12,
            color: '#FFFFFF'
          },
          padding: [0, 37, 13, 0]
        },
        series: [
          {
            name: '评估长者',
            type: 'pie',
            radius: '50%',
            data: [
              { value: 1048, name: '能力完好1' },
              { value: 735, name: '能力完好2' },
              { value: 580, name: '能力完好3' },
              { value: 484, name: '能力完好4' },
              { value: 300, name: '能力完好5' }
            ],
            center: ['30%', '60%'],
            label: { // 删除指示线
              show: false
            }
          }
        ]
      }

      option && this.myChart.setOption(option)
    },
    canvas1 () {
      var chartDom = document.getElementById('echarts1')
      this.myChart1 = this.$echarts.init(chartDom)
      const option = {
        title: {
          // text: 'Referer of a Website',
          // subtext: 'Fake Data',
          // left: 'center'
        },
        tooltip: {
          trigger: 'item'
        },
        legend: {
          // orient: 'right',
          // right: 'right',
          orient: 'vertical',
          x: '70%',
          y: 'bottom',
          textStyle: {
            fontSize: 12,
            color: '#FFFFFF'
          },
          padding: [0, 37, 13, 0]
        },
        series: [
          {
            name: '评估长者',
            type: 'pie',
            radius: '50%',
            data: [
              { value: 1048, name: '机构' },
              { value: 735, name: '空巢' },
              { value: 580, name: '孤寡' },
              { value: 484, name: '与子女同住' },
              { value: 300, name: '独居' },
              { value: 300, name: '与配偶同住' }
            ],
            center: ['30%', '60%'],
            label: { // 删除指示线
              show: false
            }
          }
        ]
      }

      option && this.myChart1.setOption(option)
    },
    canvas2 () {
      var chartDom = document.getElementById('echarts2')
      this.myChart2 = this.$echarts.init(chartDom)
      const option = {
        title: {
          // text: 'Referer of a Website',
          // subtext: 'Fake Data',
          // left: 'center'
        },
        tooltip: {
          trigger: 'item'
        },
        legend: {
          // orient: 'right',
          // right: 'right',
          orient: 'vertical',
          x: '70%',
          y: 'bottom',
          textStyle: {
            fontSize: 12,
            color: '#FFFFFF'
          },
          padding: [0, 37, 13, 0]
        },
        series: [
          {
            name: '评估长者',
            type: 'pie',
            radius: '50%',
            data: [
              { value: 1048, name: '瘫痪' },
              { value: 735, name: '半瘫痪' },
              { value: 580, name: '自理' }
            ],
            center: ['30%', '60%'],
            label: { // 删除指示线
              show: false
            }
          }
        ]
      }

      option && this.myChart2.setOption(option)
    },
    canvas3 () {
      var chartDom = document.getElementById('echarts3')
      this.myChart3 = this.$echarts.init(chartDom)
      const option = {
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'shadow'
          }
        },
        legend: {
          orient: 'horizontal',
          // x: '80%',
          y: 'top',
          x: 'right',
          textStyle: {
            fontSize: 12,
            color: '#FFFFFF'
          },
          padding: [25, 34, 0, 0]
        },
        grid: {
          show: false,
          top: '30%', // 一下数值可为百分比也可为具体像素值
          right: '4%',
          bottom: '14%',
          left: '10%'
        },
        xAxis: {
          type: 'category',
          data: ['慢性病1', '慢性病2', '慢性病3', '慢性病4', '慢性病5'],
          axisLabel: {
            show: true,
            color: '#fff',
            fontSize: 13,
            interval: 0
          },
          axisLine: {
            show: true,
            lineStyle: {
              color: ['#fff']
            },
            textStyle: {
              color: '#04B4D0',
              fontSize: 13
            }
          },
          axisTick: {
            // y轴刻度线
            show: false
          }
        },
        yAxis: {
          type: 'value',
          axisLine: {
            show: true,
            lineStyle: {
              color: '#447DC3'
            }
          },
          axisLabel: {
            show: true,
            color: '#fff',
            fontSize: 14
          },
          splitLine: {
            show: false,
            lineStyle: {
              color: '#447DC3'
            }
          }
        },
        series: [
          {
            name: '慢性病',
            data: [120, 200, 150, 80, 70],
            type: 'bar',
            barWidth: 14,
            label: {
              show: true,
              position: 'top',
              color: '#00ECA7',
              fontSize: '14'
            },
            itemStyle: {
              color: new this.$echarts.graphic.LinearGradient(0, 0, 0, 1, [{
                offset: 0,
                color: '#00F0A7'
              }, {
                offset: 1,
                color: '#0064C2'
              }]),
              borderRadius: [4, 4, 4, 4]
            }
          }
        ]
      }

      option && this.myChart3.setOption(option)
    },
    canvas4 () {
      var chartDom = document.getElementById('echarts4')
      this.myChart4 = this.$echarts.init(chartDom)
      const option = {
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'shadow'
          }
        },
        legend: {
          orient: 'horizontal',
          // x: '80%',
          y: 'top',
          x: 'right',
          textStyle: {
            fontSize: 12,
            color: '#FFFFFF'
          },
          padding: [25, 34, 0, 0]
        },
        grid: {
          show: false,
          top: '30%', // 一下数值可为百分比也可为具体像素值
          right: '4%',
          bottom: '20%',
          left: '13%'
        },
        xAxis: {
          type: 'category',
          data: ['机构1', '机构2', '机构3', '机构4', '机构5'],
          axisLabel: {
            show: true,
            color: '#fff',
            fontSize: 13,
            interval: 0
          },
          axisLine: {
            show: true,
            lineStyle: {
              color: ['#fff']
            },
            textStyle: {
              color: '#04B4D0',
              fontSize: 13
            }
          },
          axisTick: {
            // y轴刻度线
            show: false
          }
        },
        yAxis: {
          type: 'value',
          axisLine: {
            show: true,
            lineStyle: {
              color: '#447DC3'
            }
          },
          axisLabel: {
            show: true,
            color: '#fff',
            fontSize: 14
          },
          splitLine: {
            show: false,
            lineStyle: {
              color: '#447DC3'
            }
          }
        },
        series: [
          {
            name: '男',
            data: [120, 200, 150, 80, 70],
            type: 'bar',
            barWidth: 14,
            itemStyle: {
              color: new this.$echarts.graphic.LinearGradient(0, 0, 0, 1, [{
                offset: 0,
                color: '#F7903C'
              }, {
                offset: 1,
                color: '#423635'
              }]),
              borderRadius: [4, 4, 4, 4]
            }
          },
          {
            name: '女',
            data: [120, 200, 150, 80, 70],
            type: 'bar',
            barWidth: 14,
            itemStyle: {
              color: new this.$echarts.graphic.LinearGradient(0, 0, 0, 1, [{
                offset: 0,
                color: '#02DFF1'
              }, {
                offset: 1,
                color: '#074261'
              }]),
              borderRadius: [4, 4, 4, 4]
            }
          }
        ]
      }

      option && this.myChart4.setOption(option)
    },
    canvas5 () {
      var chartDom = document.getElementById('echarts5')
      this.myChart5 = this.$echarts.init(chartDom)
      const sideData = [106, 236, 226, 196, 166, 10]
      const option = {
        tooltip: {
          trigger: 'item'
        },
        grid: {
          top: '19%',
          left: '8%',
          right: '4%',
          bottom: '5%',
          containLabel: true
        },
        label: {
          show: false,
          color: '#01EEFD'
        },
        dataZoom: [
          {
            xAxisIndex: 0, // 这里是从X轴的0刻度开始
            disabled: false,
            zoomLock: true,
            preventDefaultMouseMove: true,
            filterMode: 'empty',
            type: 'inside',
            startValue: 0, // 从头开始。
            endValue: 2 // 一次性展示6个。
          }

        ],
        xAxis: {
          data: ['血压异常', '肾功能异常', '血糖异常', 'B超异常', '肝功能异常', '心电图异常'],
          axisTick: {
            show: false
          },
          axisLine: {
          },
          axisLabel: {
            fontSize: 12,
            color: '#fff'
          }
        },
        yAxis: {
          splitLine: {
            show: true,
            lineStyle: {
              color: '#447DC3'
            }
          },
          axisLabel: {
            // 调整y轴的lable
            fontSize: 12,
            color: '#BAE0FF',
            show: true
          },
          axisLine: {
            lineStyle: {
              color: '#01EEFD'
            }
          }
        },
        series: [
          {
            name: '资源数量统计',
            type: 'bar',
            showSymbol: false,
            data: sideData,
            barWidth: 10, // 柱图宽度
            itemStyle: { // 左面
              color: params => {
                const colors = [
                  ['#FB7371', '#FF997C'],
                  ['#8D3510', '#FFA783'],
                  ['#651180', '#D099FF'],
                  ['#4B8D10', '#83FFC0'],
                  ['#8D104B', '#FF83D8'],
                  ['#7F610F', '#FFE899']
                ]
                const colorItem = colors[params.dataIndex]
                return new this.$echarts.graphic.LinearGradient(0, 0, 0, 1, [{
                  offset: 0,
                  color: colorItem[1]
                }, {
                  offset: 1,
                  color: colorItem[0]
                }])
              }
            },
            textStyle: { // 数值样式
              color: 'black',
              fontSize: 16
            }
          }, {
            name: '资源数量统计',
            tooltip: {
              show: true
            },
            type: 'bar',
            barWidth: 10,
            itemStyle: { // 右面
              color: params => {
                const colors = [
                  ['#FB7371', '#FF997C'],
                  ['#8D3510', '#FFA783'],
                  ['#651180', '#D099FF'],
                  ['#4B8D10', '#83FFC0'],
                  ['#8D104B', '#FF83D8'],
                  ['#7F610F', '#FFE899']
                ]
                const colorItem = colors[params.dataIndex]
                return new this.$echarts.graphic.LinearGradient(0, 0, 0, 1, [{
                  offset: 0,
                  color: colorItem[1]
                }, {
                  offset: 1,
                  color: colorItem[0]
                }])
              }
            },
            data: sideData,
            barGap: 0
          }, {
            name: 'b',
            tooltip: {
              show: false
            },
            type: 'pictorialBar',
            itemStyle: { // 顶部
              color: params => {
                const colors = [
                  ['#FB7371', '#FF997C'],
                  ['#8D3510', '#FFA783'],
                  ['#651180', '#D099FF'],
                  ['#4B8D10', '#83FFC0'],
                  ['#8D104B', '#FF83D8'],
                  ['#7F610F', '#FFE899']
                ]
                const colorItem = colors[params.dataIndex]
                return new this.$echarts.graphic.LinearGradient(0, 0, 0, 1, [{
                  offset: 0,
                  color: colorItem[1]
                }, {
                  offset: 1,
                  color: colorItem[0]
                }])
              }
            },
            label: {
              show: true, // 开启显示
              position: 'top', // 在上方显示
              color: '#01EEFD',
              fontSize: 14
            },
            symbol: 'diamond',
            symbolSize: ['20.5', '13'],
            symbolOffset: [0, '-38%'],
            symbolPosition: 'end',
            data: sideData,
            z: 3
          }, {
            name: 'd',
            tooltip: {
              show: false
            },
            type: 'pictorialBar',
            itemStyle: { // 底部
              color: params => {
                const colors = [
                  ['#FB7371', '#FF997C'],
                  ['#8D3510', '#FFA783'],
                  ['#651180', '#D099FF'],
                  ['#4B8D10', '#83FFC0'],
                  ['#8D104B', '#FF83D8'],
                  ['#7F610F', '#FFE899']
                ]
                const colorItem = colors[params.dataIndex]
                return new this.$echarts.graphic.LinearGradient(0, 0, 0, 1, [{
                  offset: 0,
                  color: colorItem[1]
                }, {
                  offset: 1,
                  color: colorItem[0]
                }])
              },
              borderColor: '#000',
              borderWidth: 0.1,
              label: {
                show: false, // 开启显示
                position: 'top', // 在上方显示
                color: '#01EEFD',
                fontSize: 14,
                offset: [0, -2]
              }
            },
            symbol: 'diamond',
            symbolSize: ['20.5', '17'],
            symbolOffset: [0, '2.5'],
            symbolPosition: 'start',
            data: sideData,
            z: 0
          }
        ]
      }

      option && this.myChart5.setOption(option)
    },
    canvas6 () {
      var chartDom = document.getElementById('echarts6')
      this.myChart6 = this.$echarts.init(chartDom)
      const option = {
        grid: {
          containLabel: true,
          top: '0%', // 一下数值可为百分比也可为具体像素值
          right: '14%',
          bottom: '0%',
          left: '8%'
        },
        label: {
          show: true,
          color: '#01EEFD',
          fontSize: 14,
          position: 'right',
          height: 100,
          offset: [0, 0]
        },
        xAxis: [
          // x轴数据设置
          {
            type: 'value',
            splitLine: {
              show: false
            },
            axisLine: {
              lineStyle: {
                color: '#021B63'
              }
            }
          }
        ],
        yAxis: [
          {
            type: 'category',
            offset: 0,
            axisLine: {
              show: false,
              // 这是x轴文字颜色
              lineStyle: {
                color: '#01EEFD'
              }
            },
            data: ['身高体重', '血压', '生化', '血常规', '尿常规', 'B超', '心电图', '中医辨识'],
            axisTick: {
              show: false
            }
          }
        ],
        series: [
          {
            data: [120, 200, 150, 80, 70, 150, 80, 70],
            type: 'bar',
            barWidth: 12,
            label: {
              show: true
            },
            itemStyle: {
              color: params => {
                const colors = [
                  ['#FB7371', '#FF997C'],
                  ['#8D3510', '#FFA783'],
                  ['#651180', '#D099FF'],
                  ['#4B8D10', '#83FFC0'],
                  ['#8D104B', '#FF83D8'],
                  ['#7F610F', '#FFE899'],
                  ['#108D89', '#83FFE9'],
                  ['#108D89', '#83FFE9']
                ]
                const colorItem = colors[params.dataIndex]
                return new this.$echarts.graphic.LinearGradient(1, 0, 0, 0, [{
                  offset: 0,
                  color: colorItem[1]
                }, {
                  offset: 1,
                  color: colorItem[0]
                }])
              },
              borderRadius: [10, 10, 10, 10]
            },
            showBackground: true,
            backgroundStyle: {
              color: 'rgba(180, 180, 180, 0.2)'
            }
          }
        ]
      }

      option && this.myChart6.setOption(option)
    },
    canvas7 () {
      var chartDom = document.getElementById('echarts7')
      this.myChart7 = this.$echarts.init(chartDom)
      const option = {
        grid: {
          containLabel: true,
          top: '15%', // 一下数值可为百分比也可为具体像素值
          right: '14%',
          bottom: '0%',
          left: '8%'
        },
        label: {
          show: true,
          color: '#01EEFD',
          fontSize: 14,
          position: 'right',
          height: 100,
          offset: [0, 0]
        },
        xAxis: [
          // x轴数据设置
          {
            type: 'value',
            splitLine: {
              show: false
            },
            axisLine: {
              lineStyle: {
                color: '#021B63'
              }
            }
          }
        ],
        yAxis: [
          {
            type: 'category',
            offset: 0,
            axisLine: {
              show: false,
              // 这是x轴文字颜色
              lineStyle: {
                color: '#fff'
              }
            },
            data: ['高危', '中危', '低危'],
            axisTick: {
              show: false
            }
          }
        ],
        series: [
          {
            data: [120, 200, 150],
            type: 'bar',
            barWidth: 12,
            label: {
              show: true
            },
            itemStyle: {
              color: params => {
                const colors = [
                  ['#964F24', '#F86300'],
                  ['#1867A6', '#008FFC'],
                  ['#3A897F', '#43D4B4']
                ]
                const colorItem = colors[params.dataIndex]
                return new this.$echarts.graphic.LinearGradient(1, 0, 0, 0, [{
                  offset: 0,
                  color: colorItem[1]
                }, {
                  offset: 1,
                  color: colorItem[0]
                }])
              },
              borderRadius: [10, 10, 10, 10]
            },
            showBackground: true,
            backgroundStyle: {
              color: 'rgba(180, 180, 180, 0.2)'
            }
          }
        ]
      }
      option && this.myChart7.setOption(option)
    },
    canvas8 () {
      var chartDom = document.getElementById('echarts8')
      this.myChart8 = this.$echarts.init(chartDom)
      const option = {
        color: ['#FA6400', '#44D7B6', '#0091FF'],
        title: {
          text: '总人数\n23459',
          left: 'center',
          top: 'center',
          textStyle: {
            fontSize: 12,
            color: '#FFFFFF'
          }
        },
        tooltip: {
          trigger: 'item'
        },
        legend: {
          y: 'bottom',
          x: 'left',
          textStyle: {
            fontSize: 12,
            color: '#FFFFFF'
          }
        },
        series: [
          {
            name: '高血压',
            type: 'pie',
            radius: ['30%', '40%'],
            data: [
              { value: 300, name: '高危' },
              { value: 300, name: '低危' },
              { value: 300, name: '中危' }
            ],
            label: {
              formatter: '{b}{c}人',
              fontSize: 12,
              color: '#FFFFFF'
            }
          }
        ]
      }

      option && this.myChart8.setOption(option)
    },
    canvas9 () {
      var chartDom = document.getElementById('echarts9')
      this.myChart9 = this.$echarts.init(chartDom)
      const option = {
        color: ['#00F6DF'],
        title: {
          text: '总人数\n23459',
          left: 'center',
          top: 'right',
          textStyle: {
            fontSize: 12,
            color: '#FFFFFF'
          }
        },
        series: [
          {
            type: 'gauge',
            startAngle: 90,
            endAngle: -270,
            pointer: {
              show: false
            },
            progress: {
              show: true,
              overlap: false,
              roundCap: true,
              clip: false
            },
            axisLine: {
              lineStyle: {
                width: 6,
                color: [
                  [1, '#929FC6']
                ]
              }
            },
            splitLine: {
              show: false,
              distance: 0,
              length: 10
            },
            axisTick: {
              show: false
            },
            axisLabel: {
              show: false,
              distance: 50
            },
            data: [
              {
                value: 45,
                detail: {
                  valueAnimation: true,
                  offsetCenter: ['0%', '0%']
                }
              }
            ],
            detail: {
              fontSize: 14,
              color: 'rgba(24, 205, 253, 1)',
              fontWeight: '400',
              formatter: '{value}%'
            }
          }
        ]
      }

      option && this.myChart9.setOption(option)
    },
    canvas10 () {
      var chartDom = document.getElementById('echarts10')
      this.myChart10 = this.$echarts.init(chartDom)
      const option = {
        color: ['#00F6DF'],
        title: {
          text: '总人数\n23459',
          left: 'center',
          top: 'right',
          textStyle: {
            fontSize: 12,
            color: '#FFFFFF'
          }
        },
        series: [
          {
            type: 'gauge',
            startAngle: 90,
            endAngle: -270,
            pointer: {
              show: false
            },
            progress: {
              show: true,
              overlap: false,
              roundCap: true,
              clip: false
            },
            axisLine: {
              lineStyle: {
                width: 6,
                color: [
                  [1, '#929FC6']
                ]
              }
            },
            splitLine: {
              show: false,
              distance: 0,
              length: 10
            },
            axisTick: {
              show: false
            },
            axisLabel: {
              show: false,
              distance: 50
            },
            data: [
              {
                value: 45,
                detail: {
                  valueAnimation: true,
                  offsetCenter: ['0%', '0%']
                }
              }
            ],
            detail: {
              fontSize: 14,
              color: 'rgba(24, 205, 253, 1)',
              fontWeight: '400',
              formatter: '{value}%'
            }
          }
        ]
      }

      option && this.myChart10.setOption(option)
    },
    canvas11 () {
      var chartDom = document.getElementById('echarts11')
      this.myChart11 = this.$echarts.init(chartDom)
      const option = {
        color: ['#00F6DF'],
        title: {
          text: '总人数\n23459',
          left: 'center',
          top: 'right',
          textStyle: {
            fontSize: 12,
            color: '#FFFFFF'
          }
        },
        series: [
          {
            type: 'gauge',
            startAngle: 90,
            endAngle: -270,
            pointer: {
              show: false
            },
            progress: {
              show: true,
              overlap: false,
              roundCap: true,
              clip: false
            },
            axisLine: {
              lineStyle: {
                width: 6,
                color: [
                  [1, '#929FC6']
                ]
              }
            },
            splitLine: {
              show: false,
              distance: 0,
              length: 10
            },
            axisTick: {
              show: false
            },
            axisLabel: {
              show: false,
              distance: 50
            },
            data: [
              {
                value: 45,
                detail: {
                  valueAnimation: true,
                  offsetCenter: ['0%', '0%']
                }
              }
            ],
            detail: {
              fontSize: 14,
              color: 'rgba(24, 205, 253, 1)',
              fontWeight: '400',
              formatter: '{value}%'
            }
          }
        ]
      }

      option && this.myChart11.setOption(option)
    },
    canvas12 () {
      var chartDom = document.getElementById('echarts12')
      this.myChart12 = this.$echarts.init(chartDom)
      const option = {
        color: ['#00F6DF'],
        title: {
          text: '总人数\n23459',
          left: 'center',
          top: 'right',
          textStyle: {
            fontSize: 12,
            color: '#FFFFFF'
          }
        },
        series: [
          {
            type: 'gauge',
            startAngle: 90,
            endAngle: -270,
            pointer: {
              show: false
            },
            progress: {
              show: true,
              overlap: false,
              roundCap: true,
              clip: false
            },
            axisLine: {
              lineStyle: {
                width: 6,
                color: [
                  [1, '#929FC6']
                ]
              }
            },
            splitLine: {
              show: false,
              distance: 0,
              length: 10
            },
            axisTick: {
              show: false
            },
            axisLabel: {
              show: false,
              distance: 50
            },
            data: [
              {
                value: 45,
                detail: {
                  valueAnimation: true,
                  offsetCenter: ['0%', '0%']
                }
              }
            ],
            detail: {
              fontSize: 14,
              color: 'rgba(24, 205, 253, 1)',
              fontWeight: '400',
              formatter: '{value}%'
            }
          }
        ]
      }

      option && this.myChart12.setOption(option)
    },
    canvas13 () {
      var chartDom = document.getElementById('echarts13')
      this.myChart13 = this.$echarts.init(chartDom)
      const option = {
        color: ['#5470C6'],
        series: [
          {
            type: 'gauge',
            startAngle: 90,
            endAngle: -270,
            pointer: {
              show: false
            },
            progress: {
              show: true,
              overlap: false,
              roundCap: true,
              clip: false
            },
            axisLine: {
              lineStyle: {
                width: 15,
                color: [
                  [1, '#929FC6']
                ]
              }
            },
            splitLine: {
              show: false,
              distance: 0,
              length: 10
            },
            axisTick: {
              show: false
            },
            axisLabel: {
              show: false,
              distance: 50
            },
            data: [
              {
                value: 0,
                detail: {
                  valueAnimation: true,
                  offsetCenter: ['0%', '0%']
                }
              }
            ],
            detail: {
              fontSize: 30,
              color: 'rgba(24, 205, 253, 1)',
              fontWeight: '400',
              formatter: '{value}%'
            }
          }
        ]
      }

      option && this.myChart13.setOption(option)
    },
    canvas14 () {
      var chartDom = document.getElementById('echarts14')
      this.myChart14 = this.$echarts.init(chartDom)
      const option = {
        color: ['#5470C6'],
        series: [
          {
            type: 'gauge',
            startAngle: 90,
            endAngle: -270,
            pointer: {
              show: false
            },
            progress: {
              show: true,
              overlap: false,
              roundCap: true,
              clip: false
            },
            axisLine: {
              lineStyle: {
                width: 15,
                color: [
                  [1, '#929FC6']
                ]
              }
            },
            splitLine: {
              show: false,
              distance: 0,
              length: 10
            },
            axisTick: {
              show: false
            },
            axisLabel: {
              show: false,
              distance: 50
            },
            data: [
              {
                value: 0,
                detail: {
                  valueAnimation: true,
                  offsetCenter: ['0%', '0%']
                }
              }
            ],
            detail: {
              fontSize: 30,
              color: 'rgba(24, 205, 253, 1)',
              fontWeight: '400',
              formatter: '{value}%'
            }
          }
        ]
      }

      option && this.myChart14.setOption(option)
    },
    canvas15 () {
      var chartDom = document.getElementById('echarts15')
      this.myChart15 = this.$echarts.init(chartDom)
      const option = {
        color: ['#5470C6'],
        series: [
          {
            type: 'gauge',
            startAngle: 90,
            endAngle: -270,
            pointer: {
              show: false
            },
            progress: {
              show: true,
              overlap: false,
              roundCap: true,
              clip: false
            },
            axisLine: {
              lineStyle: {
                width: 15,
                color: [
                  [1, '#929FC6']
                ]
              }
            },
            splitLine: {
              show: false,
              distance: 0,
              length: 10
            },
            axisTick: {
              show: false
            },
            axisLabel: {
              show: false,
              distance: 50
            },
            data: [
              {
                value: 0,
                detail: {
                  valueAnimation: true,
                  offsetCenter: ['0%', '0%']
                }
              }
            ],
            detail: {
              fontSize: 30,
              color: 'rgba(24, 205, 253, 1)',
              fontWeight: '400',
              formatter: '{value}%'
            }
          }
        ]
      }

      option && this.myChart15.setOption(option)
    },
    back () {
      this.$router.go(-1)
    },
    tabClick (index) {
      this.tabIndex = index
    }
  }
}
</script>

<style lang="less" scoped>
.sub-page {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 100%;

  .sub-page-left {
    width: 506px;
    height: 100%;
  }

  .sub-page-center {
    width: calc(100% - 1032px);
    height: 100%;
  }

  .sub-page-right {
    width: 506px;
    height: 100%;
  }

  .sub-page-item {
    overflow: hidden;
    width: 100%;
    height: calc(100% - 480px);
    padding: 10px;
    padding-right: 0;
    background-color: #021B63;
    box-sizing: border-box;

    .title {
      height: 36px;

      .title-top {
        position: relative;
        width: 87px;
        height: 6px;
        background-color: #03BCD7;
        transform: skew(30deg);

        &::before {
          position: absolute;
          top: 0;
          left: -4px;
          content: '';
          width: 10px;
          height: 6px;
          background-color: #021B63;
          transform: skew(-50deg);
        }
      }

      .title-bottom {
        position: relative;
        width: 320px;
        height: 30px;
        padding-left: 34px;
        transform: skew(30deg);
        background: linear-gradient(97deg, #0177CD 0%, #024073 100%);
        box-sizing: border-box;

        &::before {
          position: absolute;
          top: 0;
          left: -8px;
          content: '';
          width: 12px;
          height: 10px;
          background-color: #021B63;
          transform: skew(-58deg);
        }

        .title-text {
          height: 30px;
          line-height: 30px;
          color: #fff;
          font-size: 20px;
          font-weight: bold;
          transform: skew(-30deg);
        }

        .hide {
          position: absolute;
          top: 0;
          right: 34px;
          width: 14px;
          height: 30px;
          background-color: #021B63;
        }

        .hide1 {
          width: 6px;
          right: 24px;
        }

        .hide2 {
          width: 6px;
          right: 14px;
        }

        .hide3 {
          width: 6px;
          right: 4px;
        }
      }
    }

    .scroll {
      overflow: hidden;
      width: calc(100% - 10px);
      height: calc(100% - 60px);
      margin-top: 20px;
      margin-left: 10px;
      padding-right: 10px;
      box-sizing: border-box;

      .scroll-box {
        display: flex;
        flex-direction: column;
        // animation: move linear 10s infinite;
      }

      @keyframes move {
        0% {
          transform: translateY(0);
        }
        100% {
          transform: translateY(-520px);
        }
      }

      // &::-webkit-scrollbar-thumb {
      //   padding-left: 10px;
      //   background-color: #03BCD7;
      //   border-radius: 3px;
      // }

      .scroll-list {
        position: relative;
        width: 100%;
        height: 42px;
        margin-bottom: 10px;
        background: linear-gradient(180deg, #094273 0%, #125F9E 100%);
        border: 1px solid #017CDB;
        border-radius: 6px;
        box-sizing: border-box;

        .scroll-list-hide {
          position: absolute;
          top: -1px;
          left: 26px;
          width: calc(100% - 52px);
          height: 5px;
          background-color: #021B63;
          border: 1px solid #017CDB;
          border-top: none;
          border-top: none;
        }

        .scroll-cell {
          display: flex;
          align-items: center;
          justify-content: space-between;
          height: 38px;
          padding: 0 10px;
          box-sizing: border-box;

          .scroll-cell-text {
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            color: #01EEFD;
            font-size: 14px;
          }

          .date {
            font-size: 12px;
          }
        }
      }
    }

    .line {
      display: flex;
      justify-content: space-between;
      margin-top: 20px;
      padding-right: 20px;
      box-sizing: border-box;

      .line-item {
        display: flex;
        align-items: center;

        .line-img {
          width: 90px;
          height: 69px;
        }

        .line-info {
          display: flex;
          flex-direction: column;
          justify-content: center;
          margin-left: 7px;

          .line-info-text {
            display: flex;
            align-items: center;
            color: #828C99;
            font-size: 16px;

            .green {
              margin-left: 10px;
              margin-bottom: 2px;
              color: #44D7B6;
            }
          }

          .line-info-total {
            color: #00F2F8;
            font-size: 36px;
          }
        }
      }
    }

    .label {
      display: flex;
      align-items: center;
      width: 100%;
      height: 33px;
      margin-top: 14px;
      margin-bottom: 23px;
      padding-left: 24px;
      box-sizing: border-box;

      .label-item {
        overflow: hidden;
        position: relative;
        width: 127px;
        height: 33px;
        line-height: 33px;
        padding: 0 20px;
        text-align: center;
        background-color: #036C79;
        transform: skew(30deg);
        color: #fff;
        font-size: 18px;
        font-weight: bold;

        &::before {
          position: absolute;
          top: 0;
          left: -20px;
          content: '';
          width: 30px;
          height: 16px;
          background-color: #021B63;
          transform: skew(-50deg);
        }

        &::after {
          position: absolute;
          bottom: 0;
          right: -20px;
          content: '';
          width: 30px;
          height: 16px;
          background-color: #021B63;
          transform: skew(-50deg);
        }

        &:first-child {
          margin-right: 20px;
        }

        .span {
          transform: skew(-30deg);
        }
      }
    }

    .echarts {
      width: calc(100% - 10px);
      height: 246px;
    }
  }

  .sub-page-item1 {
    width: 100%;
    height: 460px;
    margin-top: 20px;
  }

  .sub-page-item2 {
    width: 100%;
    height: 220px;
    margin-bottom: 20px;

    .echarts1 {
      width: 100%;
      height: 160px;
    }
  }

  .sub-page-item3 {
    width: 100%;
    height: calc(100% - 480px);

    .charts {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      position: relative;
      width: 100%;
      height: calc(100% - 50px);
      margin-top: 10px;

      .charts-title {
        position: absolute;
        left: 0;
        top: 0;
        color: #fff;
        font-size: 14px;
      }

      .charts-title1 {
        left: 50%;
      }

      .charts-title2 {
        top: 52%;
      }

      .charts-item {
        width: 50%;
        height: 50%;
      }

      .charts-item1 {
        width: 100%;
        height: 50%;
      }
    }
  }

  .sub-page-item4 {
    height: 424px;

    .sub-page-box {
      display: flex;
      flex-direction: row;
      justify-content: center;
      width: 100%;
      height: calc(100% - 60px);
      margin-top: 20px;
      padding: 0 37px;
      box-sizing: border-box;

      .sub-page-column {
        width: 240px;
        height: 100%;

        .sub-page-column-item {
          width: 240px;
          height: 63px;
          margin-bottom: 22px;
          padding-left: 82px;
          box-sizing: border-box;

          .sub-page-column-text {
            display: flex;
            flex-direction: column;
            justify-content: center;
            width: calc(100% - 82px);
            height: 63px;
            color: #4AEAE1;
            font-size: 16px;

            span {
              width: 100%;
              white-space: nowrap;
            }

            .span {
              text-align: right;
            }
          }
        }

        .background1 {
          background-image: url('../../../assets/img/zhdp/overview1.png');
          background-size: 100% 100%;
          background-repeat: no-repeat;
        }

        .background2 {
          background-image: url('../../../assets/img/zhdp/overview2.png');
          background-size: 100% 100%;
          background-repeat: no-repeat;
        }

        .background3 {
          background-image: url('../../../assets/img/zhdp/overview3.png');
          background-size: 100% 100%;
          background-repeat: no-repeat;
        }

        .background4 {
          background-image: url('../../../assets/img/zhdp/overview4.png');
          background-size: 100% 100%;
          background-repeat: no-repeat;
        }

        .background5 {
          display: flex;
          flex-direction: row-reverse;
          padding-right: 82px;
          padding-left: 0;
          background-image: url('../../../assets/img/zhdp/overview5.png');
          background-size: 100% 100%;
          background-repeat: no-repeat;
        }

        .background6 {
          background-image: url('../../../assets/img/zhdp/overview6.png');
          background-size: 100% 100%;
          background-repeat: no-repeat;
        }

        .background7 {
          background-image: url('../../../assets/img/zhdp/overview7.png');
          background-size: 100% 100%;
          background-repeat: no-repeat;
        }

        .background8 {
          background-image: url('../../../assets/img/zhdp/overview8.png');
          background-size: 100% 100%;
          background-repeat: no-repeat;
        }
      }

      .overview-icon {
        width: 30px;
        height: 298px;
        margin: 0 26px;
      }

      .sub-page-charts {
        width: 160px;
        height: 100%;
        margin-left: 40px;

        .sub-page-charts-box {
          display: flex;
          align-items: center;
          width: 100%;
          height: 25%;
          margin-top: -20px;
          margin-bottom: 20px;

          .sub-page-charts-item {
            width: 90px;
            height: 100%;
          }

          .sub-page-charts-title {
            margin-left: 10px;
            color: #4AEAE1;
            font-size: 14px;
            white-space: nowrap;
          }
        }
      }
    }
  }

  .sub-page-item5 {
    position: relative;
    height: calc(100% - 444px);
    margin-top: 20px;
    padding-right: 10px;

    .title {
      position: absolute;
      top: 10px;
      left: 10px;
    }

    .sub-page-map {
      width: 100%;
      height: calc(100% - 40px);
      margin-top: 40px;
    }
  }

}
</style>
