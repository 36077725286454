<template>
  <div class="sub-page">
    <div class="sub-page-left">
      <div class="sub-page-item">
        <div class="title">
          <div class="title-top"></div>
          <div class="title-bottom">
            <div class="title-text">
              <span>数据总览</span>
            </div>
            <div class="hide"></div>
            <div class="hide hide1"></div>
            <div class="hide hide2"></div>
            <div class="hide hide3"></div>
          </div>
        </div>
        <div class="sub-content">
          <div class="sub-content-item">
            <img src="../../../assets/img/zhdp/data1.png" class="sub-content-item-img">
            <div class="sub-content-item-text">
              <span>服务机构</span>
              <span class="blue">35个 ↑</span>
            </div>
          </div>
          <div class="sub-content-item">
            <img src="../../../assets/img/zhdp/data1.png" class="sub-content-item-img">
            <div class="sub-content-item-text">
              <span>社区管家</span>
              <span class="blue">35个 ↑</span>
            </div>
          </div>
          <div class="sub-content-item">
            <img src="../../../assets/img/zhdp/data1.png" class="sub-content-item-img">
            <div class="sub-content-item-text">
              <span>机构备案</span>
              <span class="blue">35个 ↑</span>
            </div>
          </div>
          <div class="sub-content-item">
            <img src="../../../assets/img/zhdp/data2.png" class="sub-content-item-img">
            <div class="sub-content-item-text">
              <span>志愿者</span>
              <span class="blue">35个 ↑</span>
            </div>
          </div>
          <div class="sub-content-item">
            <img src="../../../assets/img/zhdp/data1.png" class="sub-content-item-img">
            <div class="sub-content-item-text">
              <span>失信机构</span>
              <span class="blue">35个 ↑</span>
            </div>
          </div>
          <div class="sub-content-item">
            <img src="../../../assets/img/zhdp/data3.png" class="sub-content-item-img">
            <div class="sub-content-item-text">
              <span>机构床位数量</span>
              <span class="blue">35个 ↑</span>
            </div>
          </div>
        </div>
      </div>

      <div class="sub-page-item">
        <div class="title">
          <div class="title-top"></div>
          <div class="title-bottom">
            <div class="title-text">
              <span>老人年龄分析</span>
            </div>
            <div class="hide"></div>
            <div class="hide hide1"></div>
            <div class="hide hide2"></div>
            <div class="hide hide3"></div>
          </div>
        </div>
        <div class="sub-table">
          <div class="sub-table-item">
            <div class="sub-table-flex sub-table-flex1">
              <span>年龄段</span>
            </div>
            <div class="sub-table-flex sub-table-flex1">
              <span>人数</span>
            </div>
            <div class="sub-table-flex sub-table-flex1 sub-table-flex2">
              <span>占比</span>
            </div>
          </div>
          <div class="sub-table-item">
            <div class="sub-table-flex">
              <span>90岁以上</span>
            </div>
            <div class="sub-table-flex">
              <span>183人</span>
            </div>
            <div class="sub-table-flex sub-table-flex2">
              <el-progress :percentage="50" color="#91CC75"></el-progress>
            </div>
          </div>
          <div class="sub-table-item">
            <div class="sub-table-flex">
              <span>80-89岁</span>
            </div>
            <div class="sub-table-flex">
              <span>183人</span>
            </div>
            <div class="sub-table-flex sub-table-flex2">
              <el-progress :percentage="50" color="#CC7E75"></el-progress>
            </div>
          </div>
          <div class="sub-table-item">
            <div class="sub-table-flex">
              <span>70-79岁</span>
            </div>
            <div class="sub-table-flex">
              <span>183人</span>
            </div>
            <div class="sub-table-flex sub-table-flex2">
              <el-progress :percentage="50" color="#FF2A2A"></el-progress>
            </div>
          </div>
          <div class="sub-table-item">
            <div class="sub-table-flex">
              <span>60-69岁</span>
            </div>
            <div class="sub-table-flex">
              <span>183人</span>
            </div>
            <div class="sub-table-flex sub-table-flex2">
              <el-progress :percentage="50" color="#8475CC"></el-progress>
            </div>
          </div>
          <div class="sub-table-item">
            <div class="sub-table-flex">
              <span>60岁以下</span>
            </div>
            <div class="sub-table-flex">
              <span>183人</span>
            </div>
            <div class="sub-table-flex sub-table-flex2">
              <el-progress :percentage="50" color="#758FCC"></el-progress>
            </div>
          </div>
        </div>
      </div>

      <div class="sub-page-item sub-page-item1">
        <div class="title">
          <div class="title-top"></div>
          <div class="title-bottom">
            <div class="title-text">
              <span class="span1">机构等级统计</span>
            </div>
            <div class="hide"></div>
            <div class="hide hide1"></div>
            <div class="hide hide2"></div>
            <div class="hide hide3"></div>
          </div>
        </div>
        <div class="sub-chart" id="chart1">
        </div>
      </div>
    </div>

    <div class="sub-page-center">
      <div class="sub-page-item sub-page-item4">
        <div class="sub-top">
          <div class="sub-top-item">
            <span>老人总数：</span>
            <span class="yellow">1238人</span>
          </div>
          <div class="sub-top-item">
            <img src="../../../assets/img/zhdp/nan.png" class="sub-top-img" />
            <span>男:1235 80%</span>
          </div>
          <div class="sub-top-item">
            <img src="../../../assets/img/zhdp/nv.png" class="sub-top-img" />
            <span>女:1235 80%</span>
          </div>
        </div>
        <div class="sub-map" ref="map">

        </div>
      </div>

      <div class="sub-page-item sub-page-item5">
        <div class="sub-item-box">
          <div class="title">
            <div class="title-top"></div>
            <div class="title-bottom">
              <div class="title-text">
                <span>机构类型统计</span>
              </div>
              <div class="hide"></div>
              <div class="hide hide1"></div>
              <div class="hide hide2"></div>
              <div class="hide hide3"></div>
            </div>
          </div>
          <div class="sub-chart" id="chart3"></div>
        </div>
        <div class="sub-item-box">
          <div class="title">
            <div class="title-top"></div>
            <div class="title-bottom">
              <div class="title-text">
                <span>设备情况统计</span>
              </div>
              <div class="hide"></div>
              <div class="hide hide1"></div>
              <div class="hide hide2"></div>
              <div class="hide hide3"></div>
            </div>
          </div>
          <div class="sub-chart" id="chart4"></div>
        </div>
      </div>
    </div>

    <div class="sub-page-right">
      <div class="sub-page-item sub-page-item2">
        <div class="title">
          <div class="title-top"></div>
          <div class="title-bottom">
            <div class="title-text">
              <span>服务排行统计</span>
            </div>
            <div class="hide"></div>
            <div class="hide hide1"></div>
            <div class="hide hide2"></div>
            <div class="hide hide3"></div>
          </div>
        </div>
        <div class="sub-content">
          <div class="sub-content-label">
            <div class="sub-content-text">
              <span>01购买无物品</span>
            </div>
            <el-progress :percentage="50" color="#91CC75" :format="format"></el-progress>
            <div class="sub-content-text">
              <span>02家电维修</span>
            </div>
            <el-progress :percentage="50" color="#CC7E75" :format="format"></el-progress>
            <div class="sub-content-text">
              <span>03卫生打扫</span>
            </div>
            <el-progress :percentage="50" color="#FF2A2A" :format="format"></el-progress>
            <div class="sub-content-text">
              <span>04做饭</span>
            </div>
            <el-progress :percentage="50" color="#8475CC" :format="format"></el-progress>
            <div class="sub-content-text">
              <span>05带就医</span>
            </div>
            <el-progress :percentage="50" color="#758FCC" :format="format"></el-progress>
          </div>
        </div>
      </div>
      <div class="sub-page-item sub-page-item2">
        <div class="title">
          <div class="title-top"></div>
          <div class="title-bottom">
            <div class="title-text">
              <span>老人补贴资金趋势</span>
            </div>
            <div class="hide"></div>
            <div class="hide hide1"></div>
            <div class="hide hide2"></div>
            <div class="hide hide3"></div>
          </div>
        </div>
        <div class="sub-chart" id="chart5"></div>
      </div>
      <div class="sub-page-item sub-page-item3">
        <div class="title">
          <div class="title-top"></div>
          <div class="title-bottom">
            <div class="title-text">
              <span>设备分类统计</span>
            </div>
            <div class="hide"></div>
            <div class="hide hide1"></div>
            <div class="hide hide2"></div>
            <div class="hide hide3"></div>
          </div>
        </div>
        <div class="sub-content">
          <div class="sub-content-item">
            <img src="../../../assets/img/zhdp/data4.png" class="sub-content-item-img">
            <div class="sub-content-item-text">
              <span>呼吸心率监测仪</span>
              <span class="blue">35个</span>
            </div>
          </div>
          <div class="sub-content-item">
            <img src="../../../assets/img/zhdp/data5.png" class="sub-content-item-img">
            <div class="sub-content-item-text">
              <span>社跌倒检测仪区管家</span>
              <span class="blue">35个</span>
            </div>
          </div>
          <div class="sub-content-item">
            <img src="../../../assets/img/zhdp/data6.png" class="sub-content-item-img">
            <div class="sub-content-item-text">
              <span>紧急呼叫器</span>
              <span class="blue">35个</span>
            </div>
          </div>
          <div class="sub-content-item">
            <img src="../../../assets/img/zhdp/data7.png" class="sub-content-item-img">
            <div class="sub-content-item-text">
              <span>人体感知监测仪</span>
              <span class="blue">35个</span>
            </div>
          </div>
          <div class="sub-content-item">
            <img src="../../../assets/img/zhdp/data8.png" class="sub-content-item-img">
            <div class="sub-content-item-text">
              <span>电磁门感</span>
              <span class="blue">35个</span>
            </div>
          </div>
          <div class="sub-content-item">
            <img src="../../../assets/img/zhdp/data9.png" class="sub-content-item-img">
            <div class="sub-content-item-text">
              <span>智能手表</span>
              <span class="blue">35个</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import sichuan from '../../../../public/static/map/qiuxian.json'
export default {
  components: {},
  data () {
    return {
      myChart1: null,
      setInterval1: null,
      datas1: [
        { value: 300, name: '一级' },
        { value: 300, name: '二级' },
        { value: 300, name: '三级' },
        { value: 300, name: '四级' },
        { value: 300, name: '五级' }
      ],
      colors1: ['#EE6666', '#FAC858', '#5EFA58', '#D458FA', '#4430FF'],
      mapChart: null,
      myChart3: null,
      setInterval3: null,
      datas3: [
        { value: 70, name: '敬老院' },
        { value: 10, name: '养老服务机构' },
        { value: 10, name: '社区服务中心' }
      ],
      colors3: ['#0E5DE1', '#CE6140', '#DD9F23'],
      myChart4: null,
      setInterval4: null,
      datas4: [
        { value: 70, name: '在线' },
        { value: 10, name: '离线' }
      ],
      colors4: ['#09ABFF', '#AAAAAA'],
      myChart5: null,
      setInterval5: null,
      startValue5: 0,
      endValue5: 3
    }
  },
  mounted () {
    this.add()
  },
  beforeDestroy () {
    this.remove()
  },
  methods: {
    format (value) {
      return value + '单'
    },
    add () {
      this.$nextTick(() => {
        this.canvas1()
        this.changeInfo1()
        this.canvas2()
        this.canvas3()
        this.canvas4()
        this.canvas5()
      })
      window.addEventListener('resize', this.getresize)
    },
    remove () {
      window.removeEventListener('resize', this.getresize)
      clearInterval(this.setInterval1)
      clearInterval(this.setInterval3)
      clearInterval(this.setInterval4)
      clearInterval(this.setInterval5)
    },
    getresize () {
      setTimeout(() => {
        this.myChart1.resize()
        this.mapChart.resize()
        this.myChart3.resize()
        this.myChart4.resize()
        this.myChart5.resize()
      }, 500)
    },
    changeInfo1 () {
      const scroll4 = document.querySelector('#chart1')
      scroll4.addEventListener('mouseenter', () => this.noUpdate1())
      scroll4.addEventListener('mouseleave', () => this.update1())
    },
    canvas1 () {
      var chartDom = document.getElementById('chart1')
      this.myChart1 = this.$echarts.init(chartDom)
      const option = {
        color: this.colors1,
        grid: {
          containLabel: true,
          top: '2%',
          right: '28%',
          bottom: '0%',
          left: '8%'
        },
        tooltip: {
          trigger: 'item'
        },
        legend: {
          show: true,
          orient: 'vertical',
          y: 'center',
          x: 'left',
          textStyle: {
            fontSize: 12,
            color: '#FFFFFF'
          }
        },
        series: [
          {
            name: '高血压',
            type: 'pie',
            radius: '50%',
            data: this.datas1,
            label: {
              show: true,
              formatter: '{b}',
              fontSize: 13,
              color: '#FFFFFF'
            }
          }
        ]
      }

      option && this.myChart1.setOption(option)
      this.update1()
    },
    update1 () {
      this.setInterval1 = setInterval(() => {
        this.datas1.unshift(this.datas1.pop())
        this.colors1.unshift(this.colors1.pop())
        this.myChart1.setOption({
          color: this.colors1,
          series: {
            data: this.datas1
          }
        })
      }, 2000)
    },
    noUpdate1 () {
      clearInterval(this.setInterval1)
    },
    canvas2 () {
      this.mapChart = this.$echarts.init(this.$refs.map)
      this.$echarts.registerMap('SC', sichuan)
      const mapOption = {
        tooltip: {
          trigger: 'item',
          formatter: '{b}<br/>警量：{c}'
        },
        geo: [
          {
            map: 'SC',
            aspectScale: 1,
            label: {
              show: false
            },
            select: {
              itemStyle: {
                areaColor: '#09ABFF'
              }
            },
            emphasis: {
              label: {
                show: true
              },
              itemStyle: {
                areaColor: '#09ABFF'
              }
            },
            regions: [
              { name: '邱县', itemStyle: { areaColor: '#20C1ED' } },
              { name: '梁二庄镇', itemStyle: { areaColor: '#158FCE' } },
              { name: '新马头镇', itemStyle: { areaColor: '#0E6BBB' } }
            ]
          }
        ],
        series: [
          {
            name: 'SC',
            type: 'map',
            geoIndex: 0,
            map: 'SC',
            textFixed: {
              Alaska: [20, -20]
            },
            data: [
              { name: '邱县', value: 34 },
              { name: '梁二庄镇', value: 35 },
              { name: '新马头镇', value: 35 }
            ]
          }
        ]
      }
      this.mapChart.setOption(mapOption)
    },
    changeInfo3 () {
      const scroll1 = document.querySelector('#chart3')
      scroll1.addEventListener('mouseenter', () => this.noUpdate3())
      scroll1.addEventListener('mouseleave', () => this.update3())
    },
    canvas3 () {
      var chartDom = document.getElementById('chart3')
      this.myChart3 = this.$echarts.init(chartDom)
      const option = {
        color: this.colors3,
        grid: {
          containLabel: true,
          top: '2%',
          right: '28%',
          bottom: '0%',
          left: '8%'
        },
        tooltip: {
          trigger: 'item',
          formatter: params => {
            return params.seriesName + '<br/>' + params.data.name + ' : ' + params.data.value.toFixed(0) + '%'
          }
        },
        legend: {
          show: false,
          orient: 'vertical',
          y: 'center',
          x: 'right',
          align: 'left',
          textStyle: {
            fontSize: 12,
            color: '#FFFFFF'
          },
          formatter: (name) => {
            const total = this.datas3.reduce(function (sum, seriesItem) {
              return sum + seriesItem.value
            }, 0)
            const target = this.datas3.find(function (seriesItem) {
              return seriesItem.name === name
            })
            const percentage = ((target.value / total) * 100).toFixed(0) + '%'
            return name + ' ' + percentage
          }
        },
        series: [
          {
            name: '各类型老人占比',
            type: 'pie',
            radius: ['40%', '70%'],
            data: this.datas3,
            label: {
              show: true,
              formatter: '{b}{c}%',
              fontSize: 13,
              color: '#FFFFFF'
            }
          }
        ]
      }

      option && this.myChart3.setOption(option)
      this.update3()
    },
    update3 () {
      this.setInterval3 = setInterval(() => {
        this.datas3.unshift(this.datas3.pop())
        this.colors3.unshift(this.colors3.pop())
        this.myChart3.setOption({
          color: this.colors3,
          series: {
            data: this.datas3
          }
        })
      }, 2000)
    },
    changeInfo4 () {
      const scroll1 = document.querySelector('#chart4')
      scroll1.addEventListener('mouseenter', () => this.noUpdate4())
      scroll1.addEventListener('mouseleave', () => this.update4())
    },
    canvas4 () {
      var chartDom = document.getElementById('chart4')
      this.myChart4 = this.$echarts.init(chartDom)
      const option = {
        color: this.colors4,
        grid: {
          containLabel: true,
          top: '2%',
          right: '28%',
          bottom: '0%',
          left: '8%'
        },
        tooltip: {
          trigger: 'item',
          formatter: params => {
            return params.seriesName + '<br/>' + params.data.name + ' : ' + params.data.value.toFixed(0) + '%'
          }
        },
        legend: {
          show: false,
          orient: 'vertical',
          y: 'center',
          x: 'right',
          align: 'left',
          textStyle: {
            fontSize: 12,
            color: '#FFFFFF'
          },
          formatter: (name) => {
            const total = this.datas4.reduce(function (sum, seriesItem) {
              return sum + seriesItem.value
            }, 0)
            const target = this.datas4.find(function (seriesItem) {
              return seriesItem.name === name
            })
            const percentage = ((target.value / total) * 100).toFixed(0) + '%'
            return name + ' ' + percentage
          }
        },
        series: [
          {
            name: '各类型老人占比',
            type: 'pie',
            radius: ['40%', '70%'],
            data: this.datas4,
            label: {
              show: true,
              formatter: '{b}{c}%',
              fontSize: 13,
              color: '#FFFFFF'
            }
          }
        ]
      }

      option && this.myChart4.setOption(option)
      this.update4()
    },
    update4 () {
      this.setInterval4 = setInterval(() => {
        this.datas4.unshift(this.datas4.pop())
        this.colors4.unshift(this.colors4.pop())
        this.myChart4.setOption({
          color: this.colors4,
          series: {
            data: this.datas4
          }
        })
      }, 2000)
    },
    canvas5 () {
      const chartDom = document.getElementById('chart5')
      this.myChart5 = this.$echarts.init(chartDom)
      const option = {
        color: ['#91CC75'],
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'shadow'
          }
        },
        legend: {
          show: false,
          orient: 'horizontal',
          // x: '80%',
          y: 'top',
          x: 'center',
          textStyle: {
            fontSize: 12,
            color: '#FFFFFF'
          }
          // padding: [25, 34, 0, 0]
        },
        dataZoom: [
          {
            show: false,
            startValue: this.startValue5,
            endValue: this.endValue5
          }
        ],
        grid: {
          show: false,
          top: '15%', // 一下数值可为百分比也可为具体像素值
          right: '4%',
          bottom: '10%',
          left: '15%'
        },
        xAxis: {
          type: 'category',
          data: ['1月', '2月', '3月', '4月', '5月', '6月', '7月', '8月', '9月', '10月', '11月', '12月'],
          axisLabel: {
            show: true,
            color: '#fff',
            fontSize: 13,
            interval: 0
          },
          axisLine: {
            show: true,
            lineStyle: {
              color: '#fff'
            },
            textStyle: {
              color: '##91CC75',
              fontSize: 13
            }
          },
          axisTick: {
            // y轴刻度线
            show: false
          }
        },
        yAxis: {
          type: 'value',
          axisLine: {
            show: true,
            lineStyle: {
              color: '#fff'
            }
          },
          axisLabel: {
            show: true,
            color: '#fff',
            fontSize: 14,
            formatter: '{value} kg'
          },
          splitLine: {
            lineStyle: {
              color: '#fff'
            }
          },
          splitArea: {
            show: true,
            areaStyle: {
              color: ['rgba(0,0,0,0)', 'rgba(172, 172, 172, .07)']
            }
          }
        },
        series: [
          {
            name: '老人补贴资金趋势',
            data: [120, 200, 150, 80, 70, 110, 110, 200, 150, 80, 70, 110],
            type: 'bar',
            barWidth: '19'
          }
        ]
      }

      option && this.myChart5.setOption(option)
      this.update5()
    },
    update5 () {
      this.setInterval5 = setInterval(() => {
        this.startValue5++
        this.endValue5++
        if (this.endValue5 > 11) {
          this.startValue5 = 0
          this.endValue5 = 3
        }
        this.myChart5.setOption({
          dataZoom: [
            {
              show: false,
              startValue: this.startValue5,
              endValue: this.endValue5
            }
          ]
        })
      }, 2000)
    }
  }
}
</script>

<style lang="less" scoped>
.sub-page {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 100%;
  background-image: url('../../../assets/img/zhdp/background.png');
  background-size: 100% 100%;
  background-repeat: no-repeat;

  .sub-page-left {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 506px;
    height: 100%;
  }

  .sub-page-center {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: calc(100% - 1032px);
    height: 100%;

  }

  .sub-page-right {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 506px;
    height: 100%;
  }

  .sub-page-item {
    position: relative;
    overflow: hidden;
    width: 100%;
    height: 33%;
    padding: 10px;
    padding-right: 0;
    background-color: rgba(2, 27, 99, .6);
    box-sizing: border-box;

    .title {
      height: 36px;

      .title-top {
        position: relative;
        width: 87px;
        height: 6px;
        background-color: #03BCD7;
        transform: skew(30deg);

        &::before {
          position: absolute;
          top: 0;
          left: -4px;
          content: '';
          width: 10px;
          height: 6px;
          // background-color: rgba(2, 27, 99, .6);
          transform: skew(-50deg);
        }
      }

      .title-bottom {
        position: relative;
        width: 400px;
        height: 30px;
        padding-left: 14px;
        transform: skew(30deg);
        background: linear-gradient(97deg, #0177CD 0%, #024073 100%);
        box-sizing: border-box;

        &::before {
          position: absolute;
          top: 0;
          left: -8px;
          content: '';
          width: 12px;
          height: 10px;
          // background-color: rgba(2, 27, 99);
          transform: skew(-58deg);
        }

        .title-text {
          height: 30px;
          line-height: 30px;
          color: #fff;
          font-size: 18px;
          font-weight: bold;
          transform: skew(-30deg);

          .span {
            margin-left: 16px;
            font-size: 14px;
          }

          .span1 {
            cursor: pointer;
          }
        }

        .hide {
          position: absolute;
          top: 0;
          right: 34px;
          width: 14px;
          height: 30px;
          background-color: #021B63;
        }

        .hide1 {
          width: 6px;
          right: 24px;
        }

        .hide2 {
          width: 6px;
          right: 14px;
        }

        .hide3 {
          width: 6px;
          right: 4px;
        }
      }
    }

    .sub-content {
      display: flex;
      flex-wrap: wrap;
      align-content: space-evenly;
      width: 100%;
      height: calc(100% - 36px);

      .sub-content-item {
        display: flex;
        align-items: center;
        width: 33%;

        .sub-content-item-img {
          width: 52px;
          height: 46px;
        }

        .sub-content-item-text {
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          height: 46px;
          margin-left: 6px;
          color: #fff;
          font-size: 14px;

          .blue {
            color: #0176CB;
            font-size: 14px;
          }
        }
      }

      .sub-content-label {
        display: flex;
        flex-direction: column;
        width: calc(100% - 37px);

        .sub-content-text {
          margin: 5px 0;
          color: #fff;
          font-size: 12px;
        }
      }
    }

    .sub-table {
      width: 100%;
      height: calc(100% - 36px);

      .sub-table-item {
        display: flex;
        align-items: center;
        width: 100%;
        margin: 10px 0;

        .sub-table-flex {
          flex: 1;
          color: #fff;
          font-size: 12px;
        }

        .sub-table-flex1 {
          font-size: 16px;
        }

        .sub-table-flex2 {
          flex: 3;
        }
      }
    }

    /deep/.el-progress__text {
      color: #fff;
    }

    /deep/.el-progress-bar__outer {
      height: 16px !important;
      border-radius: 0;
    }

    /deep/.el-progress-bar__inner {
      border-radius: 0;
    }

    .sub-chart {
      width: 100%;
      height: calc(100% - 36px);
    }

    .sub-top {
      display: flex;
      justify-content: center;
      width: 100%;
      height: 80px;

      .sub-top-item {
        display: flex;
        align-items: center;
        margin: 0 16px;
        color: #fff;
        font-size: 20px;

        .sub-top-img {
          width: 32px;
          height: 40px;
          margin-right: 10px;
        }

        .yellow {
          color: #FFE602;
        }
      }
    }

    .sub-map {
      width: 100%;
      height: calc(100% - 80px);
    }

    .sub-item-box {
      overflow: hidden;
      width: calc(50% - 5px);
      height: 100%;
      padding: 10px;
      background-color: rgba(2, 27, 99, .6);
      box-sizing: border-box;
    }
  }

  .sub-page-item1 {
    width: 100%;
    height: 33%;
  }

  .sub-page-item2 {
    width: 100%;
    height: 33%;
  }

  .sub-page-item3 {
    width: 100%;
    height: 33%;
  }

  .sub-page-item4 {
    width: 100%;
    height: 66.6%;
  }

  .sub-page-item5 {
    display: flex;
    justify-content: space-between;
    height: 33%;
    padding: 0;
    background-color: transparent;
  }

}
</style>
