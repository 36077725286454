<template>
  <div class="sub">
    <div class="sub-left">
      <div class="sub-left-item">
        <div class="sub-title">
          <span>B超部位分析</span>
        </div>
        <div class="sub-box">
          <div class="sub-chart" id="echarts3"></div>
        </div>
      </div>
      <div class="sub-left-item">
        <div class="sub-title">
          <span>B超类型占比</span>
        </div>
        <div class="sub-box">
          <div class="sub-chart" id="echarts1"></div>
        </div>
      </div>
      <div class="sub-left-item1">
        <div class="sub-title">
          <span>B超分辨率统计</span>
        </div>
        <div class="sub-box" id="echarts2">
        </div>
      </div>
    </div>
    <div class="sub-center">
      <div class="sub-top">
        <div class="sub-title">
          <span>近一个月申请趋势</span>
        </div>
        <div class="sub-map" id="echarts5"></div>
      </div>
      <div class="sub-bottom">
        <div class="sub-title">
          <span>本年数据趋势</span>
        </div>
        <div class="sub-bottom-chart" id="echarts4"></div>
      </div>
    </div>
    <div class="sub-right">
      <div class="sub-left-item">
        <div class="sub-title">
          <span>申请分析</span>
        </div>
        <div class="sub-box" id="echarts7"></div>
      </div>
      <div class="sub-left-item">
        <div class="sub-title">
          <span>检查项目分析</span>
        </div>
        <div class="sub-box" id="echarts8"></div>
      </div>

      <div class="sub-left-item">
        <div class="sub-title">
          <span>疾病排行</span>
        </div>
        <div class="sub-box" id="echarts6"></div>
      </div>
    </div>
  </div>
</template>

<script>
// import geoJson from './hk.json'
export default {
  data () {
    return {
      tabIndex1: 0,
      tabIndex2: 0,
      tabIndex3: 0,
      tabIndex4: 0,
      tabIndex5: 0,
      tabIndex6: 0,
      myChart1: null,
      myChart2: null,
      myChart3: null,
      myChart4: null,
      myChart5: null,
      myChart6: null,
      myChart7: null,
      myChart8: null,
      list: [
        { name: '乌审旗', value: 20057.34, itemStyle: { color: '#eb8146' } }
      ]
    }
  },
  mounted () {
    this.$nextTick(() => {
      this.canvas1()
      this.canvas2()
      this.canvas3()
      this.canvas4()
      this.canvas5()
      this.canvas6()
      this.canvas7()
      this.canvas8()
    })
    window.addEventListener('resize', this.getresize)
  },
  beforeDestroy () {
    // 页面销毁时销毁监听事件
    window.removeEventListener('resize', this.getresize)
  },
  methods: {
    tabClick1 (index) {
      this.tabIndex1 = index
    },
    tabClick2 (index) {
      this.tabIndex2 = index
    },
    tabClick3 (index) {
      this.tabIndex3 = index
    },
    tabClick4 (index) {
      this.tabIndex4 = index
    },
    tabClick5 (index) {
      this.tabIndex5 = index
    },
    tabClick6 (index) {
      this.tabIndex6 = index
    },
    async getData () {
      const { data: res } = await this.$http.get('https://echarts.apache.org/examples/data/asset/geo/HK.json')
      console.log('res', res)
    },
    getresize () {
      setTimeout(() => {
        this.myChart1.resize()
        this.myChart2.resize()
        this.myChart3.resize()
        this.myChart4.resize()
        this.myChart5.resize()
        this.myChart6.resize()
        this.myChart7.resize()
        this.myChart8.resize()
      }, 500)
    },
    canvas1 () {
      var chartDom = document.getElementById('echarts1')
      this.myChart1 = this.$echarts.init(chartDom)
      const seriesData = [
        { value: 10, name: '普通B超' },
        { value: 10, name: '腔内B超' },
        { value: 10, name: '三维B超' },
        { value: 10, name: '四维B超' }
      ]
      const option = {
        // color: ['#56EA8C', '#14AFE1', '#FF9302', '#5A72A2'],
        tooltip: {
          trigger: 'item',
          formatter: (params) => {
            return params.name + ' ' + params.value + '%'
          }
        },
        legend: {
          show: true,
          top: '5%',
          left: 'right',
          orient: 'vertical',
          align: 'left',
          textStyle: {
            color: '#fff'
          },
          data: seriesData.map(function (item) {
            return item.name // 图例名称
          }),
          formatter: function (name) {
            // 查找对应系列数据
            var series = seriesData.find(function (item) {
              return item.name === name
            })
            // 返回自定义图例文本
            return series ? name + ' ' + series.value + '%' : name
          }
        },
        grid: {
          show: false,
          top: '10%', // 一下数值可为百分比也可为具体像素值
          right: '10%',
          bottom: '10%',
          left: '20%'
        },
        series: [
          {
            name: '申请科室占比',
            type: 'pie',
            radius: ['70%', '40%'],
            data: [
              { value: 10, name: '普通B超' },
              { value: 10, name: '腔内B超' },
              { value: 10, name: '三维B超' },
              { value: 10, name: '四维B超' }
            ],
            center: ['30%', '50%'],
            label: {
              show: false,
              color: '#fff',
              formatter: (params) => {
                return params.name + ' ' + params.value + '%'
              }
            }
          }
        ]
      }

      option && this.myChart1.setOption(option)
    },
    canvas2 () {
      var chartDom = document.getElementById('echarts2')
      this.myChart2 = this.$echarts.init(chartDom)
      const option = {
        color: ['#09ABFF', '#AAAAAA'],
        tooltip: {
          trigger: 'item',
          formatter: (params) => {
            return params.name + ' ' + params.value + '%'
          }
        },
        legend: {
          show: true,
          top: '5%',
          left: 'right',
          orient: 'vertical',
          align: 'left',
          textStyle: {
            color: '#fff'
          }
        },
        grid: {
          show: false,
          top: '10%', // 一下数值可为百分比也可为具体像素值
          right: '10%',
          bottom: '10%',
          left: '20%'
        },
        series: [
          {
            name: '申请科室占比',
            type: 'pie',
            radius: ['40%', '30%'],
            data: [
              { value: 70, name: '彩色B超' },
              { value: 30, name: '腔内B超' }
            ],
            center: ['60%', '50%'],
            label: {
              show: true,
              color: '#fff',
              formatter: (params) => {
                return params.name + ' ' + params.value + '%'
              }
            }
          }
        ]
      }

      option && this.myChart2.setOption(option)
    },
    canvas3 () {
      var chartDom = document.getElementById('echarts3')
      this.myChart3 = this.$echarts.init(chartDom)
      // this.$echarts.registerMap('HK', geoJson)
      const option = {
        color: ['#0037E4', '#CB12CA'],
        title: {
          text: '单位：次',
          top: 10,
          left: 10,
          textStyle: {
            color: '#fff',
            fontSize: 12
          }
        },
        tooltip: {
          trigger: 'item'
        },
        legend: {
          show: true,
          left: 'right',
          textStyle: {
            color: '#fff'
          }
        },
        grid: {
          show: true,
          top: '20%',
          right: '2%',
          bottom: '10%',
          left: '10%'
        },
        yAxis: {
          type: 'value',
          axisLabel: {
            color: '#fff'
          },
          splitLine: {
            show: false
          }
        },
        xAxis: {
          type: 'category',
          data: ['腹部', '妇科', '泌尿', '体表肿物及病变', '心脏及四肢血管'],
          axisLabel: {
            color: '#fff',
            formatter: function (value) {
              return value.length > 3 ? value.slice(0, 3) + '...' : value
            }
          }
        },
        series: [
          {
            name: '已完成',
            type: 'bar',
            barWidth: '8',
            data: [13168, 13168, 13168, 13168, 13168],
            label: {
              show: false
            }
          },
          {
            name: '未完成',
            type: 'bar',
            barWidth: '8',
            data: [13168, 13168, 13168, 13168, 13168],
            label: {
              show: false
            }
          }
        ]
      }

      option && this.myChart3.setOption(option)
    },
    canvas4 () {
      var chartDom = document.getElementById('echarts4')
      this.myChart4 = this.$echarts.init(chartDom)
      const option = {
        color: ['#0037E4', '#CB12CA', '#E4D400'],
        title: {
          text: '单位：次',
          top: 10,
          left: 10,
          textStyle: {
            color: '#fff',
            fontSize: 12
          }
        },
        tooltip: {
          trigger: 'item'
        },
        legend: {
          show: true,
          left: 'right',
          textStyle: {
            color: '#fff'
          }
        },
        grid: {
          show: true,
          top: '20%',
          right: '10%',
          bottom: '10%',
          left: '10%'
        },
        yAxis: {
          type: 'value',
          axisLabel: {
            color: '#fff'
          },
          splitLine: {
            show: false
          }
        },
        xAxis: {
          type: 'category',
          data: ['1月', '2月', '3月', '4月', '5月', '6月', '7月', '8月', '9月', '10月', '11月', '12月'],
          axisLabel: {
            color: '#fff'
          }
        },
        series: [
          {
            name: '申请数',
            type: 'bar',
            barWidth: '10',
            data: [13168, 13168, 13168, 13168, 13168, 13168, 13168, 13168, 13168],
            label: {
              show: false
            }
          },
          {
            name: '完成数',
            type: 'bar',
            barWidth: '10',
            data: [13168, 13168, 13168, 13168, 13168, 13168, 13168, 13168, 13168],
            label: {
              show: false
            }
          },
          {
            name: '完成率',
            type: 'line',
            barWidth: '10',
            data: [13168, 13168, 13168, 13168, 13168, 13168, 13168, 13168, 13168],
            label: {
              show: false
            }
          }
        ]
      }

      option && this.myChart4.setOption(option)
    },
    canvas5 () {
      var chartDom = document.getElementById('echarts5')
      this.myChart5 = this.$echarts.init(chartDom)
      const option = {
        color: ['#E4D400', '#CB12CA'],
        title: {
          text: '单位：次',
          top: 10,
          left: 10,
          textStyle: {
            color: '#fff',
            fontSize: 12
          }
        },
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'cross',
            label: {
              backgroundColor: '#6a7985'
            }
          }
        },
        legend: {
          show: false,
          left: 'right',
          textStyle: {
            color: '#fff'
          }
        },
        grid: {
          left: '3%',
          right: '4%',
          bottom: '3%',
          containLabel: true
        },
        xAxis: [
          {
            type: 'category',
            boundaryGap: false,
            data: ['6-26', '6-29', '7-02', '7-05', '7-08', '7-11', '7-14', '7-17', '7-20', '7-23', '7-26'],
            axisLabel: {
              color: '#fff'
            },
            axisLine: {
              show: true,
              lineStyle: {
                color: '#fff'
              }
            }
          }
        ],
        yAxis: [
          {
            type: 'value',
            axisLabel: {
              color: '#fff'
            },
            axisLine: {
              show: true,
              lineStyle: {
                color: '#fff'
              }
            }
          }
        ],
        series: [
          {
            name: '完成',
            type: 'line',
            smooth: true,
            data: [120, 132, 101, 134, 90, 230, 210, 101, 134, 90, 230, 210]
          }
        ]
      }

      option && this.myChart5.setOption(option)
    },
    canvas6 () {
      var chartDom = document.getElementById('echarts6')
      this.myChart6 = this.$echarts.init(chartDom)
      const option = {
        grid: {
          containLabel: true,
          top: '15%', // 一下数值可为百分比也可为具体像素值
          right: '21%',
          bottom: '0%',
          left: '8%'
        },
        label: {
          show: true,
          color: '#fff',
          fontSize: 14,
          position: 'right',
          height: 100,
          formatter: (params) => {
            return params.value + '次'
          }
        },
        xAxis: [
          // x轴数据设置
          {
            type: 'value',
            splitLine: {
              show: false
            },
            axisLine: {
              lineStyle: {
                color: 'rgba(0,0,0,0)'
              }
            }
          }
        ],
        yAxis: [
          {
            type: 'category',
            offset: 0,
            axisLine: {
              show: false,
              // 这是x轴文字颜色
              lineStyle: {
                color: '#fff'
              }
            },
            data: ['前列腺', '甲状腺', '腺瘤', '卵巢囊肿', '子宫肌瘤'],
            axisTick: {
              show: false
            }
          }
        ],
        series: [
          {
            data: [200, 200, 200, 200, 200],
            type: 'bar',
            barWidth: 12,
            label: {
              show: true
            },
            itemStyle: {
              color: params => {
                const colors = [
                  ['#E4D400', '#E4D400'],
                  ['#E40035', '#E40035'],
                  ['#CD6701', '#CD6701'],
                  ['#01CD0F', '#01CD0F'],
                  ['#0177CD', '#0177CD']
                ]
                const colorItem = colors[params.dataIndex]
                return new this.$echarts.graphic.LinearGradient(1, 0, 0, 0, [{
                  offset: 0,
                  color: colorItem[1]
                }, {
                  offset: 1,
                  color: colorItem[0]
                }])
              },
              borderRadius: [10, 10, 10, 10]
            },
            showBackground: true,
            backgroundStyle: {
              color: '#fff',
              borderRadius: [10, 10, 10, 10]
            }
          }
        ]
      }

      option && this.myChart6.setOption(option)
    },
    canvas7 () {
      var chartDom = document.getElementById('echarts7')
      this.myChart7 = this.$echarts.init(chartDom)
      const option = {
        color: ['#0037E4', '#CB12CA', '#18CDFD'],
        tooltip: {
          trigger: 'item'
        },
        legend: {
          show: true,
          left: 'right',
          textStyle: {
            color: '#fff'
          }
        },
        grid: {
          show: true,
          top: '20%',
          right: '2%',
          bottom: '10%',
          left: '20%'
        },
        yAxis: {
          type: 'value',
          axisLabel: {
            color: '#fff'
          },
          splitLine: {
            show: false
          }
        },
        xAxis: {
          type: 'category',
          data: ['申请数', '完成数', '完成率'],
          axisLabel: {
            color: '#fff',
            formatter: function (value) {
              return value.length > 3 ? value.slice(0, 3) + '...' : value
            }
          }
        },
        series: [
          {
            name: '已完成',
            type: 'bar',
            barWidth: '8',
            data: [13168, 13168, 13168, 13168, 13168],
            label: {
              show: false
            }
          },
          {
            name: '未完成',
            type: 'bar',
            barWidth: '8',
            data: [13168, 13168, 13168, 13168, 13168],
            label: {
              show: false
            }
          },
          {
            name: '完成率',
            type: 'bar',
            barWidth: '8',
            data: [13168, 13168, 13168, 13168, 13168],
            label: {
              show: false
            }
          }
        ]
      }

      option && this.myChart7.setOption(option)
    },
    canvas8 () {
      var chartDom = document.getElementById('echarts8')
      this.myChart8 = this.$echarts.init(chartDom)
      // this.$echarts.registerMap('HK', geoJson)
      const option = {
        title: {
          text: '单位：次',
          top: 10,
          left: 10,
          textStyle: {
            color: '#fff',
            fontSize: 12
          }
        },
        tooltip: {
          trigger: 'item'
        },
        legend: {
          show: false,
          left: 'right',
          textStyle: {
            color: '#fff'
          }
        },
        grid: {
          show: true,
          top: '20%',
          right: '2%',
          bottom: '10%',
          left: '20%'
        },
        yAxis: {
          type: 'value',
          axisLabel: {
            color: '#fff'
          },
          splitLine: {
            show: false
          }
        },
        xAxis: {
          type: 'category',
          data: ['产科', '前列腺', '盆腔和阴道', '甲状腺', '胆囊胆道'],
          axisLabel: {
            color: '#fff',
            formatter: function (value) {
              return value.length > 3 ? value.slice(0, 3) + '...' : value
            }
          }
        },
        series: [
          {
            name: '已完成',
            type: 'bar',
            barWidth: '8',
            data: [
              { value: 100, itemStyle: { color: '#0037E4' } },
              { value: 100, itemStyle: { color: '#E4D400' } },
              { value: 100, itemStyle: { color: '#00E444' } },
              { value: 100, itemStyle: { color: '#E40035' } },
              { value: 100, itemStyle: { color: '#CA7520' } }
            ],
            label: {
              show: false
            }
          }
        ]
      }

      option && this.myChart8.setOption(option)
    }
  }
}
</script>

<style lang="less" scoped>
.sub {
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: 100%;
  background-image: url('../../../assets/img/zk1.png');
  background-size: 100% 100%;
  background-repeat: no-repeat;

  .sub-left {
    width: 25%;
    height: 100%;

    .sub-left-item {
      width: 100%;
      height: calc(30% - 10px);
      margin-bottom: 10px;
      padding: 5px;
      background-color: rgba(2, 27, 99, .6);
      box-sizing: border-box;
    }

    .sub-left-item1 {
      width: 100%;
      height: 40%;
      background-color: rgba(2, 27, 99, .6);
    }

    .sub-title {
      width: 70%;
      height: 35px;
      line-height: 40px;
      padding-left: 30px;
      background-image: url('../../../assets/img/zk2.png');
      background-size: 100% 100%;
      background-repeat: no-repeat;
      box-sizing: border-box;
      color: #fff;
      font-size: 20px;
      font-weight: bold;
    }

    .sub-box {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      width: 100%;
      height: calc(100% - 35px);
      padding: 0 20px;
      box-sizing: border-box;

      .sub-box-item {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 33.3%;
        color: #fff;
        font-size: 18px;

        .blue {
          color: #18CDFD;
        }
      }

      .sub-chart {
        width: 100%;
        height: 100%;
      }
    }
  }

  .sub-center {
    width: calc(50% - 20px);
    height: 100%;

    .sub-top {
      width: 100%;
      height: calc(60% - 10px);
      margin-bottom: 10px;
      padding: 10px 26px;
      background-color: rgba(2, 27, 99, .6);
      box-sizing: border-box;

      .sub-title {
        width: 40%;
        height: 35px;
        line-height: 40px;
        padding-left: 30px;
        background-image: url('../../../assets/img/zk2.png');
        background-size: 100% 100%;
        background-repeat: no-repeat;
        box-sizing: border-box;
        color: #fff;
        font-size: 20px;
        font-weight: bold;
      }

      .sub-map {
        width: 100%;
        height: calc(100% - 40px);
      }
    }

    .sub-bottom {
      width: 100%;
      height: 40%;
      padding: 5px;
      background-color: rgba(2, 27, 99, .6);
      box-sizing: border-box;

      .sub-title {
        width: 40%;
        height: 35px;
        line-height: 40px;
        padding-left: 30px;
        background-image: url('../../../assets/img/zk2.png');
        background-size: 100% 100%;
        background-repeat: no-repeat;
        box-sizing: border-box;
        color: #fff;
        font-size: 20px;
        font-weight: bold;
      }

      .sub-bottom-chart {
        width: 100%;
        height: calc(100% - 40px);
      }
    }
  }

  .sub-right {
    width: 25%;
    height: 100%;

    .sub-left-item {
      width: 100%;
      height: calc(33.333% - 10px);
      margin-bottom: 10px;
      padding: 5px;
      background-color: rgba(2, 27, 99, .6);
      box-sizing: border-box;

      .sub-title {
        width: 70%;
        height: 35px;
        line-height: 40px;
        padding-left: 30px;
        background-image: url('../../../assets/img/zk2.png');
        background-size: 100% 100%;
        background-repeat: no-repeat;
        box-sizing: border-box;
        color: #fff;
        font-size: 20px;
        font-weight: bold;
      }

      .sub-text {
        display: flex;
        align-items: center;
        width: 100%;
        height: 50px;
        padding-left: 20px;
        box-sizing: border-box;

        .sub-text-item {
          margin-right: 20px;
          color: #fff;
          font-size: 16px;
        }

        .yellow {
          color: #DD9F23;
        }

        .blue {
          color: #03BCD7;
        }
      }

      .sub-table {
        display: flex;
        width: 100%;
        height: calc(100% - 85px);

        .sub-table-left {
          width: calc(60% - 10px);
          height: 100%;
          margin-right: 10px;
          background-color: rgba(3, 188, 215, .1);
        }

        .sub-table-right {
          width: 40%;
          height: 100%;
          margin-right: 10px;
          background-color: rgba(3, 188, 215, .1);
        }

        .sub-table-head {
          width: 100%;
          height: 30px;
          line-height: 30px;
          text-align: center;
          background-color: rgba(3, 188, 215, .3);
          color: #fff;
          font-size: 14px;
        }

        .sub-table-box {
          overflow-y: scroll;
          width: 100%;
          height: calc(100% - 60px);

          &::-webkit-scrollbar {
            width: 0;
          }
        }

        .sub-table-item {
          display: flex;
          align-items: center;
          width: 100%;
          color: #fff;
          font-size: 14px;

          >span {
            flex: 1;
            text-align: center;
          }
        }

        .sub-table-item1 {
          height: 30px;
        }
      }

      .sub-chart {
        display: flex;
        width: 100%;
        height: calc(100% - 115px);

        .sub-chart-item {
          width: 50%;
          height: 100%;
        }
      }

      .sub-info {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-evenly;
        width: 100%;
        height: 80px;

        .sub-info-item {
          display: flex;
          align-items: center;
        }

        .sub-info-img {
          width: 18px;
          height: 49px;
        }

        .sub-info-box {
          display: flex;
          flex-direction: column;
          margin-left: 7px;
          color: #fff;
          font-size: 16px;
        }
      }

      .sub-box {
        display: flex;
        align-items: center;
        justify-content: space-evenly;
        width: 100%;
        height: calc(100% - 35px);

        .sub-box-item {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          color: #fff;
          font-size: 16px;

          .sub-box-img {
            width: 47px;
            height: 47px;
          }

          .span {
            margin-top: 10px;
            margin-bottom: 10px;
          }
        }

        .sub-box-item:nth-child(2) .sub-box-img {
            width: 34px;
          }

        .sub-box-item:nth-child(3) .sub-box-img {
          width: 60px;
          height: 38px;
        }

      }

      .sub-label {
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        width: 100%;
        height: calc(100% - 35px);

        .sub-label-item {
          width: 100%;
          box-sizing: border-box;
        }

        .sub-label-item:nth-child(1) {
          color: #91CC75;
          font-size: 38px;
          padding-left: 20%;
        }

        .sub-label-item:nth-child(2) {
          text-align: right;
          color: #CD6701;
          font-size: 30px;
          padding-right: 25%;
        }

        .sub-label-item:nth-child(3) {
          color: #03BCD7;
          font-size: 30px;
          padding-left: 21%;
        }

        .sub-label-item:nth-child(4) {
          text-align: right;
          color: #5EFA58;
          font-size: 38px;
          padding-right: 25%;
        }

        .sub-label-item:nth-child(5) {
          color: #E40035;
          font-size: 38px;
          padding-left: 22%;
        }
      }
    }
  }
}

// .sub-position {
//     position: absolute;
//     top: 50%;
//     left: 50%;
//     min-width: 300px;
//     padding: 10px 15px;
//     background-color: #024B85;
//     border: 1px solid #03BCD7;

//     .sub-position-line {
//       margin: 10px 0;
//       color: #fff;
//       font-size: 16px;
//     }

//     .sub-position-line1 {
//       color: #03BCD7;
//     }

//     .sub-position-label {
//       width: 110px;
//       margin: 10px 0;
//       padding: 4px 8px;
//       text-align: center;
//       border-radius: 6px;
//       background-color: #CD0A0A;
//       color: #fff;
//       font-size: 14px;
//     }
//   }
</style>
